import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { ECurrency } from "@base/enums";
import { ISearchContent } from "@base/modules/allPageSearch/allPageSearch.interface";
import { ISiteSpecialPackage } from "@base/modules/siteSpecialPackage/interface/ISiteSpecialPackage";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import CardPackageService from "@components/CommonStyles/CardPackageService";
import { Divider } from "antd";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function ServiceSearch({ data }: { data?: ISearchContent<ISiteSpecialPackage> }) {
  const navigate = useNavigate();

  const onViewAll = () => {
    navigate(baseRoutes.bookingPackage);
  };

  const handleBooking = (data?: ISiteSpecialPackage) => {
    navigate({
      pathname: `${baseRoutes.bookingPackage}/${data?.id}${baseRoutes.paymentPackageService}`,
      search: createSearchParams({
        site_code: String(data?.site.code),
        package_code: String(data?.code),
      }).toString(),
    });
  };

  const handleDetail = (data?: ISiteSpecialPackage) => {
    navigate({ pathname: `${baseRoutes.bookingPackage}/${data?.slug}` });
  };

  const CardService = ({ item }: { item?: ISiteSpecialPackage }) => {
    return (
      <div className="p-6 max-md:p-2 bg-common-white rounded-2xl">
        <div className="flex gap-3 items-start">
          <div className="flex-none w-[188px] h-[126px] max-xl:w-[132px] max-xl:h-[89px] max-md:w-[94px] max-md:h-[63px]">
            <img
              className="rounded-lg h-full aspect-video object-fill"
              src={item?.thumbnail}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = CommonImages.DrafPackage;
              }}
            />
          </div>
          <div className="flex flex-col gap-3 max-xl:gap-1">
            <CommonStyles.TypographyUI use="title" className="!text-sm md:!text-base lg:!text-xl !font-medium line-clamp-1 !mb-0">
              {item?.name}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI
              use="paragraph"
              className="text-common-subtext text-xs md:text-sm lg:text-base line-clamp-2 max-xl:line-clamp-1 !mb-0"
            >
              {item?.description}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="!text-sm md:!text-base lg:!text-xl font-medium text-common-inprogress !mb-0">
              {item?.amount.toLocaleString()} {ECurrency.VND}
            </CommonStyles.TypographyUI>
          </div>
        </div>

        <Divider className="max-xl:my-2" />

        <div className="flex gap-1">
          <CommonIcons.IconLocation />
          <div className="flex flex-col gap-3 max-xl:gap-1">
            <CommonStyles.TypographyUI use="paragraph" className="!text-sm md:!text-base lg:!text-xl font-medium !mb-0">
              {item?.site.name}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-xs md:text-sm lg:text-base !mb-0">
              {item?.site.address}
            </CommonStyles.TypographyUI>
          </div>
        </div>

        <Divider className="my-5 max-xl:my-3" />

        <div className="flex items-center gap-6">
          <CommonStyles.ButtonUI className="lg:h-10 font-medium" onClick={() => handleBooking(item)}>
            Mua ngay
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI
            className="lg:h-10 font-medium bg-common-orange100 text-common-mainColor hover:!bg-common-orange200 hover:!text-common-mainColor"
            onClick={() => handleDetail(item)}
          >
            Xem chi tiết
          </CommonStyles.ButtonUI>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext !font-medium text-sm lg:text-lg xl:text-xl !m-0">
            Gói dịch vụ
          </CommonStyles.TypographyUI>
          <div className="min-w-6 min-h-6 bg-common-negative rounded-full text-xs text-common-white flex justify-center items-center">
            {data?.total_records}
          </div>
        </div>

        <CommonStyles.ButtonUI ghost className="h-12 font-medium text-xs md:text-sm lg:text-base max-md:!p-2 max-md:h-fit" onClick={onViewAll}>
          Xem tất cả
        </CommonStyles.ButtonUI>
      </div>

      <div className="flex flex-col gap-4">
        {data?.content_page?.map((item: ISiteSpecialPackage, index: number) => {
          return <CardService key={index} item={item} />;
        })}
      </div>
    </div>
  );
}
