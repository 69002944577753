import { useEffect, useState } from "react";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EDrugOrderStatus } from "@base/enums";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import profileService from "@base/modules/user/profile/profileService";
import { ISpecialPackage } from "@base/modules/patientSpecialPackage/patientSpecialPackage.interface";
import PrescriptionListItem from "./PrescriptionListItem";
import { IDrugOrderFilter } from "@base/modules/drugOrder/drugOrderFilter.interface";
import useGetAllByPatientRecordCode from "@base/modules/drugOrder/hooks/useGetAllByPatientRecordCode";
import { Divider } from "antd";
import { IDrugOrder } from "@base/modules/drugOrder/drugOrder.interface";

export default function PrescriptionList() {
  //! state
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [patientRecords, setPatientRecords] = useState<IPatientRecordData[]>([]);

  const [filter, setFilter] = useState<IDrugOrderFilter>({
    page_number: 0,
    page_size: 10,
    lang: "vi",
  });
  const { isLoading, data, error, refetch } = useGetAllByPatientRecordCode(filter, false);

  //! function
  useEffect(() => {
    getPatientRecords();
  }, []);

  useEffect(() => {
    if (!filter.patient_code && patientRecords.length) {
      setFilter(Object.assign(filter, { page_number: 0, patient_code: patientRecords[0].patient_record?.code }));

      refetch();
    }
  }, [patientRecords]);

  const getPatientRecords = async () => {
    try {
      const resp = await profileService.getByUserKey();

      resp.data.sort((a: IPatientRecordData, b: IPatientRecordData) => {
        if (a.patient_record?.default_record) {
          return -1;
        }
        return 0;
      });

      setPatientRecords(resp.data);
    } catch (error) {}
  };

  const onChangePatientRecord = (value: string) => {
    setFilter(Object.assign(filter, { page_number: 0, patient_code: value }));
    refetch();
  };

  const onChangePage = (page: number, pageSize: number) => {
    setFilter(Object.assign(filter, { page_number: page - 1, page_size: pageSize }));
    refetch();
  };

  //! render
  const pageData = data?.content_page ?? [];

  const operations = (
    <div className="flex justify-center items-center gap-4">
      <CommonStyles.ButtonUI
        iconPosition="start"
        className="bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
        onClick={() => navigate(baseRoutes.medicine)}
      >
        Mua thuốc
      </CommonStyles.ButtonUI>
    </div>
  );

  return (
    <div className="p-5">
      <div className="flex justify-between items-center max-md:flex-col max-md:justify-start max-md:items-start gap-2">
        <div className="flex-none w-fit text-common-cyan500 text-base font-bold font-['Inter']">Đơn thuốc đã mua</div>

        <div className="grow justify-end flex items-center gap-2 max-[370px]:flex-col">
          <CommonStyles.SelectUI
            rootClass="max-sm:w-full"
            className="w-72 max-sm:w-full float-right"
            value={filter.patient_code}
            variant="filled"
            placeholder="Chọn hồ sơ"
            options={patientRecords.map((patientRecord: IPatientRecordData) => {
              return { label: patientRecord.patient_record?.patient_name, value: patientRecord.patient_record?.code };
            })}
            onChange={onChangePatientRecord}
          />

          <div className="w-fit">{operations}</div>
        </div>
      </div>

      <Divider className="my-4" />

      <div className="min-h-[428px] relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading} />

        {data && data.content_page && data.content_page.length > 0 ? (
          <div className="w-full">
            <div className="flex flex-col gap-6 max-h-[610px] overflow-y-auto">
              {data.content_page?.map((drugOrder: IDrugOrder, idx: number) => {
                return (
                  <div key={idx} className="mr-1">
                    <PrescriptionListItem key={idx} drugOrder={drugOrder} />
                    {idx < pageData.length - 1 ? <Divider className="!my-4" /> : null}
                  </div>
                );
              })}
            </div>

            {(data.total_records ?? 0) > filter.page_size ? (
              <div className="w-full flex justify-end items-center mt-2">
                <CommonStyles.PaginationUI
                  current={filter.page_number + 1}
                  pageSize={filter.page_size}
                  total={data.total_records}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full min-h-[428px] flex flex-col justify-center items-center gap-6">
            <img src={CommonImages.EmptyServicePackageList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">Bạn chưa có đơn thuốc nào!</div>

            <CommonStyles.ButtonUI onClick={() => navigate(baseRoutes.medicine)}>Mua thuốc ngay</CommonStyles.ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
}
