import CommonIcons from "@base/assets/icons";
import { responseUI } from "@base/themes/response";
import TypographyUI from "@components/CommonStyles/Typography";
import React from "react";

interface IProps {
  title: string;
  subTitle: string;
  ComponentFilter: React.ReactNode;
}
export default function FilterBookingHeader(props: IProps) {
  //! state
  const { title, subTitle, ComponentFilter } = props;
  //! function
  //! render
  return (
    <div className={`relative ${responseUI.screen} py-5 flex flex-col ${responseUI.padding}`}>
      <div className={`lg:mt-10 `}>
        <div className="text-center">
          <TypographyUI use="title" level={1} className={`${responseUI.text.title} text-nowrap`}>
            {title}
          </TypographyUI>
          <TypographyUI use="paragraph" className="text-common-subtext text-xs lg:text-base">
            {subTitle}
          </TypographyUI>
        </div>

        <div className="flex justify-center w-full">
          <div className="flex justify-center items-center gap-4 w-full md:w-3/4 xl:w-4/5">
            <div className="grow">{ComponentFilter}</div>
          </div>
        </div>
      </div>

      <div className="absolute right-0 bottom-0">
        <CommonIcons.IconHeart width={150} height={150} />
      </div>
    </div>
  );
}
