import React from "react";

export default function IconMonitorHeart() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.11 12.45L14 10.24L10.89 16.45C10.73 16.79 10.38 17 10 17C9.62 17 9.27 16.79 9.11 16.45L7.38 13H2V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V13H16C15.62 13 15.27 12.79 15.11 12.45Z"
        fill="currentColor"
      />
      <path
        d="M20 4H4C2.9 4 2 4.9 2 6V11H8C8.38 11 8.73 11.21 8.89 11.55L10 13.76L13.11 7.55C13.45 6.87 14.56 6.87 14.9 7.55L16.62 11H22V6C22 4.9 21.1 4 20 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
