export default function IconLocation() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0415 2C8.1715 2 5.0415 5.13 5.0415 9C5.0415 14.25 12.0415 22 12.0415 22C12.0415 22 19.0415 14.25 19.0415 9C19.0415 5.13 15.9115 2 12.0415 2ZM7.0415 9C7.0415 6.24 9.2815 4 12.0415 4C14.8015 4 17.0415 6.24 17.0415 9C17.0415 11.88 14.1615 16.19 12.0415 18.88C9.9615 16.21 7.0415 11.85 7.0415 9Z"
        fill="#5A6B81"
      />
      <path
        d="M12.0415 11.5C13.4222 11.5 14.5415 10.3807 14.5415 9C14.5415 7.61929 13.4222 6.5 12.0415 6.5C10.6608 6.5 9.5415 7.61929 9.5415 9C9.5415 10.3807 10.6608 11.5 12.0415 11.5Z"
        fill="#5A6B81"
      />
    </svg>
  );
}
