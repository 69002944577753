import React from "react";

export default function K39A() {
  return (
    <div>
      <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.000976562" y="0.0499573" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16576)" />
        <rect x="0.999023" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1360_16576)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.3324 46.2999C43.1264 46.2999 44.5824 44.8719 44.5824 43.1124C44.5824 41.3529 43.1264 39.9249 41.3324 39.9249C39.5384 39.9249 38.0824 41.3529 38.0824 43.1124C38.0824 44.8719 39.5384 46.2999 41.3324 46.2999ZM36.999 35.6749H62.999C64.195 35.6749 65.1657 36.6269 65.1657 37.7999V55.5734L58.2259 49.7679C56.0809 47.9787 52.7247 47.9787 50.5992 49.7552L34.8324 62.6582V37.7999C34.8324 36.6269 35.803 35.6749 36.999 35.6749ZM62.999 31.4249H36.999C33.4154 31.4249 30.499 34.2852 30.499 37.7999V63.2999C30.499 66.8147 33.4154 69.6749 36.999 69.6749H62.999C66.5827 69.6749 69.499 66.8147 69.499 63.2999V37.7999C69.499 34.2852 66.5827 31.4249 62.999 31.4249Z"
            fill="url(#paint1_linear_1360_16576)"
          />
          <mask id="mask0_1360_16576" maskUnits="userSpaceOnUse" x="30" y="31" width="40" height="39">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M41.3324 46.2999C43.1264 46.2999 44.5824 44.8719 44.5824 43.1124C44.5824 41.3529 43.1264 39.9249 41.3324 39.9249C39.5384 39.9249 38.0824 41.3529 38.0824 43.1124C38.0824 44.8719 39.5384 46.2999 41.3324 46.2999ZM36.999 35.6749H62.999C64.195 35.6749 65.1657 36.6269 65.1657 37.7999V55.5734L58.2259 49.7679C56.0809 47.9787 52.7247 47.9787 50.5992 49.7552L34.8324 62.6582V37.7999C34.8324 36.6269 35.803 35.6749 36.999 35.6749ZM62.999 31.4249H36.999C33.4154 31.4249 30.499 34.2852 30.499 37.7999V63.2999C30.499 66.8147 33.4154 69.6749 36.999 69.6749H62.999C66.5827 69.6749 69.499 66.8147 69.499 63.2999V37.7999C69.499 34.2852 66.5827 31.4249 62.999 31.4249Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1360_16576)">
            <rect x="23.999" y="25.05" width="52" height="51" fill="url(#paint2_linear_1360_16576)" />
          </g>
        </g>
        <defs>
          <filter id="filter0_d_1360_16576" x="15.999" y="21.05" width="68" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16576" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16576" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16576" x1="49.999" y1="0.0499573" x2="49.999" y2="100.05" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16576" x1="49.999" y1="31.4249" x2="49.999" y2="69.6749" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16576" x1="49.999" y1="25.05" x2="49.999" y2="76.05" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
