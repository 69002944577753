import { NavLink, useLocation, useNavigate } from "react-router-dom";
import type { GetProp, MenuProps } from "antd";
import { Button, Divider, Dropdown, Menu, Space, Tooltip } from "antd";
import CommonStyles from "../components/CommonStyles";
import { baseRoutes } from "@base/routes/baseRoutes";
import { useTranslation } from "react-i18next";
import { languageApp, menu } from "@base/constants";
import CommonImages from "@base/assets/images";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import CommonIcons from "@base/assets/icons";
import React, { ReactNode, useEffect, useState } from "react";
import useGetListCommon from "@base/modules/common/hooks/useGetListCommon";
import useWindowDimensions from "@base/hooks/useWindowDimensions";
import useToggle from "@base/hooks/useToggle";
import { SearchOutlined } from "@ant-design/icons";

type MenuItem = GetProp<MenuProps, "items">[number];

const HeaderLayout = () => {
  //! state
  let location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);
  const { width } = useWindowDimensions();
  const [closeMenu, setCloseMenu] = useState(false);
  const { open: openDrawer, shouldRender: shouldRenderDrawer, toggle: toggleDrawer } = useToggle();

  useEffect(() => {
    if (width >= 1024) {
      setCloseMenu(false);
    } else {
      setCloseMenu(true);
    }
  }, [width]);
  //! function
  const backToLogin = () => {
    navigate(baseRoutes.login);
  };
  const backToInfo = () => {
    navigate(baseRoutes.user);
  };
  const navigateSearch = () => {
    navigate(baseRoutes.search);
  };
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const languageItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-start gap-1 group ">
          <div>
            <img src={CommonImages.VNLanguage} alt={CommonImages.VNLanguage} />
          </div>
          <CommonStyles.NavLinkTextUI hover className="group-hover:!text-common-mainColor" text="Việt Nam" />
        </div>
      ),
      onClick: () => {
        changeLanguage("vi");
      },
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-start gap-1 group">
          <div>
            <img src={CommonImages.ENLanguage} alt={CommonImages.ENLanguage} />
          </div>
          <CommonStyles.NavLinkTextUI hover className="group-hover:!text-common-mainColor" text="English" />
        </div>
      ),
      onClick: () => {
        changeLanguage("en");
      },
    },
  ];

  const getIndex = () => {
    const path = location.pathname.split("/");

    let indexKey = ["0"];
    for (let index = 0; index < menu(baseRoutes).length; index++) {
      if (menu(baseRoutes)[index].children && !menu(baseRoutes)[index].href) {
        menu(baseRoutes)[index].children?.map((c, indexC) => {
          if (c.href.includes(path[1])) {
            indexKey = [`${index}-${indexC}`];
          }
        });
      } else {
        if (menu(baseRoutes)[index].href && String(menu(baseRoutes)[index].href).includes(String(path[1]))) {
          indexKey = [`${index}`];
        }
      }
    }
    return indexKey;
  };
  //! render
  const menuItems = (): MenuItem[] => {
    return menu(baseRoutes, dataCommon).map((item, index) => {
      if (item.children) {
        return {
          key: index,
          label: <CommonStyles.NavLinkTextUI text={item.label} key={index} target={item.target} />,
          children: item.children?.map((c, indexC) => {
            return {
              key: `${index}-${indexC}`,
              label: <CommonStyles.NavLinkTextUI text={c.label} linkTo={c.href} key={`${index}-${indexC}`} target={item.target} />,
            };
          }),
        };
      } else {
        return {
          key: index,
          label: <CommonStyles.NavLinkTextUI text={item.label} linkTo={item.href} key={index} target={item.target} />,
        };
      }
    });
  };
  const renderMenu = () => {
    return menu(baseRoutes, dataCommon).map((item, index) => {
      if (item.children) {
        const path = location.pathname.split("/");
        let active = false;
        let index = 0;
        const itemChildren = item.children.map((c, index) => {
          if (location.pathname !== "/") {
            active = c.href?.includes(path[1]) && location.pathname !== "/";
            index++;
          }
          return {
            key: index,
            label: <CommonStyles.NavLinkTextUI className="text-base" hover isActive={active} text={c.label} linkTo={c.href} target={c.target} />,
          };
        });
        return (
          <Dropdown
            menu={{ items: itemChildren }}
            dropdownRender={(menu) => (
              <div>
                {React.cloneElement(menu as React.ReactElement)}
                <Divider style={{ margin: 0 }} />
              </div>
            )}
            key={index}
          >
            <Space className="cursor-pointer">
              <CommonStyles.NavLinkTextUI className="text-base font-medium" isActive={index > 0} text={t(item.label)} target={item.target} />
              <CommonIcons.IconDown />
            </Space>
          </Dropdown>
        );
      } else {
        const pathSingle = location.pathname;
        return (
          <NavLink to={String(item.href)} target={item.target}>
            <CommonStyles.NavLinkTextUI
              className="text-base font-medium"
              isActive={item.href === pathSingle}
              text={t(item.label)}
              target={item.target}
            />
          </NavLink>
        );
      }
    });
  };
  return (
    <div className={`bg-common-white  py-4 flex items-center justify-between `}>
      <div className="flex justify-between w-full lg:hidden xl:block xl:w-fit gap-5">
        <div
          className="flex-none w-fit page-layout-logo cursor-pointer "
          onClick={() => {
            navigate(baseRoutes.home);
          }}
        >
          <img src={CommonImages.pageLayoutLogo} alt={CommonImages.pageLayoutLogo} />
        </div>

        {closeMenu && (
          <div className="flex items-center gap-3">
            <Tooltip title="Tra cứu nhanh">
              <CommonStyles.ButtonUI onClick={navigateSearch} type="default" shape="circle" icon={<SearchOutlined />} />
            </Tooltip>

            <div className="xl:hidden cursor-pointer" onClick={toggleDrawer}>
              {<CommonIcons.IconMenu />}
            </div>
          </div>
        )}
      </div>

      <div className="grow nav-bar lg:flex xl:justify-center items-center hidden">
        <nav className="flex flex-row gap-5 flex-nowrap">{renderMenu()}</nav>
      </div>

      <div className="flex-none w-fit actions flex items-center gap-4">
        {!closeMenu ? (
          <Tooltip title="Tra cứu nhanh">
            <CommonStyles.ButtonUI onClick={navigateSearch} type="default" shape="circle" icon={<SearchOutlined />} />
          </Tooltip>
        ) : null}

        {/* {!closeMenu && (
          <Dropdown menu={{ items: languageItems }} placement="bottomLeft">
            <div className="rounded-[100px]">
              <div className="flex items-center gap-2 px-2 py-1 border border-common-divider rounded-3xl">
                <div className="">
                  <img src={languageApp.find((item) => item.value === i18n.language?.split("-")[0])?.display} alt="lng" />
                </div>
                <div>
                  <CommonIcons.IconDown />
                </div>
              </div>
            </div>
          </Dropdown>
        )} */}

        {!closeMenu && (
          <>
            {userInfo?.user_name ? (
              <div className="w-fit h-fit p-[2px] rounded-[50%] border border-common-orange cursor-pointer" onClick={backToInfo}>
                <img
                  className="rounded-[50%]"
                  width={30}
                  height={30}
                  src={
                    userInfo?.user_profiles?.avatar && userInfo?.user_profiles?.avatar.length > 0
                      ? userInfo?.user_profiles?.avatar[0].link
                      : CommonImages.DefaultAvatar
                  }
                  alt=""
                />
              </div>
            ) : (
              <CommonStyles.ButtonUI className="rounded-[30px]" type="primary" onClick={backToLogin}>
                <span className="text-base font-medium font-['Inter']">{t("Register/Login")}</span>
              </CommonStyles.ButtonUI>
            )}
          </>
        )}
      </div>

      {shouldRenderDrawer && (
        <CommonStyles.DrawerUI
          title={
            userInfo?.user_name ? (
              <CommonStyles.TypographyUI
                className="cursor-pointer text-common-orange font-medium"
                onClick={() => {
                  backToInfo();
                  toggleDrawer();
                }}
              >
                {userInfo?.user_profiles?.display_name ?? userInfo.user_name}
              </CommonStyles.TypographyUI>
            ) : (
              <span
                onClick={() => {
                  backToLogin();
                  toggleDrawer();
                }}
                className="text-base font-medium font-['Inter'] cursor-pointer text-common-orange"
              >
                {t("Register/Login")}
              </span>
            )
          }
          width={300}
          open={openDrawer}
          onClose={toggleDrawer}
        >
          <Menu className="border-none" mode="inline" items={menuItems()} onSelect={toggleDrawer} />
          <Divider className="my-2" />
          {/* <div>
            <CommonStyles.SelectUI
              className="border-none"
              defaultValue={i18n.resolvedLanguage}
              onChange={changeLanguage}
              options={[
                { label: "Tiếng Việt", value: "vi" },
                { label: "Tiếng Anh", value: "en" },
              ]}
            />
          </div> */}
        </CommonStyles.DrawerUI>
      )}
    </div>
  );
};

export default HeaderLayout;
