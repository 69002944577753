import { EInComingCallStatus } from "@base/enums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IStringeeClientState {
  stringeeToken?: string;
  stringeeClient?: any;
  incomingcall?: any;
  incomingcallStatus?: EInComingCallStatus;
}

const initialState: IStringeeClientState = {
  stringeeToken: undefined,
  stringeeClient: undefined,
  incomingcall: undefined,
  incomingcallStatus: undefined,
};

export const stringeeClientSlice = createSlice({
  name: "app-common-state",
  initialState,
  reducers: {
    setStringeeToken: (state, action: PayloadAction<string | undefined>) => {
      state.stringeeToken = action.payload;
    },
    setStringeeClient: (state, action: PayloadAction<any | undefined>) => {
      state.stringeeClient = action.payload;
    },
    setIncomingcall: (state, action: PayloadAction<any | undefined>) => {
      state.incomingcall = action.payload;
    },
    setIncomingcallStatus: (state, action: PayloadAction<EInComingCallStatus | undefined>) => {
      state.incomingcallStatus = action.payload;
    },
  },
});

export const { setStringeeToken, setStringeeClient, setIncomingcall, setIncomingcallStatus } = stringeeClientSlice.actions;

export default stringeeClientSlice.reducer;
