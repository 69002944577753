export const responseUI = {
  text: {
    default: "text-xs md:!text-sm lg:!text-base",
    title: "uppercase !text-common-cyan500 !text-sm !md:text-base lg:!text-2xl",
    subTitle: "text-xs md:!text-sm lg:!text-base text-common-subtext",
  },
  button: {
    default: "lg:h-10",
  },
  screen: "mx-auto w-full sm:max-w-[600px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1200px]",
  padding: "px-5 lg:px-3",
};
