import { apiAuthorize, apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IDrugOrder, IDrugOrderPage } from "./drugOrder.interface";
import { IDrugOrderFilter } from "./drugOrderFilter.interface";

class DrugOrderService {
  create(payload: IDrugOrder, lang: string) {
    return httpService.post<IDrugOrder>(`${apiService.patient.drugOrder.create}`, payload, { params: { lang } });
  }

  uploadImage(code: string, files: any[]) {
    const formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append("files", file);
    }

    const headers = {
      Authorization: apiAuthorize,
      "Content-Type": "multipart/form-data",
    };

    return httpService.post(`${apiService.patient.drugOrder.uploadImage}/${code}`, formData, { headers });
  }

  getAllByPatientRecordCode(patient_code?: string, params?: IDrugOrderFilter) {
    return httpService.get<IDrugOrderPage>(`${apiService.patient.drugOrder.getAllByPatientRecordCode}/${patient_code}`, { params });
  }

  getByCode(code?: string, lang?: string) {
    return httpService.get<IDrugOrder>(`${apiService.patient.drugOrder.getByCode}/${code}`, { params: { lang } });
  }
}

export default new DrugOrderService();
