import React from "react";

export default function K13A() {
  return (
    <div>
      <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.0499573" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16628)" />
        <rect x="1" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1360_16628)">
          <path
            d="M49.9695 70.3018C54.0414 70.3018 57.428 67.0342 57.428 62.8747C57.428 61.9285 57.2406 60.7857 57.0036 59.3387C56.0454 53.4918 54.2619 42.6084 63.16 27.7781C64.018 26.3482 62.2131 24.8063 60.9344 25.8721C60.7254 26.0463 60.4824 26.2433 60.21 26.4641C48.7165 35.783 42.5684 47.1243 42.5684 62.5567C42.5684 66.6471 45.6758 70.3018 49.9695 70.3018V70.3018Z"
            fill="url(#paint1_linear_1360_16628)"
          />
          <path
            d="M74.4222 67.0474C72.7283 66.3737 70.741 66.5788 69.2353 67.5823C68.3823 68.151 67.2787 68.151 66.4258 67.5823C64.5713 66.346 62.174 66.346 60.3195 67.5823C59.9292 67.8419 59.4874 67.9826 59.0406 68.0043C57.2456 71.1486 53.8516 73.2736 49.9703 73.2736C46.083 73.2736 42.811 71.1288 41.0337 68.0073C40.5612 67.9984 40.0907 67.8567 39.6786 67.5823C37.8241 66.346 35.4267 66.346 33.5723 67.5823C32.7183 68.151 31.6157 68.151 30.7628 67.5823C29.257 66.5788 27.2688 66.3737 25.5758 67.0474C25.0102 67.2723 24.6387 67.8201 24.6387 68.4283V74.7625C24.6387 75.5827 25.3044 76.2485 26.1246 76.2485H73.8734C74.6937 76.2485 75.3594 75.5827 75.3594 74.7625V68.4283C75.3594 67.8201 74.9879 67.2723 74.4222 67.0474V67.0474Z"
            fill="url(#paint2_linear_1360_16628)"
          />
          <path
            d="M73.877 55.4442H59.4434C59.592 56.731 59.7792 57.8702 59.9407 58.8569C60.2002 60.438 60.4043 61.6862 60.4043 62.8739C60.4043 63.3425 60.3726 63.8042 60.3112 64.2569C62.7032 63.3264 65.4951 63.5034 67.8341 64.9523C70.1445 63.5232 72.9763 63.3283 75.3629 64.2242V56.9301C75.3629 56.1099 74.6972 55.4442 73.877 55.4442V55.4442Z"
            fill="url(#paint3_linear_1360_16628)"
          />
          <path
            d="M32.1675 64.9523C34.4879 63.5149 37.3016 63.3097 39.7311 64.2737C39.6429 63.713 39.5973 63.1384 39.5973 62.5559C39.5973 60.1239 39.7509 57.7514 40.058 55.4442H26.1246C25.3044 55.4442 24.6387 56.1099 24.6387 56.9301V64.2242C27.0252 63.3284 29.8568 63.523 32.1675 64.9523V64.9523Z"
            fill="url(#paint4_linear_1360_16628)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1360_16628"
            x="16.6387"
            y="21.5174"
            width="66.7246"
            height="66.7311"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16628" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16628" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16628" x1="50" y1="0.0499573" x2="50" y2="100.05" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16628" x1="52.9757" y1="25.5174" x2="52.9757" y2="70.3018" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16628" x1="49.999" y1="66.6551" x2="49.999" y2="76.2485" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint3_linear_1360_16628" x1="67.4031" y1="55.4442" x2="67.4031" y2="64.9523" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint4_linear_1360_16628" x1="32.3483" y1="55.4442" x2="32.3483" y2="64.9523" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
