import React from "react";

export default function IconX() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1506 6.18435H25.4303L18.265 14.3739L26.6944 25.5179H20.0943L14.9248 18.7591L9.00967 25.5179H5.72792L13.392 16.7583L5.30556 6.18435H12.0733L16.7461 12.3622L22.1506 6.18435ZM20.9995 23.5548H22.8168L11.0858 8.04434H9.13559L20.9995 23.5548Z"
        fill="currentColor"
      />
    </svg>
  );
}
