import { useCallback, useEffect, useState } from "react";
import orderService from "../orderService";
import { IOrderInfo } from "../interface/OrderInfo.interface";
import { AxiosResponse } from "axios";

const useGetByOrderKey = (order_key?: string, lang?: string) => {
  const [data, setData] = useState<IOrderInfo>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return orderService.getByOrderKey(order_key, lang);
  }, []);

  const transformResponse = useCallback((response: AxiosResponse<IOrderInfo>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetByOrderKey;
