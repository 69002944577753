import React from "react";

export default function K39() {
  return (
    <div>
      <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.000976562" y="0.0499573" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="0.999023" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27274)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.3324 46.3C43.1264 46.3 44.5824 44.872 44.5824 43.1125C44.5824 41.353 43.1264 39.925 41.3324 39.925C39.5384 39.925 38.0824 41.353 38.0824 43.1125C38.0824 44.872 39.5384 46.3 41.3324 46.3ZM36.999 35.675H62.999C64.195 35.675 65.1657 36.627 65.1657 37.8V55.5735L58.2259 49.768C56.0809 47.9787 52.7247 47.9787 50.5992 49.7552L34.8324 62.6582V37.8C34.8324 36.627 35.803 35.675 36.999 35.675ZM62.999 31.425H36.999C33.4154 31.425 30.499 34.2852 30.499 37.8V63.3C30.499 66.8147 33.4154 69.675 36.999 69.675H62.999C66.5827 69.675 69.499 66.8147 69.499 63.3V37.8C69.499 34.2852 66.5827 31.425 62.999 31.425Z"
            fill="url(#paint0_linear_1205_27274)"
          />
          <mask id="mask0_1205_27274" maskUnits="userSpaceOnUse" x="30" y="31" width="40" height="39">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M41.3324 46.3C43.1264 46.3 44.5824 44.872 44.5824 43.1125C44.5824 41.353 43.1264 39.925 41.3324 39.925C39.5384 39.925 38.0824 41.353 38.0824 43.1125C38.0824 44.872 39.5384 46.3 41.3324 46.3ZM36.999 35.675H62.999C64.195 35.675 65.1657 36.627 65.1657 37.8V55.5735L58.2259 49.768C56.0809 47.9787 52.7247 47.9787 50.5992 49.7552L34.8324 62.6582V37.8C34.8324 36.627 35.803 35.675 36.999 35.675ZM62.999 31.425H36.999C33.4154 31.425 30.499 34.2852 30.499 37.8V63.3C30.499 66.8147 33.4154 69.675 36.999 69.675H62.999C66.5827 69.675 69.499 66.8147 69.499 63.3V37.8C69.499 34.2852 66.5827 31.425 62.999 31.425Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1205_27274)">
            <rect x="23.999" y="25.05" width="52" height="51" fill="url(#paint1_linear_1205_27274)" />
          </g>
        </g>
        <defs>
          <filter id="filter0_d_1205_27274" x="15.999" y="21.05" width="68" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27274" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27274" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27274" x1="49.999" y1="31.425" x2="49.999" y2="69.675" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1205_27274" x1="49.999" y1="25.05" x2="49.999" y2="76.05" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
