import React from "react";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";

interface IProps {
  Header: React.ReactNode;
  Content: React.ReactNode;
}

export default function MedicalManualLayout(props: IProps) {
  //! state
  const { Header, Content } = props;

  //! function

  //! render
  return (
    <div className="bg-common-white">
      <div className={`${responseUI.screen} ${initialPage.paddingX}`}>
        <div className={`relative py-5 max-lg:!pt-0 max-[700px]:px-2`}>{Header}</div>
      </div>

      <div className="bg-common-white">
        <div className={`${responseUI.screen} ${initialPage.paddingX} gap-8 max-[700px]:px-2`}>{Content}</div>
      </div>
    </div>
  );
}
