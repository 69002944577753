import React from "react";

export default function IconVoucher() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.29676 6.09994C2.23428 6.13898 2.14447 6.20927 2.10152 6.26003C2.02733 6.34203 2.02343 6.40841 2.01171 8.1343L2 9.92266L2.10543 10.0437C2.2499 10.2155 2.328 10.2507 2.71066 10.3053C3.28856 10.3834 3.69856 10.6294 3.99141 11.0667C4.18665 11.3557 4.26865 11.6407 4.26865 12C4.26865 12.3592 4.18665 12.6443 3.99141 12.9332C3.69856 13.3705 3.28856 13.6165 2.71066 13.6946C2.328 13.7493 2.2499 13.7844 2.10543 13.9562L2 14.0773L2.01171 15.8657L2.02343 17.654L2.12105 17.7594C2.328 17.982 2.21476 17.9742 5.00664 17.9742H7.58376L7.59547 16.4396C7.60718 15.0379 7.61499 14.8973 7.67747 14.7997C7.91566 14.4326 8.45841 14.4639 8.64975 14.8504C8.71222 14.9754 8.72003 15.1355 8.72003 16.4787V17.9742H15.1433C22.125 17.9742 21.6642 17.9898 21.879 17.7594L21.9766 17.654L21.9883 15.8657L22 14.0773L21.8946 13.9562C21.7501 13.7844 21.672 13.7493 21.2893 13.6946C20.7114 13.6165 20.3014 13.3705 20.0086 12.9332C19.8134 12.6443 19.7314 12.3592 19.7314 12C19.7314 11.6407 19.8134 11.3557 20.0086 11.0667C20.3014 10.6294 20.7114 10.3834 21.2893 10.3053C21.6603 10.2507 21.7345 10.2233 21.879 10.0671L21.9766 9.96171V8.15382V6.34593L21.879 6.24051C21.6642 6.01013 22.125 6.02575 15.1433 6.02575H8.72003V7.51735C8.72003 8.86448 8.71222 9.02457 8.64975 9.14953C8.45841 9.53609 7.91566 9.56733 7.67747 9.20029C7.61499 9.10267 7.60718 8.9621 7.59547 7.5603L7.58376 6.02575H4.99883C2.55057 6.02575 2.40609 6.02965 2.29676 6.09994ZM8.40375 10.7505C8.50918 10.8012 8.58337 10.8793 8.63803 10.9965C8.71222 11.1526 8.72003 11.2307 8.72003 12C8.72003 12.7692 8.71222 12.8473 8.63803 13.0035C8.54822 13.187 8.33737 13.3276 8.14994 13.3276C7.97813 13.3276 7.75166 13.1714 7.66576 12.9957C7.59547 12.8512 7.58766 12.7536 7.58766 12C7.58766 11.0433 7.6189 10.9184 7.88832 10.7583C8.07185 10.6528 8.21242 10.6489 8.40375 10.7505Z"
        fill="currentColor"
      />
    </svg>
  );
}
