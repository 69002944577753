export default function IconLikeStart() {
  return (
    <div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.383 29.9562V29.5185C12.383 25.9415 12.3785 22.3644 12.3927 18.7865C12.3978 18.5326 12.4751 18.2855 12.6156 18.0739C13.6559 16.5675 14.7105 15.0702 15.7795 13.5821C16.374 12.7462 17.3816 12.4675 18.2383 12.8881C19.1344 13.3243 19.5409 14.2509 19.2637 15.2614C19.0193 16.1531 18.7577 17.0448 18.5051 17.9365C18.4687 18.065 18.4382 18.195 18.3921 18.3734H19.4644C20.3805 18.3734 21.2967 18.3637 22.2137 18.3734C24.0713 18.3994 25.3212 20.0638 24.8285 21.8442C24.2673 23.8703 23.703 25.8956 23.1358 27.9202C22.7851 29.1745 21.8912 29.9555 20.5901 29.9748C17.9151 30.0157 15.2401 29.9896 12.5695 29.9904C12.5065 29.9839 12.4442 29.9725 12.383 29.9562Z"
          fill="#FC8F29"
        />
        <path
          d="M10.7988 29.9551C10.0349 29.9551 9.30301 30.0094 8.58225 29.941C7.77305 29.8667 7.15185 29.1526 7.06491 28.3405C7.05062 28.1923 7.04418 28.0435 7.0456 27.8946C7.0456 25.4311 7.0456 22.9679 7.0456 20.5049C7.0456 19.1287 7.80426 18.3753 9.18338 18.373C9.71244 18.373 10.2422 18.373 10.7988 18.373V29.9551Z"
          fill="#FBB573"
        />
        <path
          d="M24.2371 12.765C24.3226 12.2768 24.408 11.7522 24.5098 11.2306C24.5437 11.0989 24.5395 10.9603 24.4975 10.8309C24.4555 10.7016 24.3776 10.5869 24.2728 10.5002C23.91 10.1823 23.5663 9.84308 23.2437 9.48442C23.0994 9.31477 23.0204 9.09927 23.0208 8.8766C23.0401 8.58978 23.318 8.52216 23.578 8.4902C24.0439 8.43225 24.5084 8.35794 24.975 8.30221C25.117 8.29268 25.2532 8.24259 25.3676 8.1579C25.4819 8.0732 25.5695 7.95745 25.62 7.82442C25.8069 7.37987 26.0226 6.94798 26.2657 6.53149C26.3712 6.35836 26.6053 6.1726 26.7858 6.16517C26.9448 6.15922 27.1678 6.36356 27.2673 6.53149C27.5064 6.9504 27.7224 7.38201 27.9145 7.82442C28.0528 8.12759 28.2734 8.27026 28.5982 8.3089C29.0664 8.34708 29.5319 8.41358 29.9921 8.50804C30.1913 8.55411 30.4417 8.71684 30.5123 8.88848C30.5688 9.0371 30.4462 9.31426 30.3183 9.46064C29.9943 9.81798 29.6505 10.1569 29.2885 10.4757C29.1734 10.5691 29.0882 10.6942 29.0435 10.8355C28.9987 10.9768 28.9963 11.1281 29.0366 11.2707C29.1456 11.7403 29.2245 12.2163 29.2729 12.6959C29.2892 12.8884 29.2142 13.1722 29.0774 13.2703C28.9407 13.3684 28.6502 13.3446 28.4726 13.2703C28.0193 13.0756 27.5883 12.8282 27.1551 12.5904C27.0382 12.5203 26.9043 12.4837 26.768 12.4846C26.6317 12.4856 26.4983 12.524 26.3824 12.5956C25.9269 12.8445 25.4676 13.0875 25.001 13.3142C24.5775 13.5185 24.2119 13.2606 24.2371 12.765Z"
          fill="#FC8F29"
        />
        <path
          d="M2.69649 12.7616C2.77823 12.2898 2.85773 11.7629 2.96473 11.2421C3.02567 10.9448 2.96028 10.7137 2.74182 10.5064C2.37995 10.1683 2.01139 9.83322 1.68519 9.46615C1.55664 9.31753 1.43106 9.04186 1.48753 8.89399C1.55292 8.72234 1.80407 8.56035 2.00247 8.5128C2.45071 8.41826 2.90473 8.35348 3.36153 8.31886C3.71671 8.28245 3.95597 8.12492 4.10459 7.79352C4.28883 7.3602 4.499 6.93837 4.73396 6.53031C4.83427 6.36313 5.05942 6.15878 5.21769 6.16621C5.40048 6.17587 5.63157 6.36461 5.73783 6.53774C5.9772 6.94329 6.18729 7.36544 6.36647 7.80095C6.50616 8.12492 6.73279 8.27502 7.07163 8.31217C7.54029 8.35257 8.00638 8.41855 8.46783 8.50983C8.65806 8.55218 8.92036 8.70674 8.96272 8.86129C9.01101 9.03814 8.90253 9.31679 8.76952 9.4706C8.43808 9.83759 8.08552 10.1849 7.71364 10.5109C7.61324 10.5939 7.53838 10.7036 7.49775 10.8273C7.45711 10.9511 7.45235 11.0838 7.48402 11.2101C7.57616 11.6834 7.6579 12.159 7.74261 12.6338C7.78793 12.8887 7.80726 13.1785 7.53456 13.281C7.32074 13.3442 7.0916 13.3308 6.88661 13.2431C6.44077 13.0544 6.02391 12.8114 5.59888 12.5818C5.48676 12.5142 5.35829 12.4784 5.22735 12.4784C5.0964 12.4784 4.96794 12.5142 4.85582 12.5818C4.38769 12.8359 3.91808 13.0878 3.43881 13.3167C3.03458 13.5129 2.6742 13.2558 2.69649 12.7616Z"
          fill="#FC8F29"
        />
        <path
          d="M18.5229 8.64789C18.5326 9.11007 18.1648 9.34711 17.762 9.1502C17.2954 8.92133 16.8362 8.67687 16.3792 8.42868C16.2624 8.35848 16.1285 8.32176 15.9922 8.32255C15.8559 8.32334 15.7224 8.36159 15.6064 8.43314C15.1782 8.68163 14.7375 8.90783 14.286 9.11081C14.1091 9.18512 13.8156 9.20295 13.6826 9.10264C13.5496 9.00233 13.479 8.71699 13.4961 8.52305C13.5473 8.03071 13.6267 7.54172 13.7339 7.05848C13.7963 6.75605 13.7109 6.53313 13.4917 6.32805C13.1224 5.9982 12.7703 5.64975 12.4365 5.28405C12.3102 5.13915 12.2017 4.86422 12.2552 4.70744C12.3087 4.55065 12.5606 4.39164 12.7501 4.35077C13.2442 4.24228 13.7606 4.22965 14.2518 4.11299C14.4794 4.0472 14.6755 3.90124 14.8039 3.70208C15.0684 3.27185 15.2453 2.78737 15.5046 2.35342C15.6034 2.18772 15.8315 2.00121 15.9995 2.00196C16.1674 2.0027 16.3933 2.19292 16.4921 2.35937C16.7507 2.79406 16.9276 3.27853 17.1921 3.70876C17.3229 3.90627 17.52 4.05044 17.7479 4.11522C18.2272 4.22891 18.731 4.23708 19.2125 4.34557C19.4306 4.39988 19.6214 4.532 19.749 4.7171C19.8879 4.94001 19.72 5.1555 19.5364 5.32864C19.1753 5.66822 18.8008 5.99739 18.482 6.37338C18.3346 6.55912 18.2591 6.79183 18.2695 7.02876C18.3163 7.57936 18.4367 8.12626 18.5229 8.64789Z"
          fill="#FC8F29"
        />
      </svg>
    </div>
  );
}
