import CommonStyles from "..";
import { ESpecialPackageStatus } from "@base/enums";

interface IProps {
  status: ESpecialPackageStatus;
}

export default function TagSpecialPackageStatus({ status }: IProps) {
  const getTagName = () => {
    switch (status) {
      case ESpecialPackageStatus.UNPAID:
        return "Chờ thanh toán";
      case ESpecialPackageStatus.PENDING:
        return "Chờ xử lý";
      case ESpecialPackageStatus.IN_PROGRESS:
        return "Đang thực hiện";
      case ESpecialPackageStatus.COMPLETED:
        return "Đã hoàn thành";
      case ESpecialPackageStatus.CANCELLED:
        return "Đã hủy";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case ESpecialPackageStatus.UNPAID:
        return "bg-common-purple100 text-common-purple500 text-xs font-medium border-none p-1 rounded";
      case ESpecialPackageStatus.PENDING:
        return "bg-common-green100 text-common-green500 text-xs font-medium border-none p-1 rounded";
      case ESpecialPackageStatus.IN_PROGRESS:
        return "bg-common-orange100 text-common-orange500 text-xs font-medium border-none p-1 rounded";
      case ESpecialPackageStatus.COMPLETED:
        return "bg-gray-100 text-gray-500 text-xs font-medium border-none p-1 rounded";
      case ESpecialPackageStatus.CANCELLED:
        return "bg-common-red100 text-common-red500 text-xs font-medium border-none p-1 rounded";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
