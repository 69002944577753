import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IAppointment, IAppointmentPage } from "./appointment.interface";
import IFilterAppointment from "./filterAppointment.interface";

class AppointmentService {
  // create
  createAppointment(payload: IAppointment, query?: { lang: string }) {
    return httpService.post(`${apiService.doctor.appointment.create}`, payload, {
      params: query,
    });
  }

  // get my appointments
  getMyAppointments(params?: IFilterAppointment) {
    return httpService.get<IAppointmentPage>(`${apiService.doctor.appointment.getMyAppointment}`, {
      params: params,
    });
  }

  // get by id
  getById(id: string | number) {
    return httpService.get<IAppointment>(`${apiService.doctor.appointment.getById}/${id}`);
  }

  getByKey(key: string) {
    return httpService.get<IAppointment>(`${apiService.doctor.appointment.getByKey}`, { params: { key } });
  }

  // cancel
  setCancelled(id?: string | number, cancel_reason?: string) {
    return httpService.put(`${apiService.doctor.appointment.setCancelled}/${id}`, undefined, { params: { cancel_reason } });
  }
  createFromPackage(body: any) {
    return httpService.post(`${apiService.doctor.appointment.createFromPackage}`, body);
  }

  calculateFee(body: any, lang: string) {
    return httpService.post(apiService.doctor.appointment.calculateFee, body, {
      params: {
        lang: lang,
      },
    });
  }
}

export default new AppointmentService();
