import { Pagination, PaginationProps } from "antd";
import "./style.css";
interface IProps extends PaginationProps {}
export default function PaginationUI(props: IProps) {
  //! state
  const { ...res } = props;
  //! function
  //! render
  return <Pagination {...res} showSizeChanger={false} />;
}
