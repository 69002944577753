export default function IconNotFound(props: { className?: string }) {
  return (
    <svg className={`max-w-[308px] max-h-[318px] ${props.className}`} viewBox="0 0 308 318" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M157.254 317.415C240.023 317.415 307.114 250.155 307.114 167.185C307.114 84.2146 240.023 16.9548 157.254 16.9548C74.4872 16.9548 7.39014 84.2146 7.39014 167.185C7.39014 250.155 74.4872 317.415 157.254 317.415Z"
        fill="white"
      />
      <rect x="102.241" y="61.4932" width="147.353" height="202.208" rx="14.9281" fill="#DCE6F2" />
      <path
        d="M292.08 99.0153C292.08 102.692 289.106 105.667 285.443 105.667C281.782 105.667 278.805 102.692 278.805 99.0153C278.805 95.3367 281.781 92.3633 285.443 92.3633C289.106 92.3633 292.08 95.3363 292.08 99.0153Z"
        stroke="url(#paint0_linear_670_26285)"
        strokeWidth="4.97604"
      />
      <path
        d="M302.541 66.2455C302.541 68.3221 300.862 69.9994 298.797 69.9994C296.734 69.9994 295.053 68.3216 295.053 66.2455C295.053 64.1677 296.734 62.4916 298.797 62.4916C300.863 62.4916 302.541 64.1672 302.541 66.2455Z"
        stroke="url(#paint1_linear_670_26285)"
        strokeWidth="4.97604"
      />
      <path
        d="M54.3874 100.712C54.3874 102.789 52.7081 104.466 50.6435 104.466C48.5805 104.466 46.8996 102.788 46.8996 100.712C46.8996 98.634 48.5799 96.9579 50.6435 96.9579C52.7088 96.9579 54.3874 98.6334 54.3874 100.712Z"
        stroke="url(#paint2_linear_670_26285)"
        strokeWidth="4.97604"
      />
      <path
        d="M21.6683 215.742C21.6683 220.774 17.597 224.846 12.5827 224.846C7.57038 224.846 3.4973 220.774 3.4973 215.742C3.4973 210.71 7.57038 206.638 12.5827 206.638C17.597 206.638 21.6683 210.71 21.6683 215.742Z"
        stroke="url(#paint3_linear_670_26285)"
        strokeWidth="4.97604"
      />
      <path
        d="M234.261 124.788L236.041 268.353C236.041 273.258 232.035 277.047 227.138 277.047H87.3656C82.4691 277.047 78.4629 273.035 78.4629 268.353V83.7699C78.4629 78.8655 82.4691 75.0757 87.3656 75.0757H188.634L234.261 124.788Z"
        fill="url(#paint4_linear_670_26285)"
      />
      <path
        d="M146.569 248.289H141.45C140.338 248.289 139.447 247.175 139.447 245.837C139.447 244.5 140.338 243.385 141.45 243.385H146.569C147.682 243.385 148.573 244.5 148.573 245.837C148.573 247.398 147.682 248.289 146.569 248.289Z"
        fill="#CED7E2"
      />
      <path
        d="M130.99 248.289H101.166C100.053 248.289 99.1626 247.175 99.1626 245.837C99.1626 244.5 100.053 243.385 101.166 243.385H130.99C132.103 243.385 132.993 244.5 132.993 245.837C132.993 247.398 132.103 248.289 130.99 248.289Z"
        fill="#D5DDEA"
      />
      <path
        d="M168.604 216.231H101.611C100.053 216.231 98.7178 214.893 98.7178 213.333C98.7178 211.772 100.053 210.435 101.611 210.435H168.604C170.162 210.435 171.498 211.772 171.498 213.333C171.498 214.893 170.162 216.231 168.604 216.231Z"
        fill="#D5DDEA"
      />
      <path
        d="M127.429 232.283H101.611C100.053 232.283 98.7178 230.945 98.7178 229.385C98.7178 227.824 100.053 226.487 101.611 226.487H127.206C128.764 226.487 130.1 227.824 130.1 229.385C130.1 230.945 128.765 232.283 127.429 232.283Z"
        fill="#D5DDEA"
      />
      <path d="M188.634 75.0756V114.757C188.634 120.33 193.53 124.788 199.095 124.788H234.26" fill="#D5DDEA" />
      <rect width="18.2402" height="5.32981" rx="2.48802" transform="matrix(0.702823 0.711309 -0.710232 0.704023 94.6455 84.0789)" fill="#989FB0" />
      <rect width="18.2408" height="5.32916" rx="2.48802" transform="matrix(-0.667534 0.744632 0.743506 0.668671 103.268 83.8699)" fill="#989FB0" />
      <path d="M275.395 306.604L249.592 280.783L255.509 274.886L281.289 300.707L275.395 306.604Z" fill="#858B9B" />
      <path
        d="M284.364 315.699L264.582 295.875C262.928 294.243 262.928 291.544 264.582 289.878C266.232 288.212 268.926 288.212 270.576 289.878L290.361 309.702C292.014 311.368 292.014 314.033 290.361 315.699C288.711 317.365 286.017 317.365 284.364 315.699Z"
        fill="#858B9B"
      />
      <path
        d="M282.956 316.964L257.503 291.477C256.911 290.877 256.911 289.911 257.503 289.311L264.009 282.781C264.601 282.214 265.559 282.214 266.171 282.781L291.625 308.302C292.217 308.868 292.217 309.834 291.625 310.467L285.118 316.964C284.506 317.564 283.548 317.564 282.956 316.964Z"
        fill="url(#paint5_linear_670_26285)"
      />
      <path
        d="M220.387 197.588C193.38 197.588 171.496 219.511 171.496 246.565C171.496 273.585 193.38 295.508 220.367 295.508C247.371 295.508 269.255 273.619 269.255 246.565C269.255 219.511 247.371 197.588 220.387 197.588ZM220.387 286.446C198.706 286.446 181.143 268.588 181.143 246.532C181.143 224.475 198.706 206.651 220.387 206.651C242.068 206.651 259.628 224.509 259.628 246.565C259.628 268.588 242.048 286.446 220.387 286.446Z"
        fill="url(#paint6_linear_670_26285)"
      />
      <ellipse cx="220.377" cy="246.548" rx="39.6836" ry="39.7476" fill="white" fillOpacity="0.55" />
      <rect width="13.3588" height="3.90213" rx="1.95107" transform="matrix(0.621601 0.783494 -0.782403 0.622571 240.498 0.240112)" fill="#989FB0" />
      <rect width="13.3572" height="3.90376" rx="1.95188" transform="matrix(-0.744358 0.667593 0.666656 0.745534 246.791 0.774658)" fill="#989FB0" />
      <defs>
        <linearGradient id="paint0_linear_670_26285" x1="277.546" y1="107.276" x2="294.596" y2="89.9027" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68F38" />
          <stop offset="1" stopColor="#FFB059" />
        </linearGradient>
        <linearGradient id="paint1_linear_670_26285" x1="293.404" y1="71.8872" x2="305.048" y2="60.0221" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2F80ED" />
          <stop offset="1" stopColor="#82B3F4" />
        </linearGradient>
        <linearGradient id="paint2_linear_670_26285" x1="45.2505" y1="106.354" x2="56.8946" y2="94.4887" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68F38" />
          <stop offset="1" stopColor="#FFB059" />
        </linearGradient>
        <linearGradient id="paint3_linear_670_26285" x1="2.56725" y1="226.219" x2="24.1919" y2="204.185" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2F80ED" />
          <stop offset="1" stopColor="#82B3F4" />
        </linearGradient>
        <linearGradient id="paint4_linear_670_26285" x1="157.201" y1="70.4031" x2="157.201" y2="279.223" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient id="paint5_linear_670_26285" x1="257.069" y1="299.902" x2="292.082" y2="299.902" gradientUnits="userSpaceOnUse">
          <stop stopColor="#83A6FF" />
          <stop offset="1" stopColor="#5A78FF" />
        </linearGradient>
        <linearGradient id="paint6_linear_670_26285" x1="171.524" y1="246.595" x2="269.292" y2="246.595" gradientUnits="userSpaceOnUse">
          <stop stopColor="#83A6FF" />
          <stop offset="1" stopColor="#5A78FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
