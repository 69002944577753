import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import React from "react";

interface IProps extends CheckboxProps {
  titleCustom?: string | React.ReactNode;
}
export default function CheckBoxUI(props: IProps) {
  //! state
  const { titleCustom, ...res } = props;
  //! function
  const onChange: CheckboxProps["onChange"] = (e) => {};
  //! render
  return (
    <Checkbox onChange={onChange} {...res}>
      {titleCustom}
    </Checkbox>
  );
}
