export default function IconCameraBox() {
  return (
    <div>
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="40" height="40" rx="8" fill="url(#paint0_linear_906_31149)" />
        <rect x="0.5" y="0.5" width="41" height="41" rx="8.5" stroke="white" strokeOpacity="0.5" />
        <path
          d="M26.7197 23.3195V18.6749C28.0832 17.5974 29.4521 16.5287 30.8092 15.4426C31.1794 15.1453 31.5485 14.9093 32.0242 15.1474C32.4999 15.3855 32.5009 15.8165 32.4999 16.2615C32.4999 19.4219 32.4999 22.5797 32.4999 25.7351C32.4999 26.179 32.4859 26.6164 32.0231 26.848C31.5442 27.0883 31.1783 26.8481 30.8081 26.555C29.4511 25.4668 28.0832 24.397 26.7197 23.3195Z"
          fill="url(#paint1_linear_906_31149)"
        />
        <path
          d="M23.6776 12.9189H12.5403C11.7337 12.9189 10.9601 13.2397 10.3898 13.8107C9.81944 14.3817 9.49902 15.1562 9.49902 15.9637V26.0364C9.49902 26.8439 9.81944 27.6183 10.3898 28.1893C10.9601 28.7603 11.7337 29.0811 12.5403 29.0811H23.6776C24.4843 29.0811 25.258 28.7604 25.8285 28.1894C26.399 27.6184 26.7197 26.844 26.72 26.0364V15.9637C26.7197 15.1561 26.399 14.3816 25.8285 13.8107C25.258 13.2397 24.4843 12.9189 23.6776 12.9189ZM18.1095 25.3641C16.9534 25.3641 15.8447 24.9044 15.0272 24.0861C14.2096 23.2678 13.7502 22.1579 13.7499 21.0006C13.7499 19.843 14.2092 18.7329 15.0268 17.9143C15.8444 17.0958 16.9533 16.636 18.1095 16.636C19.2655 16.6363 20.3741 17.0962 21.1915 17.9147C22.0088 18.7332 22.468 19.8432 22.468 21.0006C22.468 22.1578 22.0088 23.2677 21.1914 24.086C20.374 24.9043 19.2654 25.3641 18.1095 25.3641Z"
          fill="url(#paint2_linear_906_31149)"
        />
        <path
          d="M18.1095 24.2762C19.9163 24.2762 21.381 22.8098 21.381 21.0009C21.381 19.192 19.9163 17.7256 18.1095 17.7256C16.3026 17.7256 14.8379 19.192 14.8379 21.0009C14.8379 22.8098 16.3026 24.2762 18.1095 24.2762Z"
          fill="url(#paint3_linear_906_31149)"
        />
        <defs>
          <linearGradient id="paint0_linear_906_31149" x1="3.5" y1="45" x2="40" y2="-0.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient id="paint1_linear_906_31149" x1="29.6098" y1="15.0498" x2="29.6098" y2="26.9463" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint2_linear_906_31149" x1="10.6581" y1="28.3041" x2="25.6827" y2="11.9651" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
          <linearGradient id="paint3_linear_906_31149" x1="15.2783" y1="23.9613" x2="21.3882" y2="17.7326" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
