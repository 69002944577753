export const antdTheme = {
  token: {
    //! primary
    colorPrimaryBorder: "rgba(249, 131, 21, 1)",
    colorPrimary: "rgba(249, 131, 21, 1)",

    colorPrimaryBgHover: "rgba(235, 118, 9, 1)",
    colorPrimaryBorderHover: "rgba(235, 118, 9, 1)",
    colorPrimaryHover: "rgba(235, 118, 9, 1)",
    colorPrimaryActive: "rgba(234, 104, 12, 1)",
    fontFamily: "Inter",
  },

  components: {
    Button: {
      colorError: "#ff4d4f",
      colorErrorActive: "#d9363e",
      colorErrorBg: "#fff2f0",
      colorErrorBgActive: "#ffccc7",
      colorErrorBorderHover: "#ffa39e",
      colorErrorHover: "#ff7875",
      colorBgContainerDisabled: "rgba(209, 212, 218, 1)",
      colorTextDisabled: "#fff",
    },
    Input: {
      activeShadow: "0 0 0 .5px rgba(249, 131, 21, 1)",
      // colorBorder: "rgba(209, 212, 218, 1)",
      // colorFillTertiary: "#F5F5F5",
    },
    Typography: {
      fontSizeHeading1: 38,
      fontSizeHeading2: 30,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 16,
    },
    Divider: {
      margin: 6,
    },
    Checkbox: {
      colorPrimary: "#1677ff",
      colorPrimaryHover: "##4096ff",
      colorPrimaryBorder: "#91caff",
      fontSize: 16,
      lineHeight: 1,
    },
    Modal: {
      borderRadiusLG: 24,
    },
  },
};
