export default function IconContractHome() {
  return (
    <div>
      <svg width="148" height="179" viewBox="0 0 148 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8" filter="url(#filter0_f_1532_17144)">
          <rect x="30" y="61" width="88" height="88" rx="24" fill="#FED4A5" />
        </g>
        <rect x="4.5" y="0.5" width="139" height="139" rx="31.5" fill="url(#paint0_linear_1532_17144)" stroke="url(#paint1_linear_1532_17144)" />
        <g filter="url(#filter1_i_1532_17144)">
          <path
            d="M46.1565 44.4558C46.1565 42.799 47.4996 41.4558 49.1565 41.4558H98.1587C99.8155 41.4558 101.159 42.799 101.159 44.4558V100.049C101.159 104.467 97.577 108.049 93.1587 108.049H54.1565C49.7382 108.049 46.1565 104.467 46.1565 100.049V44.4558Z"
            fill="url(#paint2_linear_1532_17144)"
          />
        </g>
        <g filter="url(#filter2_d_1532_17144)">
          <path
            d="M52.6627 41.4558H94.6524V100.034C94.6524 101.138 93.757 102.034 92.6524 102.034H54.6627C53.5581 102.034 52.6627 101.138 52.6627 100.034V41.4558Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter3_i_1532_17144)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80.5063 38.2464C80.5152 38.1032 80.5198 37.9587 80.5198 37.8132C80.5198 34.0233 77.4474 30.9509 73.6575 30.9509C69.8675 30.9509 66.7951 34.0233 66.7951 37.8132C66.7951 37.9587 66.7997 38.1032 66.8086 38.2464H62.4384C61.3338 38.2464 60.4384 39.1418 60.4384 40.2464V44.0485C60.4384 45.153 61.3338 46.0485 62.4384 46.0485H84.8762C85.9808 46.0485 86.8762 45.153 86.8762 44.0485V40.2464C86.8762 39.1418 85.9808 38.2464 84.8762 38.2464H80.5063Z"
            fill="url(#paint3_linear_1532_17144)"
          />
        </g>
        <path
          d="M73.6721 55.2699C73.9985 54.7472 74.4032 54.3034 74.9153 53.9642C76.6864 52.7908 78.9761 53.2039 80.2266 54.9595C81.2593 56.4094 81.3686 57.9693 80.6332 59.5834C80.12 60.7094 79.337 61.644 78.4648 62.5085C77.1151 63.8461 75.5932 64.9664 73.9737 65.9602C73.7838 66.0767 73.6031 66.1194 73.3961 65.9933C71.3781 64.7634 69.4973 63.3662 67.953 61.5652C67.3049 60.8094 66.7742 59.9822 66.4416 59.0357C65.7365 57.0282 66.6754 54.6614 68.5717 53.6965C70.0361 52.9508 71.853 53.2735 73.0318 54.4995C73.2574 54.7337 73.4492 54.9999 73.6721 55.2699Z"
          fill="#FF5C00"
        />
        <circle cx="62.1107" cy="76.7984" r="3.90276" fill="#B9D8FB" />
        <rect x="69.2666" y="72.8965" width="19.8408" height="3.33383" rx="1.66691" fill="#B9D8FB" />
        <rect x="69.2666" y="77.3683" width="19.8408" height="3.33383" rx="1.66691" fill="#B9D8FB" />
        <circle cx="62.1108" cy="91.7621" r="3.90276" fill="#B9D8FB" />
        <rect x="69.2667" y="87.8593" width="19.8408" height="3.33383" rx="1.66691" fill="#B9D8FB" />
        <rect x="69.2667" y="92.3311" width="19.8408" height="3.33383" rx="1.66691" fill="#B9D8FB" />
        <defs>
          <filter id="filter0_f_1532_17144" x="0" y="31" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1532_17144" />
          </filter>
          <filter
            id="filter1_i_1532_17144"
            x="46.1565"
            y="41.4558"
            width="55.0022"
            height="68.5933"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.561321 0 0 0 0 0 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17144" />
          </filter>
          <filter
            id="filter2_d_1532_17144"
            x="52.6627"
            y="41.4558"
            width="41.9897"
            height="62.5779"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.895833 0 0 0 0 0.464449 0 0 0 0 0.00995401 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1532_17144" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1532_17144" result="shape" />
          </filter>
          <filter
            id="filter3_i_1532_17144"
            x="60.4384"
            y="30.9509"
            width="26.4379"
            height="16.0975"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.644445 0 0 0 0 0.757265 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17144" />
          </filter>
          <linearGradient id="paint0_linear_1532_17144" x1="128" y1="4.66364e-06" x2="14.5" y2="128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_1532_17144" x1="74" y1="0" x2="74" y2="140" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="0.44" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="paint2_linear_1532_17144" x1="73.6576" y1="41.4558" x2="73.6576" y2="108.049" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFAD32" />
            <stop offset="1" stopColor="#F98416" />
          </linearGradient>
          <linearGradient id="paint3_linear_1532_17144" x1="73.6573" y1="30.9509" x2="73.6573" y2="46.0486" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B3D3FA" />
            <stop offset="1" stopColor="#1B67FF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
