export default function IconSearchHome() {
  return (
    <div>
      <svg width="149" height="179" viewBox="0 0 149 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8" filter="url(#filter0_f_1532_17142)">
          <rect x="30.4382" y="61" width="88" height="88" rx="24" fill="#FED4A5" />
        </g>
        <rect x="4.5" y="0.5" width="139" height="139" rx="31.5" fill="url(#paint0_linear_1532_17142)" stroke="url(#paint1_linear_1532_17142)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.268 83.6189C99.5691 83.6189 111.162 72.0256 111.162 57.7245C111.162 43.4234 99.5691 31.8301 85.268 31.8301C70.9669 31.8301 59.3735 43.4234 59.3735 57.7245C59.3735 72.0256 70.9669 83.6189 85.268 83.6189ZM85.268 75.2279C94.935 75.2279 102.772 67.3913 102.772 57.7243C102.772 48.0573 94.935 40.2207 85.268 40.2207C75.601 40.2207 67.7644 48.0573 67.7644 57.7243C67.7644 67.3913 75.601 75.2279 85.268 75.2279Z"
          fill="url(#paint2_linear_1532_17142)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.2682 78.8365C96.9283 78.8365 106.381 69.384 106.381 57.7238C106.381 46.0637 96.9283 36.6112 85.2682 36.6112C73.608 36.6112 64.1555 46.0637 64.1555 57.7238C64.1555 69.384 73.608 78.8365 85.2682 78.8365ZM85.268 75.2278C94.935 75.2278 102.772 67.3911 102.772 57.7242C102.772 48.0572 94.935 40.2206 85.268 40.2206C75.601 40.2206 67.7644 48.0572 67.7644 57.7242C67.7644 67.3911 75.601 75.2278 85.268 75.2278Z"
          fill="url(#paint3_linear_1532_17142)"
        />
        <path
          d="M64.3601 72.3718C65.0804 71.6194 66.238 71.4954 67.1014 72.078L67.1977 72.143C69.1831 73.4827 70.9211 75.1565 72.3346 77.09C72.9275 77.9009 72.8534 79.0204 72.1586 79.746L71.9181 79.9972C70.9682 80.9895 69.3937 81.0237 68.4014 80.0738L64.2372 76.087C63.2477 75.1396 63.2135 73.5694 64.1609 72.5799L64.3601 72.3718Z"
          fill="url(#paint4_linear_1532_17142)"
        />
        <g filter="url(#filter1_i_1532_17142)">
          <path
            d="M41.1097 98.79C40.4705 96.7027 40.9446 94.4334 42.3661 92.7767L49.3323 84.6575L54.5371 79.6542C57.318 76.9809 61.7271 77.0259 64.4529 79.7553C67.1738 82.4797 67.2184 86.8791 64.5535 89.6582L59.5446 94.8818L51.3447 101.794C49.682 103.196 47.4144 103.655 45.3357 103.016C43.3157 102.395 41.7285 100.811 41.1097 98.79Z"
            fill="url(#paint5_linear_1532_17142)"
          />
        </g>
        <mask id="mask0_1532_17142" maskUnits="userSpaceOnUse" x="40" y="77" width="27" height="27">
          <path
            d="M41.1097 98.79C40.4705 96.7027 40.9446 94.4334 42.3661 92.7767L49.3323 84.6575L54.5371 79.6542C57.318 76.9809 61.7271 77.0259 64.4529 79.7553C67.1738 82.4797 67.2184 86.8791 64.5535 89.6582L59.5446 94.8818L51.3447 101.794C49.682 103.196 47.4144 103.655 45.3357 103.016C43.3157 102.395 41.7285 100.811 41.1097 98.79Z"
            fill="url(#paint6_linear_1532_17142)"
          />
        </mask>
        <g mask="url(#mask0_1532_17142)">
          <g opacity="0.3">
            <rect x="44.547" y="87.7909" width="16.8271" height="4.30502" transform="rotate(43.6001 44.547 87.7909)" fill="#DE8E03" />
          </g>
        </g>
        <path
          d="M60.3532 74.8592C61.2437 73.9479 62.6932 73.8973 63.645 74.7443L66.6079 77.3809L69.4862 80.4582C70.3569 81.3892 70.341 82.8404 69.4501 83.7521C68.5301 84.6935 67.0211 84.7108 66.0797 83.7909L60.392 78.2325C59.4497 77.3117 59.4323 75.8014 60.3532 74.8592Z"
          fill="url(#paint7_linear_1532_17142)"
        />
        <g filter="url(#filter2_i_1532_17142)">
          <circle cx="85.2681" cy="57.7243" r="17.5036" fill="url(#paint8_linear_1532_17142)" />
        </g>
        <defs>
          <filter
            id="filter0_f_1532_17142"
            x="0.438232"
            y="31"
            width="148"
            height="148"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1532_17142" />
          </filter>
          <filter
            id="filter1_i_1532_17142"
            x="38.8303"
            y="73.6783"
            width="27.6929"
            height="29.6202"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="-2" dy="-4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.876389 0 0 0 0 0.446309 0 0 0 0 0 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17142" />
          </filter>
          <filter
            id="filter2_i_1532_17142"
            x="67.7645"
            y="40.2207"
            width="35.0072"
            height="35.0072"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.401705 0 0 0 0 0.545506 0 0 0 0 0.915278 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17142" />
          </filter>
          <linearGradient id="paint0_linear_1532_17142" x1="128" y1="4.66364e-06" x2="14.5" y2="128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_1532_17142" x1="74" y1="0" x2="74" y2="140" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="0.44" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="paint2_linear_1532_17142" x1="72.3907" y1="35.2178" x2="95.0745" y2="81.5811" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BBD9FB" />
            <stop offset="0.520277" stopColor="#3F7CE4" />
            <stop offset="1" stopColor="#1A41C8" />
          </linearGradient>
          <linearGradient id="paint3_linear_1532_17142" x1="72.4751" y1="38.5355" x2="94.7162" y2="77.9277" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C88F5" />
            <stop offset="1" stopColor="#3B60E7" />
          </linearGradient>
          <linearGradient id="paint4_linear_1532_17142" x1="70.1313" y1="74.1037" x2="66.3218" y2="78.0827" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA52A" />
            <stop offset="1" stopColor="#FA8617" />
          </linearGradient>
          <linearGradient id="paint5_linear_1532_17142" x1="43.0823" y1="103.665" x2="65.7892" y2="79.1077" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F98B23" />
            <stop offset="1" stopColor="#FBC87F" />
          </linearGradient>
          <linearGradient id="paint6_linear_1532_17142" x1="43.0823" y1="103.665" x2="65.7892" y2="79.1077" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E99000" />
            <stop offset="1" stopColor="#F09000" />
          </linearGradient>
          <linearGradient id="paint7_linear_1532_17142" x1="66.6973" y1="77.4682" x2="63.2351" y2="81.0109" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDD322" />
            <stop offset="0.0266663" stopColor="#FFA321" />
            <stop offset="1" stopColor="#FA8718" />
          </linearGradient>
          <linearGradient id="paint8_linear_1532_17142" x1="72.7997" y1="44.0534" x2="94.8285" y2="72.7577" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="0.365" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
