import React from "react";

export default function IconMedication() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 3H7C6.45 3 6 3.45 6 4C6 4.55 6.45 5 7 5H17C17.55 5 18 4.55 18 4C18 3.45 17.55 3 17 3Z" fill="currentColor" />
      <path
        d="M17 6H7C5.9 6 5 6.9 5 8V19C5 20.1 5.9 21 7 21H17C18.1 21 19 20.1 19 19V8C19 6.9 18.1 6 17 6ZM14.5 15H13.5V16C13.5 16.83 12.83 17.5 12 17.5C11.17 17.5 10.5 16.83 10.5 16V15H9.5C8.67 15 8 14.33 8 13.5C8 12.67 8.67 12 9.5 12H10.5V11C10.5 10.17 11.17 9.5 12 9.5C12.83 9.5 13.5 10.17 13.5 11V12H14.5C15.33 12 16 12.67 16 13.5C16 14.33 15.33 15 14.5 15Z"
        fill="currentColor"
      />
    </svg>
  );
}
