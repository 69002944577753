import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { getAvatarLink } from "@base/helpers/common";
import { IDoctor } from "@base/modules/doctor/interface/doctor.interface";
import doctorService from "@base/modules/doctor/doctorService";
import CommonStyles from "@components/CommonStyles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardDoctor from "@components/CommonStyles/CardDoctor";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

export default function DoctorHome() {
  //! sttae
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  //! function
  useEffect(() => {
    getDoctors();
  }, []);

  const getDoctors = async () => {
    try {
      const resp = await doctorService.getList({ page: 0, size: 10 });
      setDoctors(resp.data.content_page ?? []);
    } catch (error) {}
  };

  //! render
  const renderDoctor = () => {
    return doctors.map((doctor, index) => {
      return (
        <div className="px-3 pb-8" key={index}>
          <CardDoctor doctor={doctor} />
        </div>
      );
    });
  };

  return (
    <div className={` bg-common-white`}>
      <div className={`${responseUI.screen} pb-10 lg:pb-20 ${responseUI.padding}`}>
        <div className="flex justify-between items-center lg:mb-10">
          <div className="basis-2/3">
            <CommonStyles.TypographyUI use="title" className={`${responseUI.text.title}`} level={3}>
              {t("home.leadingDoctor")}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.subTitle}`}>
              {t("home.subLeadingDoctor")}
            </CommonStyles.TypographyUI>
          </div>
          <CommonStyles.ButtonUI onClick={() => navigate(baseRoutes.doctor)} ghost className=" lg:h-12 lg:text-base font-medium">
            {t("home.viewAll")}
          </CommonStyles.ButtonUI>
        </div>

        <CommonStyles.CarouselUI settings={settings}>{renderDoctor()}</CommonStyles.CarouselUI>
      </div>
    </div>
  );
}
