import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { ISpecialization } from "./interface/specialization.interface";
import IFilterSpecialization from "./interface/IFilterSpecialization.interfcae";

class SpecializationService {
  // get list
  getList(lang?: string, site_code?: string, appt_type?: string) {
    return httpService.get<ISpecialization[]>(`${apiService.doctor.specialization.getList}`, {
      params: { lang, site_code, appt_type },
    });
  }

  // get by id
  getById(id: string | number) {
    return httpService.get<ISpecialization>(`${apiService.doctor.specialization.getById}/${id}`);
  }

  getAll(filters?: IFilterSpecialization) {
    return httpService.get(apiService.doctor.specialization.all, {
      params: filters,
    });
  }
}

export default new SpecializationService();
