import React from "react";

export default function IconHeartPlus() {
  return (
    <div>
      <svg width="163" height="147" viewBox="0 0 163 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_708_10084)">
          <path
            d="M93.0317 18.5936C108.128 8.70884 130.614 9.51717 144.988 20.224C158.496 30.2937 163.315 43.953 162.206 59.3384C161.369 70.9015 156.124 80.8822 147.185 89.26C133.153 102.44 119.059 115.537 102.572 126.333C98.7312 128.737 94.7498 130.953 90.6448 132.971C84.3811 136.211 77.821 136.211 71.8994 132.724C63.4541 127.744 54.8645 122.764 47.3541 116.75C35.7314 107.44 24.5952 97.5692 13.9074 87.3693C-4.02458 70.2028 -4.5643 41.5691 12.8508 24.108C28.3579 8.55813 57.0917 6.72915 75.9435 22.9298C83.8263 29.7046 91.3442 36.8083 99.0598 43.7338C102.313 46.652 105.551 47.289 109.01 45.8436C114.232 43.6515 115.365 37.7947 110.994 33.8764C105.278 28.725 99.2421 23.8477 93.0317 18.5936ZM73.1005 86.287C73.1005 88.7463 73.0473 90.7876 73.1005 92.8221C73.2678 97.7816 76.4984 100.878 81.371 100.816C86.0611 100.761 89.1854 97.7131 89.3754 92.9249C89.4514 90.8013 89.3754 88.6778 89.3754 86.2939C92.416 86.2939 94.9093 86.3692 97.4178 86.2939C102.199 86.1089 105.643 82.8688 105.521 78.834C105.399 74.7993 102.085 71.9839 97.3645 71.8195C94.8712 71.7304 92.3628 71.8195 89.3754 71.8195C89.3754 69.3055 89.4514 67.1682 89.3754 65.0309C89.1854 60.3523 85.8787 57.1532 81.3026 57.1121C76.7265 57.071 73.3286 60.2838 73.1157 64.935C73.0169 67.0723 73.1157 69.2233 73.1157 71.8263C70.0751 71.8263 67.4754 71.7373 64.8529 71.8263C60.2235 72.0181 56.9245 75.1007 56.9549 79.0669C56.9805 80.9716 57.8309 82.792 59.3249 84.1395C60.8188 85.4871 62.8381 86.2553 64.9517 86.2802C67.4602 86.3761 69.9839 86.287 73.1005 86.287Z"
            fill="#F68E1E"
          />
        </g>
        <defs>
          <clipPath id="clip0_708_10084">
            <rect width="162.217" height="146" fill="white" transform="translate(0.141602 0.478516)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
