import { responseUI } from "@base/themes/response";
import { Input } from "antd";
const { TextArea } = Input;

interface IProps {
  label?: string;
  value?: string;
  labelStyle?: string;
  required?: boolean;
  placeholder?: string;
  rows?: number;
  autoSize?: boolean;
  maxLength?: number;
  minLength?: number;
  className?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  variant?: "borderless" | "filled" | "outlined";
}
export default function TextAreaUI(props: IProps) {
  //! state
  const {
    label,
    value,
    labelStyle,
    required,
    maxLength,
    placeholder,
    rows = 4,
    autoSize,
    minLength,
    className,
    disabled,
    onChange,
    variant,
    ...res
  } = props;
  //! function
  //! render
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <div className="mb-1">
            <span className={`${responseUI.text.default} ${labelStyle}`}>{label}</span>
            {required && <span className="text-common-red500">{` *`}</span>}
          </div>
        )}
        {/* {extraTitle && extraTitle} */}
      </div>

      <TextArea
        value={value}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        autoSize={autoSize}
        className={className}
        disabled={disabled}
        // style={{ resize: "none" }}
        onChange={onChange}
        variant={variant}
        {...res}
      />
    </div>
  );
}
