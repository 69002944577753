export default function IconKSK() {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="39" height="39" rx="11.5" fill="white" stroke="white" />
        <g clipPath="url(#clip0_655_5746)">
          <path
            d="M21.5979 12.1867C23.6453 10.6972 26.6948 10.819 28.6443 12.4324C30.4762 13.9497 31.1298 16.008 30.9793 18.3263C30.8659 20.0687 30.1546 21.5727 28.9422 22.8351C27.0391 24.8211 25.1278 26.7947 22.8917 28.4214C22.3709 28.7837 21.8309 29.1176 21.2742 29.4216C20.4247 29.9099 19.535 29.9099 18.7319 29.3845C17.5865 28.6341 16.4216 27.8836 15.403 26.9774C13.8267 25.5746 12.3164 24.0871 10.867 22.5502C8.43499 19.9634 8.3618 15.6488 10.7237 13.0176C12.8267 10.6745 16.7237 10.3989 19.2804 12.8401C20.3494 13.861 21.369 14.9314 22.4154 15.975C22.8566 16.4147 23.2958 16.5107 23.7649 16.2929C24.4731 15.9626 24.6267 15.08 24.034 14.4896C23.2587 13.7134 22.4401 12.9784 21.5979 12.1867ZM18.8948 22.3871C18.8948 22.7577 18.8876 23.0653 18.8948 23.3718C18.9175 24.1191 19.3556 24.5857 20.0164 24.5764C20.6525 24.5682 21.0762 24.1088 21.102 23.3873C21.1123 23.0673 21.102 22.7473 21.102 22.3881C21.5144 22.3881 21.8525 22.3995 22.1927 22.3881C22.8412 22.3602 23.3082 21.872 23.2917 21.264C23.2752 20.6561 22.8257 20.2318 22.1855 20.207C21.8474 20.1936 21.5072 20.207 21.102 20.207C21.102 19.8282 21.1123 19.5062 21.102 19.1841C21.0762 18.4791 20.6278 17.9971 20.0072 17.9909C19.3865 17.9847 18.9257 18.4688 18.8969 19.1697C18.8834 19.4917 18.8969 19.8158 18.8969 20.2081C18.4845 20.2081 18.1319 20.1947 17.7762 20.2081C17.1484 20.237 16.701 20.7015 16.7051 21.2991C16.7086 21.5861 16.8239 21.8604 17.0265 22.0635C17.2291 22.2666 17.503 22.3823 17.7896 22.3861C18.1298 22.4005 18.4721 22.3871 18.8948 22.3871Z"
            fill="#F68E1E"
          />
        </g>
        <defs>
          <clipPath id="clip0_655_5746">
            <rect width="22" height="22" fill="white" transform="translate(9 9.45703)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
