import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import profileService from "../profileService";
import { IPatientRecordData } from "../profile.interface";

const useGetListByUserKey = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<AxiosResponse<IPatientRecordData[]>>();
  const [error, setError] = useState<AxiosError | any>(null);

  const callApi = useCallback(() => {
    return profileService.getByUserKey();
  }, []);

  const transformResponse = useCallback((response: AxiosResponse<IPatientRecordData[]>) => {
    if (response) {
      response.data.sort((a: IPatientRecordData, b: IPatientRecordData) => {
        if (a.patient_record?.default_record) {
          return -1;
        }
        return 0;
      });

      setData(response);
    }
  }, []);
  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    isLoading,
    data,
    error,
    refetch,
  };
};

export default useGetListByUserKey;
