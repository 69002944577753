import CommonImages from "@base/assets/images";
import { categories } from "@base/constants";
import { EStatusCode } from "@base/enums";
import useGetByOrderTransCode from "@base/modules/order/hooks/useGetByOrderTransCode";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function Result() {
  //! state
  const statusSucess = "00";
  const statusCodeMomo = "0";
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const np_ResponseCode = searchParams.get("vnp_ResponseCode");
  const vnp_TxnRef = searchParams.get("vnp_TxnRef");
  const payment = searchParams.get("payment");
  const orderId = searchParams.get("orderId");
  const resultCode = searchParams.get("resultCode");

  const transCode = vnp_TxnRef ?? orderId;
  const { data: dataOrderKey } = useGetByOrderTransCode(String(transCode), { lang: "vi" });

  //! function
  const handleDetail = () => {
    if (dataOrderKey?.order_type === categories.APPT) {
      navigate(baseRoutes.userAppointmentDetail.replace(":key", dataOrderKey?.ref_key ? (dataOrderKey?.ref_key as string) : ""));
    }
    if (dataOrderKey?.order_type === categories.PACKAGE) {
      navigate(baseRoutes.userPackageDetail.replace(":key", dataOrderKey?.ref_key ? (dataOrderKey?.ref_key as string) : ""));
    }
  };

  const backToHome = () => {
    navigate(baseRoutes.home);
  };

  useEffect(() => {
    if (String(np_ResponseCode) === statusSucess || String(resultCode) === statusCodeMomo || Number(payment) === EStatusCode.Success) {
      return;
    }

    backToHome();
  }, []);

  useEffect(() => {
    if (!dataOrderKey) {
      return;
    }

    if (String(np_ResponseCode) === statusSucess || String(resultCode) === statusCodeMomo || Number(payment) === EStatusCode.Success) {
      handleDetail();
    }
  }, [dataOrderKey]);

  //! render
  const CasePayment = () => {
    if (String(np_ResponseCode) === statusSucess || String(resultCode) === statusCodeMomo || Number(payment) === EStatusCode.Success) {
      return <ResultSuccess />;
    } else {
      return <ResultError backToHome={backToHome} />;
    }
  };

  return <div className="bg-common-white py-32 rounded-s-lg flex flex-col items-center gap-6 px-80 text-center">{CasePayment()}</div>;
}

const ResultError = ({ backToHome }: { backToHome: () => void }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <img src={CommonImages.PaymentError} alt={CommonImages.PaymentError} />
      </div>
      <CommonStyles.TypographyUI use="title" level={3} className="text-base font-semibold text-common-maintext !m-0">
        Thất bại
      </CommonStyles.TypographyUI>
      <div>
        <CommonStyles.ButtonUI className="h-12" size="large" ghost onClick={backToHome}>
          Quay lại trang chủ
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
};

const ResultSuccess = () => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <img src={CommonImages.PaymentSuccess} alt={CommonImages.PaymentSuccess} />
      </div>
      <CommonStyles.TypographyUI use="title" level={3} className="text-base font-semibold text-common-maintext !m-0">
        Thành Công
      </CommonStyles.TypographyUI>
    </div>
  );
};
