import React from "react";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import dayjs from "dayjs";
import { EAppointmentType } from "@base/enums";
import TagAppointmentType from "@components/CommonStyles/TagAppointmentType";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {
  appointment: IAppointment;
}

export default function AppointmentCard({ appointment }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full bg-common-background p-3 rounded-2xl flex justify-between items-start">
      <div className="flex items-start gap-4">
        <img className="h-[125px]" src={CommonImages.DefaultDoctorAvatar} alt="" />
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-common-blue600">{dayjs(appointment.time_table_period?.period_start_time).format("HH:mm")}</span>&nbsp;
            <span className="text-common-blue600">{dayjs(appointment.appointment_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
          </div>

          <TagAppointmentType type={appointment.appointment_type as EAppointmentType} />

          <div className="text-common-maintext text-base font-bold font-['Inter']">{appointment.doctor?.doctor_name}</div>

          {/* <div className="text-common-maintext text-xs font-normal font-['Inter']">Tư vấn da liễu</div> */}
        </div>
      </div>

      <div className="max-md:hidden">
        <CommonStyles.DayCalendarUI value={dayjs(appointment.appointment_date, "YYYY-MM-DD").format("DD/MM/YYYY")} format="DD/MM/YYYY" />
      </div>
    </div>
  );
}
