import { useCallback, useEffect, useState } from "react";
import siteSpecialPackageService from "../siteSpecialPackageService";
import { ResponseSiteSpecialPackagePage } from "../interface/ISiteSpecialPackage";
import IFilterSiteSpecialPackage from "../interface/filterSiteSpecialPackage";

const useGetSiteSpecialPackage = (filters: IFilterSiteSpecialPackage, enabled = true) => {
  const [data, setData] = useState<ResponseSiteSpecialPackagePage>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return siteSpecialPackageService.getAllForApp(filters);
  }, [filters]);

  const transformResponse = useCallback((response: ResponseSiteSpecialPackagePage) => {
    if (response) {
      setData(response);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetSiteSpecialPackage;
