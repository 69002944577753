import { useCallback, useEffect, useState } from "react";
import siteSpecialPackageService from "../siteSpecialPackageService";
import { ResponseDetailSiteSpecialPackagePage } from "../interface/ISiteSpecialPackage";

const useGetSiteSpecialPackageBySlug = (slug: string) => {
  const [data, setData] = useState<ResponseDetailSiteSpecialPackagePage>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return siteSpecialPackageService.getBySlug(slug);
  }, [slug]);

  const transformResponse = useCallback(
    (response: ResponseDetailSiteSpecialPackagePage) => {
      if (response) {
        setData(response);
      }
    },
    [slug],
  );

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetSiteSpecialPackageBySlug;
