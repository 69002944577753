export default function IconCall() {
  return (
    <div>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.5651 17.3031C20.5651 17.5763 20.5044 17.8571 20.3754 18.1302C20.2464 18.4034 20.0795 18.6614 19.8595 18.9042C19.4876 19.3139 19.0779 19.6099 18.615 19.7996C18.1598 19.9892 17.6666 20.0879 17.1354 20.0879C16.3614 20.0879 15.5344 19.9058 14.6617 19.534C13.7891 19.1622 12.9165 18.6614 12.0515 18.0316C11.1789 17.3942 10.3518 16.6885 9.56269 15.907C8.78113 15.1178 8.07546 14.2907 7.44566 13.4257C6.82346 12.5607 6.32266 11.6957 5.95844 10.8383C5.59422 9.97323 5.41211 9.14615 5.41211 8.35701C5.41211 7.84104 5.50316 7.34782 5.68527 6.89255C5.86738 6.42969 6.15572 6.00477 6.55788 5.62537C7.04351 5.14733 7.57466 4.91211 8.13616 4.91211C8.34862 4.91211 8.56108 4.95764 8.75078 5.04869C8.94807 5.13975 9.12259 5.27633 9.25917 5.47361L11.0196 7.95485C11.1561 8.14455 11.2548 8.31907 11.3231 8.48601C11.3914 8.64535 11.4293 8.8047 11.4293 8.94887C11.4293 9.13098 11.3762 9.31309 11.27 9.48761C11.1713 9.66213 11.0271 9.84424 10.845 10.0263L10.2684 10.6258C10.1849 10.7093 10.147 10.8079 10.147 10.9293C10.147 10.99 10.1545 11.0431 10.1697 11.1038C10.1925 11.1645 10.2152 11.2101 10.2304 11.2556C10.367 11.506 10.6022 11.8323 10.9361 12.2268C11.2775 12.6214 11.6418 13.0236 12.0363 13.4257C12.4461 13.8279 12.8407 14.1997 13.2428 14.5411C13.6374 14.875 13.9637 15.1026 14.2217 15.2392C14.2596 15.2544 14.3051 15.2772 14.3582 15.2999C14.4189 15.3227 14.4796 15.3303 14.5479 15.3303C14.6769 15.3303 14.7756 15.2848 14.859 15.2013L15.4357 14.6322C15.6254 14.4425 15.8075 14.2983 15.982 14.2073C16.1566 14.101 16.3311 14.0479 16.5208 14.0479C16.665 14.0479 16.8167 14.0783 16.9836 14.1466C17.1506 14.2149 17.3251 14.3135 17.5148 14.4425L20.0264 16.2257C20.2237 16.3622 20.3603 16.5216 20.4437 16.7113C20.5196 16.901 20.5651 17.0907 20.5651 17.3031Z"
          stroke="#5A6B81"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
