export default function IconPersonEdit() {
  return (
    <div>
      <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 16V14.75C10 14.4833 10.0542 14.2292 10.1625 13.9875C10.2708 13.7458 10.4167 13.5333 10.6 13.35L15.525 8.425C15.675 8.275 15.8417 8.16667 16.025 8.1C16.2083 8.03333 16.3917 8 16.575 8C16.775 8 16.9667 8.0375 17.15 8.1125C17.3333 8.1875 17.5 8.3 17.65 8.45L18.575 9.375C18.7083 9.525 18.8125 9.69167 18.8875 9.875C18.9625 10.0583 19 10.2417 19 10.425C19 10.6083 18.9667 10.7958 18.9 10.9875C18.8333 11.1792 18.725 11.35 18.575 11.5L13.65 16.425C13.4667 16.6083 13.2542 16.75 13.0125 16.85C12.7708 16.95 12.5167 17 12.25 17H11C10.7167 17 10.4792 16.9042 10.2875 16.7125C10.0958 16.5208 10 16.2833 10 16ZM0 15V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C8.61667 9 9.225 9.0375 9.825 9.1125C10.425 9.1875 11.025 9.30833 11.625 9.475L8.875 12.225C8.59167 12.5083 8.375 12.8333 8.225 13.2C8.075 13.5667 8 13.95 8 14.35V16H1C0.716667 16 0.479167 15.9042 0.2875 15.7125C0.0958333 15.5208 0 15.2833 0 15ZM16.575 11.4L17.5 10.425L16.575 9.5L15.625 10.45L16.575 11.4ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8Z"
          fill="#F98315"
        />
      </svg>
    </div>
  );
}
