import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { DataPartner } from "@base/constants/mocks";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import CarouselHome from "@components/Home/Carousel";
import PartnerConnect from "@components/Home/PartnerConnect";
import FormPartner from "@components/Partner/FormPartner";
import { useTranslation } from "react-i18next";

export default function Partner() {
  //! state
  const { t } = useTranslation();

  const benefits = [
    {
      icon: CommonIcons.IconClound,
      title: "Tích hợp nhanh chóng",
      content: "Nền tảng sẵn sàng, sử dụng thuận tiện và tối ưu chi phí đầu tư ban đầu",
    },
    {
      icon: CommonIcons.IconSettingPartner,
      title: "Quản lý quy trình toàn diện",
      content: "Giúp cơ sở Quản lý một cách toàn diện hệ thống kết quả khám, lịch hẹn và nhiều dịch vụ khác",
    },
    {
      icon: CommonIcons.IconUtilities,
      title: "Đa dạng tiện ích cho khách hàng",
      content: "Như đặt lịch khám, tra cứu kết quả khám, mua thuốc",
    },
    {
      icon: CommonIcons.IconMedia,
      title: "Truyền thông chuyên nghiệp",
      content: "Được hỗ trợ truyền thông trọn gói từ agency chuyên nghiệp",
    },
  ];

  //! function

  //! render
  const renderPartner = () => {
    return (
      <div className="flex justify-between items-center flex-wrap">
        {DataPartner.map((item, index) => {
          return (
            <div className="flex flex-col items-center gap-2" key={index}>
              <div className="rounded-full w-24 h-24 flex items-center justify-center border-8 border-common-sGradiantOrange04">
                <div className="">
                  <img src={item.icon} alt={item.icon} />
                </div>
              </div>

              <CommonStyles.TypographyUI className="text-base text-center">{`${item.number} ${t(
                `Partner.${item.title}`,
              )}`}</CommonStyles.TypographyUI>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBenefit = () => {
    return benefits.map((item, index) => {
      return (
        <div className="rounded-2xl bg-common-white p-4" key={index}>
          <div className="flex justify-center items-center">
            <item.icon />
          </div>

          <div className="text-center mt-8 px-2">
            <CommonStyles.TypographyUI use="paragraph" className="text-lg font-medium !mb-2 text-common-maintext line-clamp-2">
              {item.title}
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI className="text-common-subtext line-clamp-3">{item.content}</CommonStyles.TypographyUI>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      {/* top */}
      {/* <div className={`flex gap-14 ${initialPage.paddingX} pt-4`}>
        <div className="basis-2/3 h-full">
          <img className="w-full" src={CommonImages.medihomePartner} alt="partner" />
        </div>

        <div className="grid gap-14">
          <div className="row-span-2 border-2 border-common-white rounded-[40px] px-14 py-20 bg-gradient-to-br from-common-sGradient1 to-common-sGradiantOrange04 flex flex-col justify-center">
            <CommonStyles.TypographyUI use="title" className="!text-common-white !text-[40px]">
              {t("Partner.Connect")}
            </CommonStyles.TypographyUI>

            <div className="flex gap-2 items-center">
              <CommonStyles.ButtonUI className="h-12 grow text-base"> {t("Partner.Register for cooperation")}</CommonStyles.ButtonUI>
              <CommonStyles.ButtonUI className="h-12 grow text-base" ghost>
                {t("Partner.VisitPartner")}
              </CommonStyles.ButtonUI>
            </div>
          </div>

          <div className="border-2 border-common-white rounded-[40px] px-14 py-6 bg-gradient-to-bl from-common-sWhite75 to-common-sWhite8 flex flex-col justify-center gap-4">
            <CommonStyles.TypographyUI use="text" className="!text-lg">
              {t("Partner.Proud")}
            </CommonStyles.TypographyUI>
            {renderPartner()}
          </div>
        </div>
      </div> */}
      <div className="w-full bg-common-white px-2 pb-10">
        <div className={`${responseUI.screen} ${initialPage.paddingX}`}>
          <CarouselHome />
        </div>
      </div>

      {/* about */}
      <div className={`grid max-lg:grid-cols-1 grid-cols-2 gap-20 mt-24 ${responseUI.screen} ${initialPage.paddingX} max-sm:px-4`}>
        <div>
          <CommonStyles.TypographyUI use="paragraph" className="uppercase text-[24px] leading-[38px] text-common-cyan500 font-semibold">
            {t("Partner.About")}
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
            365 Medihome tự hào là Sàn thương mại y tế đầu tiên tại Việt Nam cho phép kết nối các nhà cung cấp dịch vụ y tế, nhân viên y tế trên toàn
            quốc đến rộng rãi người dân, từ đó mang đến các giải pháp tiếp cận dịch vụ chăm sóc sức khỏe chất lượng cao hơn cùng giá cả phải chăng.
            <br />
            <br />
            365 Medihome sẽ là công cụ CNTT thông minh và tiện lợi mang đến hiệu quả đột phá cao giúp các đơn vị y tế xây dựng uy tín và thương hiệu
            online, tăng số lượng bệnh nhân và quản lý công việc hiệu quả.
          </CommonStyles.TypographyUI>
        </div>
        <div className="max-lg:hidden">
          <img className="w-full" src={CommonImages.PartnerAbout} alt={CommonImages.PartnerAbout} />
        </div>

        <div className="max-lg:hidden">
          <img className="w-full" src={CommonImages.PartAboutDevice} alt={CommonImages.PartAboutDevice} />
        </div>
        <div className="flex justify-center items-center max-lg:justify-start max-lg:items-start">
          <div>
            <CommonStyles.TypographyUI use="paragraph" className="uppercase text-[24px] leading-[38px] text-common-cyan500 font-semibold">
              {t("Partner.Digital")}
            </CommonStyles.TypographyUI>

            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <span className="text-common-orange">
                  <CommonIcons.IconCheckCircle />
                </span>
                <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
                  Kênh review CSYT và mua sắm dịch vụ ưu đãi
                </CommonStyles.TypographyUI>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-common-orange">
                  <CommonIcons.IconCheckCircle />
                </span>
                <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
                  Tổng hợp, quản lý Hồ sơ sức khỏe đã khám
                </CommonStyles.TypographyUI>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-common-orange">
                  <CommonIcons.IconCheckCircle />
                </span>
                <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
                  Tư vấn sức khỏe từ xa
                </CommonStyles.TypographyUI>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-common-orange">
                  <CommonIcons.IconCheckCircle />
                </span>
                <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
                  Liên kết CSYT, bác sĩ, kỹ thuật viên trên toàn quốc
                </CommonStyles.TypographyUI>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefit */}
      <div className={`mt-28 ${responseUI.screen} ${initialPage.paddingX} max-sm:px-4`}>
        <div className="text-center">
          <CommonStyles.TypographyUI use="paragraph" className="uppercase !mb-3 text-[24px] leading-[38px] text-common-cyan500 font-semibold">
            {t("Partner.benefit")}
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
            {t("Partner.benefitSubTitle")}
          </CommonStyles.TypographyUI>
        </div>
        <div className="grid grid-cols-4 gap-8 mt-14 max-md:grid-cols-2">{renderBenefit()}</div>
      </div>

      {/* join */}
      <div className="w-full bg-common-white max-sm:px-4">
        <div className={`mt-28 bg-common-white ${responseUI.screen} ${initialPage.paddingX} py-28`}>
          <div className="flex flex-col">
            <CommonStyles.TypographyUI use="paragraph" className="uppercase !mb-3 text-[24px] leading-[38px] text-common-cyan500 font-semibold">
              {t("Partner.Join the collaboration")}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
              {t("Partner.Schedule")}
            </CommonStyles.TypographyUI>
          </div>

          <div className="flex gap-6 pt-10">
            <div className="border-8 rounded-2xl border-common-blue200 max-lg:hidden">
              <img className="h-full w-full" src={CommonImages.PartnerJoin} alt={CommonImages.PartnerJoin} />
            </div>
            <div className="grow">
              <FormPartner />
            </div>
          </div>
        </div>
      </div>

      <div>
        <PartnerConnect />
      </div>
    </div>
  );
}
