import CommonImages from "@base/assets/images";
import { Service365MediHome } from "@base/constants";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ServiceHome() {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  //! function

  //! render
  const renderService = () => {
    return Service365MediHome(baseRoutes).map((service, index) => {
      return (
        <div
          key={index}
          className="bg-common-white border-common-white rounded-3xl lg:px-10 xl:px-16 p-4  lg:py-10 flex flex-col items-center text-center gap-3 lg:gap-8"
          // onClick={() => {
          //   navigate(service.route);
          // }}
        >
          <div className="size-20 bg-[#FFF2E3] rounded-2xl p-4  flex items-center justify-center">
            <service.Icon />
          </div>
          <div className="flex flex-col justify-between h-full gap-3 lg:gap-0">
            <div className="flex flex-col gap-2">
              <CommonStyles.TypographyUI className="text-sm md:text-base lg:text-lg font-medium text-common-maintext ">
                {t(`${service.title}`)}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.subTitle} hidden lg:block`}>
                {t(`${service.subTitle}`)}
              </CommonStyles.TypographyUI>
            </div>
            <div>
              <CommonStyles.ButtonUI
                onClick={() => {
                  navigate(service.route);
                }}
                className="h-8 lg:h-10"
                ghost
              >
                {t("home.ViewDetail")}
              </CommonStyles.ButtonUI>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      style={{
        backgroundImage: `url(${CommonImages.BGServiceHome})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: 800,
      }}
    >
      <div className={`${responseUI.screen} ${responseUI.padding} py-8  lg:py-16`}>
        <CommonStyles.TypographyUI use="title" level={2} className={`${responseUI.text.title} text-center`}>
          {t("home.365MediService")}
        </CommonStyles.TypographyUI>

        <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.subTitle} text-center`}>
          {t("home.Service corresponding to 365 Medihome")}
        </CommonStyles.TypographyUI>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-5 lg:gap-9 mt-10 lg:mt-20">{renderService()}</div>
      </div>
    </div>
  );
}
