import CommonIcons from "@base/assets/icons";
import TypographyUI from "../Typography";

interface IProps {
  rating?: number | string;
  maxRating?: number | string;
  className?: string;
}
export default function RatingUI(props: IProps) {
  //! state
  const { rating = "0,0", maxRating = "5", className } = props;
  //! function
  //! render
  return (
    <div className={`flex items-center justify-center gap-0 py-0.5 px-2 bg-common-orange50 rounded-xl w-fit lg:h-6 ${className}`}>
      <TypographyUI className=" text-common-warning font-medium text-xs md:text-sm text-nowrap">{rating}</TypographyUI>
      <CommonIcons.IconRating />
    </div>
  );
}
