import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { IDistrict } from "@base/modules/administrative/administrative.interface";
import administrativeService from "@base/modules/administrative/administrativeService";
import { ISite, ISitePage, IState } from "@base/modules/site/site.interface";
import siteService from "@base/modules/site/siteService";
import stateService from "@base/modules/state/stateService";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SiteListItem from "./SiteListItem";
import { debounce } from "lodash";
import themeColor from "@base/themes/colorTheme";

export default function SiteListForSync() {
  //! state
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [states, setStates] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [search, setSearch] = useState<string | undefined>();
  const [selectedState, setSelectedState] = useState<string | number | undefined>();
  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [sites, setSites] = useState<ISitePage | undefined>();

  const [dropRenderKey, setDropRenderKey] = useState<number>(0);

  //! function
  useEffect(() => {
    initDat();
  }, []);

  useEffect(() => {
    setSelectedDistrict(undefined);
    setDistricts([]);
    setDropRenderKey(dropRenderKey + 1);

    if (selectedState) {
      const state = states.find((s) => {
        return s.id === selectedState;
      });

      if (state && state.code) {
        getDistricts(state.code);
      }

      return;
    }

    setDistricts([]);
  }, [selectedState]);

  const initDat = async () => {
    getStates();
    getSites(pageNumber, pageSize, selectedState, selectedDistrict);
  };

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setPageNumber(1);
        getSites(1, pageSize, selectedState, selectedDistrict, value);
      }, 1000),
    [],
  );

  const onChangeState = (value: any) => {
    setSelectedState(value);
    setPageNumber(1);
    getSites(1, pageSize, value, selectedDistrict, search);
  };

  const onChangeDistrict = (value: any) => {
    setSelectedDistrict(value);
    setPageNumber(1);
    getSites(1, pageSize, selectedState, value, search);
  };

  const getStates = async () => {
    try {
      const resp = await stateService.getList();
      setStates(resp.data);
    } catch (error) {}
  };

  const getDistricts = async (province_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);
      setDistricts(resp.data);
    } catch (error) {}
  };

  const getSites = async (pageNumber: number, pageSize: number, selectedState?: string | number, selectedDistrict?: string, search?: string) => {
    try {
      setIsLoading(true);
      const resp = await siteService.getList(pageNumber - 1, pageSize, selectedState, selectedDistrict, undefined, undefined, search, true);

      setSites(resp.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onClearFilter = () => {
    setPageNumber(1);
    setSearch(undefined);
    setSelectedState(undefined);
    setSelectedDistrict(undefined);

    requestAnimationFrame(async () => {
      setDropRenderKey(dropRenderKey + 1);
      getSites(1, pageSize, undefined, undefined, undefined);
    });
  };

  const onChangePage = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);

    requestAnimationFrame(async () => {
      getSites(page, pageSize, selectedState, selectedDistrict, search);
    });
  };

  //! render
  const clearBtn = (
    <div className="flex-none w-fit flex items-center gap-3 float-right">
      <CommonStyles.ButtonUI
        className="h-12 bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
        onClick={onClearFilter}
        loading={isLoading}
      >
        <CommonIcons.IconRotateRight />
      </CommonStyles.ButtonUI>
    </div>
  );

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.profile.syncFromSite.title")}</div>

        <div className="flex items-center gap-4"></div>
      </div>

      <Divider />

      <div className="grid grid-flow-row gap-5">
        <div className="flex items-center gap-4">
          <div key={dropRenderKey} className="grow grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <CommonStyles.InputUI
              onChange={changeSearch}
              placeholder="Tìm kiếm..."
              variant="filled"
              prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
              value={search}
            />
            <CommonStyles.SelectUI
              className="h-12 w-full"
              defaultValue={selectedState}
              variant="filled"
              placeholder={t("user.profile.syncFromSite.placeHolderState")}
              options={states.map((state: IState) => {
                return { label: state.name, value: state.id };
              })}
              onChange={onChangeState}
            />
            <CommonStyles.SelectUI
              className="h-12 w-full"
              defaultValue={selectedDistrict}
              variant="filled"
              placeholder={t("user.profile.syncFromSite.placeHolderDistrict")}
              options={districts.map((district: IDistrict) => {
                return { label: district.name, value: district.code };
              })}
              onChange={onChangeDistrict}
            />
            <div className="lg:hidden">{clearBtn}</div>
          </div>

          <div className="max-lg:hidden">{clearBtn}</div>
        </div>

        <div className="w-full p-3 bg-common-blue100 border border-common-blue500 rounded-lg flex items-center gap-2">
          <CommonIcons.InfoCircleOutlined className="text-common-inprogress text-[20px]" />
          <CommonStyles.TypographyUI use="paragraph" className="!m-0 text-common-maintext font-normal text-[14px] leading-4">
            Chọn cơ sở y tế bạn đã từng khám chữa để đồng bộ hồ sơ
          </CommonStyles.TypographyUI>
        </div>

        {sites && sites.content_page && sites.content_page.length > 0 ? (
          <div className="flex flex-col gap-4">
            <div className="grid max-lg:grid-cols-1 grid-cols-2 gap-6 max-h-[610px] overflow-y-auto">
              {sites.content_page.map((site: ISite, idx: number) => {
                return <SiteListItem key={idx} site={site} />;
              })}
            </div>

            {(sites.total_records ?? 0) > pageSize ? (
              <div className="w-full flex justify-end items-center">
                <CommonStyles.PaginationUI current={pageNumber} pageSize={pageSize} total={sites.total_records} onChange={onChangePage} />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-6 mt-16">
            <img src={CommonImages.EmptyRecordList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("recordNotFound")}!</div>
          </div>
        )}
      </div>
    </div>
  );
}
