export default function PlusHome() {
  return (
    <div>
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5833 2.25H4.08325C3.25825 2.25 2.59075 2.925 2.59075 3.75L2.58325 14.25C2.58325 15.075 3.25825 15.75 4.08325 15.75H14.5833C15.4083 15.75 16.0833 15.075 16.0833 14.25V3.75C16.0833 2.925 15.4083 2.25 14.5833 2.25ZM13.0833 10.5H10.8333V12.75C10.8333 13.1625 10.4958 13.5 10.0833 13.5H8.58325C8.17075 13.5 7.83325 13.1625 7.83325 12.75V10.5H5.58325C5.17075 10.5 4.83325 10.1625 4.83325 9.75V8.25C4.83325 7.8375 5.17075 7.5 5.58325 7.5H7.83325V5.25C7.83325 4.8375 8.17075 4.5 8.58325 4.5H10.0833C10.4958 4.5 10.8333 4.8375 10.8333 5.25V7.5H13.0833C13.4958 7.5 13.8333 7.8375 13.8333 8.25V9.75C13.8333 10.1625 13.4958 10.5 13.0833 10.5Z"
          fill="#5A6B81"
        />
      </svg>
    </div>
  );
}
