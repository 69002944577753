import CommonIcons from "@base/assets/icons";
import { optionAppointmentTypeString, optionGender } from "@base/constants";
import { LocalKey } from "@base/constants/LocalKey";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";
import useGetListByUserKey from "@base/modules/user/profile/hooks/useGetListByUserKey";
import useSavePatientRecord from "@base/modules/user/profile/hooks/useSavePatientRecord";
import { IPatientRecord, IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor from "@base/themes/colorTheme";
import { IDataLocalAppointment } from "@base/types";
import CommonStyles from "@components/CommonStyles";
import { CollapseProps, DatePickerProps, Divider, RadioChangeEvent } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { EStatusCode } from "@base/enums";
import appointmentService from "@base/modules/appointment/appointmentService";
import useCreateFromPackage from "@base/modules/appointment/hooks/useCreateFormPackage";
import useToggle from "@base/hooks/useToggle";
import ModalCreateUser from "./ModalStep3/ModalCreateUser";

export default function Step2() {
  //! state
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const patient = searchParams.get("patient");

  const { data, isLoading, refetch } = useGetListByUserKey();
  const [patientNew, setPatientNew] = useState<IPatientRecord>({
    patient_name: "",
    patient_dob: "",
    patient_gender: true,
  });
  const { handleCreatePatientRecord } = useSavePatientRecord();
  const { isLoading: loadingCreatePackage, handleCreateFromPackage } = useCreateFromPackage();
  const collapseItems: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <CommonStyles.TypographyUI className="font-semibold text-sm lg:text-base text-common-maintext">Thông tin dịch vụ</CommonStyles.TypographyUI>
      ),
      children: <ContentSerice patient={String(patient)} />,
    },

    {
      key: "2",
      label: (
        <CommonStyles.TypographyUI className="font-semibold text-sm lg:text-base text-common-maintext">
          Thông tin người khám
        </CommonStyles.TypographyUI>
      ),
      children: (
        <ContentUser
          patient={String(patient)}
          setPatientNew={setPatientNew}
          patientNew={patientNew}
          data={data?.data as IPatientRecordData[]}
          isLoading={isLoading}
          refetch={refetch}
        />
      ),
    },
  ];
  //! function
  const handleStep = async (step: number) => {
    const dataStep1 = localStorage.getItem(LocalKey.BOOKING_APPOINTMENT);
    const parseDataStep1: IDataLocalAppointment = JSON.parse(String(dataStep1));

    if (!!patient) {
      const body = {
        appointment_date: parseDataStep1.appointment_date,
        appointment_type: parseDataStep1.appointment_type,
        appointment_used_services: parseDataStep1.payload.appointment_used_services,
        doctor: parseDataStep1.payload.doctor,
        site_code: parseDataStep1.site_code,
        site_name: parseDataStep1.payload.site_name,
        symptoms: parseDataStep1.symptoms,
        time_table_period: parseDataStep1.payload.time_table_period,
        patient_record: patientNew,
      };
      handleCreateFromPackage(body);
      return;
    }

    if (data?.data && data.data.length <= 0) {
      const result = await handleCreatePatientRecord({
        id: 0,
        active: true,
        patient_record: {
          ...patientNew,
          default_record: true,
        },
      });
      if (result?.status === EStatusCode.Success || result?.status === EStatusCode.Created) {
        localStorage.setItem(LocalKey.BOOKING_APPOINTMENT, JSON.stringify({ ...parseDataStep1, patient_record: result.data.patient_record }));

        navigate({
          pathname: baseRoutes.bookingAppointment,
          search: createSearchParams({
            step: String(step),
          }).toString(),
        });
      }
    } else {
      localStorage.setItem(LocalKey.BOOKING_APPOINTMENT, JSON.stringify({ ...parseDataStep1, patient_record: patientNew }));
      navigate({
        pathname: baseRoutes.bookingAppointment,
        search: createSearchParams({
          step: String(step),
        }).toString(),
      });
    }
  };
  //! render
  return (
    <div className="bg-common-white p-8 rounded-s-lg flex flex-col justify-between h-full">
      <CommonStyles.CollapseUI
        className="bg-white"
        bordered={false}
        expandIconPosition={"end"}
        defaultActiveKey={["1", "2"]}
        items={collapseItems}
        expandIcon={({ isActive }) =>
          isActive ? (
            <CommonIcons.UpOutlined style={{ color: themeColor.orange, fontSize: "medium" }} />
          ) : (
            <CommonIcons.DownOutlined style={{ color: themeColor.orange, fontSize: "medium" }} />
          )
        }
      />
      <div>
        <Divider className="my-4 border-common-disable" />

        <div className="flex justify-end items-center">
          <div className="flex items-center gap-4">
            <CommonStyles.ButtonUI
              onClick={() => {
                navigate(-1);
              }}
              className="h-12 font-medium text-sm lg:text-base bg-common-orange100 text-common-orange hover:!bg-common-orange200 hover:!text-common-orange"
              icon={<CommonIcons.IconArrowLeft />}
              iconPosition="start"
            >
              Quay lại
            </CommonStyles.ButtonUI>

            <CommonStyles.ButtonUI
              onClick={() => {
                handleStep(3);
              }}
              className="h-12 font-medium text-sm lg:text-base"
              icon={<CommonIcons.IconArrowRight />}
              iconPosition="end"
            >
              Tiếp tục
            </CommonStyles.ButtonUI>
          </div>
        </div>
      </div>
    </div>
  );
}

const ContentSerice = ({ patient }: { patient: string }) => {
  //! state

  const dataStep1 = localStorage.getItem(LocalKey.BOOKING_APPOINTMENT);
  const parseDataStep1: IDataLocalAppointment = JSON.parse(String(dataStep1));
  const parseAppointmentType = optionAppointmentTypeString.find((item) => item.value === parseDataStep1?.appointment_type);
  const { data: dataSite } = useGetSiteByCode(String(parseDataStep1?.site_code));

  const timeAppointment = dayjs(parseDataStep1?.payload.time_table_period?.period_start_time).format("YYYY-MM-DD HH:mm");
  //! function
  //! render
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-sm  text-common-subtext">Hình thức</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base  text-common-maintext font-medium">
          {parseAppointmentType?.label}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-sm  text-common-subtext">Cơ sở y tế</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {parseDataStep1?.payload?.site_name}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Chuyên khoa</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {parseDataStep1?.payload?.doctor?.specialization?.specialization_name}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Dịch vụ</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {parseDataStep1?.payload?.appointment_used_services && parseDataStep1?.payload?.appointment_used_services[0]?.name}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Bác sĩ</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {parseDataStep1?.payload.doctor?.doctor_name}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Thời gian</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {timeAppointment}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Địa chỉ</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {dataSite?.data?.address}
        </CommonStyles.TypographyUI>
      </div>
      {!patient && (
        <div className="flex justify-between items-center">
          <CommonStyles.TypographyUI className="text-sm lg:text-base text-common-subtext">Giá</CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI className="text-sm lg:text-lg !text-common-inprogress font-semibold">
            {parseDataStep1?.payload?.appointment_used_services &&
              parseDataStep1?.payload?.appointment_used_services[0]?.real_amount?.toLocaleString()}
            Đ
          </CommonStyles.TypographyUI>
        </div>
      )}
    </div>
  );
};

const ContentUser = ({
  setPatientNew,
  patientNew,
  data,
  isLoading,
  patient,
  refetch,
}: {
  setPatientNew: any;
  patientNew: any;
  data: IPatientRecordData[];
  isLoading: boolean;
  patient: string;
  refetch: () => void;
}) => {
  //! ko có hồ sơ
  //! state
  const { t } = useTranslation();
  const [userService, setUserService] = useState<IPatientRecordData>();
  const { open, shouldRender, toggle } = useToggle();
  //! function
  const getGender = optionGender.find((item) => item.value === userService?.patient_record?.patient_gender);
  const handleChangeOption = (value: any) => {
    const userSelected = data.find((item) => item.patient_record?.code === value);
    if (userSelected) {
      setUserService(userSelected);
      setPatientNew(userSelected?.patient_record);
    }
  };

  const parseOption = useCallback(() => {
    if (!data) {
      return [];
    } else {
      return data.map((item) => {
        return { label: item.patient_record?.patient_name, value: item.patient_record?.code };
      });
    }
  }, [isLoading]);

  const handleChangeRadio = (event: RadioChangeEvent) => {
    setPatientNew((prev: any) => ({ ...prev, patient_gender: event.target.value }));
  };
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientNew((prev: any) => ({ ...prev, patient_name: event.target.value }));
  };

  const handleChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    setPatientNew((prev: any) => ({ ...prev, patient_dob: String(dateString) }));
  };

  useEffect(() => {
    if (patient) {
      const patientDetail = data?.find((item) => item.patient_record?.id === Number(patient));
      setPatientNew(patientDetail?.patient_record);
      setUserService(patientDetail);
      return;
    }
    const getOptionDefault = data?.find((item) => item.patient_record?.default_record === true);
    if (getOptionDefault) {
      setUserService(getOptionDefault);
      setPatientNew(getOptionDefault?.patient_record);
    }
  }, [isLoading]);

  //! render
  if (data?.length <= 0) {
    return (
      <div className="flex flex-col gap-6">
        <div className="bg-common-blue100 p-2 rounded-lg flex gap-2 items-center">
          <CommonIcons.InfoCircleOutlined style={{ color: themeColor.inprogress, fontSize: 20 }} />
          <CommonStyles.TypographyUI className="font-medium text-xs !text-common-inprogress text-wrap">
            Thông tin này sẽ được lưu trên hệ thống hồ sơ của 365 Medihome để bạn có thể đặt lịch nhanh hơn trong lần kế tiếp
          </CommonStyles.TypographyUI>
        </div>

        <CommonStyles.InputUI
          value={patientNew.patient_name}
          onChange={handleChangeName}
          use="text"
          variant="filled"
          label="Họ và tên"
          placeholder="Nhập Họ và tên"
        />

        <CommonStyles.RadioUI
          value={patientNew.patient_gender}
          handleChange={handleChangeRadio}
          options={optionGender}
          label={t("user.profile.handler.labelGender")}
        />

        <CommonStyles.DatePickerUI onChange={handleChangeDate} label="Ngày sinh" variant="filled" />
      </div>
    );
  }

  const extraAction = () => {
    return (
      <CommonStyles.TypographyUI onClick={toggle} className="!text-common-mainColor cursor-pointer">
        Tạo hồ sơ
      </CommonStyles.TypographyUI>
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <CommonStyles.SelectUI
        onChange={handleChangeOption}
        value={userService?.patient_record?.code}
        label="Hồ sơ"
        placeholder="Chọn hồ sơ"
        options={parseOption()}
        variant="filled"
        disabled={!!patient}
        extraAction={extraAction}
      />

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Họ và tên</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
          {userService?.patient_record?.patient_name}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Giới tính</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end  text-sm lg:text-base text-common-maintext font-medium">
          {getGender?.label}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Ngày sinh</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end  text-sm lg:text-base text-common-maintext font-medium">
          {String(userService?.patient_record?.patient_dob ?? "")}
        </CommonStyles.TypographyUI>
      </div>

      {/* <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="text-sm lg:text-base text-common-subtext">Số điện thoại</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="text-sm lg:text-base text-common-maintext font-medium">
          {userService?.patient_record?.patient_phone_number}
        </CommonStyles.TypographyUI>
      </div> */}

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Địa chỉ</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end  text-sm lg:text-base text-common-maintext font-medium">
          {userService?.patient_record?.patient_address}
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-between items-center">
        <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Email</CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI className="basis-2/3 text-end  text-sm lg:text-base text-common-maintext font-medium">
          {userService?.patient_record?.patient_email}
        </CommonStyles.TypographyUI>
      </div>

      {shouldRender && (
        <CommonStyles.ModalConfirmationUI
          width={800}
          type="normal"
          title="Tạo hồ sơ"
          toggle={toggle}
          open={open}
          content={<ModalCreateUser refetch={refetch} toggle={toggle} />}
          hiddenAction
        />
      )}
    </div>
  );
};
