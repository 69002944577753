import React from "react";

export default function ApptMedicine() {
  return (
    <div>
      <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.00909424" width="84" height="84" rx="18" fill="#FFF2E3" />
        <g filter="url(#filter0_d_674_568)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.5014 50.4413H33.8136V47.9174H31.328V45.1882H33.8136V42.6642H36.5014V45.1882H38.987V47.9174H36.5014V50.4413Z"
            fill="#FFBB7C"
          />
          <path
            d="M25.8617 19.4181C25.653 19.5129 25.3495 19.7406 25.1788 19.9113C24.6667 20.4614 24.5813 20.7838 24.5813 22.2918C24.5813 23.8188 24.6856 24.1603 25.2547 24.7103C25.9186 25.3458 25.359 25.3079 35.2227 25.3079C45.2855 25.3079 44.5647 25.3553 45.2666 24.5776C45.7692 24.0275 45.8641 23.6386 45.8736 22.2729C45.8736 20.9071 45.7787 20.5278 45.2666 19.9682C44.5647 19.1905 45.295 19.2379 35.2037 19.2379C26.6678 19.2379 26.2221 19.2474 25.8617 19.4181Z"
            fill="#F98315"
          />
          <path
            d="M26.8575 27.5651V28.3049L26.2979 28.4092C24.0027 28.836 21.9067 30.6855 21.11 32.9712C20.9867 33.3411 20.8444 34.0334 20.7875 34.5171C20.6642 35.7216 20.6642 62.0501 20.7875 62.8183C20.9014 63.4917 21.2333 64.0133 21.8213 64.4306L22.2292 64.7152L31.9031 64.7436L41.5771 64.7626L40.9132 64.0607C38.931 61.9268 38.5042 58.9013 39.8415 56.3405L40.2019 55.6576H31.2582H22.305V46.5527V37.4478H35.2037H48.1023V45.0352V52.6227H48.8611H49.6198L49.6008 43.1573L49.5724 33.7015L49.3163 33.0091C48.4532 30.6475 46.4141 28.8455 44.1094 28.4092L43.5499 28.3049V27.5651V26.8254H35.2037H26.8575V27.5651Z"
            fill="#F98315"
          />
          <path
            d="M45.1622 54.1402C43.1136 54.3867 41.4159 55.7904 40.752 57.7821C40.2588 59.2522 40.4865 61.0921 41.3116 62.3441C41.7763 63.0554 42.554 63.7667 43.2654 64.1366C44.3845 64.7246 44.6596 64.7626 48.0739 64.7626H51.1373V59.4039V54.0453L48.4628 54.0548C46.9832 54.0643 45.5037 54.1022 45.1622 54.1402Z"
            fill="#FFBB7C"
          />
          <path
            d="M52.6547 59.4514V64.7626H55.794C58.8954 64.7626 58.9333 64.7626 59.6447 64.5255C61.9019 63.7667 63.2866 61.8319 63.2866 59.4419C63.2866 57.0803 61.8829 55.1265 59.6447 54.3773C58.9333 54.1402 58.8954 54.1402 55.794 54.1402H52.6547V59.4514Z"
            fill="#FFBB7C"
          />
        </g>
        <defs>
          <filter id="filter0_d_674_568" x="11.9909" y="16" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.745098 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_674_568" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_674_568" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
