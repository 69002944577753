import { AxiosError } from "axios";
import { useState } from "react";
import profileService from "../profileService";
import { IPatientRecord, IPatientRecordData } from "../profile.interface";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";

const useSavePatientRecord = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IPatientRecordData | IPatientRecord>();
  const [error, setError] = useState<AxiosError | any>(null);

  const handleCreatePatientRecord = async (payload: IPatientRecordData) => {
    try {
      setLoading(true);
      const resp = await profileService.createPatientRecord(payload);
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));

      setData(resp.data);

      if (resp.data.patient_record?.code && payload.patient_record?.default_record) {
        profileService.setDefault(resp.data.patient_record?.code);

        return resp;
      }
    } catch (error: AxiosError | any) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePatientRecord = async (payload: IPatientRecord, id: string | number) => {
    try {
      setLoading(true);
      const resp = await profileService.updatePatientRecord(payload, id);
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));

      setData(resp.data);

      if (resp.data.code && payload.default_record) {
        profileService.setDefault(resp.data.code);
      }
    } catch (error: AxiosError | any) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isSavingPatientRecord: isLoading,
    savePatientRecordData: data,
    savePatientRecordError: error,
    handleCreatePatientRecord,
    handleUpdatePatientRecord,
  };
};

export default useSavePatientRecord;
