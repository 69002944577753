import { Tag, TagProps } from "antd";
import React from "react";

interface IProps extends TagProps {
  tagName: string | React.ReactNode;
}
export default function TagUI(props: IProps) {
  //! state
  const { tagName, ...res } = props;
  //! function
  //! render
  return (
    <Tag {...res} className={`!rounded-lg ${res.className}`}>
      {tagName}
    </Tag>
  );
}
