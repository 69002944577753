import { ISearchContent } from "@base/modules/allPageSearch/allPageSearch.interface";
import { ISite } from "@base/modules/site/site.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import CardOrderFacility from "@components/OrderFacility/CardOrderFacility";
import { useNavigate } from "react-router-dom";

export default function FacilitieSearch({ data }: { data?: ISearchContent<ISite> }) {
  const navigate = useNavigate();

  const onViewAll = () => {
    navigate(baseRoutes.orderFacility);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext !font-medium text-sm lg:text-lg xl:text-xl !m-0">
            Cơ sở y tế
          </CommonStyles.TypographyUI>
          <div className="min-w-6 min-h-6 bg-common-negative rounded-full text-xs text-common-white flex justify-center items-center">
            {data?.total_records}
          </div>
        </div>

        <CommonStyles.ButtonUI ghost className="h-12 font-medium text-xs md:text-sm lg:text-base max-md:!p-2 max-md:h-fit" onClick={onViewAll}>
          Xem tất cả
        </CommonStyles.ButtonUI>
      </div>

      <div className="flex flex-col gap-4">
        {data?.content_page?.map((site: ISite, index: number) => {
          return <CardOrderFacility key={index} site={site} />;
        })}
      </div>
    </div>
  );
}
