import { getErrorMsg } from "@base/helpers/common";
import { useState } from "react";
import patientSpecialPackageService from "../patientSpecialPackageService";

const useBuySpecialPackage = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");
  const [data, setData] = useState();

  const handleBuy = async (body: any) => {
    setLoading(true);
    try {
      const result = await patientSpecialPackageService.buy(body);
      return result;
    } catch (error) {
      setLoading(false);
      setError(getErrorMsg(error));
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleBuy,
  };
};

export default useBuySpecialPackage;
