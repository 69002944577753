import { useEffect, useState } from "react";
import { Divider, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";
import CommonStyles from "@components/CommonStyles";
import { optionBloodGroup, optionGender, optionEthics } from "@base/constants";
import dayjs, { Dayjs } from "dayjs";
import { IPatientRecord, IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import useFormValidation from "@base/hooks/useFormvalidation";
import useSavePatientRecord from "@base/modules/user/profile/hooks/useSavePatientRecord";
import useGetDetail from "@base/modules/user/profile/hooks/useGetDetail";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IState } from "@base/modules/site/site.interface";
import { IDistrict } from "@base/modules/administrative/administrative.interface";
import stateService from "@base/modules/state/stateService";
import administrativeService from "@base/modules/administrative/administrativeService";

export default function ProfileHandler() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { patientRecord, accountSetting } = useFormValidation();
  const naviagte = useNavigate();
  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);
  const { isGettingDetail, getDetailData, getDetailerror, handleGetDetail } = useGetDetail();
  const { isSavingPatientRecord, savePatientRecordError, handleCreatePatientRecord, handleUpdatePatientRecord } = useSavePatientRecord();

  const [states, setStates] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [dropRenderKey, setDropRenderKey] = useState<number>(0);

  const [form] = Form.useForm();

  useEffect(() => {
    getStates();
    if (id && id != "0") {
      handleGetDetail(id);
    }
  }, []);

  useEffect(() => {
    if (getDetailData.administrative_province_code) {
      getDistricts(getDetailData.administrative_province_code);
    }

    if (getDetailData) {
      getDetailData.patient_dob = getDetailData.patient_dob ? dayjs(getDetailData.patient_dob, "YYYY-MM-DD") : undefined;
      getDetailData.patient_phone_number = getDetailData.patient_phone_number ?? userInfo?.user_name;

      form.resetFields(Object.keys(getDetailData as IPatientRecord));
    }
  }, [getDetailData, userInfo]);

  const getStates = async () => {
    try {
      const resp = await stateService.getList();
      setStates(resp.data);
    } catch (error) {}
  };

  const getDistricts = async (province_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);
      setDistricts(resp.data);
    } catch (error) {}
  };

  const handleChangeRadio = (event: RadioChangeEvent, fieldName: string) => {
    form.setFieldValue(fieldName, event.target.value);
  };

  const onChangeState = (value: any, option: any) => {
    form.setFieldValue("administrative_province_name", option?.label);
    form.setFieldValue("administrative_province_code", option?.value);

    form.setFieldValue("administrative_district_name", undefined);
    form.setFieldValue("administrative_district_code", undefined);

    setDistricts([]);
    setDropRenderKey(dropRenderKey + 1);

    if (option?.value) {
      getDistricts(option.value);
      return;
    }

    setDistricts([]);
  };

  const onChangeDistrict = (value: any, option: any) => {
    form.setFieldValue("administrative_district_name", option?.label);
    form.setFieldValue("administrative_district_code", option?.value);
  };

  const handleSubmit = async (values: IPatientRecord) => {
    if (!userInfo?.user_key) {
      return;
    }

    const data = JSON.parse(JSON.stringify(values)) as IPatientRecord;
    if (values.patient_dob) {
      data.patient_dob = (values.patient_dob as Dayjs).format("YYYY-MM-DD");
    }

    let payload: IPatientRecordData;

    if (id && id != "0") {
      // update
      payload = data as any;
      payload.id = id;

      handleUpdatePatientRecord(payload, id);
      naviagte(baseRoutes.userProfileList);
    } else {
      // create new
      payload = {
        id: 0,
        active: true,
        patient_record: undefined,
        user_key: userInfo.user_key,
      };

      payload.patient_record = data;

      await handleCreatePatientRecord(payload);
      naviagte(baseRoutes.userProfileList);
    }
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">
          {id && id != "0" ? t("user.profile.labelDetail") : t("user.profile.labelNew")}
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-6 relative">
        <CommonStyles.SpinnerUI isSpinning={isGettingDetail} />

        <Form form={form} initialValues={getDetailData} onFinish={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
          <CommonStyles.FormFieldUI
            name="patient_name"
            rules={patientRecord.patientName()}
            component={
              <CommonStyles.InputUI
                variant="filled"
                label={t("user.profile.handler.labelName")}
                placeholder={t("user.profile.handler.placeholderName")}
                required
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_dob"
            rules={patientRecord.patientDob()}
            component={
              <CommonStyles.DatePickerUI
                variant="filled"
                label={t("user.profile.handler.labelDateOfBirth")}
                placeholder={t("user.profile.handler.placeholderDateOfBirth")}
                required
                format="DD/MM/YYYY"
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_gender"
            component={
              <CommonStyles.RadioUI
                handleChange={(e) => handleChangeRadio(e, "patient_gender")}
                options={optionGender}
                label={t("user.profile.handler.labelGender")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_ethic"
            component={
              <CommonStyles.RadioUI
                handleChange={(e) => handleChangeRadio(e, "patient_ethic")}
                options={optionEthics}
                label={t("user.profile.handler.labelEthic")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="administrative_province_code"
            rules={patientRecord.province()}
            component={
              <CommonStyles.SelectUI
                variant="filled"
                placeholder={t("user.profile.syncFromSite.placeHolderState")}
                options={states.map((state: IState) => {
                  return { label: state.name, value: state.code };
                })}
                onChange={onChangeState}
                label={t("user.profile.handler.labelProvince")}
                required
              />
            }
          />

          <CommonStyles.FormFieldUI
            key={dropRenderKey}
            name="administrative_district_code"
            rules={patientRecord.district()}
            component={
              <CommonStyles.SelectUI
                variant="filled"
                placeholder={t("user.profile.syncFromSite.placeHolderDistrict")}
                options={districts.map((district: IDistrict) => {
                  return { label: district.name, value: district.code };
                })}
                onChange={onChangeDistrict}
                label={t("user.profile.handler.labelDistrict")}
                required
              />
            }
          />

          <div className="sm:col-span-2">
            <CommonStyles.FormFieldUI
              name="patient_address"
              rules={patientRecord.address()}
              component={
                <CommonStyles.InputUI
                  variant="filled"
                  label={t("user.profile.handler.labelAddress")}
                  required
                  placeholder={t("user.profile.handler.placeHolderAddress")}
                />
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-x-3">
            <CommonStyles.FormFieldUI
              name="patient_height"
              component={
                <CommonStyles.InputUI
                  type="number"
                  variant="filled"
                  label={t("user.profile.handler.labelHeight") + " (cm)"}
                  placeholder={t("user.profile.handler.placeHolderHeight")}
                  min={1}
                />
              }
            />

            <CommonStyles.FormFieldUI
              name="patient_weight"
              component={
                <CommonStyles.InputUI
                  type="number"
                  variant="filled"
                  label={t("user.profile.handler.labelWeight") + " (kg)"}
                  placeholder={t("user.profile.handler.placeHolderWeight")}
                />
              }
            />
          </div>

          <CommonStyles.FormFieldUI
            name="blood_group"
            component={
              <CommonStyles.SelectUI
                variant="filled"
                label={t("user.profile.handler.labelBloodGroup")}
                placeholder={t("user.profile.handler.placeholderBloodGroup")}
                options={optionBloodGroup}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_phone_number"
            component={
              <CommonStyles.InputUI
                variant="filled"
                disabled
                label={t("user.profile.handler.labelPhoneNumber")}
                placeholder={t("user.profile.handler.placeholderPhoneNumber")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_email"
            rules={accountSetting.email()}
            component={
              <CommonStyles.InputUI
                variant="filled"
                label={t("user.profile.handler.labelEmail")}
                placeholder={t("user.profile.handler.placeholderEmail")}
              />
            }
          />

          <div className="sm:col-span-2">
            <CommonStyles.FormFieldUI
              name="default_record"
              valuePropName="checked"
              component={<CommonStyles.CheckBoxUI titleCustom={t("user.profile.handler.labelSetAsPrimary")} />}
            />
          </div>

          <div className="sm:col-span-2 flex justify-center items-center">
            <CommonStyles.ButtonUI htmlType="submit" loading={isSavingPatientRecord}>
              {t("user.profile.handler.btnSave")}
            </CommonStyles.ButtonUI>
          </div>
        </Form>
      </div>
    </div>
  );
}
