import React from "react";

export default function IconClockFilled() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3374 12.4885H10.2422C9.85667 12.4885 9.54379 12.1763 9.54379 11.7901V9.69488C9.54379 9.30866 9.85667 8.99648 10.2422 8.99648C10.6277 8.99648 10.9406 9.30866 10.9406 9.69488V11.0917H12.3374C12.7229 11.0917 13.0358 11.4039 13.0358 11.7901C13.0358 12.1763 12.7229 12.4885 12.3374 12.4885ZM10.9321 5.545C10.9335 5.53033 10.9405 5.51916 10.9405 5.50449V4.8061H11.6389C12.0244 4.8061 12.3373 4.49391 12.3373 4.1077C12.3373 3.72149 12.0244 3.4093 11.6389 3.4093H8.84532C8.45981 3.4093 8.14693 3.72149 8.14693 4.1077C8.14693 4.49391 8.45981 4.8061 8.84532 4.8061H9.54372V5.50449C9.54372 5.51916 9.5507 5.53033 9.5521 5.545C6.41001 5.89001 3.95654 8.55789 3.95654 11.7901C3.95654 15.2562 6.77667 18.0756 10.2421 18.0756C13.7076 18.0756 16.5277 15.2562 16.5277 11.7901C16.5277 8.55789 14.0742 5.89001 10.9321 5.545Z"
        fill="currentColor"
      />
    </svg>
  );
}
