import { useState } from "react";
import { IOrderInfo } from "@base/modules/order/interface/OrderInfo.interface";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import useParseOption from "@base/hooks/useParseOptions";
import { methodPayment } from "@base/constants";
import { BankCode, ECurrency, EOrderType } from "@base/enums";
import { useTranslation } from "react-i18next";
import orderService from "@base/modules/order/orderService";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";
import { RootState } from "@base/store/store";
import { baseRoutes } from "@base/routes/baseRoutes";
import useToggle from "@base/hooks/useToggle";

interface IProps {
  orderInfo: IOrderInfo;
}

export default function OrderCard({ orderInfo }: IProps) {
  //! state
  const { t } = useTranslation();
  const [method, setMethod] = useState<BankCode | null>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: dataSite, isLoading: loadingSite } = useGetSiteByCode(String(orderInfo.site_code));
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);
  const paymentSupport = dataCommon?.data.commons.find((item) => item.key === "PAYMENT_METHOD");
  const { open: openConfirmTransfer, shouldRender: shouldRenderConfirmTransfer, toggle: toggleConfirmTransfer } = useToggle();
  const [resultOrder, setResultOrder] = useState<any>();

  //! function
  const getMethodPayment = methodPayment.filter(
    (item) =>
      item.value !== BankCode.CASH && (dataSite?.data.supported_payment_methods ?? paymentSupport?.description)?.includes(String(item.bankSupport)),
  );
  const optionPayment = useParseOption(getMethodPayment, "title", "value");
  const handleChangeMethod = (value: BankCode) => {
    setMethod(value);
  };

  const handleBuyAndOrder = async () => {
    if (!orderInfo.order_key) {
      return;
    }

    try {
      setIsLoading(true);
      const resp = await orderService.getOrderCode(orderInfo.order_key, { lang: "vi", bank_code: method });
      setResultOrder(resp.data);
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: "Thanh toán thành công!" }));

      if (
        resp.data?.bank_code &&
        resp.data?.bank_code !== BankCode.BANK_TRANSFER &&
        resp.data?.bank_code !== BankCode.CASH &&
        resp.data?.payment_url
      ) {
        location.replace(resp.data.payment_url);
        return;
      }

      if (resp.data?.bank_code == BankCode.BANK_TRANSFER) {
        toggleConfirmTransfer();
        return;
      }

      if (resp.data?.bank_code == BankCode.CASH) {
        setTimeout(() => {
          redirectToResult();
        }, 1000); // alert msg
      }
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: "Không thể thanh toán! Vui lòng thử lại sau." }));
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToResult = () => {
    if (resultOrder?.order_type == EOrderType.APPT && resultOrder?.ref_key) {
      location.replace(`${baseRoutes.userAppointmentDetail.replace(":key", resultOrder?.ref_key)}`);
      return;
    }

    if (resultOrder?.order_type == EOrderType.PACKAGE && resultOrder?.ref_key) {
      location.replace(`${baseRoutes.userPackageDetail.replace(":key", resultOrder?.ref_key)}`);
      return;
    }
  };

  const onConfirmTransferInformation = () => {
    toggleConfirmTransfer();

    requestAnimationFrame(() => {
      redirectToResult();
    });
  };

  //! render
  return (
    <div className="flex flex-col items-start gap-4 w-[370px] p-6 bg-white rounded-2xl [box-shadow:_0px_0px_24px_-4px_rgba(0,0,0,0.03),_0px_1px_4px_0px_rgba(0,0,0,0.06)]">
      <div className="w-full flex flex-col gap-4">
        <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base font-medium text-common-maintext">
          Số tiền thanh toán:
        </CommonStyles.TypographyUI>

        <div className="flex items-center gap-4">
          <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] !mb-0 text-base font-normal text-common-subtext">
            Tổng tiền:
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="grow !mb-0 text-base font-normal text-common-maintext">
            {orderInfo?.real_amounts?.toLocaleString()}
            {ECurrency.VND}
          </CommonStyles.TypographyUI>
        </div>

        <div className="flex items-center gap-4">
          <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] !mb-0 text-base font-normal text-common-subtext">
            Khuyến mại:
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="grow !mb-0 text-base font-normal text-common-maintext">
            {orderInfo?.discount_amounts?.toLocaleString()}
            {ECurrency.VND}
          </CommonStyles.TypographyUI>
        </div>

        <div className="flex items-center gap-4">
          <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] !mb-0 text-base font-normal text-common-subtext">
            Thanh toán:
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="grow !mb-0 text-base font-normal text-common-maintext">
            {orderInfo?.total_amounts?.toLocaleString()}
            {ECurrency.VND}
          </CommonStyles.TypographyUI>
        </div>
      </div>

      <Divider className="my-0" />

      <CommonStyles.SelectUI
        required
        label="Phương thức thanh toán:"
        labelStyle="text-base font-medium text-common-maintext"
        rootClass="w-full"
        style={{ height: 48 }}
        options={optionPayment}
        value={method}
        onChange={handleChangeMethod}
        variant="filled"
        placeholder="Chọn phương thức thanh toán"
      />

      <Divider className="my-0" />

      <CommonStyles.CheckBoxUI
        defaultChecked
        titleCustom={
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-sm font-normal">
            <span className="text-common-maintext">Tôi đã đọc và đồng ý với các</span>{" "}
            <span className="text-common-inprogress">Điều khoản giao dịch và chính sách của 365 Medihome</span>
          </CommonStyles.TypographyUI>
        }
      />

      <CommonStyles.ButtonUI disabled={!method} loading={isLoading} onClick={handleBuyAndOrder} className="w-full h-12 font-medium text-base">
        {t("payNow")}
      </CommonStyles.ButtonUI>

      {shouldRenderConfirmTransfer && (
        <CommonStyles.TransferInformationDialog
          resultOrder={resultOrder}
          transferContent={resultOrder.description}
          toggle={onConfirmTransferInformation}
          open={openConfirmTransfer}
        />
      )}
    </div>
  );
}
