import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";

class StringeeService {
  getStringeeToken() {
    return httpService.post<string>(`${apiService.conferencing.stringee.getToken}`, undefined);
  }
}

export default new StringeeService();
