import { responseUI } from "@base/themes/response";
import { Typography } from "antd";
import React from "react";
const { Title, Text, Link, Paragraph } = Typography;
interface IProps {
  use?: "text" | "title" | "link" | "paragraph";
  children: React.ReactNode | string;
  className?: string;
  code?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  editable?: boolean;
  keyboard?: boolean;
  mark?: boolean;
  onClick?: (event: any) => void;
  strong?: boolean;
  italic?: boolean;
  type?: "secondary" | "success" | "warning" | "danger";
  underline?: boolean;
  level?: 1 | 2 | 3 | 4 | 5;
  href?: string;
  target?: string;
  title?: string;
}
export default function TypographyUI(props: IProps) {
  //! state
  const {
    use = "text",
    code,
    deleted,
    disabled,
    editable,
    italic,
    keyboard,
    mark,
    onClick,
    strong,
    type,
    underline,
    level,
    href,
    target,
    children,
    className,
    title,
    ...res
  } = props;
  //! render
  if (use === "title") {
    return (
      <Title
        code={code}
        delete={deleted}
        disabled={disabled}
        editable={editable}
        mark={mark}
        onClick={onClick}
        italic={italic}
        type={type}
        underline={underline}
        level={level}
        className={`${className} `}
        {...res}
      >
        {children}
      </Title>
    );
  }
  if (use === "link") {
    return (
      <Link href={href} target={target}>
        {children}
      </Link>
    );
  }
  if (use === "paragraph") {
    return (
      <Paragraph
        code={code}
        delete={deleted}
        disabled={disabled}
        editable={editable}
        mark={mark}
        onClick={onClick}
        strong={strong}
        italic={italic}
        type={type}
        underline={underline}
        // className={`${className} ${responseUI.text.default}`}
        className={className}
        title={title}
        {...res}
      >
        {children}
      </Paragraph>
    );
  }
  return (
    <Text
      code={code}
      delete={deleted}
      disabled={disabled}
      editable={editable}
      keyboard={keyboard}
      mark={mark}
      onClick={onClick}
      strong={strong}
      italic={italic}
      type={type}
      underline={underline}
      className={`text-common-maintext ${className}`}
      title={title}
      {...res}
    >
      {children}
    </Text>
  );
}
