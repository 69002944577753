import React from "react";

export default function IconVideo() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5157 9.86997C27.681 9.5073 26.7131 9.66464 26.0477 10.278L23.1664 12.9446V11.0086C23.1664 8.8033 21.3731 7.00864 19.1664 7.00864H7.16638C4.96105 7.00864 3.16638 8.8033 3.16638 11.0086V21.6753C3.16638 23.882 4.96105 25.6753 7.16638 25.6753H19.1664C21.3731 25.6753 23.1664 23.882 23.1664 21.6753V19.7406L26.0477 22.406C26.4757 22.802 27.0291 23.0086 27.5931 23.0086C27.9037 23.0086 28.2171 22.946 28.5157 22.8153C29.3277 22.4606 29.8331 21.702 29.8331 20.8366V11.8486C29.8331 10.9833 29.3277 10.2246 28.5157 9.86997Z"
        fill="#231F20"
      />
      <mask id="mask0_2302_1423" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3" y="7" width="27" height="19">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5157 9.86997C27.681 9.5073 26.7131 9.66464 26.0477 10.278L23.1664 12.9446V11.0086C23.1664 8.8033 21.3731 7.00864 19.1664 7.00864H7.16638C4.96105 7.00864 3.16638 8.8033 3.16638 11.0086V21.6753C3.16638 23.882 4.96105 25.6753 7.16638 25.6753H19.1664C21.3731 25.6753 23.1664 23.882 23.1664 21.6753V19.7406L26.0477 22.406C26.4757 22.802 27.0291 23.0086 27.5931 23.0086C27.9037 23.0086 28.2171 22.946 28.5157 22.8153C29.3277 22.4606 29.8331 21.702 29.8331 20.8366V11.8486C29.8331 10.9833 29.3277 10.2246 28.5157 9.86997Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_2302_1423)">
        <rect x="0.5" y="0.342773" width="32" height="32" fill="currentColor" />
      </g>
    </svg>
  );
}
