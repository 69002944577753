import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";

class PromotionService {
  getPromotionListPublic(query: any) {
    return httpService.get(apiService.utils.promotion.listPublic, {
      params: query,
    });
  }

  getPromotionListPublicOnline(query: any) {
    return httpService.get(apiService.utils.promotion.listPublicOnline, {
      params: query,
    });
  }
  promotionApply(code: string, query: any) {
    return httpService.post(`${apiService.utils.promotion.apply}/${code}`, {}, { params: query });
  }
  promotionGetByType(code: string, query: any) {
    return httpService.get(`${apiService.utils.promotion.getByType}/${code}`, {
      params: query,
    });
  }
}

export default new PromotionService();
