import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IDrService } from "./interface/drService.interface";

class DrServiceService {
  // get list
  getList(lang: string, online?: boolean, specialization_code?: string, siteCode?: string) {
    return httpService.get<IDrService[]>(`${apiService.doctor.drService.getList}`, {
      params: { lang, online, specialization_code, siteCode },
    });
  }

  // get by id
  getById(id: string | number, lang: string) {
    return httpService.get<IDrService>(`${apiService.doctor.drService.getById}/${id}/${lang}`);
  }
}

export default new DrServiceService();
