import React, { useState } from "react";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { WPPostsFilter } from "@base/modules/wpApis/interface";
import TypographyUI from "@components/CommonStyles/Typography";
import { Post, PostsCategory } from "@base/types/wp";
import { NavLink } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonImages from "@base/assets/images";

interface IProps {
  category?: PostsCategory;
  className?: string;
}

const LatestMedicationInfo = ({ category, className }: IProps) => {
  const [filter, setFilter] = useState<WPPostsFilter>({
    _embed: true,
    page: 1,
    per_page: 6,
    categories: category ? [category.id] : undefined,
    orderby: "date",
    order: "desc",
  });
  const { data, error, isLoading, refetch } = useGetPosts(filter, true);

  const Cart = ({ p }: { p: Post }) => {
    const imgLink =
      p._embedded &&
      p._embedded["wp:featuredmedia"] &&
      p._embedded["wp:featuredmedia"].length &&
      p._embedded["wp:featuredmedia"][0] &&
      p._embedded["wp:featuredmedia"][0].source_url
        ? p._embedded["wp:featuredmedia"][0].source_url
        : "";

    return (
      <div className="w-full flex flex-col gap-0">
        <div className="grid grid-cols-2 gap-3">
          <div className="rounded-lg w-full flex justify-center items-center mb-1">
            <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
              <img
                className="rounded-lg w-full cursor-pointer drop-shadow-lg"
                src={imgLink ?? ""}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.DrafInforArticle;
                }}
              />
            </NavLink>
          </div>

          <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
            <TypographyUI className="text-sm font-medium text-[#000000] line-clamp-3 cursor-pointer hover:text-[#005984] !mb-0">
              <div dangerouslySetInnerHTML={{ __html: p.title?.rendered ?? "" }}></div>
            </TypographyUI>
          </NavLink>
        </div>

        <TypographyUI use="paragraph" className="text-[#525252] text-xs font-light line-clamp-3 !mb-0">
          <div dangerouslySetInnerHTML={{ __html: p.excerpt?.rendered ?? "" }}></div>
        </TypographyUI>
      </div>
    );
  };

  return data?.data.length ? (
    <div
      className={`w-full h-fit px-3 py-4 my-4 border border-[#F68E1E] rounded-xl bg-[#AAAAAA] bg-opacity-[0.14]${className ? " " + className : ""}`}
    >
      <div className="w-full flex justify-center mb-3">
        <TypographyUI className="text-base font-medium !text-[#F68E1E] line-clamp-1 uppercase">Tin tức mới cập nhật</TypographyUI>
      </div>

      <div className="flex flex-col gap-4">
        {data?.data.map((e, idx: number) => {
          return <Cart key={idx} p={e} />;
        })}
      </div>
    </div>
  ) : null;
};

export default LatestMedicationInfo;
