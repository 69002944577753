export default function IconRating() {
  return (
    <div>
      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.85166 4.20485H1.50666C0.779156 4.20485 0.479156 5.14235 1.07166 5.56235L3.80166 7.51235L2.72916 10.9698C2.51166 11.6673 3.32166 12.2298 3.89916 11.7873L6.66666 9.68735L9.43416 11.7948C10.0117 12.2373 10.8217 11.6748 10.6042 10.9773L9.53166 7.51985L12.2617 5.56985C12.8542 5.14235 12.5542 4.21235 11.8267 4.21235H8.48166L7.37916 0.582349C7.16166 -0.130151 6.15666 -0.130151 5.94666 0.582349L4.85166 4.20485Z"
          fill="#F9A006"
        />
      </svg>
    </div>
  );
}
