import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";

export default function Step4() {
  //! state
  //! function
  //! render
  return (
    <div className="bg-common-white p-8 rounded-s-lg flex flex-col items-center gap-6 px-80 text-center">
      <div>
        <img src={CommonImages.PaymentSuccess} alt={CommonImages.PaymentSuccess} />
      </div>
      <CommonStyles.TypographyUI use="paragraph" className="text-base font-semibold text-common-maintext !m-0">
        Đặt lịch khám thành công
      </CommonStyles.TypographyUI>

      <CommonStyles.TypographyUI use="paragraph" className="text-base text-common-subtext">
        Bạn đã đặt lịch khám thành công lúc <span className="text-common-maintext font-medium">07:00 ngày 04-08-2021</span> tại
        <span className="text-common-maintext font-medium">Bệnh Viện An Việt</span>. Vui lòng đến theo đúng thời gian đã hẹn lịch, nếu tới muộn sau 15
        phút quý khách khám theo thứ tự phát số. Vui lòng mang thẻ BHYT (nếu có) khi đến khám. Rất mong được đón tiếp quý khách
      </CommonStyles.TypographyUI>
    </div>
  );
}
