import React from "react";

export default function K18() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.287109" y="0.833954" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.28711" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27311)">
          <path
            d="M53.7479 49.1135C54.2276 48.6338 54.2277 47.8532 53.7479 47.3734C53.2681 46.8936 52.4875 46.8936 52.0077 47.3734C51.528 47.8531 51.528 48.6338 52.0077 49.1135C52.4875 49.5933 53.2681 49.5932 53.7479 49.1135Z"
            fill="url(#paint0_linear_1205_27311)"
          />
          <path
            d="M52.0079 39.2527C50.2461 41.0145 49.6275 43.4921 50.1492 45.758C50.1877 45.7158 50.227 45.674 50.2677 45.6333C51.707 44.194 54.0489 44.194 55.4882 45.6333C56.9274 47.0725 56.9274 49.4144 55.4882 50.8537C55.4474 50.8944 55.4057 50.9337 55.3635 50.9722C57.6293 51.4939 60.1069 50.8754 61.8688 49.1135C64.5874 46.3949 64.5874 41.9713 61.8688 39.2527C59.1501 36.534 54.7265 36.534 52.0079 39.2527ZM56.3582 42.443C55.8777 42.9235 55.0986 42.9235 54.6181 42.443C54.1376 41.9625 54.1376 41.1834 54.6181 40.7029C55.0986 40.2223 55.8777 40.2223 56.3582 40.7029C56.8388 41.1833 56.8388 41.9625 56.3582 42.443ZM60.4186 46.5033C59.9381 46.9838 59.159 46.9838 58.6785 46.5033C58.1979 46.0228 58.1979 45.2437 58.6785 44.7632C59.159 44.2826 59.9381 44.2826 60.4186 44.7632C60.8992 45.2437 60.8992 46.0228 60.4186 46.5033Z"
            fill="url(#paint1_linear_1205_27311)"
          />
          <path
            d="M40.3574 40.4626L36.1416 44.6783L43.4174 71.8318C45.1497 71.858 46.8856 71.5733 48.5339 70.9778L40.3574 40.4626Z"
            fill="url(#paint2_linear_1205_27311)"
          />
          <path
            d="M50.7999 69.9264C51.8684 69.3115 52.8744 68.5489 53.7868 67.6365L59.8278 61.5956L47.6968 58.3451L50.7999 69.9264Z"
            fill="url(#paint3_linear_1205_27311)"
          />
          <path
            d="M67.0893 54.334C72.6865 48.7369 72.6865 39.6295 67.0893 34.0324C61.4921 28.4352 52.3848 28.4352 46.7876 34.0324L42.3667 38.4533L46.9613 55.6003L61.8371 59.5862L67.0893 54.334ZM50.2679 37.5126C53.946 33.8345 59.9308 33.8345 63.609 37.5126C67.2872 41.1907 67.2872 47.1756 63.609 50.8537C59.9308 54.5318 53.9461 54.5318 50.268 50.8537C46.5898 47.1756 46.5898 41.1907 50.2679 37.5126Z"
            fill="url(#paint4_linear_1205_27311)"
          />
          <path
            d="M34.1322 46.6877L33.485 47.3349C27.8878 52.9321 27.8878 62.0394 33.485 67.6367C35.5586 69.7103 38.1142 71.0149 40.7945 71.552L34.1322 46.6877Z"
            fill="url(#paint5_linear_1205_27311)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27311"
            x="21.2871"
            y="25.834"
            width="58"
            height="58"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27311" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27311" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27311" x1="52.8778" y1="47.0135" x2="52.8778" y2="49.4733" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1205_27311" x1="56.939" y1="37.2137" x2="56.939" y2="51.1512" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint2_linear_1205_27311" x1="42.3377" y1="40.4626" x2="42.3377" y2="71.8335" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint3_linear_1205_27311" x1="53.7623" y1="58.3451" x2="53.7623" y2="69.9264" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint4_linear_1205_27311" x1="56.8269" y1="29.8345" x2="56.8269" y2="59.5862" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint5_linear_1205_27311" x1="35.0408" y1="46.6877" x2="35.0408" y2="71.552" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
