import CommonIcons from "@base/assets/icons";
import { imageSpecializationActive } from "@base/constants";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";
import useGetSpecializetation from "@base/modules/specialization/hooks/useGetSpecialization";
import { ISpecialization } from "@base/modules/specialization/interface/specialization.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import TabDoctorSpecialList from "@components/Specialist/[id]/TabDoctorSpecialList";
import TabFacility from "@components/Specialist/[id]/TabFacility";
import TabInfo from "@components/Specialist/[id]/TabInfo";
import TabPackageServiceSpecialList from "@components/Specialist/[id]/TabPackageService";
import { TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export default function SpecialistDetail() {
  //! state
  const { id } = useParams();

  const [searchParams, setSearchPamrams] = useSearchParams();
  const specialist = searchParams.get("root") === "specialist";
  const tabActive = searchParams.get("tab");
  const site_code = searchParams.get("site_code");
  const specialization_code = searchParams.get("specialization_code");

  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.orderFacility,
      title: "Đặt lịch",
    },
    {
      href: baseRoutes.orderFacility,
      title: "Khám tại cơ sở y tế",
    },
    {
      href: `${baseRoutes.orderFacility}/1`,
      title: "PKĐK. Dr.Binh Tele Clinic",
    },
    { title: "Chuyên khoa tai mũi họng" },
  ];
  const [activeKey, setActiveKey] = useState(tabActive);

  const { data: dataSpecialization, isLoading: loadingSpecialization } = useGetSpecializetation();
  const [specializationDetail, setSpecializatonDetail] = useState<{
    data: ISpecialization | undefined;
    icon: any;
  }>();
  const ImageIcon = specializationDetail?.icon;
  const { data: detailSite } = useGetSiteByCode(String(site_code));
  //! function
  useEffect(() => {
    const speDetail = dataSpecialization?.data.find((item) => item.id === Number(id));
    const ImageSpecialization = imageSpecializationActive.find((item) => item.key === speDetail?.specialization_code);

    setSpecializatonDetail({
      data: speDetail,
      icon: ImageSpecialization,
    });
  }, [loadingSpecialization]);

  const tabs: TabsProps["items"] = [
    // {
    //   key: "1",
    //   label: "Thông tin chi tiết",
    //   children: <TabInfo />,
    // },
    {
      key: "2",
      label: "Bác sĩ chuyên khoa",
      children: <TabDoctorSpecialList />,
    },
    {
      key: "3",
      label: "Gói dịch vụ",
      children: <TabPackageServiceSpecialList />,
    },
  ];

  const tabsSpecialList: TabsProps["items"] = [
    {
      key: "1",
      label: "Cơ sở y tế",
      children: <TabFacility />,
    },
    {
      key: "2",
      label: "Bác sĩ chuyên khoa",
      children: <TabDoctorSpecialList />,
    },
    {
      key: "3",
      label: "Gói dịch vụ",
      children: <TabPackageServiceSpecialList specialization={specializationDetail?.data} />,
    },
  ];
  //! render
  const CustomTabBar = ({ activeKey, setActiveKey }: any) => {
    // Handle tab click event
    //! state

    //! function

    const handleTabClick = (key: any) => {
      setActiveKey(key);
      setSearchPamrams({ site_code: String(site_code), specialization_code: String(specialization_code), tab: key });
    };
    //! render

    const renderTab = () => {
      return (specialist ? tabsSpecialList : tabs).map((item, index) => {
        const active = activeKey === `${item.key}` ? "text-common-orange bg-common-orange100" : "";
        return (
          <div
            className={`py-3 px-10 text-common-maintext font-medium text-base text-center gap-3 rounded-lg cursor-pointer grow custom-tab  ${active}`}
            onClick={() => handleTabClick(item.key)}
            key={index}
          >
            {item.icon}
            {item.label}
          </div>
        );
      });
    };
    return <div className="custom-tab-bar bg-white p-3 rounded-2xl flex">{renderTab()}</div>;
  };
  return (
    <div>
      <div className={`bg-common-white pb-8 relative ${initialPage.paddingX} py-5 flex flex-col justify-between`}>
        {/* <CommonStyles.BreadcrumbUI items={breadcrumItem} /> */}

        <div className="flex gap-7 p-4 rounded-2xl bg-common-white mt-9">
          <div className="bg-common-background p-3 rounded-2xl">
            <div className="bg-common-white p-2 rounded-lg">
              <div className="p-24 bg-common-orange100 rounded-lg">{ImageIcon ? <ImageIcon.value /> : <CommonIcons.NoA />}</div>
            </div>
          </div>

          <div className="grow">
            <div className="flex flex-col justify-center gap-4 h-full">
              <CommonStyles.TypographyUI className="font-medium text-xl text-common-maintext uppercase">
                {specializationDetail?.data?.specialization_name}
              </CommonStyles.TypographyUI>

              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <CommonIcons.IconFacilityOutline />
                  <CommonStyles.TypographyUI className="text-base text-common-subtext">{detailSite?.data.name}</CommonStyles.TypographyUI>
                </div>
                <div className="flex items-center gap-3">
                  {specialist ? <CommonIcons.IconUserOutline /> : <CommonIcons.IconLocation />}

                  <CommonStyles.TypographyUI className="text-base text-common-subtext">{detailSite?.data.address}</CommonStyles.TypographyUI>
                </div>
              </div>

              <div>{/* <CommonStyles.TypographyUI>{detailSite?.data.name}</CommonStyles.TypographyUI> */}</div>
              {/* <div className="flex items-center gap-4">
                <CommonStyles.ButtonUI
                  icon={<CommonIcons.RightOutlined />}
                  iconPosition="end"
                  className="h-12 font-medium !py-8 bg-gradient-to-b from-[#FFB571] to-common-mainColor min-w-64 justify-between"
                >
                  <div className="flex gap-2">
                    <CommonIcons.IconCameraBox />
                    <div className="flex flex-col items-start">
                      <CommonStyles.TypographyUI className="text-common-white text-base font-medium">Tư vấn online</CommonStyles.TypographyUI>
                      <CommonStyles.TypographyUI className="text-sm text-common-white">110.000.000đ</CommonStyles.TypographyUI>
                    </div>
                  </div>
                </CommonStyles.ButtonUI>

                <CommonStyles.ButtonUI
                  icon={<CommonIcons.RightOutlined />}
                  iconPosition="end"
                  className="h-12 font-medium !py-8 bg-gradient-to-t from-[#2F80ED] to-[#82B3F4] min-w-64 justify-between hover:!bg-gradient-to-t"
                >
                  <div className="flex gap-2">
                    <CommonIcons.IconFacilityBox />
                    <div className="flex flex-col items-start">
                      <CommonStyles.TypographyUI className="text-common-white text-base font-medium">Tại cơ sở y tế</CommonStyles.TypographyUI>
                      <CommonStyles.TypographyUI className="text-sm text-common-white">300.000đ</CommonStyles.TypographyUI>
                    </div>
                  </div>
                </CommonStyles.ButtonUI>
              </div> */}
            </div>
          </div>
        </div>

        <div className="absolute right-0 bottom-0">
          <CommonIcons.IconHeart width={150} height={150} />
        </div>
      </div>

      <div className={`${initialPage.paddingX} py-4`}>
        <CommonStyles.TabsUI
          activeKey={String(activeKey)}
          items={specialist ? tabsSpecialList : tabs}
          renderTabBar={(props, DefaultTabBar) => <CustomTabBar setActiveKey={setActiveKey} {...props} />}
        />
      </div>
    </div>
  );
}
