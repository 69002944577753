import React from "react";

export default function IconDrafSpecialist() {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_di_899_19318)">
          <rect x="6" y="2" width="60" height="60" rx="16" fill="url(#paint0_linear_899_19318)" shape-rendering="crispEdges" />
          <rect x="5" y="1" width="62" height="62" rx="17" stroke="white" strokeOpacity="0.5" strokeWidth="2" shape-rendering="crispEdges" />
        </g>
        <g filter="url(#filter1_d_899_19318)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.8205 19.3395C29.6101 16.6241 32.5464 15.1475 36.7728 15.1475C40.4075 15.1475 42.8175 16.9392 44.2791 19.1297C45.6981 21.2563 46.2371 23.7576 46.3134 25.4372C46.3491 26.2212 45.7423 26.8858 44.9583 26.9214C44.1742 26.957 43.5097 26.3503 43.474 25.5662C43.415 24.2681 42.9795 22.3029 41.9148 20.7073C40.8928 19.1757 39.3099 17.9898 36.7728 17.9898C33.2842 17.9898 31.3479 19.1525 30.1937 20.9036C28.9579 22.7786 28.4488 25.5753 28.4488 29.1561C28.4488 32.3354 29.6416 34.0238 31.0271 35.4093C31.3913 35.7735 31.7685 36.1155 32.1635 36.4721L32.2063 36.5108C32.5795 36.8477 32.9743 37.2042 33.3429 37.5728C34.11 38.3399 34.8721 39.2625 35.2787 40.4821C35.4189 40.9028 35.5404 41.2934 35.6588 41.6737C35.8284 42.2188 35.9915 42.7429 36.1939 43.3052C36.5186 44.2072 36.8698 44.9728 37.3097 45.5727C37.7354 46.1531 38.2301 46.5602 38.8656 46.789C39.5132 47.0222 40.4322 47.1149 41.7727 46.8468C42.1367 46.774 42.6712 46.3538 43.1031 45.4469C43.5286 44.5532 43.6098 43.6855 43.515 43.3064C43.3246 42.545 43.7876 41.7734 44.549 41.583C45.3105 41.3927 46.0821 41.8556 46.2725 42.6171C46.5837 43.8622 46.2589 45.4308 45.6693 46.6689C45.086 47.8937 43.9964 49.3007 42.3301 49.634C40.6252 49.9749 39.1587 49.9154 37.9029 49.4633C36.6351 49.0069 35.7087 48.1959 35.0177 47.2536C34.341 46.3308 33.8801 45.2693 33.5196 44.2679C33.3069 43.6771 33.0995 43.0143 32.9086 42.4042C32.7929 42.0345 32.6833 41.6841 32.5822 41.3809C32.3797 40.7734 31.9744 40.224 31.333 39.5826C31.0167 39.2663 30.6694 38.9526 30.2778 38.5991L30.2585 38.5817C29.8684 38.2293 29.4398 37.8417 29.0173 37.4191C27.256 35.6578 25.6064 33.2857 25.6064 29.1561C25.6064 25.4281 26.1124 21.931 27.8205 19.3395Z"
            fill="url(#paint1_linear_899_19318)"
          />
          <path
            d="M35.1488 23.4714C32.2253 23.1466 31.2237 27.6672 31.0883 29.9682C29.4641 25.0956 31.4944 23.4714 33.1186 22.2533C38.3972 19.817 40.0214 24.2835 40.0214 25.5016C40.0214 26.7198 41.6456 28.75 42.0516 30.3742C42.3765 31.6736 41.3749 32.5398 40.8335 32.8105C40.5628 32.9459 40.0214 33.379 40.0214 34.0287C40.0214 34.6783 41.3749 36.7356 42.0516 37.6831C42.0516 38.0891 41.8892 38.9824 41.2395 39.3073C38.3972 40.1194 36.773 38.9012 35.5549 36.4649C34.3367 34.0287 36.773 31.5924 37.1791 30.7803C37.5851 29.9682 38.8032 23.8775 35.1488 23.4714Z"
            fill="url(#paint2_linear_899_19318)"
          />
        </g>
        <defs>
          <filter id="filter0_di_899_19318" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.858135 0 0 0 0 0.694445 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_899_19318" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_899_19318" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="3.7" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_899_19318" />
          </filter>
          <filter
            id="filter1_d_899_19318"
            x="17.6064"
            y="11.1475"
            width="36.7861"
            height="50.7048"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_899_19318" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_899_19318" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_899_19318" x1="36" y1="2" x2="36" y2="62" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_899_19318" x1="35.9996" y1="15.1475" x2="35.9996" y2="49.8523" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_899_19318" x1="36.3031" y1="21.5376" x2="36.3031" y2="39.5578" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
