import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { PostsCategory } from "@base/types/wp";

class WPCategoriesService {
  getCategories() {
    return httpService.get<PostsCategory[]>(apiService.wp.categories.getCategories, {});
  }
}

export default new WPCategoriesService();
