export default function IconHomeService() {
  return (
    <div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.3334 30.333H2.66675C2.12008 30.333 1.66675 29.8797 1.66675 29.333C1.66675 28.7863 2.12008 28.333 2.66675 28.333H29.3334C29.8801 28.333 30.3334 28.7863 30.3334 29.333C30.3334 29.8797 29.8801 30.333 29.3334 30.333Z"
          fill="url(#paint0_linear_655_5879)"
        />
        <path
          d="M28 13.3069V29.3336H3.93335L4.00002 13.2936C4.00002 12.4802 4.37335 11.707 5.02668 11.2003L14.36 3.94695C15.32 3.18695 16.68 3.18695 17.64 3.94695L19.4267 5.33359L25.3333 9.92026L26.9734 11.2003C27.6267 11.707 28 12.4802 28 13.3069Z"
          fill="url(#paint1_linear_655_5879)"
        />
        <path
          d="M17.3334 22.667H14.6667C13.5601 22.667 12.6667 23.5603 12.6667 24.667V29.3337H19.3334V24.667C19.3334 23.5603 18.4401 22.667 17.3334 22.667Z"
          fill="white"
        />
        <path
          d="M12.6667 18.3337H10.0001C9.26675 18.3337 8.66675 17.7337 8.66675 17.0003V15.0003C8.66675 14.267 9.26675 13.667 10.0001 13.667H12.6667C13.4001 13.667 14.0001 14.267 14.0001 15.0003V17.0003C14.0001 17.7337 13.4001 18.3337 12.6667 18.3337Z"
          fill="white"
        />
        <path
          d="M22 18.3337H19.3333C18.6 18.3337 18 17.7337 18 17.0003V15.0003C18 14.267 18.6 13.667 19.3333 13.667H22C22.7333 13.667 23.3333 14.267 23.3333 15.0003V17.0003C23.3333 17.7337 22.7333 18.3337 22 18.3337Z"
          fill="white"
        />
        <path d="M26.9067 9.58667L21 5H25.5467C26.28 5 26.88 5.58665 26.88 6.31999L26.9067 9.58667Z" fill="#FBB573" />
        <defs>
          <linearGradient id="paint0_linear_655_5879" x1="22.6737" y1="28.3301" x2="22.3659" y2="31.6293" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9939" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint1_linear_655_5879" x1="21.5694" y1="3.33873" x2="1.34595" y2="17.3594" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9939" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
