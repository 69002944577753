import React, { useEffect, useRef, useState } from "react";
import { optionContribues } from "@base/constants";
import useToggle from "@base/hooks/useToggle";
import { ICollaboration } from "@base/modules/collaboration/collaboration.interface";
import CommonStyles from "@components/CommonStyles";
import { Form, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import { ECollaborationType } from "@base/enums";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import useFormValidation from "@base/hooks/useFormvalidation";
import collaborationService from "@base/modules/collaboration/collaborationService";
import administrativeService from "@base/modules/administrative/administrativeService";
import { IProvince } from "@base/modules/administrative/administrative.interface";

export default function FormPartner() {
  //! state
  const dispatch = useDispatch();
  const { collaboration } = useFormValidation();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { open, shouldRender, toggle } = useToggle();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [provinces, setProvinces] = useState<IProvince[]>([]);

  const initialValues: ICollaboration = {
    type: ECollaborationType.SITE,
    name: "",
    province: "",
    phone_number: "",
    email: "",
    address: "",
    name_of_representative: "",
    referral_code: "",
  };

  const [token, setToken] = useState<string | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);

  //! function
  useEffect(() => {
    getProvinces();
  }, []);

  const getProvinces = async () => {
    try {
      const resp = await administrativeService.getAllProvince();
      setProvinces(resp.data);
    } catch (error) {}
  };

  const handleChangeRadio = (event: RadioChangeEvent) => {
    form.setFieldValue("type", event.target.value);
  };

  const handleSubmit = async (values: ICollaboration) => {
    if (!token) {
      dispatch(setAlertMessage({ type: "warning", message: "Thông báo", description: "Bạn chưa xác nhận CAPTCHA!" }));
      return;
    }

    try {
      setLoading(true);
      await collaborationService.register(token, values);
      form.resetFields(["type", "name", "province", "phone_number", "email", "address", "name_of_representative", "referral_code"]);

      toggle();
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: "Thông báo", description: "Gửi yêu cầu thất bại! Vui lòng thử lại." }));
    } finally {
      setLoading(false);
      captchaRef.current?.reset();
    }
  };

  //! render
  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit} layout="vertical" className="grid grid-cols-2 gap-x-6">
      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.type()}
          name="type"
          component={<CommonStyles.RadioUI handleChange={handleChangeRadio} options={optionContribues} label={t("Partner.Form.position")} required />}
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.name()}
          name="name"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("Partner.Form.facilityName")}
              placeholder={t("Partner.Form.placeholderFacilityName")}
              required
              onChange={(event) => form.setFieldValue("name", event.target.value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.name_of_representative()}
          name="name_of_representative"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("Partner.Form.fullname")}
              placeholder={t("Partner.Form.placeholderfullname")}
              required
              onChange={(event) => form.setFieldValue("name_of_representative", event.target.value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.province()}
          name="province"
          component={
            <CommonStyles.SelectUI
              variant="filled"
              showSearch
              label={t("Partner.Form.Province/City")}
              labelSameWithInput={true}
              placeholder={t("Partner.Form.placeholderProvince")}
              required
              options={provinces.map((province: IProvince) => {
                return { label: province.name, value: province.name };
              })}
              onChange={(value: any, option: any) => form.setFieldValue("province", value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.phone_number()}
          name="phone_number"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("Partner.Form.Phonenumber")}
              placeholder={t("Partner.Form.placeholderPhonenumber")}
              required
              onChange={(event) => form.setFieldValue("phone_number", event.target.value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.email()}
          name="email"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("Partner.Form.Email")}
              placeholder={t("Partner.Form.placeholderEmail")}
              required
              onChange={(event) => form.setFieldValue("email", event.target.value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.address()}
          name="address"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("Partner.Form.Address")}
              placeholder={t("Partner.Form.placeholderAddress")}
              required
              onChange={(event) => form.setFieldValue("address", event.target.value)}
            />
          }
        />
      </div>

      <div className="max-sm:col-span-2">
        <CommonStyles.FormFieldUI
          rules={collaboration.referral_code()}
          name="referral_code"
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={"Mã giới thiệu"}
              placeholder={"Nhập mã giới thiệu"}
              onChange={(event) => form.setFieldValue("referral_code", event.target.value)}
            />
          }
        />
      </div>

      {process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY ? (
        <div className="col-span-2 mb-6 flex justify-center">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
            ref={captchaRef}
            hl={i18n.resolvedLanguage}
            onChange={(_token) => setToken(_token)}
          />
        </div>
      ) : null}

      <div className="col-span-2 flex justify-center">
        <CommonStyles.ButtonUI htmlType="submit" className="h-12" disabled={!captchaRef.current?.getValue()} loading={loading}>
          <CommonStyles.TypographyUI className="text-base font-semibold text-common-white">{t("Partner.Form.SendRequest")}</CommonStyles.TypographyUI>
        </CommonStyles.ButtonUI>
      </div>

      <CommonStyles.NotificationUI
        open={open}
        toggle={toggle}
        title={t("Partner.Form.titleMessage")}
        content={t("Partner.Form.contentMessage")}
        hiddenAction
      />
    </Form>
  );
}
