import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { DataPartnerConnect } from "@base/constants";
import { getAvatarLink } from "@base/helpers/common";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";

export default function PartnerConnect() {
  //! state
  const { t } = useTranslation();
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrowCarousel />,
    prevArrow: <RightArrowCarousel />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
    ],
  };
  //! function
  const renderPartner = () => {
    return DataPartnerConnect?.map((partner, index) => {
      // const linkBanner = getAvatarLink(partner.avatar as any, "banner");
      return (
        <div className="px-5 py-5" key={index}>
          <div className="bg-common-white rounded-xl flex justify-center w-[150px] lg:w-[188px]">
            <img
              className="p-2 rounded-xl aspect-video"
              src={partner.image}
              alt={partner.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = CommonImages.SiteDefault;
              }}
            />
          </div>
        </div>
      );
    });
  };
  //! render
  return (
    <div
      style={{
        backgroundImage: `url(${CommonImages.PartnerConnect})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      // className={`py-10`}
    >
      <div className={`${responseUI.screen} py-10 ${responseUI.padding}`}>
        <div>
          <CommonStyles.TypographyUI className="!text-common-white text-center uppercase !text-base lg:!text-2xl" use="title" level={3}>
            {t("home.partnerConnect")}
          </CommonStyles.TypographyUI>

          <CommonStyles.CarouselUI settings={settings}>{renderPartner()}</CommonStyles.CarouselUI>
        </div>
      </div>
    </div>
  );
}

const NextArrowCarousel = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <div className="!bg-common-white border rounded-full text-common-orange flex items-center justify-center p-2 w-8 h-8">
        <CommonIcons.RightOutlined />
      </div>
    </div>
  );
};

const RightArrowCarousel = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <div className="!bg-common-white border rounded-full text-common-orange flex items-center justify-center p-2 w-8 h-8">
        <CommonIcons.LeftOutlined />
      </div>
    </div>
  );
};
