import CommonIcons from "@base/assets/icons";
import themeColor from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { Select, SelectProps } from "antd";
import React from "react";

interface IProps extends SelectProps {
  label?: string;
  required?: boolean;
  labelStyle?: string;
  rootClass?: string;
  labelSameWithInput?: boolean;
  extraAction?: () => React.ReactNode;
}
export default function SelectUI(props: IProps) {
  //! state
  const { label, required, labelStyle, rootClass, labelSameWithInput = false, extraAction, ...res } = props;
  //! function
  const handleChange = (value: string) => {};
  //! render
  return (
    <div className={rootClass}>
      {label && !labelSameWithInput ? (
        <div className="flex justify-between items-center">
          <div className="mb-1 flex">
            <div className={`${labelStyle} ${responseUI.text.default}`}>{label}</div>
            {required && <span className="text-common-red500">&nbsp;*</span>}
          </div>
          {extraAction && extraAction()}
        </div>
      ) : null}

      {label && labelSameWithInput ? (
        <div className="flex justify-between">
          <div className={`mb-1 ${responseUI.text.default}`}>
            {label}
            {required && <span className="text-common-red500">{` *`}</span>}
          </div>
          {extraAction && extraAction()}
        </div>
      ) : null}

      <Select
        className={`h-10 lg:h-12 !w-full ${res.className}`}
        onChange={handleChange}
        options={res.options}
        placeholder={res.placeholder}
        suffixIcon={<CommonIcons.IconDown color={themeColor.mainColor} />}
        {...res}
      />
    </div>
  );
}
