export default function IconWarning() {
  return (
    <div>
      <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 32.5C0 14.8269 14.3269 0.5 32 0.5C49.6731 0.5 64 14.8269 64 32.5C64 50.1731 49.6731 64.5 32 64.5C14.3269 64.5 0 50.1731 0 32.5Z"
          fill="#FEE59B"
        />
        <path
          d="M21.96 43.8383H42.04C44.0933 43.8383 45.3733 41.6116 44.3467 39.8383L34.3067 22.4916C33.28 20.7183 30.72 20.7183 29.6933 22.4916L19.6533 39.8383C18.6267 41.6116 19.9067 43.8383 21.96 43.8383ZM32 34.505C31.2667 34.505 30.6667 33.905 30.6667 33.1716V30.505C30.6667 29.7716 31.2667 29.1716 32 29.1716C32.7333 29.1716 33.3333 29.7716 33.3333 30.505V33.1716C33.3333 33.905 32.7333 34.505 32 34.505ZM33.3333 39.8383H30.6667V37.1716H33.3333V39.8383Z"
          fill="#F9A006"
        />
      </svg>
    </div>
  );
}
