export default function IconExperienceHome() {
  return (
    <div>
      <svg width="149" height="179" viewBox="0 0 149 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8" filter="url(#filter0_f_1532_17146)">
          <rect x="30.1233" y="61" width="88" height="88" rx="24" fill="#FED4A5" />
        </g>
        <rect x="4.5" y="0.5" width="139" height="139" rx="31.5" fill="url(#paint0_linear_1532_17146)" stroke="url(#paint1_linear_1532_17146)" />
        <g clipPath="url(#clip0_1532_17146)">
          <path
            d="M49.6184 91.0407C49.6165 90.3549 49.5372 89.6579 49.6241 88.9834C49.9679 86.2836 48.9157 84.1701 47.0797 82.2534C45.049 80.1344 43.1563 77.886 41.2069 75.6883C40.253 74.6109 39.7997 73.32 39.7959 71.9167C39.7732 63.8039 39.777 55.6893 39.7921 47.5765C39.7959 45.7067 41.2976 44.1909 43.0298 44.219C44.8224 44.249 46.2032 45.701 46.207 47.5953C46.2145 52.0882 46.224 56.583 46.1994 61.0759C46.1937 62.1757 46.5375 63.0414 47.3347 63.8245C51.7623 68.1713 56.1598 72.5462 60.5648 76.9155C60.8293 77.1778 61.0654 77.4701 61.3128 77.7492C62.1421 79.0795 62.8429 80.4697 62.9959 82.0511C63.266 84.8409 63.0941 87.6401 63.0922 90.4355C63.0922 90.7147 63.0185 90.9938 62.7598 91.1756C62.3555 91.3967 61.9097 91.3723 61.4734 91.3723C57.994 91.3779 54.5145 91.3798 51.0351 91.3686C50.544 91.3704 50.0189 91.4529 49.6184 91.0407Z"
            fill="url(#paint2_linear_1532_17146)"
          />
          <path
            d="M83.2321 91.0407C83.336 88.3389 82.9846 85.6278 83.5608 82.9354C84.0406 80.6908 85.1248 78.8078 86.7266 77.2077C91.228 72.7129 95.7444 68.235 100.278 63.772C101.022 63.0395 101.362 62.2207 101.358 61.1902C101.341 56.5717 101.356 51.9533 101.351 47.3348C101.349 46.0195 102.097 45.1427 103.153 44.5843C104.279 43.9885 105.448 44.1009 106.476 44.9459C106.544 45.3469 106.57 45.7479 106.57 46.1563C106.564 54.0217 106.536 61.8891 106.589 69.7545C106.602 71.615 106.071 73.1907 104.842 74.551C102.805 76.8049 100.871 79.1488 98.7875 81.3597C97.4256 82.8042 96.7153 84.4568 96.7418 86.4334C96.7588 87.6194 96.7569 88.8054 96.7361 89.9914C96.7286 90.4186 96.7815 90.8814 96.3621 91.1905C95.8521 91.3742 95.3175 91.3742 94.7867 91.3742C91.4074 91.3798 88.0281 91.3835 84.6488 91.3723C84.1577 91.3685 83.6325 91.451 83.2321 91.0407Z"
            fill="#FFD0B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.2148 70.0298C65.9522 71.7352 67.6895 73.4406 69.4183 75.1543C69.4184 75.1543 69.4184 75.1542 69.4184 75.1542C69.6102 75.3509 69.7996 75.5504 69.989 75.7499C70.402 76.1851 70.8153 76.6205 71.2543 77.0276C72.7598 78.4291 74.8584 78.4497 76.3317 76.9958C77.144 76.194 77.9564 75.3925 78.7689 74.5909C84.2411 69.1924 89.7138 63.7934 95.1135 58.3233C97.9677 55.4323 99.0519 51.7788 98.4361 47.818C97.5483 42.1053 94.2162 38.3993 88.5154 36.9004C88.5155 36.9005 88.5157 36.9007 88.5158 36.9008C88.5157 36.9008 88.5156 36.9007 88.5155 36.9006C84.8037 35.9938 81.3621 36.6439 78.1622 38.6731C76.8171 39.5259 75.73 40.6718 74.6483 41.8121C74.5893 41.8744 74.5303 41.9366 74.4712 41.9987C73.9197 42.5796 73.5853 42.5533 73.0753 41.9744C72.4368 41.2474 71.7228 40.5804 71.0069 39.9246C65.8822 35.2312 57.875 35.3849 52.8976 40.2619C47.8901 45.1689 47.567 53.2442 52.4273 58.2617C56.2883 62.249 60.2515 66.1394 64.2148 70.0298Z"
            fill="url(#paint3_linear_1532_17146)"
          />
          <path
            d="M96.4414 91.0148C96.4433 89.3922 96.4471 87.7715 96.4471 86.149C96.4471 84.251 97.18 82.6753 98.4512 81.272C100.69 78.8007 102.885 76.2919 105.068 73.7737C105.913 72.7995 106.281 71.6397 106.277 70.3319C106.26 62.2548 106.266 54.1776 106.275 46.1004C106.275 45.7126 106.122 45.2817 106.472 44.9463C107.435 45.5777 107.766 46.5426 107.768 47.6068C107.783 55.7571 107.783 63.9054 107.769 72.0556C107.766 73.9592 106.715 75.3813 105.489 76.7303C103.404 79.0217 101.343 81.3338 99.3126 83.6721C98.4305 84.6876 97.9393 85.8942 97.9507 87.2694C97.962 88.5191 97.9601 89.7669 97.9639 91.0166C97.4558 91.4382 96.9495 91.4363 96.4414 91.0148Z"
            fill="#FBBA9A"
          />
          <g filter="url(#filter1_i_1532_17146)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.223 91.0402C54.0215 91.0406 51.8199 91.0411 49.6184 91.0411C49.4136 91.0452 49.2086 91.0476 49.0035 91.0501C48.5483 91.0555 48.0926 91.0609 47.6369 91.0842C46.6943 91.1329 46.1918 91.6257 46.188 92.5643C46.1828 94.2129 46.1922 95.8605 46.2015 97.5085C46.2056 98.2299 46.2097 98.9513 46.2126 99.6728C46.2132 99.6734 46.2139 99.674 46.2145 99.6746C46.2109 99.8147 46.2053 99.9548 46.1997 100.095C46.1879 100.391 46.1761 100.686 46.1825 100.982C46.2051 102.057 46.6471 102.492 47.7578 102.494C53.8931 102.5 60.0265 102.5 66.1618 102.494C67.2952 102.492 67.7806 102.022 67.7863 100.909C67.7934 99.4375 67.7923 97.9667 67.7913 96.4957C67.7904 95.2209 67.7895 93.946 67.7938 92.6707C67.7957 92.0224 67.6125 91.509 66.9703 91.2392C66.9693 91.2392 66.9683 91.2392 66.9674 91.2392C66.4485 90.9823 65.9066 91.0171 65.3646 91.052C65.015 91.0744 64.6654 91.0969 64.3219 91.0411C63.8251 90.6795 63.3264 90.687 62.8277 91.0392C60.6262 91.0392 58.4246 91.0397 56.223 91.0402Z"
              fill="url(#paint4_linear_1532_17146)"
            />
          </g>
          <path
            d="M64.3238 91.0407C63.8251 91.0407 63.3264 91.0388 62.8278 91.0388C62.8259 88.6818 62.8013 86.3248 62.8315 83.9678C62.8561 81.9049 62.3839 79.9882 61.309 78.2214C61.2033 78.049 61.1031 77.9066 61.3128 77.7511C63.2433 79.7802 64.1916 82.2328 64.3087 84.9814C64.3937 86.9955 64.3238 89.0209 64.3238 91.0407Z"
            fill="#FBBA9A"
          />
          <path
            d="M68.1282 54.1244C68.7893 54.1244 69.4523 54.0851 70.1078 54.1357C70.7538 54.1844 70.9937 53.9914 70.9673 53.315C70.9163 52.0072 70.9408 50.6957 70.9559 49.386C70.9748 47.6192 72.1271 46.407 73.7704 46.4014C75.4138 46.3976 76.5793 47.6061 76.6001 49.3692C76.6171 50.7107 76.6284 52.0522 76.5925 53.3937C76.5774 53.9633 76.755 54.1525 77.3387 54.1375C78.7214 54.1001 80.106 54.1151 81.4887 54.13C83.1604 54.1488 84.3372 55.2655 84.3693 56.8337C84.4014 58.46 83.2473 59.6647 81.5567 59.7078C80.2061 59.7434 78.8517 59.7528 77.5011 59.7022C76.8211 59.6778 76.551 59.8352 76.5869 60.5678C76.6492 61.8437 76.6133 63.1253 76.6001 64.4031C76.5812 66.2205 75.4025 67.4646 73.7364 67.4458C72.1195 67.4271 70.9729 66.1886 70.9559 64.4162C70.9427 63.0747 70.9314 61.7332 70.9616 60.3917C70.9748 59.8539 70.7821 59.6947 70.257 59.7059C68.9045 59.7359 67.5521 59.7265 66.1996 59.7115C64.3522 59.6909 63.1603 58.5462 63.1867 56.8449C63.2113 55.2055 64.407 54.1375 66.2411 54.1244C66.8701 54.1207 67.4992 54.1244 68.1282 54.1244Z"
            fill="white"
          />
          <path
            d="M44.6882 68.8814C44.6939 66.708 46.3713 65.3721 48.485 65.7618C48.6437 65.7918 48.8118 65.803 48.8836 65.9623C48.9629 66.1384 48.808 66.2377 48.706 66.3501C46.9436 68.3249 46.9833 69.6271 48.8628 71.4913C50.9312 73.5429 52.9996 75.5964 55.0698 77.6462C55.3834 77.9572 55.7045 78.2607 55.8065 78.7085C55.9066 79.1507 55.7593 79.4935 55.3721 79.754C55.0018 80.0032 54.6637 79.9601 54.3369 79.7015C54.165 79.5666 54.012 79.4074 53.8552 79.2537C51.1182 76.5407 48.3886 73.8221 45.6421 71.1166C44.9848 70.4665 44.5749 69.7414 44.6882 68.8814Z"
            fill="#FBBA9A"
          />
          <path
            d="M99.7302 65.7621C101.245 65.6647 102.135 66.446 102.698 67.6788C103.194 68.7655 102.904 70.1257 101.991 71.0307C99.2183 73.7905 96.434 76.5429 93.6591 79.3008C93.1661 79.7898 92.6523 80.277 92.0006 79.5894C91.3962 78.9523 91.7834 78.3622 92.2689 77.875C94.4355 75.7035 96.621 73.5488 98.7989 71.3886C100.556 69.6461 100.58 68.1622 98.8915 66.3766C98.7914 66.2698 98.6478 66.1106 98.6667 66.0019C98.7045 65.7939 98.9293 65.7658 99.1219 65.7621C99.3429 65.7583 99.5639 65.7621 99.7302 65.7621Z"
            fill="#FBBA9A"
          />
          <g filter="url(#filter2_i_1532_17146)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.9641 91.0146H96.4416C92.2315 91.1239 88.0215 91.0842 83.8131 91.0445L83.2341 91.039C83.0296 91.0431 82.8251 91.0455 82.6207 91.048C82.166 91.0534 81.7117 91.0588 81.2583 91.0821C80.3195 91.1327 79.8095 91.6311 79.8 92.5604C79.7878 93.5904 79.798 94.6194 79.8082 95.6488L79.8082 95.6488C79.8122 96.052 79.8162 96.4551 79.8189 96.8585L79.8208 96.8602H79.817L79.8057 99.6669H79.8075C79.8021 99.7451 79.7954 99.8236 79.7887 99.9021C79.7742 100.072 79.7597 100.241 79.7584 100.41C79.7414 102.157 80.0814 102.498 81.8249 102.498L86.3015 102.498C90.7809 102.498 95.2608 102.499 99.7396 102.496C100.96 102.496 101.379 102.085 101.385 100.871C101.396 98.2528 101.392 95.6361 101.389 93.0175V93.0171V93.0167V93.0153C101.387 91.4189 101.001 91.0461 99.3788 91.0498C99.1798 91.0498 98.9798 91.0611 98.7802 91.0723C98.6975 91.077 98.615 91.0816 98.5326 91.0854L98.5304 91.0874L97.9641 91.0146Z"
              fill="url(#paint5_linear_1532_17146)"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_1532_17146"
            x="0.123291"
            y="31"
            width="148"
            height="148"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1532_17146" />
          </filter>
          <filter
            id="filter1_i_1532_17146"
            x="46.1807"
            y="88.7725"
            width="21.6132"
            height="13.7258"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-4" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.951389 0 0 0 0 0.470409 0 0 0 0 0.0264278 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17146" />
          </filter>
          <filter
            id="filter2_i_1532_17146"
            x="79.7578"
            y="89.0146"
            width="21.6346"
            height="13.4834"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-4" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.470588 0 0 0 0 0.027451 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_17146" />
          </filter>
          <linearGradient id="paint0_linear_1532_17146" x1="128" y1="4.66364e-06" x2="14.5" y2="128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_1532_17146" x1="74" y1="0" x2="74" y2="140" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="0.44" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="paint2_linear_1532_17146" x1="39.4383" y1="43.9999" x2="63.4383" y2="92.9999" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFD0B9" />
            <stop offset="1" stopColor="#FFD0B9" />
          </linearGradient>
          <linearGradient id="paint3_linear_1532_17146" x1="89.6669" y1="31.507" x2="73.7795" y2="78.0825" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9BC2FB" />
            <stop offset="1" stopColor="#2169F7" />
          </linearGradient>
          <linearGradient id="paint4_linear_1532_17146" x1="56.9873" y1="90.7725" x2="56.9873" y2="102.498" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA52D" />
            <stop offset="1" stopColor="#FB881A" />
          </linearGradient>
          <linearGradient id="paint5_linear_1532_17146" x1="90.5751" y1="91.0146" x2="90.5751" y2="102.498" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA42D" />
            <stop offset="1" stopColor="#FC8B1C" />
          </linearGradient>
          <clipPath id="clip0_1532_17146">
            <rect width="68" height="66" fill="white" transform="translate(39.7808 36.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
