import React from "react";

export default function IconNetWork() {
  return (
    <div>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.9255 2.96783C7.40555 2.96783 2.93555 7.44783 2.93555 12.9678C2.93555 18.4878 7.40555 22.9678 12.9255 22.9678C18.4555 22.9678 22.9355 18.4878 22.9355 12.9678C22.9355 7.44783 18.4555 2.96783 12.9255 2.96783ZM19.8555 8.96783H16.9055C16.5855 7.71783 16.1255 6.51783 15.5255 5.40783C17.3655 6.03783 18.8955 7.31783 19.8555 8.96783ZM12.9355 5.00783C13.7655 6.20783 14.4155 7.53783 14.8455 8.96783H11.0255C11.4555 7.53783 12.1055 6.20783 12.9355 5.00783ZM5.19555 14.9678C5.03555 14.3278 4.93555 13.6578 4.93555 12.9678C4.93555 12.2778 5.03555 11.6078 5.19555 10.9678H8.57555C8.49555 11.6278 8.43555 12.2878 8.43555 12.9678C8.43555 13.6478 8.49555 14.3078 8.57555 14.9678H5.19555ZM6.01555 16.9678H8.96555C9.28555 18.2178 9.74555 19.4178 10.3455 20.5278C8.50555 19.8978 6.97555 18.6278 6.01555 16.9678ZM8.96555 8.96783H6.01555C6.97555 7.30783 8.50555 6.03783 10.3455 5.40783C9.74555 6.51783 9.28555 7.71783 8.96555 8.96783ZM12.9355 20.9278C12.1055 19.7278 11.4555 18.3978 11.0255 16.9678H14.8455C14.4155 18.3978 13.7655 19.7278 12.9355 20.9278ZM15.2755 14.9678H10.5955C10.5055 14.3078 10.4355 13.6478 10.4355 12.9678C10.4355 12.2878 10.5055 11.6178 10.5955 10.9678H15.2755C15.3655 11.6178 15.4355 12.2878 15.4355 12.9678C15.4355 13.6478 15.3655 14.3078 15.2755 14.9678ZM15.5255 20.5278C16.1255 19.4178 16.5855 18.2178 16.9055 16.9678H19.8555C18.8955 18.6178 17.3655 19.8978 15.5255 20.5278ZM17.2955 14.9678C17.3755 14.3078 17.4355 13.6478 17.4355 12.9678C17.4355 12.2878 17.3755 11.6278 17.2955 10.9678H20.6755C20.8355 11.6078 20.9355 12.2778 20.9355 12.9678C20.9355 13.6578 20.8355 14.3278 20.6755 14.9678H17.2955Z"
          fill="#5A6B81"
        />
      </svg>
    </div>
  );
}
