import { IDoctor } from "@base/modules/doctor/interface/doctor.interface";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";

interface IProps {
  doctor?: IDoctor;
}
export default function TabInfo(props: IProps) {
  //! state
  const { doctor } = props;
  //! function
  //! render
  const renderDegrees = () => {
    return doctor?.degrees?.map((item, index) => {
      return <img src={item.link} alt={item.object_name} key={index} />;
    });
  };
  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div>
        <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">Giới thiệu</CommonStyles.TypographyUI>
        <pre className="text-wrap break-words font-[Inter] lg:text-base ml-4 my-2">{doctor?.summary}</pre>
      </div>
      <Divider />

      {doctor?.experience_details && (
        <>
          <div>
            <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">
              Kinh nghiệm chuyên môn
            </CommonStyles.TypographyUI>
            <pre className="text-wrap font-[Inter] lg:text-base ml-4 my-2">{doctor?.experience_details}</pre>
          </div>
          <Divider />
        </>
      )}

      {!!doctor?.degrees && doctor?.degrees?.length > 0 && (
        <div>
          <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">Thành tựu</CommonStyles.TypographyUI>
          <div className="grid grid-cols-3 gap-4 my-4">{renderDegrees()}</div>
        </div>
      )}
    </div>
  );
}
