import React from "react";
import { EDrugOrderStatus } from "@base/enums";
import CommonStyles from "..";

interface IProps {
  status: EDrugOrderStatus;
}

export default function TagDrugOrderStatus({ status }: IProps) {
  const getTagName = () => {
    switch (status) {
      case EDrugOrderStatus.PENDING:
        return "Chờ xác nhận";
      case EDrugOrderStatus.COMPLETED:
        return "Đã xác nhận";
      case EDrugOrderStatus.CANCELLED:
        return "Đã hủy";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case EDrugOrderStatus.PENDING:
        return "bg-common-blue100 text-common-blue500 text-xs font-medium border-none p-1 rounded mr-0";
      case EDrugOrderStatus.COMPLETED:
        return "bg-common-green100 text-common-green500 text-xs font-medium border-none p-1 rounded mr-0";
      case EDrugOrderStatus.CANCELLED:
        return "bg-common-red100 text-common-red500 text-xs font-medium border-none p-1 rounded mr-0";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
