import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IDoctorPage, ITimeTable } from "./interface/doctor.interface";
import IFilterDcotor from "./interface/filterDoctor.interafce";

class DoctorService {
  // get list
  getList(parmas?: IFilterDcotor) {
    return httpService.get<IDoctorPage>(`${apiService.doctor.getList}`, {
      params: parmas,
    });
  }

  getByCodeLess(doctorCode: string) {
    return httpService.get(`${apiService.doctor.getByCodeLess}/${doctorCode}`);
  }

  getBySlug(slug: string) {
    return httpService.get(`${apiService.doctor.getBySlug}/${slug}`);
  }

  // get time table
  getTimeTable(doctor_code: string, assign_date: string) {
    return httpService.get<ITimeTable>(`${apiService.doctor.timeTable.getAllByDoctorCodeAndDate}/${doctor_code}`, {
      params: { assign_date },
    });
  }
}

export default new DoctorService();
