import { useState } from "react";
import authService from "../authService";
import { AxiosError } from "axios";
import { baseRoutes } from "@base/routes/baseRoutes";
import { LocalKey } from "@base/constants/LocalKey";
import { useDispatch } from "react-redux";
import { setAuth } from "@base/store/reducers/appCommonSlice";
import useCheckLogin from "@base/hooks/useCheckLogin";

const useAuth = () => {
  const dispatch = useDispatch();
  const { redirectTo } = useCheckLogin(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | any>(null);

  const handleLogin = async (username: string, password: string) => {
    try {
      setLoading(true);
      const data = await authService.login(username, password);

      if (data.data.user_type !== "PATIENT") {
        // Do not allow non-patient users to log in
        localStorage.clear();
        dispatch(setAuth(undefined));
        window.location.replace(baseRoutes.login);
        return;
      }

      if (data.data) {
        // save data to localstorage
        localStorage.setItem(LocalKey.AUTH, JSON.stringify(data.data));
        // save data to redux
        dispatch(setAuth(data.data));

        // login success
        window.location.replace(redirectTo ? redirectTo : baseRoutes.home);
      }
    } catch (error: AxiosError | any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleLogin,
  };
};

export default useAuth;
