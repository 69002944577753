import React from "react";
import { ETagSpecialPackageDetailType } from "@base/enums";
import CommonStyles from "..";

interface IProps {
  type: ETagSpecialPackageDetailType;
}

export default function TagSpecialPackageDetailType({ type }: IProps) {
  const getTagName = () => {
    switch (type) {
      case ETagSpecialPackageDetailType.ONLINE_SERVICE:
        return "Trực tuyến qua app";
      case ETagSpecialPackageDetailType.OFFLINE_SERVICE:
        return "Trực tiếp tại Cơ sở y tế";
      case ETagSpecialPackageDetailType.HOMECARE_SERVICE:
        return "Khám tại nhà";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (type) {
      case ETagSpecialPackageDetailType.ONLINE_SERVICE:
        return "bg-common-orange100 text-common-orange500 border-common-orange500";
      case ETagSpecialPackageDetailType.OFFLINE_SERVICE:
        return "bg-common-blue100 text-common-inprogress border-common-blue500";
      case ETagSpecialPackageDetailType.HOMECARE_SERVICE:
        return "bg-common-purple100 text-common-purple500 border-common-purple500";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
