import useCalCulateFeeAppt from "@base/modules/appointment/hooks/useCalculateFeeAppt";
import useCalCulateFee from "@base/modules/patientSpecialPackage/hooks/useCalculateFee";
import IFilterPromotionByType from "@base/modules/promotion/interface/IFilterPromotionByType.interface";
import { IPromotion } from "@base/modules/promotion/interface/promotion.interface";
import promotionService from "@base/modules/promotion/promotionService";
import { IUserInfo } from "@base/modules/user/account/account.interface";
import useGetUserInfo from "@base/modules/user/account/hooks/useGetUserInfo";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import CommonStyles from "@components/CommonStyles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface IProps {
  discount: IPromotion | undefined;
  toggle: () => void;
  setDiscount: any;
  filterPromotionByType: IFilterPromotionByType;
  user: IUserInfo | undefined;
  bodyFee: any;
}
export default function ModalVerifyUseCodePackage(props: IProps) {
  //! state
  const { discount, toggle, setDiscount, filterPromotionByType, user, bodyFee } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: dataCalculate, handleFee, isLoading: loadingCalculate } = useCalCulateFee();

  //! function
  const handleApplyDiscount = async () => {
    const filter = {
      lang: filterPromotionByType.lang,
      apply_date: dayjs().format("YYYY-MM-DD"),
      site_code: filterPromotionByType.site_code,
      category: filterPromotionByType.category,
      phone: user?.user_name,
    };
    try {
      const result = await promotionService.promotionApply(String(discount?.type), filter);
      const resultFee = await handleFee({
        ...bodyFee,
        patient_special_package_promotions: [result.data],
      });
      setDiscount(resultFee?.data);
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: "Áp dụng thành công" }));

      toggle();
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: "Áp dụng thất bại" }));
    }
  };
  //! render
  return (
    <div>
      <div className="flex flex-col gap-2">
        <CommonStyles.TypographyUI className="text-base flex gap-1">
          Mã:
          <span className="uppercase text-common-maintext"> {discount?.name}</span>
        </CommonStyles.TypographyUI>

        <CommonStyles.TypographyUI className="text-base flex gap-1">
          Giá trị:
          <span className="uppercase">
            {discount?.value}
            {discount?.unit}
          </span>
        </CommonStyles.TypographyUI>
        {discount?.max_value && (
          <CommonStyles.TypographyUI className="text-base flex gap-1">
            Giảm tối đa:
            <span className="uppercase">{discount?.max_value?.toLocaleString()}</span>
          </CommonStyles.TypographyUI>
        )}
      </div>

      <div className="flex justify-end items-center gap-4">
        <CommonStyles.ButtonUI ghost className="h-12" onClick={toggle}>
          Hủy
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI className="h-12" onClick={handleApplyDiscount}>
          Áp dụng
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
