import React from "react";

export default function IconHealthcare() {
  return (
    <div>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4762 3.8065C9.78319 3.96216 9.14574 4.4736 8.83442 5.12587C8.62318 5.5669 8.57129 5.78927 8.57129 6.25253C8.56773 6.6904 8.61546 6.91284 8.80974 7.33043C8.82598 7.36534 8.84526 7.3989 8.86684 7.43079L9.04201 7.68956C9.06665 7.72597 9.0944 7.76018 9.12493 7.79181L11.0914 9.82892L12.9114 11.6521C13.0605 11.8015 13.3027 11.8014 13.4517 11.6518L15.2312 9.86598C17.4474 7.63861 17.4622 7.62008 17.6512 7.03081C18.0256 5.85598 17.4993 4.55884 16.4245 4.01775C16.0206 3.81391 15.8093 3.76944 15.2571 3.77315C14.8198 3.77315 14.7197 3.78797 14.4603 3.87321C14.0711 4.00293 13.7413 4.19935 13.4374 4.48472L13.4329 4.48893C13.2915 4.61987 13.0748 4.62492 12.9275 4.50072L12.8778 4.45878C12.5331 4.16599 12.2144 3.9844 11.829 3.86209C11.4917 3.75462 10.8246 3.72867 10.4762 3.8065Z"
          fill="#5A6B81"
        />
        <path
          d="M7.73371 13.2941C7.1222 13.409 6.55517 13.661 6.06596 14.0353C5.89178 14.1651 4.90966 15.1064 3.87936 16.1256L2.55231 17.4395C2.25194 17.7369 2.25051 18.2218 2.54914 18.521L5.56338 21.5379C5.86142 21.836 6.34463 21.836 6.64267 21.5379L6.77754 21.4031L7.23377 20.9493C7.37601 20.8079 7.56822 20.7281 7.76883 20.7273L11.1063 20.7137L14.6483 20.703C14.7203 20.7027 14.7918 20.6923 14.8608 20.6721L15.0347 20.6211C15.4128 20.5062 15.8056 20.3024 16.0502 20.0985C16.2689 19.9132 20.8125 14.8285 20.9311 14.632C21.0164 14.4912 21.0238 14.2058 20.946 14.0428C20.8793 13.9019 20.7125 13.7796 20.4456 13.6796C19.8453 13.4498 19.1707 13.624 18.4073 14.2058C17.9514 14.5542 16.532 15.7142 15.498 16.5889L14.896 17.0978C14.7582 17.2143 14.5837 17.2782 14.4033 17.2782H11.214C11.1282 17.2782 11.0458 17.245 10.984 17.1855C10.9358 17.1336 10.8839 17.0595 10.8728 17.0225C10.8468 16.9224 10.9469 16.7408 11.0544 16.6889C11.1174 16.663 11.5288 16.6481 12.3293 16.6481C13.0594 16.6481 13.5856 16.6333 13.7079 16.6074C14.1749 16.5073 14.6122 16.1849 14.805 15.7957C14.8902 15.6179 14.905 15.5474 14.905 15.2584C14.9013 14.8692 14.8383 14.6913 14.5974 14.4208C14.4455 14.2503 14.1342 14.0613 13.8673 13.976C13.7672 13.9464 13.278 13.9242 12.3478 13.9056C10.8246 13.876 10.7727 13.8686 10.1649 13.5906C9.64606 13.3534 9.24209 13.2682 8.58611 13.2534C8.19697 13.2459 7.92272 13.2571 7.73371 13.2941Z"
          fill="#5A6B81"
        />
      </svg>
    </div>
  );
}
