import React from "react";

export default function IconVideoOff() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5001 18.1145L23.1668 20.7812L28.1094 25.7238C28.6308 26.2452 28.6308 27.0878 28.1094 27.6092C27.8494 27.8692 27.5081 27.9998 27.1668 27.9998C26.8254 27.9998 26.4841 27.8692 26.2241 27.6092L22.3481 23.7332L20.4081 21.7932L7.9481 9.33318L5.60143 6.98651L4.89076 6.27585C4.36943 5.75451 4.36943 4.91185 4.89076 4.39051C5.4121 3.86918 6.25476 3.86918 6.7761 4.39051L9.0521 6.66651L11.7188 9.33318L20.5001 18.1145ZM28.5157 9.52718C27.6811 9.16451 26.7131 9.32185 26.0477 9.93518L23.1664 12.6018V10.6658C23.1664 8.46051 21.3731 6.66585 19.1664 6.66585H10.9371L12.6611 8.38985L21.4424 17.1712L24.1091 19.8378L26.7904 22.5192C27.0464 22.6152 27.3184 22.6658 27.5931 22.6658C27.9037 22.6658 28.2171 22.6032 28.5157 22.4725C29.3277 22.1178 29.8331 21.3592 29.8331 20.4938V11.5058C29.8331 10.6405 29.3277 9.88185 28.5157 9.52718ZM19.4649 22.7356L7.00493 10.2756L4.65959 7.93025L4.46493 7.73558C3.67159 8.46758 3.16626 9.50491 3.16626 10.6662V21.3329C3.16626 23.5396 4.96093 25.3329 7.16626 25.3329H19.1663C19.9889 25.3329 20.7516 25.0836 21.3863 24.6569L19.4649 22.7356Z"
        fill="#231F20"
      />
      <mask id="mask0_2303_2210" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3" y="3" width="27" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5001 18.1145L23.1668 20.7812L28.1094 25.7238C28.6308 26.2452 28.6308 27.0878 28.1094 27.6092C27.8494 27.8692 27.5081 27.9998 27.1668 27.9998C26.8254 27.9998 26.4841 27.8692 26.2241 27.6092L22.3481 23.7332L20.4081 21.7932L7.9481 9.33318L5.60143 6.98651L4.89076 6.27585C4.36943 5.75451 4.36943 4.91185 4.89076 4.39051C5.4121 3.86918 6.25476 3.86918 6.7761 4.39051L9.0521 6.66651L11.7188 9.33318L20.5001 18.1145ZM28.5157 9.52718C27.6811 9.16451 26.7131 9.32185 26.0477 9.93518L23.1664 12.6018V10.6658C23.1664 8.46051 21.3731 6.66585 19.1664 6.66585H10.9371L12.6611 8.38985L21.4424 17.1712L24.1091 19.8378L26.7904 22.5192C27.0464 22.6152 27.3184 22.6658 27.5931 22.6658C27.9037 22.6658 28.2171 22.6032 28.5157 22.4725C29.3277 22.1178 29.8331 21.3592 29.8331 20.4938V11.5058C29.8331 10.6405 29.3277 9.88185 28.5157 9.52718ZM19.4649 22.7356L7.00493 10.2756L4.65959 7.93025L4.46493 7.73558C3.67159 8.46758 3.16626 9.50491 3.16626 10.6662V21.3329C3.16626 23.5396 4.96093 25.3329 7.16626 25.3329H19.1663C19.9889 25.3329 20.7516 25.0836 21.3863 24.6569L19.4649 22.7356Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_2303_2210)">
        <rect x="0.5" width="32" height="32" fill="currentColor" />
      </g>
    </svg>
  );
}
