import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { IDrugOrder } from "../drugOrder.interface";
import drugOrderService from "../drugOrderService";

const useGetByCode = (code?: string, lang?: string, enabled = true) => {
  const [data, setData] = useState<IDrugOrder>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return drugOrderService.getByCode(code, lang);
  }, [code, lang]);

  const transformResponse = useCallback((response: AxiosResponse<IDrugOrder>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [code, lang]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetByCode;
