export default function IconArrowRight({ fillColor }: { fillColor?: string }) {
  return (
    <div>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4697 6.94037C12.7626 6.64748 13.2374 6.64748 13.5303 6.94037L18.5303 11.9404C18.8232 12.2333 18.8232 12.7081 18.5303 13.001L13.5303 18.001C13.2374 18.2939 12.7626 18.2939 12.4697 18.001C12.1768 17.7081 12.1768 17.2333 12.4697 16.9404L16.1893 13.2207H6C5.58579 13.2207 5.25 12.8849 5.25 12.4707C5.25 12.0565 5.58579 11.7207 6 11.7207H16.1893L12.4697 8.00103C12.1768 7.70814 12.1768 7.23327 12.4697 6.94037Z"
          fill={fillColor ? fillColor : "white"}
        />
      </svg>
    </div>
  );
}
