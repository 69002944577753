import CommonImages from "@base/assets/images";
import { linkAppStore, linkGGPlay } from "@base/constants";
import { ResponseHomeModule } from "@base/modules/homeModules/interfaces/IHomeModule.interface";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import { QRCode } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DownloadApp({ dataCommon }: { dataCommon?: ResponseHomeModule }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        backgroundImage: `url(${CommonImages.BGDownloadApp})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: 400,
      }}
      className={`py-16`}
    >
      <div className={`flex justify-between ${responseUI.screen} ${responseUI.padding}`}>
        <div className="sm:basis-1/2 sm:pr-40 flex flex-col gap-5 justify-center">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <CommonStyles.TypographyUI className="text-common-white text-lg lg:text-3xl font-semibold text-nowrap">
                {t("home.App365")}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="text-common-white text-lg lg:text-3xl font-semibold text-nowrap">
                {t("home.avaiable")}
              </CommonStyles.TypographyUI>
            </div>
          </div>
          <CommonStyles.TypographyUI use="text" className="text-white text-xs lg:text-base">
            {t("home.subApp365")}
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="text-white text-xs lg:text-base font-semibold">
            {t("home.scanQR")}
          </CommonStyles.TypographyUI>

          <div className="flex gap-4">
            <div className="bg-white p-2 rounded-lg">
              <QRCode
                className="!p-0"
                size={93}
                value={dataCommon?.data.commons.find((item) => item.key === "LINK_DOWNLOAD_APP")?.article_link || "-"}
              />
            </div>

            <div className="flex flex-col justify-around">
              <div className="cursor-pointer">
                <Link to={linkGGPlay} target="_blank">
                  <img src={CommonImages.googleplayBlack} alt={CommonImages.googleplayBlack} />
                </Link>
              </div>

              <div className="cursor-pointer">
                <Link to={linkAppStore} target="_blank">
                  <img src={CommonImages.appstoreBlack} alt={CommonImages.appstoreBlack} />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          <img src={CommonImages.MobileAppDownload} />
        </div>
      </div>
    </div>
  );
}
