import { useCallback, useEffect, useState } from "react";
import stringeeService from "../stringeeService";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import { setStringeeToken } from "@base/store/reducers/appCommonSlice";

const useGetStringToken = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | any>(null);

  const handleGetStringeeToken = async () => {
    try {
      setLoading(true);
      const resp = await stringeeService.getStringeeToken();

      setData(resp.data);
      dispatch(setStringeeToken(resp.data));
    } catch (error: AxiosError | any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, isLoading, handleGetStringeeToken };
};

export default useGetStringToken;
