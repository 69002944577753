export default function IconLineCalendar() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.5 7.5H7.5V12H28.5V7.5Z" fill="currentColor" />
      <path
        d="M18 6H28.5C29.325 6 30 6.675 30 7.5V28.5C30 29.325 29.325 30 28.5 30H7.5C6.675 30 6 29.325 6 28.5V7.5C6 6.675 6.675 6 7.5 6H18Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.5 6V3M25.5 6V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 16.5H25.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 22.5H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
