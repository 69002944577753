import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { LocalKey } from "@base/constants/LocalKey";
import { getAvatarLink } from "@base/helpers/common";
import { ISite } from "@base/modules/site/site.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

interface IProps {
  root?: "csyt" | "specialist";
  site?: ISite;
}
export default function CardOrderFacility(props: IProps) {
  //! state
  const { root, site } = props;
  const specialist = root === "specialist";
  const { t } = useTranslation();
  const navigate = useNavigate();

  //! function
  const handleDetailFacility = () => {
    navigate({ pathname: `${baseRoutes.orderFacility}/${site?.slug}` });
  };

  const handleBooking = (site?: ISite) => {
    if (!site) {
      return;
    }

    localStorage.setItem(
      LocalKey.BOOKING_APPOINTMENT,
      JSON.stringify({
        site_code: site.code,
        site_name: site.name,
        site_type: site.site_type,
      }),
    );

    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        site_code: String(site.code),
      }).toString(),
    });
  };

  //! render
  return (
    <div className="p-2 lg:p-4 rounded-2xl bg-common-white border border-common-divider">
      <div className="flex gap-6 ">
        <div className="flex justify-center items-center">
          <div className="w-[104px] lg:w-[188px]">
            <div className="aspect-square">
              <img
                className="rounded-xl aspect-square object-contain h-[104px] lg:h-[188px]"
                src={getAvatarLink(site?.avatar ?? [])}
                alt={getAvatarLink(site?.avatar ?? [])}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.SiteDefault;
                }}
              />
            </div>

            {/* <CommonStyles.ImageUI className="rounded-xl aspect-square" src={getAvatarLink(site?.avatar ?? [])} fallback={CommonImages.SiteDefault} /> */}
          </div>
        </div>
        <div className="grow">
          <div className="flex flex-col justify-around lg:justify-between h-full">
            <CommonStyles.TypographyUI className="font-medium text-sm md:text-base lg:text-xl text-common-maintext line-clamp-2">
              {site?.name}
            </CommonStyles.TypographyUI>

            <div className="flex flex-col gap-3">
              {site?.address ? (
                <div className="flex items-center lg:gap-3">
                  <div className="hidden lg:block">
                    <CommonIcons.IconLocation />
                  </div>
                  <CommonStyles.TypographyUI className="text-xs md:text-sm lg:text-base text-common-subtext line-clamp-2">
                    {site.address}
                  </CommonStyles.TypographyUI>
                </div>
              ) : null}

              {specialist ? (
                <div className="flex items-center gap-3">
                  <CommonIcons.IconCall />
                  <CommonStyles.TypographyUI className="text-base text-common-subtext">{site?.phone}</CommonStyles.TypographyUI>
                </div>
              ) : null}
            </div>

            <div className="hidden md:flex flex-col  md:flex-row  xl:items-center gap-x-4 gap-y-2 ">
              <CommonStyles.ButtonUI onClick={() => handleBooking(site)} className="lg:h-10 font-medium basis-1/2">
                {t("home.bookAppointment")}
              </CommonStyles.ButtonUI>

              <CommonStyles.ButtonUI
                onClick={handleDetailFacility}
                className="lg:h-10 font-medium bg-common-orange100 text-common-mainColor hover:!bg-common-orange200 hover:!text-common-mainColor basis-1/2 "
              >
                {t("user.profile.viewDetail")}
              </CommonStyles.ButtonUI>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:hidden flex-row xl:items-center gap-x-4 gap-y-2 mt-4">
        <CommonStyles.ButtonUI onClick={() => handleBooking(site)} className="lg:h-10 font-medium basis-1/2">
          {t("home.bookAppointment")}
        </CommonStyles.ButtonUI>

        <CommonStyles.ButtonUI
          onClick={handleDetailFacility}
          className="lg:h-10 font-medium bg-common-orange100 text-common-mainColor hover:!bg-common-orange200 hover:!text-common-mainColor basis-1/2"
        >
          {t("user.profile.viewDetail")}
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
