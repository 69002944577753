import React from "react";

export default function IconClound() {
  return (
    <div>
      <svg width="139" height="138" viewBox="0 0 139 138" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="69.4999" cy="68.6429" r="68.6429" fill="url(#paint0_linear_933_42939)" />
        <circle cx="69.4999" cy="68.6429" r="63.6429" stroke="url(#paint1_linear_933_42939)" strokeOpacity="0.3" strokeWidth="10" />
        <path
          d="M66.2096 40.6033C61.8414 41.2343 57.9585 43.2606 54.7795 46.561C52.7167 48.6966 51.1515 51.3296 50.2778 54.1204C50.0594 54.8242 49.8289 55.4309 49.7682 55.4673C49.7075 55.5158 49.3678 55.5765 49.0038 55.625C48.6519 55.6614 47.8996 55.8434 47.3536 56.0133C42.6699 57.4936 39.1147 61.425 38.2289 66.1329C38.0105 67.2857 37.9741 69.858 38.1561 70.9379C39.2603 77.1384 44.3686 81.4944 51.0058 81.8706L52.2678 81.9434L51.9523 81.4095C51.1636 80.0505 50.8602 78.1333 51.1757 76.6166C51.5761 74.748 52.4255 73.5953 55.9565 70.125C58.6744 67.4677 59.4389 66.958 61.259 66.5576C64.2075 65.9024 67.5807 67.5526 68.8669 70.2706L69.146 70.8409L75.1522 70.9015C81.753 70.9501 81.6924 70.9501 83.294 71.7266C84.4468 72.2969 85.83 73.7045 86.3882 74.8936C87.3468 76.8957 87.3832 78.8856 86.5217 80.7542C86.279 81.276 86.0848 81.7249 86.0848 81.7614C86.0848 81.8706 87.4196 81.64 88.4752 81.3609C93.2074 80.0869 97.2116 76.5923 99.0195 72.1635C99.8082 70.1978 100.063 68.9844 100.124 66.679C100.221 63.5606 99.784 61.5585 98.4492 58.9618C96.9689 56.0861 94.3844 53.538 91.448 52.0698C90.4409 51.5602 88.4388 50.9049 87.3346 50.7108L86.461 50.5652L85.7936 49.5217C82.7238 44.6803 77.6275 41.4041 71.8882 40.5912C70.3351 40.3728 67.7384 40.3728 66.2096 40.6033Z"
          fill="url(#paint2_linear_933_42939)"
        />
        <g filter="url(#filter0_d_933_42939)">
          <path
            d="M61.7565 71.6415C61.259 71.8721 56.4055 76.7863 56.2356 77.211C56.0658 77.6963 56.0779 78.4607 56.2842 78.849C56.5875 79.4193 61.5988 84.3093 61.9749 84.4063C62.521 84.5398 63.4553 84.3942 63.8436 84.1151C64.4745 83.6662 64.608 83.2051 64.608 81.4942V79.9653H72.6649H80.7096L81.1222 79.6863C81.3406 79.5407 81.6561 79.2252 81.8138 78.9946C82.0565 78.6428 82.0929 78.4607 82.0565 77.8662C81.9958 77.029 81.7289 76.6164 80.9887 76.2281L80.4548 75.9612H72.5314H64.608V74.3959C64.608 72.9884 64.5837 72.8064 64.341 72.4424C64.1833 72.2239 63.9164 71.9327 63.7344 71.7993C63.3097 71.4838 62.254 71.3988 61.7565 71.6415Z"
            fill="url(#paint3_linear_933_42939)"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_d_933_42939)">
          <path
            d="M74.582 84.6612C73.7812 85.0495 73.6477 85.4135 73.6113 87.27L73.5628 88.9445H65.5301H57.4854L57.0728 89.2236C56.8544 89.3692 56.5389 89.6847 56.3812 89.9152C56.1385 90.2671 56.1021 90.4491 56.1385 91.0436C56.1992 91.8809 56.4661 92.2934 57.2063 92.6696L57.7402 92.9487H65.6636H73.587V94.5139C73.587 95.9215 73.6113 96.1035 73.8661 96.4675C74.4 97.2562 75.128 97.5595 76.0987 97.3775C76.487 97.3047 76.936 96.9286 79.1565 94.7323C80.5761 93.3127 81.8138 91.9901 81.9109 91.7717C82.1293 91.2621 82.1293 90.4734 81.9109 90.0608C81.6924 89.6725 76.7904 84.7826 76.4142 84.5884C76.0259 84.3943 75.0431 84.4307 74.582 84.6612Z"
            fill="url(#paint4_linear_933_42939)"
            shape-rendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_933_42939"
            x="35.1179"
            y="52.5013"
            width="71.9507"
            height="58.9551"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42939" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42939" result="shape" />
          </filter>
          <filter
            id="filter1_d_933_42939"
            x="35.1265"
            y="65.4629"
            width="71.9482"
            height="58.9658"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42939" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42939" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_933_42939" x1="122.453" y1="4.57323e-06" x2="11.1534" y2="125.519" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_933_42939" x1="0.856926" y1="150.655" x2="80.1981" y2="-6.88642" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint2_linear_933_42939" x1="38.0396" y1="81.9434" x2="117.043" y2="-11.1225" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
          <linearGradient id="paint3_linear_933_42939" x1="40.5001" y1="93.4286" x2="74.8602" y2="58.0719" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint4_linear_933_42939" x1="47.7501" y1="104.179" x2="76.2688" y2="72.9857" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
