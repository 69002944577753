import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import CommonStyles from "..";
import Slider from "react-slick";
import { CloseCircleOutlined } from "@ant-design/icons";
import themeColor from "@base/themes/colorTheme";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

interface ILink {
  image_link?: string;
  article_link?: string;
}

interface IProps {
  links: ILink[];
  sessionStorageKey?: string;
  defaultEnable?: boolean;
  routeToCheck?: string;
}

export default function ImageLinkPopup({ links = [], defaultEnable, sessionStorageKey, routeToCheck }: IProps) {
  //! state
  let location = useLocation();
  let sliderRef = useRef<Slider>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [availableLinks, setAvailableLinks] = useState<ILink[]>([]);

  useEffect(() => {
    const _links = links.filter((item) => {
      return !!item.image_link;
    });

    setAvailableLinks(_links);

    if (!defaultEnable || !sessionStorageKey || routeToCheck !== location.pathname || !_links.length) {
      return;
    }

    if (defaultEnable && sessionStorageKey) {
      if (!sessionStorage.getItem(sessionStorageKey) && routeToCheck === location.pathname) {
        setOpen(true);
        sessionStorage.setItem(sessionStorageKey, dayjs().unix().toString());
      }
    }
  }, [links]);

  const settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: availableLinks.length > 1 ? false : true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          dots: false,
        },
      },
    ],
  };

  //! function

  //! render
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      styles={{ content: { backgroundColor: "transparent", padding: 0, margin: 0, boxShadow: "none" }, footer: { display: "none" } }}
      closeIcon={<CloseCircleOutlined style={{ fontSize: "28px", color: themeColor.white }} />}
    >
      <CommonStyles.CarouselUI refSlider={sliderRef} settings={settings}>
        {availableLinks.map((item, index) => {
          return (
            <a key={index} href={item.article_link} target="_blank">
              <img src={item.image_link} alt="Broadcast News" />
            </a>
          );
        })}
      </CommonStyles.CarouselUI>
    </Modal>
  );
}
