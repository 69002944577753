import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import ProfileListItem from "./ProfileListItem";
import CommonImages from "@base/assets/images";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import useGetListByUserKey from "@base/modules/user/profile/hooks/useGetListByUserKey";
import { IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import CommonIcons from "@base/assets/icons";

export default function ProfileList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);

  const { isLoading, data, error } = useGetListByUserKey();
  const dataUser = data?.data as IPatientRecordData[];

  return (
    <div className="p-5">
      <div className="flex flex-col min-[450px]:flex-row min-[450px]:justify-between min-[450px]:items-center min-h-8">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.profile.profile")}</div>

        <div className="flex items-center gap-4">
          <CommonStyles.ButtonUI
            hidden={!dataUser || !dataUser.length}
            icon={<CommonIcons.IconSync />}
            iconPosition="start"
            className="bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
            onClick={() => navigate(baseRoutes.userProfileSync)}
            loading={isLoading}
          >
            {t("user.profile.btnSync")}
          </CommonStyles.ButtonUI>

          {dataUser && dataUser?.length > 0 ? (
            <CommonStyles.ButtonUI
              hidden={!dataUser || !dataUser.length}
              icon={<PlusOutlined />}
              iconPosition="start"
              className="bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
              onClick={() => navigate(baseRoutes.userProfileHandler.replace(":id", "0"))}
              loading={isLoading}
            >
              {t("user.profile.btnNew")}
            </CommonStyles.ButtonUI>
          ) : null}
        </div>
      </div>

      <Divider />

      <div className="min-h-[442px] relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading} />

        {dataUser && dataUser?.length > 0 ? (
          <div className="flex flex-col gap-6 max-h-[610px] overflow-y-auto">
            {dataUser?.map((patient_record: IPatientRecordData, idx: number) => {
              return <ProfileListItem key={idx} data={patient_record} />;
            })}
          </div>
        ) : (
          <div className="w-full min-h-[442px] flex flex-col justify-center items-center gap-6">
            <img src={CommonImages.EmptyRecordList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("user.profile.recordNotFound")}!</div>

            <CommonStyles.ButtonUI onClick={() => navigate(baseRoutes.userProfileHandler.replace(":id", "0"))}>
              {t("user.profile.btnCreate")}
            </CommonStyles.ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
}
