export default function IconGraduate() {
  return (
    <div>
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.67354 9.88203V11.9895C3.67354 12.537 3.97354 13.047 4.45354 13.3095L8.20355 15.357C8.65354 15.6045 9.19355 15.6045 9.64355 15.357L13.3935 13.3095C13.8735 13.047 14.1735 12.537 14.1735 11.9895V9.88203L9.64355 12.357C9.19355 12.6045 8.65354 12.6045 8.20355 12.357L3.67354 9.88203ZM8.20355 2.63703L1.88104 6.08703C1.36354 6.37203 1.36354 7.12203 1.88104 7.40703L8.20355 10.857C8.65354 11.1045 9.19355 11.1045 9.64355 10.857L15.6735 7.56453V11.997C15.6735 12.4095 16.011 12.747 16.4235 12.747C16.836 12.747 17.1735 12.4095 17.1735 11.997V7.18953C17.1735 6.91203 17.0235 6.66453 16.7835 6.52953L9.64355 2.63703C9.19355 2.39703 8.65354 2.39703 8.20355 2.63703Z"
          fill="#5A6B81"
        />
      </svg>
    </div>
  );
}
