import CommonImages from "@base/assets/images";
import { ECurrency, EOrderStatus } from "@base/enums";
import useGetByOrderKey from "@base/modules/order/hooks/useGetByOrderKey";
import useGetOrderCode from "@base/modules/order/hooks/useGetOrderCode";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PaymentCard from "./PaymentCard";
import dayjs from "dayjs";
import OrderCard from "./OrderCard";
import { getPaymentMethodDescription, getPaymentMethodIcon } from "@base/helpers/common";

export default function PaymentDetail() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";

  const { key } = useParams();

  const { isLoading, data, error, refetch } = useGetByOrderKey(key, i18n.resolvedLanguage);

  return (
    <div className="p-5">
      <div className="flex flex-col gap-1">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.payment.paymentDetail")}</div>
        <div className="flex items-center gap-2">
          <CommonStyles.ImageUI src={CommonImages.paymentProtect} />
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0">
            Tất cả thông tin giao dịch được mã hóa an toàn
          </CommonStyles.TypographyUI>
        </div>
      </div>

      <Divider className="mt-4 mb-5" />

      <div className="flex justify-between max-lg:flex-col max-lg:justify-start items-start gap-20 max-lg:gap-2 relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading} />

        <div className="grow">
          <PaymentCard orderInfo={data ?? {}} />

          <div className="w-full mt-5">
            <div className="w-full">
              <div className="flex items-center gap-5">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                  Mã Order:
                </CommonStyles.TypographyUI>

                <CommonStyles.TypographyUI use="paragraph" className="grow text-common-orange text-base font-normal">
                  {data?.order_key}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-5">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                  Thời gian:
                </CommonStyles.TypographyUI>

                <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                  {data?.order_time ? dayjs(data?.order_time).format("HH:mm DD/MM/YYYY") : ""}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-5 mb-4">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal !mb-0">
                  Hình thức:
                </CommonStyles.TypographyUI>

                <div className="flex items-center gap-2">
                  {getPaymentMethodIcon(data?.payment_method, data?.bank_code) ? (
                    <img className="h-6" src={getPaymentMethodIcon(data?.payment_method, data?.bank_code)} alt="" />
                  ) : null}

                  <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal !mb-0">
                    {getPaymentMethodDescription(data?.payment_method, data?.bank_code)}
                  </CommonStyles.TypographyUI>
                </div>
              </div>
            </div>

            <Divider className="mt-0 mb-5" />

            <div className="w-full">
              <div className="flex items-center gap-5">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                  Tên:
                </CommonStyles.TypographyUI>

                <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                  {data?.customer_name}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-5">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                  SĐT:
                </CommonStyles.TypographyUI>

                <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                  {data?.customer_phone}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-5">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                  Email:
                </CommonStyles.TypographyUI>

                <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                  {data?.customer_email}
                </CommonStyles.TypographyUI>
              </div>
            </div>

            {/* <Divider className="mt-0 mb-5" />

          <div className="w-full">
            <div className="flex items-center gap-5">
              <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                Dịch vụ:
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex items-center gap-5">
              <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                Bác sĩ:
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex items-center gap-5">
              <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                Thời gian:
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex items-center gap-5">
              <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                Phí dịch vụ:
              </CommonStyles.TypographyUI>
            </div>
          </div> */}

            {data?.status != EOrderStatus.UNPAID ? (
              <React.Fragment>
                <Divider className="mt-0 mb-5" />

                <div className="w-full">
                  <div className="flex items-center gap-5">
                    <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                      Tổng tiền:
                    </CommonStyles.TypographyUI>

                    <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                      {data?.real_amounts?.toLocaleString()}
                      {ECurrency.VND}
                    </CommonStyles.TypographyUI>
                  </div>

                  <div className="flex items-center gap-5">
                    <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                      Khuyến mại:
                    </CommonStyles.TypographyUI>

                    <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-normal">
                      {data?.discount_amounts?.toLocaleString()}
                      {ECurrency.VND}
                    </CommonStyles.TypographyUI>
                  </div>

                  <div className="flex items-center gap-5">
                    <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[104px] text-common-subtext text-base font-normal">
                      Thanh toán:
                    </CommonStyles.TypographyUI>

                    <CommonStyles.TypographyUI use="paragraph" className="grow text-common-maintext text-base font-medium">
                      {data?.total_amounts?.toLocaleString()}
                      {ECurrency.VND}
                    </CommonStyles.TypographyUI>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>

        {data?.status == EOrderStatus.UNPAID ? (
          <div className="flex-none w-fit max-lg:w-full max-lg:flex max-lg:justify-center">
            <OrderCard orderInfo={data ?? {}} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
