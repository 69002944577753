import React from "react";
import CommonImages from "@base/assets/images";
import { ECurrency, EOrderStatus } from "@base/enums";
import { IOrderInfo } from "@base/modules/order/interface/OrderInfo.interface";
import CommonStyles from "@components/CommonStyles";
import TagOrderStatus from "@components/CommonStyles/TagOrderStatus";
import dayjs from "dayjs";
import { getPaymentMethodDescription, getPaymentMethodIcon } from "@base/helpers/common";

interface IProps {
  orderInfo: IOrderInfo;
}

export default function PaymentCard({ orderInfo }: IProps) {
  return (
    <div className="w-full flex justify-between max-sm:flex-col gap-36 max-lg:gap-2">
      <div className="grow flex items-start gap-5">
        <div className="w-fit max-lg:hidden">
          <CommonStyles.ImageUI width={104} height={104} src={CommonImages.Order} />
        </div>
        <div className="flex flex-col gap-1">
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-maintext text-base font-medium">
            {orderInfo.customer_name}
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI
            use="paragraph"
            className="!mb-0 text-common-subtext text-sm font-normal lg:line-clamp-1"
            title={orderInfo.description}
          >
            {orderInfo.description}
          </CommonStyles.TypographyUI>
          {orderInfo.site_name ? (
            <CommonStyles.TypographyUI
              use="paragraph"
              className="!mb-0 text-common-subtext text-sm font-normal lg:line-clamp-1"
              title={orderInfo.site_name}
            >
              {orderInfo.site_name}
            </CommonStyles.TypographyUI>
          ) : null}
          <TagOrderStatus status={orderInfo.status as EOrderStatus} />
        </div>
      </div>

      {orderInfo.status != EOrderStatus.UNPAID ? (
        <div className="flex-none w-max flex flex-col text-right max-sm:text-left max-sm:w-full gap-1">
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-inprogress text-base font-semibold">
            {orderInfo.total_amounts?.toLocaleString()}
            {ECurrency.VND}
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-subtext text-sm font-normal">
            {orderInfo.order_time ? dayjs(orderInfo.order_time).format("DD/MM/YYYY | HH:mm") : ""}
          </CommonStyles.TypographyUI>
          {/* <div className="flex items-center gap-2">
            {getPaymentMethodIcon(orderInfo.payment_method, orderInfo.bank_code) ? (
              <img className="h-5" src={getPaymentMethodIcon(orderInfo.payment_method, orderInfo.bank_code)} alt="" />
            ) : null}

            <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-subtext text-sm font-normal line-clamp-1">
              {getPaymentMethodDescription(orderInfo.payment_method, orderInfo.bank_code)}
            </CommonStyles.TypographyUI>
          </div> */}
        </div>
      ) : null}
    </div>
  );
}
