import React from "react";

export default function ApptSpecialization() {
  return (
    <div>
      <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.657547" width="84" height="84" rx="18" fill="#FFF2E3" />
        <g filter="url(#filter0_d_674_534)">
          <path
            d="M59.7687 29.0203H51.2131V24.687C51.2131 22.3036 49.2881 20.3536 46.9353 20.3536H38.3798C36.027 20.3536 34.102 22.3036 34.102 24.687V29.0203H25.5464C23.1937 29.0203 21.2687 30.9703 21.2687 33.3536V59.3536C21.2687 61.737 23.1937 63.687 25.5464 63.687H59.7687C62.1214 63.687 64.0464 61.737 64.0464 59.3536V33.3536C64.0464 30.9703 62.1214 29.0203 59.7687 29.0203ZM38.3798 24.687H46.9353V29.0203H38.3798V24.687Z"
            fill="url(#paint0_linear_674_534)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.6702 54.7744H39.6455V49.1166H34.074V42.999H39.6455V37.3417H45.6702V42.999H51.2418V49.1166H45.6702V54.7744Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.213 29V24.6868C51.213 22.3035 49.288 20.3535 46.9352 20.3535H38.3797C36.0269 20.3535 34.1019 22.3035 34.1019 24.6868V29H38.3797V24.6868H46.9352V29H51.213Z"
            fill="#FFBB7C"
          />
        </g>
        <defs>
          <filter id="filter0_d_674_534" x="12.6575" y="16" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.745098 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_674_534" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_674_534" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_674_534" x1="62.4496" y1="21.9572" x2="23.9603" y2="63.687" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA958" />
            <stop offset="0.737687" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
