export default function IconPhone24h() {
  return (
    <div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.5217 29.9992C17.0206 29.8672 15.6413 29.4287 14.3397 28.7798C9.28129 26.2595 5.44384 22.4485 2.66744 17.557C1.8734 16.1598 1.30446 14.6595 1.12671 13.0373C1.0064 11.939 1.18648 10.954 2.08064 10.1926C2.50289 9.83241 2.87157 9.40861 3.3039 9.06166C4.24308 8.30644 5.204 8.26995 6.13542 9.02984C7.23294 9.92555 8.08519 11.0231 8.47095 12.421C8.64637 13.0559 8.47095 13.6295 8.07199 14.1286C7.81196 14.4669 7.52234 14.7815 7.20655 15.0685C6.71988 15.497 6.55145 15.9619 6.92324 16.6729C7.34005 17.4728 7.83898 18.2272 8.41196 18.9238C9.80133 20.5996 11.3351 22.1403 13.1211 23.4009C13.5868 23.7315 14.1301 23.9768 14.661 24.1941C15.1034 24.3742 15.5148 24.2368 15.8517 23.8704C16.114 23.5856 16.402 23.3232 16.6899 23.064C17.3691 22.4531 18.1569 22.2878 18.9944 22.641C20.2293 23.1595 21.2935 23.931 22.0898 25.0223C22.6184 25.748 22.6642 26.5428 22.19 27.3144C21.6445 28.1986 20.9155 28.9553 20.0524 29.5335C19.6293 29.8199 19.0371 29.8525 18.5217 29.9992Z"
          fill="url(#paint0_linear_655_5893)"
        />
        <path
          d="M17.6173 6.34165C17.1989 5.63532 16.8023 4.99885 16.4437 4.3422C16.3048 4.08762 16.1449 4.04182 15.8856 4.08607C12.7778 4.61439 10.292 6.14864 8.42808 8.68883C8.35977 8.78197 8.28837 8.872 8.21851 8.96127L6.82138 7.65961C8.83945 4.46329 13.6378 1.2693 19.5787 2.14871C22.3923 2.56114 24.9985 3.86839 27.0114 5.87702C29.0244 7.88565 30.3373 10.4889 30.7557 13.3017C31.7058 19.6974 28.0158 24.8722 24.0123 26.895C23.9393 26.2383 23.864 25.5553 23.7841 24.8373C24.2839 24.4259 24.8808 23.9928 25.4141 23.4883C27.295 21.7005 28.4357 19.5197 28.8362 16.9459C28.8743 16.6998 28.8021 16.6059 28.6104 16.4957C27.9398 16.1122 27.28 15.7109 26.5698 15.2879C27.2684 14.875 27.9172 14.4791 28.5785 14.1058C28.8005 13.9808 28.8735 13.8613 28.8254 13.592C28.0686 9.35557 25.688 6.41151 21.7435 4.74815C20.9673 4.42138 20.1034 4.30107 19.2807 4.08529C19.0796 4.03251 18.9547 4.08529 18.8437 4.28321C18.4556 4.95384 18.0504 5.61282 17.6173 6.34165Z"
          fill="#FBB573"
        />
        <path
          d="M13.7954 16.603H16.831V18.1313C16.7255 18.1414 16.6153 18.1608 16.505 18.1608C15.0435 18.1608 13.5812 18.1538 12.1196 18.1686C11.8185 18.1686 11.7276 18.0739 11.6989 17.7805C11.5871 16.6317 11.9745 15.6949 12.8515 14.9637C13.2684 14.6168 13.7178 14.3094 14.1509 13.9818C14.2879 13.8857 14.4196 13.7821 14.5452 13.6714C14.8758 13.3609 14.9441 13.0054 14.7447 12.7027C14.5537 12.4132 14.0857 12.2634 13.7403 12.4497C13.4539 12.6049 13.2148 12.8471 12.8997 13.0939L11.6539 12.2649C11.9644 11.5827 12.4821 11.1496 13.1411 10.8678C14.2099 10.4153 15.6815 10.7413 16.3063 11.5586C17.0825 12.577 16.8543 14.0129 15.7459 14.8962C15.312 15.2424 14.8324 15.5288 14.3868 15.861C14.1486 16.0387 13.939 16.2553 13.717 16.4548L13.7954 16.603Z"
          fill="#FC8F29"
        />
        <path
          d="M23.5312 14.8651V16.3468L22.6774 16.388V18.132H20.9023V16.3786H17.6338C17.647 15.8307 17.5484 15.3417 17.8853 14.841C18.7228 13.5991 19.5036 12.3161 20.2961 11.0424C20.4203 10.8437 20.5545 10.7575 20.7928 10.7637C21.3983 10.7793 22.0045 10.7692 22.6557 10.7692V14.862L23.5312 14.8651ZM20.8852 14.8589V12.6662L19.4772 14.8589H20.8852Z"
          fill="#FC8F29"
        />
        <defs>
          <linearGradient id="paint0_linear_655_5893" x1="16.7933" y1="8.44561" x2="-0.359541" y2="21.2133" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9939" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
