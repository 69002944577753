import React from "react";

export default function K03A() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.713867" y="0.0499573" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16620)" />
        <rect x="1.71387" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1360_16620)">
          <path
            d="M59.9083 28.3213C60.4694 22.102 54.2579 20.3133 51.082 20.1964C39.8006 20.6056 41.2035 27.0353 41.2035 27.9121C41.2035 28.7889 41.6127 28.7305 41.2035 30.1333C38.8654 31.9453 38.807 33.4066 39.976 33.4651C41.1451 33.5236 40.9697 36.0955 41.2035 37.4983C41.4373 38.9012 41.905 39.135 43.5416 39.0181C45.1783 38.9012 46.3473 39.5441 46.2889 42.2329C46.2304 44.9218 45.1783 44.6295 37.521 47.3768C27.9933 50.2994 29.3962 56.846 29.4546 61.2884C28.8116 66.4322 29.7469 73.6803 28.8116 75.0832C28.0634 76.2054 27.7205 79.8763 27.6426 81.5714H36.0597C35.8259 80.5582 35.4986 78.0292 36.0597 76.0184C36.6209 74.0076 37.4626 66.6465 37.8133 63.2173C39.5903 64.9475 39.5279 76.1743 39.2746 81.5714H62.3633C62.2853 81.4155 62.071 79.6892 61.8372 74.031C61.6034 68.3728 63.0257 64.4643 63.7661 63.2173C63.2985 64.9125 64.7014 74.7909 65.2274 76.0184C65.6483 77.0004 65.4028 80.1296 65.2274 81.5714H73.7615C73.9953 79.584 72.4171 76.0769 72.534 73.0958C72.6509 70.1147 72.5924 63.7434 72.0664 62.399C71.5403 61.0546 72.7093 58.8334 71.3649 53.4558C70.0205 48.0782 62.1879 47.0845 58.1547 45.7985C54.1215 44.5126 55.1152 42.233 54.7645 39.5441C54.4138 36.8553 59.2069 36.0955 59.9083 28.3213Z"
            fill="url(#paint1_linear_1360_16620)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.8977 38.546H51.8191V50.1983H53.6901V47.7491C53.7808 47.2502 54.2479 46.2796 55.3909 46.3885C56.8196 46.5245 61.9902 53.532 61.8882 57.2739C61.8201 59.485 60.6976 59.7571 60.2894 59.8251C59.8812 59.8932 53.384 60.2674 53.384 57.9542V52.0353H51.8191V59.8875C51.842 60.7796 52.2857 62.3716 53.8778 61.603C55.8678 60.6423 59.2989 61.603 59.3675 63.8675C59.4361 66.1321 58.6813 71.9649 52.9857 72.857H58.8871C59.7106 72.9943 61.33 73.804 61.2203 75.945C61.1105 78.086 59.6191 78.7585 58.8871 78.8271H42.6237V81.572H40.9768V78.8271C41.0225 78.2095 41.5532 76.9194 43.3099 76.6999H58.4068C58.7638 76.3854 59.2637 75.5967 58.4068 74.9576H42.6237C41.7144 74.9957 39.9529 74.2654 40.1811 71.0399C40.2056 70.108 40.7285 68.1985 42.6237 68.016H50.7934C51.2815 67.9969 52.2464 67.7269 52.2007 66.7988C52.1634 66.041 51.7447 64.9585 51.0082 64.2618C50.3452 63.6346 49.5762 62.8822 49.5762 61.9695V52.0353H48.0447V57.9542C48.0447 60.2674 41.5475 59.8932 41.1393 59.8251C40.7311 59.7571 39.6086 59.485 39.5406 57.2739C39.4385 53.532 44.6091 46.5245 46.0378 46.3885C47.1808 46.2796 47.6479 47.2502 47.7386 47.7491V50.1983H49.5762V38.546H49.8977Z"
            fill="url(#paint2_linear_1360_16620)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1360_16620"
            x="19.6426"
            y="16.1964"
            width="62.1426"
            height="77.3756"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16620" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16620" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16620" x1="50.7139" y1="0.0499573" x2="50.7139" y2="100.05" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16620" x1="50.7139" y1="20.1964" x2="50.7139" y2="81.5714" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16620" x1="50.7144" y1="38.546" x2="50.7144" y2="81.572" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
