import { useCallback, useEffect, useState } from "react";
import { ResponseState } from "../state.interface";
import stateService from "../stateService";

const useGetState = () => {
  const [data, setData] = useState<ResponseState>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return stateService.getList();
  }, []);

  const transformResponse = useCallback((response: ResponseState) => {
    if (response) {
      setData(response);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading };
};

export default useGetState;
