import useOTP from "@base/modules/user/account/hooks/useOTP";
import CommonStyles from "@components/CommonStyles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function ExtraOTP() {
  //! state
  let interval: any;
  let start: number = 119;

  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { handleGetOTP } = useOTP();

  const phoneUrl = searchParams.get("phone");
  const [timeCounter, setTimeCounter] = useState<number>(start);

  //! function
  const funcTime = () => {
    let minutes = Math.floor(timeCounter / 60);
    let extraSeconds: string | number = timeCounter % 60;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    return `0${minutes}:${extraSeconds} `;
  };
  useEffect(() => {
    createInterval();

    return () => clearInterval(interval);
  }, []);

  const handleResendOTP = () => {
    if (phoneUrl) {
      handleGetOTP(phoneUrl, i18n.resolvedLanguage ?? "");

      createInterval();
    }
  };

  const createInterval = () => {
    interval = setInterval(() => {
      setTimeCounter(--start);

      if (start === 0) clearInterval(interval);
    }, 1000);
  };

  //! render
  return (
    <div className="text-center mt-4">
      {timeCounter === 0 ? (
        <CommonStyles.TypographyUI className="text-common-orange font-semibold cursor-pointer" onClick={handleResendOTP}>
          {t("LoginPage.Resend code")}
        </CommonStyles.TypographyUI>
      ) : (
        <CommonStyles.TypographyUI className="text-common-subtext">
          {t("LoginPage.TimeAuth")} (<span>{funcTime()}</span>)
        </CommonStyles.TypographyUI>
      )}
    </div>
  );
}
