import CommonIcons from "@base/assets/icons";
import IFilterSite from "@base/modules/site/filterSite.interface";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import themeColor from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import CardOrderFacility from "@components/OrderFacility/CardOrderFacility";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function TabFacility() {
  //! state
  const [searchParams] = useSearchParams();
  const root = searchParams.get("root");
  const [filters, setFilters] = useState<IFilterSite>({
    page_number: 0,
    page_size: 10,
    state_id: "",
    district_code: "",
    search_text: "",
    supported_seller: "",
    ward_code: "",
  });
  const [textSearch, setTextSearch] = useState("");

  const { data: dataFacilities, isLoading: loadingFacilities, refetch } = useGetListSite(filters, false);
  //! function
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, search_text: value }));
      }, 1000),
    [],
  );
  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page_number: value - 1 }));
  };
  useEffect(() => {
    refetch();
  }, [filters]);
  //! render
  const renderFacility = () => {
    return dataFacilities?.data.content_page?.map((item, index) => {
      return <CardOrderFacility site={item} key={index} root={root as any} />;
    });
  };
  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div className="flex items-center gap-4 ">
        <div className="grow">
          <CommonStyles.InputUI
            value={textSearch}
            onChange={changeSearch}
            prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
            placeholder="Nhập tên bác sĩ cần tìm..."
            variant="filled"
          />
        </div>
        <CommonStyles.ButtonUI icon={<CommonIcons.SearchOutlined />} className=" h-12 !px-6 hover:!bg-common-orange100" />
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-4">{renderFacility()}</div>

      <div className="flex justify-end items-center mt-8">
        <CommonStyles.PaginationUI
          current={filters.page_number + 1}
          pageSize={filters.page_size}
          onChange={handleChangePage}
          total={dataFacilities?.data.total_records}
        />
      </div>
    </div>
  );
}
