import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import IFilterCollaboration from "./filterCollaboration.interface";
import { ICollaboration, ICollaborationPage } from "./collaboration.interface";

class collaborationService {
  getAll(params?: IFilterCollaboration) {
    return httpService.get<ICollaborationPage>(`${apiService.utils.collaboration.getAll}`, { params });
  }

  register(captcha?: string, payload?: ICollaboration) {
    return httpService.post(`${apiService.utils.collaboration.register}`, payload, { params: { captcha } });
  }

  update(captcha?: string, payload?: ICollaboration) {
    return httpService.put(`${apiService.utils.collaboration.update}`, payload, { params: captcha });
  }

  testCaptcha(captcha?: string) {
    return httpService.post(`${apiService.utils.collaboration.testCaptcha}`, undefined, { params: captcha });
  }
}

export default new collaborationService();
