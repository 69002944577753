import React from "react";

export default function IconLocationFooter() {
  return (
    <div>
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.03031 2.8241C6.44764 2.8241 4.32703 4.98212 4.32703 7.67789C4.32703 9.00008 4.907 10.2359 5.86057 11.5095C6.66542 12.5844 7.69549 13.6353 8.79473 14.7567C8.87293 14.8365 8.95147 14.9167 9.03031 14.9972C9.10915 14.9167 9.1877 14.8365 9.2659 14.7567C10.3651 13.6353 11.3952 12.5844 12.2001 11.5095C13.1536 10.2359 13.7336 9.00008 13.7336 7.67789C13.7336 4.98212 11.613 2.8241 9.03031 2.8241ZM3.19824 7.67789C3.19824 4.3889 5.79447 1.69531 9.03031 1.69531C12.2662 1.69531 14.8624 4.3889 14.8624 7.67789C14.8624 9.34808 14.1254 10.8213 13.1036 12.186C12.2495 13.3267 11.1563 14.4415 10.058 15.5614C9.84998 15.7736 9.64177 15.9859 9.43498 16.1986C9.32872 16.3079 9.18276 16.3696 9.03031 16.3696C8.87787 16.3696 8.73191 16.3079 8.62564 16.1986C8.41885 15.9859 8.21064 15.7736 8.00261 15.5614C6.90435 14.4415 5.81109 13.3267 4.957 12.186C3.93519 10.8213 3.19824 9.34808 3.19824 7.67789ZM9.03031 5.8342C8.09519 5.8342 7.33713 6.59226 7.33713 7.52738C7.33713 8.4625 8.09519 9.22056 9.03031 9.22056C9.96543 9.22056 10.7235 8.4625 10.7235 7.52738C10.7235 6.59226 9.96543 5.8342 9.03031 5.8342ZM6.20834 7.52738C6.20834 5.96885 7.47178 4.70541 9.03031 4.70541C10.5888 4.70541 11.8523 5.96885 11.8523 7.52738C11.8523 9.08591 10.5888 10.3494 9.03031 10.3494C7.47178 10.3494 6.20834 9.08591 6.20834 7.52738Z"
          fill="#F98315"
        />
      </svg>
    </div>
  );
}
