import CommonStyles from "@components/CommonStyles";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import ExtraFormLogin from "./ExtraForm";
import useFormValidation from "@base/hooks/useFormvalidation";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "@base/modules/auth/hooks/useAuth";
import useOTP from "@base/modules/user/account/hooks/useOTP";
import { baseRoutes } from "@base/routes/baseRoutes";

export default function LoginPasswordForm() {
  //! state
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { login } = useFormValidation();
  const [searchParams] = useSearchParams();

  const phoneUrl = searchParams.get("phone");

  const initialValues = {
    password: "",
  };

  const { isLoading, error, handleLogin } = useAuth();
  const { handleGetOTP } = useOTP();

  //! function
  const handleLoginSubmit = (values: { password: string }) => {
    if (!values.password || !phoneUrl) {
      return;
    }

    handleLogin(phoneUrl, values.password);
  };

  const handleForgotPassword = () => {
    if (!phoneUrl) {
      return;
    }

    handleGetOTP(phoneUrl, i18n.resolvedLanguage ?? "");
    navigate({
      pathname: baseRoutes.login,
      search: createSearchParams({
        phone: phoneUrl,
        otp: "true",
        reset: "true",
      }).toString(),
    });
  };

  //! render
  const ForgotPassword = () => {
    return (
      <CommonStyles.TypographyUI onClick={handleForgotPassword} className="!text-common-inprogress cursor-pointer">
        {t("LoginPage.ForgotPassword")}
      </CommonStyles.TypographyUI>
    );
  };

  return (
    <Form form={form} name="loginPassword" layout="vertical" initialValues={initialValues} onFinish={handleLoginSubmit}>
      <input hidden name="username" defaultValue={phoneUrl ?? ""} autoComplete="username" />

      {error ? <CommonStyles.TypographyUI className="text-common-red500">{t("LoginPage.invalidPass")}</CommonStyles.TypographyUI> : null}

      <CommonStyles.FormFieldUI
        name="password"
        rules={login.password()}
        component={
          <CommonStyles.InputUI
            autoComplete="new-password"
            use="password"
            placeholder={t("LoginPage.PlaceholderPassword")}
            variant="filled"
            label={t("LoginPage.Password")}
            extraTitle={<ForgotPassword />}
          />
        }
      />

      {/* <div className="flex gap-2 mb-4">
        <CommonStyles.CheckBoxUI titleCustom={t("LoginPage.SavePassowrd")} />
      </div> */}

      <div className="flex justify-center items-center">
        <CommonStyles.ButtonUI htmlType="submit" className="h-12 w-full" loading={isLoading}>
          <CommonStyles.TypographyUI use="text" className="text-white text-base font-semibold">
            {t("LoginPage.Continue")}
          </CommonStyles.TypographyUI>
        </CommonStyles.ButtonUI>
      </div>

      <ExtraFormLogin />
    </Form>
  );
}
