import { Breadcrumb } from "antd";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from "antd/es/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import CommonIcons from "@base/assets/icons";
import TypographyUI from "../Typography";

interface IProps {
  items?: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
  className?: string;
}
export default function BreadcrumbUI(props: IProps) {
  //! state
  const { items, className } = props;

  //! function

  const itemRender = (currentRoute: any, params: any, items: any, paths: any) => {
    const isLast = currentRoute?.title === items[items.length - 1]?.title;

    return isLast ? (
      <TypographyUI className="!text-common-mainColor text-base font-medium">{currentRoute.title}</TypographyUI>
    ) : (
      <Link to={currentRoute?.href} className="text-base font-medium cursor-pointer hover:!bg-transparent">
        <TypographyUI className="text-common-subtext">{currentRoute.title}</TypographyUI>
      </Link>
    );
  };

  //! render
  return (
    <Breadcrumb className={`[&_ol]:items-center  ${className}`} separator={<CommonIcons.RightOutlined />} items={items} itemRender={itemRender} />
  );
}
