export default function IconK31({ width, height }: { width?: number; height?: number }) {
  return (
    <div>
      <svg width={width ?? 40} height={height ?? 41} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="100" height="39" rx="11.5" fill="white" stroke="white" />
        <g clipPath="url(#clip0_655_5737)">
          <path
            d="M14.2045 10.5636C14.7883 13.1776 16.2807 15.2767 17.8229 17.3383C20.795 21.3071 24.2054 24.9234 27.6253 28.5327C27.7431 28.6566 27.8567 28.7835 28 28.9389L25.2116 33.0011C24.2935 31.9662 23.3902 30.9974 22.5453 29.9859C19.9883 26.922 17.4366 23.853 14.921 20.7576C14.3393 20.0417 13.902 19.2171 13.4126 18.4341C12.7291 17.3403 12.4574 16.1867 12.6696 14.8929C12.9042 13.4528 13.5464 11.4075 14.2045 10.5636Z"
            fill="#F68E1E"
          />
          <path
            d="M19.8896 18.5778C21.7886 15.9373 23.7618 13.4381 24.6661 10.2939C24.7723 10.4544 24.8551 10.5722 24.9251 10.7002C25.7645 12.1933 26.2553 13.8429 26.3634 15.5352C26.4111 16.1056 26.3363 16.6793 26.1436 17.221C25.4304 19.0388 24.2256 20.5885 23.0007 22.2286L19.8896 18.5778Z"
            fill="#F68E1E"
          />
          <path
            d="M22.9231 12.6233C20.4468 11.3559 18.1764 11.428 15.924 12.7096C15.4273 11.694 14.9931 10.6856 15.1407 9.57054C15.1933 9.17743 15.3712 8.80938 15.6502 8.5164C17.7369 6.50663 21.1696 6.50866 23.3127 8.46359C23.699 8.81598 23.8912 9.21916 23.7744 9.68224C23.5345 10.6399 23.2278 11.5833 22.9231 12.6233Z"
            fill="#F68E1E"
          />
          <path d="M15.8455 23.5352L18.896 27.2135L13.781 33.0021L11 28.9663L15.8455 23.5352Z" fill="#F68E1E" />
        </g>
        <defs>
          <clipPath id="clip0_655_5737">
            <rect width="17" height="26" fill="white" transform="translate(11 7)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
