import React from "react";

export default function IconCamera() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 4.66667H14.0251L12.5001 3H7.50008L5.97508 4.66667H3.33341C2.41675 4.66667 1.66675 5.41667 1.66675 6.33333V16.3333C1.66675 17.25 2.41675 18 3.33341 18H16.6667C17.5834 18 18.3334 17.25 18.3334 16.3333V6.33333C18.3334 5.41667 17.5834 4.66667 16.6667 4.66667ZM16.6667 16.3333H3.33341V6.33333H6.70842L8.23342 4.66667H11.7667L13.2917 6.33333H16.6667V16.3333ZM10.0001 7.16667C7.70008 7.16667 5.83342 9.03333 5.83342 11.3333C5.83342 13.6333 7.70008 15.5 10.0001 15.5C12.3001 15.5 14.1667 13.6333 14.1667 11.3333C14.1667 9.03333 12.3001 7.16667 10.0001 7.16667ZM10.0001 13.8333C8.62508 13.8333 7.50008 12.7083 7.50008 11.3333C7.50008 9.95833 8.62508 8.83333 10.0001 8.83333C11.3751 8.83333 12.5001 9.95833 12.5001 11.3333C12.5001 12.7083 11.3751 13.8333 10.0001 13.8333Z"
        fill="currentColor"
      />
    </svg>
  );
}
