import { apiService, apiAuthorize } from "@base/configs";
import httpService from "@base/services/httpService";
import { IAuth } from "./auth.interface";

class AuthService {
  // resgister
  register(phone_number: string, lang: string) {
    return httpService.post(`${apiService.auth.user.register}/${phone_number}/${lang}`, undefined);
  }

  // login
  login(username: string, password: string) {
    const params = { grant_type: "password", username, password };

    const headers = {
      Authorization: apiAuthorize,
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };

    return httpService.post<IAuth>(apiService.auth.login, undefined, { headers, params });
  }

  // logout
  logout() {
    return httpService.post(`${apiService.auth.user.logout}`, undefined);
  }
}

export default new AuthService();
