import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { ISite } from "@base/modules/site/site.interface";
import useGetSiteSpecialPackage from "@base/modules/siteSpecialPackage/hooks/useGetSiteSpecialPackage";
import IFilterSiteSpecialPackage from "@base/modules/siteSpecialPackage/interface/filterSiteSpecialPackage";
import CommonStyles from "@components/CommonStyles";
import CardPackageService from "@components/CommonStyles/CardPackageService";
import { Divider } from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

export default function TabPackageService({ site }: { site?: ISite }) {
  //! state
  const [filters, setFilters] = useState<IFilterSiteSpecialPackage>({
    page_number: 0,
    page_size: 12,
    search: undefined,
    site_code: site?.code,
    lang: "vi",
  });
  const [textSearch, setTextSearch] = useState("");

  const { data: siteSpecialPackages, isLoading, refetch: refetchSiteSpecialPackage } = useGetSiteSpecialPackage(filters, false);
  const dataSiteSpecialPackage = siteSpecialPackages?.data.content_page;
  //! function
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };
  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, search: value }));
      }, 1000),
    [],
  );
  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page_number: value - 1 }));
  };
  const clearFilter = () => {
    if (filters.page_number !== 0 || filters.search) {
      setFilters({ page_number: 0, page_size: 10 });
      setTextSearch("");
    }
  };

  useEffect(() => {
    setFilters((prev) => ({ ...prev, site_code: site?.code }));
  }, [site]);

  useEffect(() => {
    if (!site?.code) {
      return;
    }
    refetchSiteSpecialPackage();
  }, [filters]);
  //! render
  const renderPackage = () => {
    return dataSiteSpecialPackage?.map((item, index) => {
      return <CardPackageService data={item} key={index} />;
    });
  };
  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div className="flex items-center gap-4 ">
        <div className="grow">
          <CommonStyles.InputUI
            value={textSearch}
            onChange={changeSearch}
            prefix={<CommonIcons.SearchOutlined />}
            placeholder="Nhập tên chuyên khoa cần tìm..."
            variant="filled"
          />
        </div>
        <CommonStyles.ButtonUI
          onClick={clearFilter}
          icon={<CommonIcons.IconRefresh />}
          className="bg-common-orange100 h-12 !px-6 hover:!bg-common-orange100"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 mt-6">{renderPackage()}</div>

      <div className="flex justify-end items-center mt-6">
        <CommonStyles.PaginationUI
          current={Number(filters.page_number) + 1}
          onChange={handleChangePage}
          pageSize={filters.page_size}
          total={siteSpecialPackages?.data.total_records}
        />
      </div>
    </div>
  );
}
