import CommonStyles from "@components/CommonStyles";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import ExtraFormLogin from "./ExtraForm";
import useFormValidation from "@base/hooks/useFormvalidation";
import useRegister from "@base/modules/auth/hooks/useRegister";
import { useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import useGetListCommon from "@base/modules/common/hooks/useGetListCommon";
import KeyCommon from "@base/modules/common/key";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { RootState } from "@base/store/store";

export default function LoginForm() {
  //! state
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { login } = useFormValidation();
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);
  const [form] = Form.useForm();
  const initialValues = {
    phoneNumber: "",
  };
  const { isLoading, handleRegister } = useRegister();
  const [agreeToTheTerms, setAgreeToTheTerms] = useState<boolean>(true);
  const policies = dataCommon?.data.commons.find(
    (item) => item.key === (i18n.resolvedLanguage === "vi" ? KeyCommon.WEB_DIEU_KHOAN : KeyCommon.WEB_DIEU_KHOAN_EN),
  );
  //! function
  const handleLoginSubmit = (values: { phoneNumber: string }) => {
    if (!agreeToTheTerms) {
      dispatch(setAlertMessage({ type: "warning", message: t("Notification"), description: t("PleaseConfirmTerms") + "!" }));
      return;
    }

    if (!values.phoneNumber) {
      return;
    }
    handleRegister(values.phoneNumber);
  };

  //! render
  return (
    <Form form={form} name="loginPhone" layout="vertical" initialValues={initialValues} onFinish={handleLoginSubmit}>
      <CommonStyles.FormFieldUI
        name="phoneNumber"
        rules={login.phone()}
        component={<CommonStyles.InputUI placeholder={t("LoginPage.PlaceholderPhonenumber")} label={t("LoginPage.Phonenumber")} variant="filled" />}
      />

      <div className="flex gap-2 mb-4">
        <CommonStyles.CheckBoxUI
          defaultChecked={agreeToTheTerms}
          onChange={(event: CheckboxChangeEvent) => setAgreeToTheTerms(event.target.checked)}
        />
        <CommonStyles.TypographyUI className="text-xs lg:text-sm">
          {t("LoginPage.read agree")}{" "}
          <Link to={String(policies?.article_link)} target="_blank" className="text-common-inprogress cursor-pointer">
            {t("LoginPage.policies")}
          </Link>
        </CommonStyles.TypographyUI>
      </div>

      <div className="flex justify-center items-center">
        <CommonStyles.ButtonUI htmlType="submit" className="h-10 lg:h-12 w-full" loading={isLoading}>
          <CommonStyles.TypographyUI use="text" className="text-white  lg:text-base font-semibold">
            {t("LoginPage.Continue")}
          </CommonStyles.TypographyUI>
        </CommonStyles.ButtonUI>
      </div>

      {/* <ExtraFormLogin /> */}
    </Form>
  );
}
