import React, { useEffect, useState } from "react";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ReactQuill from "react-quill";
import styles from "!!raw-loader!./index.module.css";
import { Post, PostsCategory } from "@base/types/wp";
import dayjs from "dayjs";
import CommonIcons from "@base/assets/icons";
import CommonStyles from "@components/CommonStyles";
import CommonImages from "@base/assets/images";
import { baseRoutes } from "@base/routes/baseRoutes";
import useGetCategories from "@base/modules/wpApis/hooks/useGetCategories";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from "antd/es/breadcrumb/Breadcrumb";
import { Divider } from "antd";
import LatestMedicationInfo from "@components/MedicalInfo/LatestMedicationInfo";

const StyledReactQuill = styled(ReactQuill)`
  ${styles}
`;

export default function MedicalInfoDetail() {
  //! state
  const { slug } = useParams();
  const { data: categories } = useGetCategories();
  const { data, error, isLoading, refetch } = useGetPosts(
    { slug: slug ? slug.replace(".html", "") : slug, _embed: true, page: 1, per_page: 1 },
    true,
  );
  const [post, setPost] = useState<Post>();
  const [postContent, setPostContent] = useState<string>("");
  const [author, setAuthor] = useState<any>();
  const [featuredmedia, setFeaturedmedia] = useState<any>();
  const [category, setCategory] = useState<PostsCategory | undefined>(undefined);
  const [breadcrumItems, setBreadcrumItems] = useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined>(undefined);

  //! function
  useEffect(() => {
    if (data?.data.length) {
      const _post = data?.data[0] ?? {};
      setPost(_post);

      setPostContent(_post.content?.rendered ?? "");

      if (_post._embedded && _post._embedded.author && _post._embedded.author.length) {
        setAuthor(_post._embedded.author[0]);
      }

      if (_post._embedded && _post._embedded["wp:featuredmedia"] && _post._embedded["wp:featuredmedia"].length) {
        setFeaturedmedia(_post._embedded["wp:featuredmedia"][0]);
      }

      const _cat = _post.categories?.at(0);

      if (_cat) {
        const _category = categories?.data.find((c) => {
          return Number(c.id) === Number(_cat);
        });

        if (_category) {
          setCategory(_category);
          setBreadcrumItems([
            {
              href: baseRoutes.home,
              title: <CommonIcons.IconHome />,
            },
            {
              href: baseRoutes.medicalInfo,
              title: "Tin tức",
            },
            {
              href: baseRoutes.medicalInfo + "?cat=" + _category.id,
              title: _category.name as string,
            },
          ]);
        }
      }
    }
  }, [data, categories]);

  //! render
  const header = () => {
    return (
      <div className="w-full">
        <div
          className="rounded-2xl"
          style={{
            backgroundImage: `url(${CommonImages.NewsBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full flex flex-col gap-2 px-[70px] py-[22px] rounded-xl">
            <CommonStyles.TypographyUI use="title" level={1} className="!text-2xl !font-light !text-[#005984] uppercase !mb-0">
              Thông tin y tế
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="!text-xs !font-light !text-[#525252] !mb-0">
              {`Nắm bắt nhanh chóng các thông tin y tế chính xác, tham khảo trải nghiệm khám chữa bệnh từ các người dùng khác.`}
            </CommonStyles.TypographyUI>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-common-white">
      <div className={`${responseUI.screen} ${initialPage.paddingX}`}>
        <div className={`relative py-5 max-lg:!pt-0 max-[700px]:px-2`}>{header()}</div>
      </div>

      <div className="bg-common-white">
        <div className={`${responseUI.screen} ${initialPage.paddingX} max-[700px]:px-2`}>
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-3 max-lg:!col-span-4">
              {breadcrumItems ? <CommonStyles.BreadcrumbUI items={breadcrumItems} className="mb-4" /> : null}

              <CommonStyles.TypographyUI use="title" level={2} className="!text-[32px] !leading-10 !text-[#000000] !font-light !mb-3">
                <span dangerouslySetInnerHTML={{ __html: post?.title?.rendered ?? "" }}></span>
              </CommonStyles.TypographyUI>

              <div className="flex gap-6">
                <div className="flex gap-1 items-center text-base text-[#A1A1A1] font-light">
                  <CommonIcons.IconLineCalendar />
                  <span>{dayjs(post?.date).format("DD/MM/YYYY")}</span>
                </div>
                <div className="flex gap-1 items-center text-base text-[#A1A1A1] font-light">
                  <CommonIcons.IconPerson />
                  <span>{author?.name}</span>
                </div>
              </div>

              <StyledReactQuill className={`${!post ? "hidden" : ""}`}>
                <div className="wp-embed-content relative">
                  <article id={"post-" + (post?.id ?? "-1")} className={post?.class_list ? post.class_list.join(" ") : ""}>
                    <div className="article-inner">
                      <div className="entry-content single-page">
                        <div dangerouslySetInnerHTML={{ __html: postContent }}></div>

                        <div className="blog-share text-center">
                          <div className="is-divider medium"></div>
                          <div className="social-icons share-icons share-row relative">
                            <a
                              href="https://www.facebook.com/sharer.php?u=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                              data-label="Facebook"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                              className="icon button circle is-outline tooltip facebook"
                              title="Chia sẻ lên Facebook"
                              aria-label="Chia sẻ lên Facebook"
                            >
                              <CommonIcons.IconFacebook />
                            </a>
                            <a
                              href="https://twitter.com/share?url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                              className="icon button circle is-outline tooltip twitter"
                              title="Chia sẻ lên Twitter"
                              aria-label="Chia sẻ lên Twitter"
                            >
                              <CommonIcons.IconX />
                            </a>
                            <a
                              href="mailto:enteryour@addresshere.com?subject=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024&amp;body=Check%20this%20out:%20https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                              rel="nofollow"
                              className="icon button circle is-outline tooltip email"
                              title="Gửi Email"
                              aria-label="Gửi Email"
                            >
                              <CommonIcons.IconMail />
                            </a>
                            <a
                              href="https://pinterest.com/pin/create/button/?url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html&amp;media=https://news.365medihome.com.vn/wp-media/2024/10/thiet-bi-y-te-thong-minh-365medihome-techconnect2024-0.jpg&amp;description=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                              className="icon button circle is-outline tooltip pinterest"
                              title="Ghim trên Pinterest"
                              aria-label="Ghim trên Pinterest"
                            >
                              <CommonIcons.IconPinterest />
                            </a>
                            <a
                              href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html&amp;title=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                              className="icon button circle is-outline tooltip linkedin"
                              title="Chia sẻ lên LinkedIn"
                              aria-label="Chia sẻ lên LinkedIn"
                            >
                              <CommonIcons.IconLinkedin />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>

                  <Divider className="!m-0 [border-block-start-width:1px] [border-block-start-color:#D9D9D9]" />
                </div>
              </StyledReactQuill>
            </div>

            <LatestMedicationInfo className="!mt-0 max-lg:!hidden" category={category} />
          </div>
        </div>
      </div>
    </div>
  );
}
