import React from "react";

export default function K19() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.859375" y="0.833954" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.85938" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27216)">
          <path
            d="M42.2511 73.233H40.5889C39.6591 74.1473 38.6409 74.9109 37.5293 75.529H42.2511C42.5368 75.529 42.7706 75.2953 42.7706 75.0096V73.7525C42.7706 73.4668 42.5368 73.233 42.2511 73.233Z"
            fill="url(#paint0_linear_1205_27216)"
          />
          <path
            d="M51.9916 73.233H45.6542C45.3685 73.233 45.1348 73.4668 45.1348 73.7525V75.0096C45.1348 75.2953 45.3685 75.529 45.6542 75.529H51.9916C52.2773 75.529 52.511 75.2953 52.511 75.0096V73.7525C52.511 73.4668 52.2773 73.233 51.9916 73.233Z"
            fill="url(#paint1_linear_1205_27216)"
          />
          <path
            d="M61.7318 73.233H55.3945C55.1088 73.233 54.875 73.4668 54.875 73.7525V75.0096C54.875 75.2953 55.1088 75.529 55.3945 75.529H61.7318C62.0175 75.529 62.2513 75.2953 62.2513 75.0096V73.7525C62.2513 73.4668 62.0175 73.233 61.7318 73.233Z"
            fill="url(#paint2_linear_1205_27216)"
          />
          <path
            d="M71.4711 73.233H65.1337C64.848 73.233 64.6143 73.4668 64.6143 73.7525V75.0096C64.6143 75.2953 64.848 75.529 65.1337 75.529H71.4711C71.7568 75.529 71.9905 75.2953 71.9905 75.0096V73.7525C71.9905 73.4668 71.7568 73.233 71.4711 73.233Z"
            fill="url(#paint3_linear_1205_27216)"
          />
          <path
            d="M42.283 67.8263C45.7167 61.1565 46.7815 59.3954 48.1581 58.3981L45.1089 55.3489L25.9358 74.5221C25.7956 74.6623 25.8579 74.9013 26.0449 74.9532C26.7513 75.1558 28.3097 75.5299 30.2525 75.5299C31.8005 75.5299 33.5926 75.2908 35.3951 74.5481C36.1898 74.2208 36.9898 73.7897 37.769 73.2391C39.4208 72.0598 40.9896 70.3352 42.283 67.8263Z"
            fill="url(#paint4_linear_1205_27216)"
          />
          <path
            d="M72.6817 28.6222C72.1362 28.0767 71.4246 27.8066 70.713 27.8066C70.0013 27.8066 69.2897 28.0767 68.7494 28.6222L45.4622 51.9094C45.1298 52.2418 45.1298 52.782 45.4622 53.1145L46.8855 54.5377L49.2646 52.1586L56.0643 45.359L61.5705 39.8527C61.6068 39.8164 61.638 39.7852 61.6744 39.7592C61.6796 39.754 61.69 39.7488 61.6951 39.7437C61.7107 39.7281 61.7315 39.7177 61.7523 39.7021C62.3185 39.2969 63.1029 39.3489 63.612 39.8527L63.8145 40.0553C64.2613 40.5021 64.3547 41.1773 64.0899 41.7176C64.0223 41.8526 63.9288 41.9825 63.8145 42.0968L56.4746 49.4315L51.3787 54.5274L49.1295 56.7767L50.5527 58.1999C50.719 58.3661 50.9371 58.4493 51.1554 58.4493C51.3735 58.4493 51.5917 58.3661 51.7579 58.1999L75.0451 34.9127C76.1308 33.8271 76.1308 32.0661 75.0451 30.9804L72.6817 28.6222Z"
            fill="url(#paint5_linear_1205_27216)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27216"
            x="17.8594"
            y="23.8066"
            width="66"
            height="63.7232"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27216" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27216" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27216" x1="41.3701" y1="73.2297" x2="39.7038" y2="76.0739" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint1_linear_1205_27216" x1="50.5401" y1="73.2297" x2="49.1846" y2="76.4858" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint2_linear_1205_27216" x1="60.2803" y1="73.2297" x2="58.9248" y2="76.4858" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint3_linear_1205_27216" x1="70.0196" y1="73.2297" x2="68.6641" y2="76.4858" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint4_linear_1205_27216" x1="42.1999" y1="55.3192" x2="25.7111" y2="68.9422" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint5_linear_1205_27216" x1="67.6707" y1="27.7615" x2="43.2162" y2="46.0491" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
