import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import ISearchParams from "./searchParams.interface";
import { IPageSearch, ITopKeyword } from "./allPageSearch.interface";

class allPageSearchService {
  getTopKeyWords(lang?: string) {
    return httpService.get<ITopKeyword[]>(`${apiService.utils.allPageSearch.getTopKeyWords}`, { params: { lang } });
  }

  search(params?: ISearchParams) {
    return httpService.get<IPageSearch>(`${apiService.utils.allPageSearch.search}`, { params });
  }
}

export default new allPageSearchService();
