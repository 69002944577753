import CommonImages from "@base/assets/images";
import { LocalKey } from "@base/constants/LocalKey";
import { BankCode, EPaymentMethod } from "@base/enums";
import { IAuth } from "@base/modules/auth/auth.interface";
import { ICoordinate } from "@base/types";
import dayjs from "dayjs";
import * as _ from "lodash";

export const getAuthLocal = () => {
  const authString = localStorage.getItem(LocalKey.AUTH);

  if (authString) {
    return JSON.parse(authString) as IAuth;
  }

  return;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const limitString = (text: string, limit = 30) => {
  return _.truncate(text, {
    length: limit,
    separator: /,? +/,
  });
};

export const getAvatarLink = (avatars: any[], objectName?: string) => {
  const avatar = avatars.find((e) => {
    if (objectName) {
      return e.object_name === objectName;
    } else {
      return e.object_name === "avatar";
    }
  });

  return avatar && avatar.link ? avatar.link : undefined;
};

export const getMinMax = (value1: number, value2: number) => {
  if (value1 > value2) {
    return {
      min: value2,
      max: value1,
    };
  }
  if (value1 > value2) {
    return {
      min: value1,
      max: value2,
    };
  }
};

export const getValueDate = (dateTime: string) => {
  const year = dayjs(dateTime).get("year");
  const month = dayjs(dateTime).get("month");
  const date = dayjs(dateTime).get("date");
  const hours = dayjs(dateTime).get("hour");
  const minutes = dayjs(dateTime).get("minutes");
  const seconds = dayjs(dateTime).get("seconds");

  return {
    year,
    month,
    date,
    hours,
    minutes,
    seconds,
    nanos: 0,
    time: 0,
  };
};

export const getErrorMsg = (error: any) => {
  if (error?.response?.data?.error?.message) {
    return error.response.data.error.message;
  }
  if (error?.response?.data?.[0]) {
    return error?.response?.data?.[0];
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  }

  return `Something wrong!`;
};

export const backToTop = (behavior?: "auto" | "smooth") => {
  return window.scrollTo({
    top: 0,
    left: 0,
    behavior: behavior ?? "auto",
  });
};

export const getPaymentMethodDescription = (paymentMethod?: EPaymentMethod, bankCode?: BankCode) => {
  let value = "";

  switch (paymentMethod) {
    case EPaymentMethod.CASH:
      value = "Tiền mặt";
      break;

    case EPaymentMethod.TRANSFER:
      value = "Chuyển khoản";
      break;

    case EPaymentMethod.GATEWAY:
      value = "Qua app";
      break;

    default:
      break;
  }

  switch (bankCode) {
    case BankCode.ATM:
      value = "Thẻ ATM nội địa";
      break;

    case BankCode.CASH:
      value = "Tiền mặt";
      break;

    case BankCode.MOMO:
      value = "Momo";
      break;

    case BankCode.VNPAY:
      value = "VNPAY";
      break;

    case BankCode.VITAPAY:
      value = "VITAPAY";
      break;

    default:
      break;
  }

  return value;
};

export const getPaymentMethodIcon = (paymentMethod?: EPaymentMethod, bankCode?: BankCode) => {
  let value = "";

  switch (paymentMethod) {
    case EPaymentMethod.CASH:
      value = CommonImages.Cash;
      break;

    case EPaymentMethod.TRANSFER:
      value = CommonImages.Banking;
      break;

    case EPaymentMethod.GATEWAY:
      value = "Qua app";
      break;

    default:
      break;
  }

  switch (bankCode) {
    case BankCode.ATM:
      value = CommonImages.ATM;
      break;

    case BankCode.CASH:
      value = CommonImages.Cash;
      break;

    case BankCode.MOMO:
      value = CommonImages.Momo;
      break;

    case BankCode.VNPAY:
      value = CommonImages.VNPAY;
      break;

    case BankCode.VITAPAY:
      value = CommonImages.VitaPay;
      break;

    default:
      break;
  }

  return value;
};

export const CompareDateWithhNow = (date: string) => {
  const currentDate = dayjs();
  const targetDate = dayjs(date);
  let result = 0;
  if (targetDate.isBefore(currentDate)) {
    // console.log("Ngày cụ thể đã trôi qua.");
    result = -1;
  } else if (targetDate.isSame(currentDate, "day")) {
    // console.log("Ngày cụ thể là ngày hôm nay.");
    result = 0;
  } else if (targetDate.isAfter(currentDate)) {
    // console.log("Ngày cụ thể sẽ đến sau ngày hôm nay.");
    result = 1;
  }
  return result;
};

export const locateToLocationOnGGMap = (coordinate: ICoordinate) => {
  const ggMapLink = "http://maps.google.com/maps?q=" + coordinate.lat + "," + coordinate.lon;
  window.open(ggMapLink, "_blank");
};

export const getAvatarLinkFromObjectName = (object_name?: string, avatars?: any[]) => {
  if (!object_name || !avatars || !avatars.length) return "";

  const avatar = avatars.find((a) => {
    return a.object_name === object_name;
  });

  return avatar ? avatar.link ?? "" : "";
};
