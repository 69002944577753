import CommonIcons from "@base/assets/icons";
import React from "react";
import TypographyUI from "../Typography";
import { Modal, Skeleton, Space } from "antd";
import ButtonUI from "../Button";
import { useTranslation } from "react-i18next";

interface IProps {
  type?: "success" | "error" | "info" | "warning";
  title: string | React.ReactNode;
  content?: string | React.ReactNode;
  hiddenAction?: boolean;
  toggle?: () => void;
  onConfirm?: () => void;
  open: boolean;
}
export default function NotificationUI(props: IProps) {
  //! state
  const { type, content, title, hiddenAction, toggle, onConfirm, open } = props;
  const { t } = useTranslation();
  //! function
  let IconRender = null;
  switch (type) {
    case "success":
      IconRender = CommonIcons.IconSuccess;
      break;
    case "error":
      IconRender = CommonIcons.IconError;
      break;
    case "info":
      IconRender = CommonIcons.IconInfo;
      break;
    case "warning":
      IconRender = CommonIcons.IconWarning;
      break;

    default:
      IconRender = CommonIcons.IconSuccess;
      break;
  }
  //! render

  return (
    <Modal
      open={open}
      centered
      onCancel={toggle}
      footer={
        !hiddenAction && (
          <Space>
            <ButtonUI className="h-12 w-24 text-base font-medium" onClick={toggle} type="primary" ghost danger>
              {t("Cancel")}
            </ButtonUI>
            <ButtonUI className="h-12 w-24 text-base font-medium" onClick={onConfirm}>
              {t("Submit")}
            </ButtonUI>
          </Space>
        )
      }
    >
      <div className="mb-4">
        <IconRender />
      </div>

      <TypographyUI use="title" level={3}>
        {title}
      </TypographyUI>

      <TypographyUI>{content}</TypographyUI>
    </Modal>
  );
}
