import { TOption } from "@base/types";
import { Radio, RadioChangeEvent, RadioProps, Space } from "antd";
import { useTranslation } from "react-i18next";

interface IProps extends RadioProps {
  label?: string;
  required?: boolean;
  direction?: "vertical" | "horizontal";
  options: TOption[];
  handleChange?: (value: any) => void;
  afterOnchange?: () => void;
  className?: string;
}
export default function RadioUI(props: IProps) {
  //! state
  const { t } = useTranslation();
  const { label, required, direction = "vertical", options, handleChange, afterOnchange, className, ...res } = props;

  const onChange = (value: RadioChangeEvent) => {
    handleChange && handleChange(value);
    if (!!afterOnchange) {
      afterOnchange();
    }
  };
  //! function
  //! render
  const renderOption = () => {
    return options.map((item, index) => {
      return (
        <Radio key={index} value={item.value}>
          <span className="text-[#121212]">{t(`${item.label}`)}</span>
        </Radio>
      );
    });
  };
  return (
    <div>
      {label && (
        <div className="mb-1 flex">
          <div className="text-common-maintext">{label}</div>
          {required && <span className="text-common-red500">{` *`}</span>}
        </div>
      )}
      <Radio.Group className={className} onChange={onChange} value={res.value} {...res}>
        <Space direction={direction}>{renderOption()}</Space>
      </Radio.Group>
    </div>
  );
}
