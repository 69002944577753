import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IFilterSymptom } from "./interfaces/IFilterSymptom.interface";

class SymptomService {
  getListSymptom(query: IFilterSymptom) {
    return httpService.get(apiService.doctor.symptom.getList, {
      params: query,
    });
  }
}

export default new SymptomService();
