import { Form, Typography } from "antd";

const { Paragraph } = Typography;

export default function FormAntdDebugs() {
  return (
    <div className="h-[400px] bg-gray-200 overflow-y-scroll p-4 mt-11 rounded-md">
      <p className="text-blue-600 text-base"> Form Antd Debugs</p>
      <Form.Item shouldUpdate>
        {(form) => {
          return (
            <Paragraph>
              <pre>Values: {JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
              <pre>FieldErrors: {JSON.stringify(form.getFieldsError(), null, 2)}</pre>
            </Paragraph>
          );
        }}
      </Form.Item>
    </div>
  );
}
