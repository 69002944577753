import React from "react";

export default function IconHistory() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4041 3.00172C8.31407 2.86172 4.14407 6.95172 4.14407 12.0017H2.35407C1.90407 12.0017 1.68407 12.5417 2.00407 12.8517L4.79407 15.6517C4.99407 15.8517 5.30407 15.8517 5.50407 15.6517L8.29407 12.8517C8.60407 12.5417 8.38407 12.0017 7.93407 12.0017H6.14407C6.14407 8.10172 9.32407 4.95172 13.2441 5.00172C16.9641 5.05172 20.0941 8.18172 20.1441 11.9017C20.1941 15.8117 17.0441 19.0017 13.1441 19.0017C11.5341 19.0017 10.0441 18.4517 8.86407 17.5217C8.46407 17.2117 7.90407 17.2417 7.54407 17.6017C7.12407 18.0217 7.15407 18.7317 7.62407 19.0917C9.14407 20.2917 11.0541 21.0017 13.1441 21.0017C18.1941 21.0017 22.2841 16.8317 22.1441 11.7417C22.0141 7.05172 18.0941 3.13172 13.4041 3.00172ZM12.8941 8.00172C12.4841 8.00172 12.1441 8.34172 12.1441 8.75172V12.4317C12.1441 12.7817 12.3341 13.1117 12.6341 13.2917L15.7541 15.1417C16.1141 15.3517 16.5741 15.2317 16.7841 14.8817C16.9941 14.5217 16.8741 14.0617 16.5241 13.8517L13.6441 12.1417V8.74172C13.6441 8.34172 13.3041 8.00172 12.8941 8.00172Z"
        fill="currentColor"
      />
    </svg>
  );
}
