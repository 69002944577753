import { useState } from "react";
import CommonStyles from "@components/CommonStyles";
import { Divider, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IPassword } from "@base/modules/user/account/account.interface";
import useFormValidation from "@base/hooks/useFormvalidation";
import accountService from "@base/modules/user/account/accountService";
import { RootState } from "@base/store/store";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

export default function ChangePassword() {
  //! state
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { login } = useFormValidation();
  const [form] = Form.useForm();
  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues: IPassword = {
    old_password: "",
    password: "",
    user_name: userInfo?.user_name ?? "",
  };

  //! function
  const handleSubmit = async (values: { old_password: string; password: string }) => {
    try {
      setIsLoading(true);
      await accountService.updatePassword({
        old_password: values.old_password,
        password: values.password,
        user_name: userInfo?.user_name ?? "",
      });

      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));

      requestAnimationFrame(() => {
        navigate(baseRoutes.userAccount);
      });
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
    } finally {
      setIsLoading(false);
    }
  };

  //! render
  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.changePassword.changePassword")}</div>
      </div>

      <Divider />

      <div className="flex flex-col gap-6">
        <Form form={form} initialValues={initialValues} onFinish={handleSubmit} className="w-full sm:w-[80%] lg:w-[70%] xl:w-[50%] mx-auto">
          <CommonStyles.FormFieldUI
            name="old_password"
            rules={login.password()}
            component={
              <CommonStyles.InputUI
                use="password"
                placeholder={t("user.changePassword.PlaceholderCurrentPassword")}
                variant="filled"
                label={t("user.changePassword.currentPassword")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="password"
            rules={login.password()}
            component={
              <CommonStyles.InputUI
                use="password"
                placeholder={t("user.changePassword.PlaceholderNewPassword")}
                variant="filled"
                label={t("user.changePassword.newPassword")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="confirmPassword"
            initialValue=""
            rules={login.confirmPassword()}
            component={
              <CommonStyles.InputUI
                use="password"
                placeholder={t("user.changePassword.PlaceholderConfirmNewPassword")}
                variant="filled"
                label={t("user.changePassword.confirmNewPassword")}
              />
            }
          />

          <div className="col-span-2 flex justify-center items-center">
            <CommonStyles.ButtonUI className="h-12" htmlType="submit" loading={isLoading}>
              {t("user.accountSetting.btnSave")}
            </CommonStyles.ButtonUI>
          </div>
        </Form>
      </div>
    </div>
  );
}
