import React from "react";
import dayjs from "dayjs";

interface IProps {
  value: string;
  format: string;
}

export default function DayCalendarUI(props: IProps) {
  const { value, format } = props;

  return (
    <div className="rounded-lg border border-[#E1E4E5]">
      <div className="bg-common-blue300 flex justify-center items-center text-sm text-common-white font-semibold font-['Inter'] py-[5px] px-6 rounded-t-lg">
        {dayjs(value, format).format("MM/YYYY")}
      </div>

      <div className="flex justify-center items-center text-[32px] leading-[45px] font-semibold font-['Inter'] py-6 px-9">
        {dayjs(value, format).format("DD")}
      </div>
    </div>
  );
}
