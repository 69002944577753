import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";

class BannerService {
  listBanner() {
    return httpService.get(apiService.utils.banner.list, {
      headers: {
        platform: "web",
      },
    });
  }

  addView(id?: string | number, lang?: string) {
    return httpService.put(`${apiService.utils.banner.addView}/${id}`, { params: { lang } });
  }
}

export default new BannerService();
