export default function IconApplication() {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="39" height="39" rx="11.5" fill="white" stroke="white" />
        <path
          d="M12 14.25C12 13.5596 12.5596 13 13.25 13H17.75C18.4404 13 19 13.5596 19 14.25V18.75C19 19.4404 18.4404 20 17.75 20H13.25C12.5596 20 12 19.4404 12 18.75V14.25Z"
          fill="#F68E1E"
        />
        <path
          d="M12 23.25C12 22.5596 12.5596 22 13.25 22H17.75C18.4404 22 19 22.5596 19 23.25V27.75C19 28.4404 18.4404 29 17.75 29H13.25C12.5596 29 12 28.4404 12 27.75V23.25Z"
          fill="#F68E1E"
        />
        <path
          d="M20.9999 23.25C20.9999 22.5596 21.5596 22 22.2499 22H26.7499C27.4403 22 27.9999 22.5596 27.9999 23.25V27.75C27.9999 28.4404 27.4403 29 26.7499 29H22.2499C21.5596 29 20.9999 28.4404 20.9999 27.75V23.25Z"
          fill="#FFD09C"
        />
        <path
          d="M21 14.25C21 13.5596 21.5596 13 22.25 13H26.75C27.4404 13 28 13.5596 28 14.25V18.75C28 19.4404 27.4404 20 26.75 20H22.25C21.5596 20 21 19.4404 21 18.75V14.25Z"
          fill="#F68E1E"
        />
      </svg>
    </div>
  );
}
