import CommonStyles from "..";
import { ESpecialPackageStatus } from "@base/enums";

interface IProps {
  status: ESpecialPackageStatus;
}

export default function TagSpecialPackageDetailImplementStatus({ status }: IProps) {
  const getTagName = () => {
    switch (status) {
      case ESpecialPackageStatus.PENDING:
        return "Chưa thực hiện";
      case ESpecialPackageStatus.COMPLETED:
        return "Hoàn thành";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case ESpecialPackageStatus.PENDING:
        return "bg-common-blue100 text-common-blue500 border-none";
      case ESpecialPackageStatus.COMPLETED:
        return "bg-common-green100 text-common-positive border-none";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
