import React from "react";

export default function K29() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.144531" y="0.833954" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.14453" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27215)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.881 29.1465C64.0762 30.5512 66.9928 32.9154 67.9391 35.9848C68.0131 36.2251 68.0888 36.468 68.1653 36.7134C69.7238 41.712 71.6128 47.771 65.9658 54.547C65.9658 54.547 69.1234 67.9738 61.6209 74.292C61.6209 74.292 56.1418 76.7604 56.8808 71.9234C57.3642 68.7834 57.329 57.1448 50.1454 56.5761C42.9633 57.1433 42.928 68.7805 43.4085 71.9234C44.149 76.7604 38.6684 74.292 38.6684 74.292C31.1674 67.9753 34.325 54.547 34.325 54.547C28.6788 47.769 30.5675 41.7106 32.1259 36.7119C32.2023 36.467 32.2779 36.2246 32.3517 35.9848C33.2965 32.9154 36.2146 30.5512 38.4127 29.1465C39.91 28.19 41.8245 28.168 43.3453 29.0892L47.264 31.4637C49.036 32.5378 51.2562 32.5378 53.0297 31.4637L56.9484 29.0892C58.4662 28.168 60.3808 28.19 61.881 29.1465ZM56.1461 34.7383C55.1491 34.7383 54.3408 35.5465 54.3408 36.5436C54.3408 37.5406 55.1491 38.3489 56.1461 38.3489C57.0761 38.3489 58.5324 38.7093 59.6801 39.7859C60.762 40.8009 61.7788 42.6491 61.5656 46.0594C61.5035 47.0545 62.2597 47.9116 63.2548 47.9738C64.25 48.036 65.1071 47.2797 65.1693 46.2846C65.4375 41.9922 64.1475 39.0261 62.1504 37.1526C60.219 35.3407 57.8239 34.7383 56.1461 34.7383Z"
            fill="url(#paint0_linear_1205_27215)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27215"
            x="22.5225"
            y="24.4134"
            width="55.2461"
            height="62.5084"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27215" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27215" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27215" x1="59.2823" y1="28.3449" x2="24.3568" y2="50.3821" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
