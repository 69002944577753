import { categories } from "@base/constants";
import { LocalKey } from "@base/constants/LocalKey";
import useGetPromotionList from "@base/modules/promotion/hooks/useGetPromotionList";
import IFilterPromotion from "@base/modules/promotion/interface/IFilterPromotion.interface";
import { IDataLocalAppointment } from "@base/types";
import CommonStyles from "@components/CommonStyles";
import CardDiscount from "@components/CommonStyles/CardDiscount";

interface IProps {
  setDiscountCode: any;
  discountCode: string;
  toggle: () => void;
  type: string;
}
export default function ModalListDiscount(props: IProps) {
  //! state
  const { setDiscountCode, discountCode, toggle, type } = props;
  const dataStep1 = localStorage.getItem(LocalKey.BOOKING_APPOINTMENT);
  const parseDataPaymentLocal: IDataLocalAppointment = JSON.parse(String(dataStep1));

  const filterPromotion: IFilterPromotion = {
    lang: "vi",
    category: type,
    site_code: parseDataPaymentLocal?.payload?.site_code,
  };
  const { data: dataPromotion, isLoading } = useGetPromotionList(filterPromotion, String(parseDataPaymentLocal?.appointment_type));

  //! function
  //! render
  const renderDiscount = () => {
    return dataPromotion?.data.map((item, index) => {
      const isChecked = item.type === discountCode;
      return <CardDiscount key={index} data={item} isChecked={isChecked} setDiscountCode={setDiscountCode} />;
    });
  };
  if (isLoading) {
    return <CommonStyles.LoadingUI />;
  }
  return (
    <div className="max-h-[500px] overflow-y-scroll">
      <div className="grid grid-cols-2 gap-3">{renderDiscount()}</div>

      <div className="flex justify-center items-center mt-7">
        <CommonStyles.ButtonUI className="h-12" onClick={toggle}>
          Đồng ý
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
