import { responseUI } from "@base/themes/response";
import { Button, ButtonProps } from "antd";
import React from "react";

interface IProps extends ButtonProps {
  children?: string | React.ReactNode;
}
export default function ButtonUI(props: IProps) {
  //! state
  const { children, ...res } = props;
  //! function
  //! render
  return (
    <Button className={`rounded-lg text-base ${res.className} ${responseUI.text.default}`} type={res.type ?? "primary"} {...res}>
      {children}
    </Button>
  );
}
