import { useCallback, useEffect, useState } from "react";
import IFilterPromotion from "../interface/IFilterPromotion.interface";
import { ResponsePromotions } from "../interface/promotion.interface";
import promotionService from "../promotionService";

const useGetPromotionList = (filters: IFilterPromotion, type: string, enabled = true) => {
  const [data, setData] = useState<ResponsePromotions>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    if (type === "OFFLINE") {
      return promotionService.getPromotionListPublic(filters);
    } else return promotionService.getPromotionListPublicOnline(filters);
  }, [filters]);

  const transformResponse = useCallback((response: ResponsePromotions) => {
    if (response) {
      setData(response);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetPromotionList;
