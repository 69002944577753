import React, { useState } from "react";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { WPPostsFilter } from "@base/modules/wpApis/interface";
import { Post, PostsCategory } from "@base/types/wp";
import CommonStyles from "@components/CommonStyles";
import { NavLink } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonImages from "@base/assets/images";
import TypographyUI from "@components/CommonStyles/Typography";
import CommonIcons from "@base/assets/icons";
import dayjs from "dayjs";

interface IProps {
  category: PostsCategory;
  onSelectCategory: () => void;
}

const IndividualMedicalInfo = ({ category, onSelectCategory }: IProps) => {
  const [filter, setFilter] = useState<WPPostsFilter>({
    _embed: true,
    page: 1,
    per_page: 6,
    categories: [category.id],
    orderby: "modified",
    order: "desc",
  });
  const { data, error, isLoading, refetch } = useGetPosts(filter, true);

  const MainPart = () => {
    const posts: Post[] = data?.data ?? [];

    const MainCart = ({ p }: { p: Post }) => {
      const imgLink =
        p._embedded &&
        p._embedded["wp:featuredmedia"] &&
        p._embedded["wp:featuredmedia"].length &&
        p._embedded["wp:featuredmedia"][0] &&
        p._embedded["wp:featuredmedia"][0].source_url
          ? p._embedded["wp:featuredmedia"][0].source_url
          : "";

      return (
        <div className="w-full flex flex-col gap-3">
          <div className="rounded-lg w-full flex justify-center items-center">
            <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
              <img
                className="rounded-lg w-full cursor-pointer drop-shadow-lg"
                src={imgLink ?? ""}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.DrafInforArticle;
                }}
              />
            </NavLink>
          </div>

          <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
            <TypographyUI className="text-base font-medium text-[#000000] line-clamp-2 cursor-pointer hover:text-[#005984] !mb-0">
              <div dangerouslySetInnerHTML={{ __html: p.title?.rendered ?? "" }}></div>
            </TypographyUI>
          </NavLink>

          <TypographyUI use="paragraph" className="text-[#000000] text-xs font-light line-clamp-3 !mb-0">
            <div dangerouslySetInnerHTML={{ __html: p.excerpt?.rendered ?? "" }}></div>
          </TypographyUI>

          <TypographyUI className="text-[#A1A1A1] text-xs font-light !mb-0 flex items-center gap-1">
            <CommonIcons.IconCalendarFill /> {dayjs(p.date).format("DD/MM/YYYY")}
          </TypographyUI>
        </div>
      );
    };

    return posts.length ? (
      <div>
        <MainCart p={posts[0]} />
      </div>
    ) : null;
  };

  const SubPart = () => {
    const posts: Post[] = data?.data ?? [];
    const noOfDisplay = posts.length - 1 >= 3 ? 3 : posts.length - 1;

    const arr = noOfDisplay > 0 ? posts.slice(1, noOfDisplay + 1) : [];

    const SubCart = ({ p }: { p: Post }) => {
      const imgLink =
        p._embedded &&
        p._embedded["wp:featuredmedia"] &&
        p._embedded["wp:featuredmedia"].length &&
        p._embedded["wp:featuredmedia"][0] &&
        p._embedded["wp:featuredmedia"][0].source_url
          ? p._embedded["wp:featuredmedia"][0].source_url
          : "";

      return (
        <div className="w-full grid grid-cols-2 gap-[18px]">
          <div className="rounded-lg w-full flex justify-center items-center">
            <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
              <img
                className="rounded-lg w-full cursor-pointer drop-shadow-lg"
                src={imgLink ?? ""}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.DrafInforArticle;
                }}
              />
            </NavLink>
          </div>

          <div className="flex flex-col gap-1">
            <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
              <TypographyUI className="text-base font-medium text-[#000000] line-clamp-3 cursor-pointer hover:text-[#005984] !mb-0">
                <div dangerouslySetInnerHTML={{ __html: p.title?.rendered ?? "" }}></div>
              </TypographyUI>
            </NavLink>

            <TypographyUI className="text-[#A1A1A1] text-xs font-light !mb-0 flex items-center gap-1">
              <CommonIcons.IconCalendarFill /> {dayjs(p.date).format("DD/MM/YYYY")}
            </TypographyUI>
          </div>
        </div>
      );
    };

    return arr.length ? (
      <div className="flex flex-col gap-6">
        {arr.map((e, idx: number) => {
          return <SubCart key={idx} p={e} />;
        })}
      </div>
    ) : null;
  };

  return (
    <div className="py-4">
      <CommonStyles.TypographyUI
        use="title"
        level={2}
        className="!text-2xl !font-light !text-[#005984] !mb-4 cursor-pointer"
        onClick={() => onSelectCategory()}
      >
        {category.name}
      </CommonStyles.TypographyUI>

      <div className="w-full grid grid-cols-2 max-lg:!grid-cols-1 gap-9">
        <MainPart />

        <SubPart />
      </div>
    </div>
  );
};

export default IndividualMedicalInfo;
