import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { WPPostsFilter } from "./interface";
import { Post } from "@base/types/wp";
import axios from "axios";

class WPPostsService {
  getPosts(filter: WPPostsFilter) {
    const _filter = Object.assign({}, filter) as WPPostsFilter;
    delete _filter._embed;
    let uri = axios
      .create({
        baseURL: apiService.wp.posts.getPosts,
        params: _filter,
      })
      .getUri();

    uri += filter._embed ? "&_embed" : "";

    return httpService.get<Post[]>(uri);
  }
}

export default new WPPostsService();
