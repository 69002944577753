import CommonImages from "@base/assets/images";

export const DataPartner = [
  {
    title: "Doctor",
    number: "200+",
    icon: CommonImages.Doctor,
  },
  {
    title: "Facilities",
    number: "50+",
    icon: CommonImages.Facilities,
  },
  {
    title: "Employee",
    number: "50+",
    icon: CommonImages.Employee,
  },
];

export const articles = [
  {
    title: "Trẻ bị ngộ độc thức ăn nên làm gì? 4 nguyên tắc cần nắm rõ",
    date: "14/05/2024",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  do eiusmod tempor",
  },
  {
    title: "Trẻ bị ngộ độc thức ăn nên làm gì? 4 nguyên tắc cần nắm rõ",
    date: "14/05/2024",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  do eiusmod tempor",
  },
  {
    title: "Trẻ bị ngộ độc thức ăn nên làm gì? 4 nguyên tắc cần nắm rõ",
    date: "14/05/2024",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  do eiusmod tempor",
  },
];

export const dataFeedBack = [
  {
    id: 1,
    author: "Anh Lê Quốc Huy",
    age: "45",
    address: "Cần Thơ",
    feedback: `Tôi rất thích tính năng thanh toán trực tuyến của 365 Medihome. Việc thanh toán trở nên dễ dàng và nhanh chóng. Ngoài ra, ứng dụng còn bảo mật thông tin rất tốt, tôi hoàn toàn yên tâm khi sử dụng.`,
    avatar: CommonImages.feedback1,
  },
  {
    id: 2,
    author: "Anh Nguyễn Hoàng Anh",
    age: "37",
    address: "TP.HCM",
    feedback: `Từ khi sử dụng 365 Medihome, tôi có thể chăm sóc sức khỏe cho cả gia đình một cách dễ dàng. Ứng dụng cung cấp nhiều thông tin hữu ích về sức khỏe và tư vấn từ các chuyên gia y tế hàng đầu. Tôi thật sự hài lòng và sẽ tiếp tục sử dụng.`,
    avatar: CommonImages.feedback2,
  },
  {
    id: 3,
    author: "Chị Nguyễn Thị Mai Anh",
    age: "30",
    address: "Hà Nội",
    feedback:
      "Ứng dụng 365 Medihome thực sự là một cứu cánh cho những người bận rộn như tôi. Tôi có thể dễ dàng quản lý sức khỏe của mình và gia đình chỉ với vài thao tác đơn giản trên điện thoại.",
    avatar: CommonImages.feedback3,
  },
  {
    id: 4,
    author: "Chị Phạm Thùy Linh",
    age: "28",
    address: "Đà Nẵng",
    feedback:
      "Ứng dụng 365 Medihome giúp tôi kết nối với các bác sĩ chuyên khoa giỏi một cách nhanh chóng. Tôi không còn phải lo lắng về việc xếp hàng chờ đợi hay di chuyển xa để khám bệnh. Tư vấn online qua video call rất tiện lợi và hiệu quả.",
    avatar: CommonImages.feedback4,
  },
  {
    id: 5,
    author: "Anh Trần Văn Dũng",
    age: "38",
    address: "Đà Nẵng",
    feedback:
      "365 Medihome thật sự là một ứng dụng hữu ích. Tôi có thể dễ dàng quản lý lịch sử khám của mình và nhận thông báo nhắc nhở lịch hẹn. Điều này giúp tôi không bỏ lỡ bất kỳ cuộc hẹn quan trọng nào. Các bác sĩ tư vấn rất nhiệt tình và chuyên nghiệp.",
    avatar: CommonImages.feedback5,
  },
  {
    id: 6,
    author: "Chị Nguyễn Thị Thu",
    age: "48",
    address: "Đà Nẵng",
    feedback:
      "Tôi đã sử dụng 365 Medihome để đặt lịch khám và rất hài lòng với dịch vụ. Ứng dụng rất dễ sử dụng, giúp tôi tiết kiệm thời gian và công sức. Tôi còn có thể nhận được tư vấn online từ các bác sĩ chuyên khoa mà không cần phải đến bệnh viện.",
    avatar: CommonImages.feedback6,
  },
];
