import React from "react";

export default function ApptPackage() {
  return (
    <div>
      <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.324219" width="84" height="84" rx="18" fill="#FFF2E3" />
        <g filter="url(#filter0_d_674_550)">
          <g filter="url(#filter1_b_674_550)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.3208 44.1544C19.3208 43.45 19.8919 42.8789 20.5963 42.8789H27.2839C27.9884 42.8789 28.5594 43.45 28.5594 44.1544V44.9717H28.5403V59.8802H28.5548C28.4989 60.5331 27.9513 61.0456 27.2839 61.0456H20.5963C19.8919 61.0456 19.3208 60.4746 19.3208 59.7702V44.1544ZM31.4149 58.9989C31.4544 59.0134 31.4932 59.0296 31.5314 59.0474L41.3734 63.6557C42.3095 64.0941 43.3878 64.1142 44.3396 63.7112L63.5924 55.5597C65.1126 54.9161 65.7665 53.1172 65.0146 51.6476C64.3502 50.3489 62.8105 49.764 61.4514 50.2941L52.1993 53.9024V53.005C52.1993 50.9923 50.5677 49.3607 48.555 49.3607H43.2132C42.971 49.3607 42.7326 49.3004 42.5196 49.1851L36.2274 45.7811C35.9078 45.6082 35.5503 45.5177 35.187 45.5177H31.4149V58.9989Z"
              fill="url(#paint0_linear_674_550)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.071 42.8788C46.7159 42.8788 46.3768 42.7386 46.1258 42.4863L35.7572 32.1004C33.0393 29.3771 33.0393 24.9464 35.7572 22.223C37.0695 20.9095 38.8223 20.1846 40.6899 20.1846C42.5575 20.1846 44.3103 20.9095 45.6225 22.223L47.071 23.6741L48.5181 22.2244C49.8316 20.9095 51.5844 20.1846 53.452 20.1846C55.3196 20.1846 57.0724 20.9095 58.3847 22.223C61.1027 24.9464 61.1027 29.3771 58.386 32.1004L48.0161 42.4876C47.7651 42.7386 47.4261 42.8788 47.071 42.8788Z"
              fill="#FFBB7C"
            />
          </g>
        </g>
        <defs>
          <filter id="filter0_d_674_550" x="12.3242" y="16" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.745098 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_674_550" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_674_550" result="shape" />
          </filter>
          <filter
            id="filter1_b_674_550"
            x="-4.6792"
            y="-3.81543"
            width="94.0066"
            height="91.8151"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_674_550" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_674_550" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_674_550" x1="29.933" y1="65.326" x2="40.8646" y2="36.2552" gradientUnits="userSpaceOnUse">
            <stop offset="0.292971" stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
