export default function IconOrderHome() {
  return (
    <div>
      <svg width="149" height="179" viewBox="0 0 149 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8" filter="url(#filter0_f_1532_17148)">
          <rect x="30.5615" y="61" width="88" height="88" rx="24" fill="#FED4A5" />
        </g>
        <rect x="5.5" y="0.5" width="139" height="139" rx="31.5" fill="url(#paint0_linear_1532_17148)" stroke="url(#paint1_linear_1532_17148)" />
        <g clipPath="url(#clip0_1532_17148)">
          <path
            d="M101.066 65.3716C81.1448 65.3716 61.2233 65.3697 41.2999 65.3697C41.2566 63.7521 41.1043 62.123 41.3732 60.5208C41.6271 59.0072 42.5675 58.0502 44.0214 57.64C44.3806 57.2838 44.8226 57.1701 45.306 57.1701C46.8332 57.1682 48.3604 57.1239 49.8877 57.1933C51.0274 57.1413 52.1691 57.1413 53.3088 57.1933C55.6147 57.122 57.9225 57.1547 60.2283 57.1701C61.6822 57.1952 63.1361 57.1008 64.5881 57.1952C67.3435 57.1297 70.0988 57.1278 72.8542 57.1913C77.908 57.1278 82.9636 57.122 88.0173 57.1913C89.0913 57.1528 90.1652 57.1355 91.2392 57.1952C91.9971 57.1817 92.7551 57.1336 93.5131 57.1971C94.2014 57.1798 94.8898 57.147 95.5801 57.1856C96.259 57.201 96.938 57.1509 97.617 57.1836C97.9499 57.2298 98.2978 57.1798 98.6194 57.3204C101.189 58.9071 102.33 62.6641 101.066 65.3716Z"
            fill="#F98315"
          />
          <path
            d="M41.2885 94.2514C41.5687 94.0742 41.8809 94.0762 42.1969 94.0762C61.8213 94.0819 81.4475 94.0858 101.072 94.0897C101.647 95.5147 101.433 96.9724 101.202 98.4147C101.132 98.848 100.711 99.0579 100.286 99.1446C99.8832 99.2254 99.4751 99.2139 99.067 99.2139C81.299 99.2158 63.5309 99.2158 45.7629 99.2139C45.4808 99.2139 45.1987 99.2197 44.9166 99.1927C43.1448 99.0232 41.8095 98.2549 41.2828 96.3908C41.2847 95.6783 41.2866 94.9658 41.2885 94.2514Z"
            fill="#F98315"
          />
          <path
            d="M41.2848 96.3921C41.5989 96.5366 41.6986 96.8697 41.8603 97.1316C42.6202 98.3544 43.7374 98.8801 45.101 98.882C63.3524 98.8897 81.6037 98.884 99.8551 98.8974C100.745 98.8974 101.104 98.5585 101.074 97.6477C101.034 96.4634 101.07 95.2752 101.072 94.0909L101.066 94.0775C101.333 93.8156 101.67 93.7617 102.016 93.7559C103.57 93.7347 105.125 93.7328 106.679 93.7559C107.023 93.7617 107.365 93.804 107.625 94.0794C107.636 95.618 107.692 97.1586 107.649 98.6972C107.602 100.373 106.054 101.88 104.39 101.967C104.172 101.979 103.952 101.969 103.731 101.969C84.2707 101.969 64.81 101.944 45.3492 102.002C42.9568 102.009 41.0572 100.22 41.2491 97.7382C41.2848 97.2914 41.2735 96.8408 41.2848 96.3921Z"
            fill="#E57004"
          />
          <path
            d="M97.6659 57.4686C96.9813 57.4667 96.2966 57.4629 95.6101 57.4609C95.4559 56.9853 95.7945 56.683 96.0014 56.344C96.844 54.9653 97.7656 53.6365 98.6984 52.3251C100.365 49.9854 99.5166 48.5239 97.1712 47.5379C96.6766 46.8447 96.6408 46.0417 96.7518 45.2502C96.8646 44.4433 96.5825 43.908 95.9525 43.4555C92.4636 40.9502 88.89 38.5662 85.3992 36.0647C84.7955 35.6334 84.2256 35.5621 83.5598 35.9107C82.9278 36.2419 82.294 36.652 81.5172 36.4402C80.0333 35.4601 78.6189 34.3778 77.1462 33.3822C76.9017 33.2186 76.5651 33.0607 76.7964 32.6428C77.5769 31.4469 78.3255 30.2241 79.1493 29.0591C80.0276 27.8209 81.363 27.6688 82.7059 28.6008C87.0788 31.6414 91.4441 34.6936 95.8133 37.7401C98.4238 39.5617 101.036 41.3815 103.643 43.209C105.266 44.3471 105.486 45.5988 104.375 47.2934C103.068 49.2864 101.76 51.2795 100.451 53.2726C100.504 53.5114 100.414 53.7213 100.282 53.8984C99.4019 55.0827 98.7887 56.4711 97.6659 57.4686Z"
            fill="#71A1FF"
          />
          <path
            d="M89.5972 79.8277C89.396 76.4096 92.4692 72.5274 96.3587 72.6892C96.3869 72.6757 96.417 72.6507 96.4452 72.6526C99.7536 72.7912 103.022 72.1153 106.327 72.177C106.793 72.1866 107.271 72.1538 107.662 72.4966C108.471 72.6584 109.312 72.3676 110.109 72.6853C109.765 73.0647 109.304 72.9896 108.876 72.9954C107.653 73.0108 106.432 73.0127 105.21 72.9954C104.772 72.9896 104.606 72.9992 104.644 73.6116C104.852 77.0046 104.755 80.4073 104.706 83.8042C104.693 84.7151 104.108 85.3775 103.137 85.4179C100.265 85.5373 97.3461 85.907 94.5362 85.1098C92.1119 84.4224 90.3815 82.799 89.7458 80.1762C89.7176 80.0511 89.6612 79.9374 89.5972 79.8277Z"
            fill="#F98315"
          />
          <path
            d="M89.5972 79.8277C89.9113 79.9491 89.9263 80.261 90.0354 80.521C91.3388 83.604 93.6767 85.1253 96.9173 85.2042C98.7624 85.2505 100.611 85.2177 102.456 85.21C104.002 85.2042 104.45 84.7382 104.45 83.163C104.45 80.2475 104.448 77.3301 104.452 74.4147C104.452 73.8427 104.538 73.2535 104.059 72.6835C106.075 72.6835 108.093 72.6835 110.109 72.6835C110.941 72.7721 111.204 73.369 111.208 74.0931C111.225 77.8674 111.227 81.6398 111.208 85.4141C111.202 86.3539 110.664 86.87 109.731 86.9219C109.047 86.9585 108.358 86.9489 107.672 86.9605C106.735 87.4188 101.973 87.4168 101.057 86.9566C99.131 86.8468 97.1994 87.0933 95.2754 86.7602C92.1156 86.2152 89.5144 83.1881 89.5972 79.8277Z"
            fill="#E57004"
          />
          <path
            d="M51.5842 39.9375C51.6143 38.5298 51.6952 37.1202 51.9434 35.7395C52.2067 34.2741 53.2637 33.4923 54.7157 33.6348C57.883 33.9448 61.0447 34.2972 64.2101 34.6226C67.2212 34.9327 70.2324 35.2273 73.2455 35.5354C73.742 35.5855 74.2348 35.6586 74.7294 35.7222C74.8592 36.0861 74.6147 36.3326 74.4567 36.5945C73.8304 37.6325 73.0988 38.5953 72.4405 39.6101C72.2806 39.8547 72.1076 40.0839 71.7747 40.0704C68.8989 39.718 66.0062 39.5581 63.1342 39.173C62.2691 39.0575 61.3945 39.096 60.5293 38.9496C59.824 38.8302 59.382 39.2847 58.9701 39.8066C58.5319 40.3612 58.2385 41.1045 57.4072 41.2065C55.7257 40.9234 54.0349 40.7193 52.329 40.6827C51.8343 40.6712 51.3171 40.727 51.5842 39.9375Z"
            fill="#6195FC"
          />
          <path
            d="M71.9213 39.9643C72.858 38.5508 73.7928 37.1354 74.7294 35.722C75.4178 34.6956 76.108 33.6692 76.7964 32.6428C77.4829 33.5575 78.5757 33.9234 79.3976 34.6571C80.0653 35.254 80.9718 35.5313 81.506 36.3093C81.5154 36.679 81.222 36.679 80.9925 36.7272C80.7179 36.7869 80.4283 36.8042 80.1555 36.7599C78.7769 36.5385 77.9982 37.301 77.4434 38.4507C74.1539 43.209 71.0261 48.0791 67.7986 52.8817C66.9297 54.1738 66.0288 55.4448 65.2069 56.7697C65.0357 57.045 64.8194 57.2703 64.5617 57.4571C63.1286 57.4571 61.6935 57.4571 60.2603 57.4571C60.1512 56.941 60.5218 56.6194 60.7587 56.2671C63.0496 52.8586 65.3103 49.427 67.603 46.0205C68.7353 44.2624 69.9183 42.5389 71.0787 40.7981C71.2988 40.4669 71.4587 40.049 71.9213 39.9643Z"
            fill="#71A1FF"
          />
          <path
            d="M51.584 39.9375C51.5991 40.2918 51.6348 40.4651 52.122 40.4151C53.7865 40.2437 55.4209 40.727 57.0817 40.7867C57.1964 40.7906 57.3055 40.9812 57.4164 41.0871C57.3788 41.5031 57.0704 41.7168 56.7563 41.8266C55.3664 42.3099 54.6667 43.2535 54.7626 44.7787C54.5727 48.6108 54.0329 52.4082 53.747 56.2307C53.7131 56.6794 53.6398 57.1242 53.2993 57.4593C52.1727 57.4631 51.0443 57.467 49.9176 57.4708C49.4305 56.8777 49.6506 56.2056 49.7277 55.5567C49.944 53.7427 49.9496 53.7697 48.1911 53.7292C47.5083 53.7138 46.7598 53.9796 46.1598 53.3922C47.2676 53.1303 48.3904 53.3094 49.5058 53.2844C50.0324 53.2728 50.26 53.1631 50.3126 52.5546C50.5891 49.3175 50.8938 46.0823 51.2436 42.853C51.3471 41.8824 51.2906 40.8869 51.584 39.9375Z"
            fill="#71A1FF"
          />
          <path
            d="M101.066 65.372C101.065 63.974 101.11 62.5721 101.048 61.176C100.991 59.9185 100.5 58.8575 99.4057 58.1469C99.1292 57.9678 98.7737 57.8522 98.6251 57.4979C98.8753 57.1917 99.227 57.1802 99.5693 57.1763C101.098 57.1571 102.627 57.1359 104.157 57.1879C104.757 57.2091 105.343 57.315 105.736 57.8677C107.128 58.7631 107.805 60.0052 107.679 61.7306C107.591 62.9399 107.64 64.1608 107.629 65.3759C107.367 65.6493 107.025 65.6917 106.681 65.6975C105.125 65.7206 103.572 65.7186 102.016 65.6975C101.668 65.6936 101.333 65.6378 101.063 65.3836L101.066 65.372Z"
            fill="#E57004"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.2953 79.8103L41.2953 79.8103C41.2976 74.9966 41.3 70.1828 41.3019 65.3696C41.6914 65.0056 42.1584 65.024 42.6183 65.0421C42.718 65.046 42.8174 65.0499 42.9156 65.0499C48.0809 65.0424 53.2462 65.0431 58.4114 65.0438C59.8873 65.044 61.3632 65.0442 62.839 65.0442L70.0922 65.044C79.7662 65.0437 89.4402 65.0434 99.1142 65.048C99.2333 65.048 99.3527 65.0452 99.4722 65.0424C99.947 65.0313 100.423 65.0201 100.884 65.1847C100.986 65.2304 101.073 65.2968 101.145 65.3826C102.211 65.3817 103.277 65.3803 104.343 65.3789L104.343 65.3789L104.343 65.3789C105.437 65.3774 106.532 65.376 107.627 65.375C107.638 67.7494 107.649 70.1218 107.662 72.4962C106.059 72.488 104.456 72.5495 102.853 72.6111C102.159 72.6377 101.465 72.6643 100.771 72.6853C100.546 72.7529 100.237 72.7746 99.8158 72.7835C99.4665 72.7911 99.1171 72.7843 98.7678 72.7775C98.0542 72.7638 97.3412 72.75 96.6315 72.8586C94.8579 73.0088 93.2141 73.4459 91.9088 74.8285C90.5659 76.2516 89.8174 77.8942 89.8587 79.8873C89.855 83.5865 92.8398 86.527 96.75 86.6541C97.3461 86.6741 97.9427 86.67 98.5393 86.6659C99.0364 86.6625 99.5335 86.659 100.03 86.6695C100.086 86.6708 100.143 86.6707 100.2 86.6706C100.54 86.67 100.887 86.6694 101.14 86.9581C102.214 86.959 103.289 86.9595 104.364 86.9599C105.466 86.9604 106.568 86.9609 107.67 86.9619L107.625 94.0792C106.531 94.0782 105.438 94.0777 104.345 94.0772C103.279 94.0768 102.213 94.0763 101.147 94.0754C101.093 94.1406 101.028 94.1938 100.952 94.2355C100.563 94.4101 100.152 94.4062 99.7426 94.4023C99.7135 94.402 99.6843 94.4017 99.6551 94.4015C99.624 94.4013 99.5929 94.4011 99.5618 94.4011C89.1499 94.4043 78.7384 94.404 68.327 94.4037C59.651 94.4035 50.9751 94.4032 42.2987 94.405C42.2594 94.4054 42.22 94.4062 42.1806 94.4071L42.1804 94.4071C41.8746 94.4134 41.5669 94.4198 41.2887 94.2509C41.2906 89.4377 41.2929 84.624 41.2953 79.8103Z"
            fill="url(#paint2_linear_1532_17148)"
          />
          <path
            d="M101.059 65.3827C103.248 65.3808 105.437 65.3769 107.627 65.375C107.638 67.7494 107.649 70.1218 107.662 72.4962C103.893 72.4769 100.13 72.8428 96.3588 72.6887C97.1299 72.2978 97.9499 72.5308 98.7436 72.5173C101.063 72.4808 101.063 72.5039 101.063 70.0833C101.063 68.5158 101.061 66.9502 101.059 65.3827Z"
            fill="#F98E2B"
          />
          <path
            d="M101.055 86.958C103.259 86.9599 105.466 86.9599 107.67 86.9619C107.655 89.3343 107.64 91.7067 107.625 94.0792C105.437 94.0772 103.252 94.0772 101.064 94.0753C101.063 91.7029 101.059 89.3304 101.055 86.958Z"
            fill="#F98E2B"
          />
          <path
            d="M105.735 57.8673C103.865 57.1702 101.926 57.5996 100.019 57.4898C99.5542 57.4629 99.0878 57.4937 98.6232 57.4995C98.3053 57.4898 97.9856 57.4783 97.6659 57.4687C98.595 56.0706 99.5241 54.6726 100.451 53.2745C101.228 53.2803 102.007 53.2803 102.784 53.2938C104.662 53.3246 105.708 54.4068 105.731 56.3402C105.738 56.8486 105.735 57.3589 105.735 57.8673Z"
            fill="#FFA72B"
          />
          <path
            d="M46.158 53.3936C47.2846 53.4032 48.4112 53.4301 49.5378 53.4147C50.0625 53.407 50.1791 53.5264 50.1453 54.1272C50.0832 55.2422 49.7992 56.3437 49.9158 57.4702C47.9523 57.5761 45.9774 57.3104 44.0214 57.6416C44.0308 57.1313 44.0421 56.6229 44.0515 56.1126C44.0778 54.5894 44.7116 53.771 46.158 53.3936Z"
            fill="#FFA72B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.2815 57.4533C91.2812 57.4544 91.2808 57.4555 91.2804 57.4566C92.0252 57.4586 92.77 57.4605 93.5129 57.4605C93.5553 57.4245 93.6019 57.3927 93.6484 57.3609C93.7338 57.3024 93.8187 57.2443 93.8759 57.162C94.0486 56.9125 94.2215 56.6632 94.3945 56.4139C95.6633 54.5852 96.9326 52.756 98.0664 50.8342C98.3184 50.4067 98.3767 49.9099 98.198 49.4477C98.1551 49.3361 98.1132 49.2238 98.0713 49.1113C97.8602 48.5446 97.648 47.9749 97.284 47.4816C97.2683 47.4252 97.2524 47.3688 97.2366 47.3125C97.0322 46.5856 96.831 45.87 97.0301 45.0764C97.1974 44.4121 96.9586 43.8344 96.3737 43.4261C92.6967 40.8611 89.0197 38.2923 85.3521 35.7119C84.7728 35.3036 84.2368 35.2459 83.6124 35.5983C82.9597 35.968 82.2901 36.3281 81.5058 36.3069C81.1504 36.6035 80.7121 36.5284 80.3416 36.4629C78.7128 36.176 77.7574 36.9616 77.1781 38.4174C77.1789 38.418 77.1796 38.4185 77.1804 38.419C77.1796 38.4193 77.1789 38.4195 77.1781 38.4197C76.9326 38.777 76.6857 39.1335 76.4388 39.49C75.9272 40.2287 75.4153 40.9677 74.9155 41.7146C74.4214 42.4538 73.9261 43.1923 73.4307 43.9309C71.8048 46.3552 70.1784 48.7801 68.5941 51.2332C68.1411 51.9345 67.667 52.6217 67.1929 53.309C66.2635 54.6563 65.3338 56.004 64.5616 57.4589H72.8447C73.0729 57.2316 73.0888 56.9447 73.1047 56.6567C73.1082 56.5934 73.1117 56.53 73.1175 56.4672C73.5011 52.3732 76.6647 49.4288 80.6238 49.4731C84.4663 49.5174 87.5132 52.5215 87.8179 56.5712C87.821 56.6116 87.8234 56.6523 87.8258 56.6928C87.8426 56.9728 87.8592 57.2504 88.0925 57.4589C88.624 57.4589 89.1551 57.4584 89.686 57.4579C90.2164 57.4575 90.7465 57.457 91.2767 57.457C91.2783 57.4558 91.2799 57.4545 91.2815 57.4533Z"
            fill="url(#paint3_linear_1532_17148)"
          />
          <path
            d="M72.8486 57.4594C72.668 53.4231 75.2542 50.0089 79.4578 49.2329C82.4294 48.684 86.0989 50.731 87.3553 53.5599C87.9139 54.8173 88.1565 56.0979 88.0982 57.4594C83.0144 57.4594 77.9324 57.4594 72.8486 57.4594Z"
            fill="#CFDFFD"
          />
          <path
            d="M93.515 57.4606C94.4893 55.6293 95.7269 53.9732 96.8986 52.2825C97.8127 50.9634 98.5086 49.7656 97.4177 48.227C97.2879 48.0421 97.3255 47.734 97.286 47.4818C98.6383 47.3874 100.186 49.3651 99.7762 50.6977C99.3493 52.0938 98.3694 53.1587 97.6171 54.3584C96.9625 55.4002 96.2817 56.4266 95.6121 57.4587C94.9125 57.4606 94.2147 57.4606 93.515 57.4606Z"
            fill="#71A1FF"
          />
          <path
            d="M96.8233 83.0783C95.0929 83.086 93.5808 81.5608 93.5544 79.7834C93.5281 77.9829 95.0854 76.3788 96.8383 76.4039C98.5687 76.4289 100.083 77.9964 100.071 79.7526C100.06 81.5262 98.5518 83.0706 96.8233 83.0783Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.7704 46.1224C67.771 46.1224 67.7716 46.1225 67.7723 46.1226C68.147 45.5662 68.5218 45.0098 68.8966 44.4533C69.9048 42.9569 70.9131 41.4601 71.9195 39.9623C71.08 39.6127 70.1959 39.5507 69.3132 39.4887C68.9658 39.4643 68.6186 39.44 68.2745 39.3981C66.7005 39.2074 65.1221 39.0574 63.544 38.9073C62.6196 38.8194 61.6952 38.7316 60.7719 38.6355C59.965 38.5527 59.3387 38.7183 58.8478 39.4809C58.805 39.5473 58.7624 39.6145 58.7196 39.682C58.3772 40.2223 58.0215 40.7837 57.4146 41.085C57.2934 41.1614 57.1748 41.2455 57.056 41.3297C56.7916 41.5171 56.5269 41.7048 56.2335 41.8071C54.9113 42.2693 54.3959 43.184 54.5972 44.5685C54.5973 44.5691 54.5974 44.5697 54.5976 44.5703C54.5974 44.5703 54.5973 44.5703 54.5972 44.5703C54.362 45.0218 54.3861 45.497 54.4102 45.9724C54.4231 46.227 54.436 46.4817 54.4091 46.7328C54.1332 49.3413 53.8653 51.9508 53.5974 54.5606C53.4982 55.5266 53.399 56.4928 53.2994 57.4589H60.2584C60.4243 57.2118 60.5911 56.965 60.758 56.7181C61.0699 56.2566 61.3822 55.7945 61.6897 55.3291C62.9593 53.4082 64.2282 51.4865 65.4973 49.5646C66.2548 48.4173 67.0125 47.2698 67.7704 46.1224C67.7704 46.1224 67.7704 46.1224 67.7704 46.1224Z"
            fill="url(#paint4_linear_1532_17148)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1532_17148"
            x="0.561523"
            y="31"
            width="148"
            height="148"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1532_17148" />
          </filter>
          <linearGradient id="paint0_linear_1532_17148" x1="129" y1="4.66364e-06" x2="15.5" y2="128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_1532_17148" x1="75" y1="0" x2="75" y2="140" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="0.44" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="paint2_linear_1532_17148" x1="41.2887" y1="78.6631" x2="111.203" y2="78.6631" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FA8618" />
            <stop offset="1" stopColor="#FFAA30" />
          </linearGradient>
          <linearGradient id="paint3_linear_1532_17148" x1="94.4507" y1="39.9131" x2="74.408" y2="60.452" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6A9CFF" />
            <stop offset="1" stopColor="#3E7FFF" />
          </linearGradient>
          <linearGradient id="paint4_linear_1532_17148" x1="66.8492" y1="38.6162" x2="58.0321" y2="58.141" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4986FF" />
            <stop offset="1" stopColor="#3875ED" />
          </linearGradient>
          <clipPath id="clip0_1532_17148">
            <rect width="70" height="74" fill="white" transform="translate(41.219 28)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
