import { useCallback, useEffect, useState } from "react";

import IFilterSite from "../filterSite.interface";
import siteService from "../siteService";
import { AxiosResponse } from "axios";
import { ISitePage } from "../site.interface";

const useGetListSite = (filters: IFilterSite, enabled = true) => {
  const [data, setData] = useState<AxiosResponse<ISitePage>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return siteService.getList(
      filters.page_number,
      filters.page_size,
      filters?.state_id,
      filters.district_code,
      filters.ward_code,
      filters.supported_seller,
      filters.search_text,
    );
  }, [filters]);

  const transformResponse = useCallback((response: AxiosResponse<ISitePage>) => {
    if (response) {
      setData(response);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetListSite;
