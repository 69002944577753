import React, { useEffect } from "react";
import { Modal } from "antd";
import TypographyUI from "../Typography";
import CommonImages from "@base/assets/images";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import useToggle from "@base/hooks/useToggle";
import stringee from "@base/helpers/stringee";
import { setIncomingcall, setIncomingcallStatus, setStringeeClient } from "@base/store/reducers/stringeeClientSlice";
import { EInComingCallStatus } from "@base/enums";

export default function InComingCall() {
  const dispatch = useDispatch();

  const stringeeToken = useSelector((state: RootState) => state.appCommonSlice.stringeeToken);
  const stringeeClient = useSelector((state: RootState) => state.stringeeClientSlice.stringeeClient);
  const incomingcall = useSelector((state: RootState) => state.stringeeClientSlice.incomingcall);

  const { open, toggle } = useToggle();

  useEffect(() => {
    const client = stringee.getStringeeClientInstance();
    settingsClientEvents(client);
    dispatch(setStringeeClient(client));

    client.connect(stringeeToken);
  }, [stringeeToken]);

  useEffect(() => {
    if (!incomingcall && open) {
      toggle();
    }
  }, [incomingcall]);

  // init stringee client
  const settingsClientEvents = (client: any) => {
    client.on("connect", () => {
      // todo
    });

    client.on("authen", (res: any) => {
      // todo
    });

    client.on("incomingcall2", (incomingcall: any) => {
      dispatch(setIncomingcall(incomingcall));

      toggle();
    });

    client.on("requestnewtoken", () => {
      // todo
    });

    client.on("disconnect", () => {
      // todo
    });
  };

  const rejectCall = () => {
    incomingcall?.reject((res?: any) => {
      // todo
    });

    toggle();
    dispatch(setIncomingcallStatus(EInComingCallStatus.REJECTED));
  };

  const receiveCall = () => {
    toggle();
    incomingcall?.answer(function (res?: any) {
      // todo
      if (res?.r === 0) {
        dispatch(setIncomingcallStatus(EInComingCallStatus.RECEIVED));
      }
    });
  };

  return (
    <Modal
      width={575}
      open={open}
      centered
      closable={false}
      maskClosable
      title={
        <div className="w-full flex justify-center">
          <TypographyUI use="paragraph" className="text-2xl font-medium text-common-maintext">
            Cuộc gọi đến
          </TypographyUI>
        </div>
      }
      footer={
        <div className="w-full flex justify-center gap-11">
          <div className="flex flex-col gap-[10px] items-center">
            <img src={CommonImages.RejectIncomingCall} alt="" className="!w-[72px] !h-[72px] cursor-pointer" onClick={rejectCall} />
            <TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
              Từ chối
            </TypographyUI>
          </div>

          <div className="flex flex-col gap-[10px] items-center">
            <img src={CommonImages.ReceiveIncomingCall} alt="" className="!w-[72px] !h-[72px] cursor-pointer" onClick={receiveCall} />
            <TypographyUI use="paragraph" className="!mb-0 text-base font-normal text-common-subtext">
              Chấp nhận
            </TypographyUI>
          </div>
        </div>
      }
    >
      <div className="w-full flex flex-col items-center gap-7">
        <div className="!w-[138px] !h-[138px] relative">
          <img src={CommonImages.DefaultDoctorAvatar} alt="" className="absolute z-20 !w-[138px] !h-[138px]" />
          <span className="absolute z-10 top-[13.8px] left-[13.8px] inline-flex animate-ping h-4/5 w-4/5 rounded-full bg-[#DAE1F0] opacity-75"></span>
        </div>

        <TypographyUI use="paragraph" className="text-lg font-medium text-common-maintext">
          Bác sĩ Medihome {incomingcall?.fromNumber ?? ""} đang gọi cho bạn
        </TypographyUI>
      </div>
    </Modal>
  );
}
