import React from "react";

export default function IconCopy() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4167 0.666748H5.91675C5.00008 0.666748 4.25008 1.41675 4.25008 2.33341V12.3334C4.25008 13.2501 5.00008 14.0001 5.91675 14.0001H13.4167C14.3334 14.0001 15.0834 13.2501 15.0834 12.3334V2.33341C15.0834 1.41675 14.3334 0.666748 13.4167 0.666748ZM13.4167 12.3334H5.91675V2.33341H13.4167V12.3334ZM0.916748 11.5001V9.83342H2.58341V11.5001H0.916748ZM0.916748 6.91675H2.58341V8.58342H0.916748V6.91675ZM6.75008 15.6667H8.41675V17.3334H6.75008V15.6667ZM0.916748 14.4167V12.7501H2.58341V14.4167H0.916748ZM2.58341 17.3334C1.66675 17.3334 0.916748 16.5834 0.916748 15.6667H2.58341V17.3334ZM5.50008 17.3334H3.83341V15.6667H5.50008V17.3334ZM9.66675 17.3334V15.6667H11.3334C11.3334 16.5834 10.5834 17.3334 9.66675 17.3334ZM2.58341 4.00008V5.66675H0.916748C0.916748 4.75008 1.66675 4.00008 2.58341 4.00008Z"
        fill="currentColor"
      />
    </svg>
  );
}
