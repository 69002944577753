import React from "react";

export default function K31A() {
  return (
    <div>
      <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.833954" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16669)" />
        <rect x="1.5" y="2.33395" width="97" height="97" rx="14.5" stroke="white" strokeOpacity="0.5" strokeWidth="3" />
        <g filter="url(#filter0_d_1360_16669)">
          <path
            d="M66.6534 65.0439C66.0756 65.0439 65.5117 65.1634 64.9675 65.3998L59.0212 61.2107V41.1929C59.0212 33.6378 52.8953 27.4912 45.3398 27.4912H28.0859C27.2454 27.4912 26.5635 28.1726 26.5635 29.0136V45.2526C26.5635 46.0936 27.2454 46.775 28.0859 46.775H42.8431C43.5458 46.775 44.1593 47.2607 44.3228 47.9387C44.6252 50.9385 44.9403 53.2116 45.1931 55.0417C45.8948 60.1109 46.0832 61.4733 44.2911 65.7304C43.9769 66.4772 43.8173 67.2706 43.8173 68.0888C43.8173 71.4468 46.549 74.1784 49.9069 74.1784H66.6534C69.1719 74.1784 71.2207 72.1296 71.2207 69.6112C71.2207 67.0927 69.172 65.0439 66.6534 65.0439ZM54.4743 68.0887H51.4294C50.589 68.0887 49.907 67.4072 49.907 66.5663C49.907 65.7253 50.589 65.0439 51.4294 65.0439H54.4743C55.3147 65.0439 55.9967 65.7253 55.9967 66.5663C55.9967 67.4072 55.3147 68.0887 54.4743 68.0887Z"
            fill="url(#paint1_linear_1360_16669)"
            shape-rendering="crispEdges"
          />
        </g>
        <path
          d="M65.6375 27.4912C64.7971 27.4912 64.1151 28.1726 64.1151 29.0136V30.536H62.5927C61.7522 30.536 61.0703 31.2175 61.0703 32.0584V35.1032C61.0703 35.9442 61.7522 36.6256 62.5927 36.6256C63.4332 36.6256 64.0948 35.9442 64.0948 35.1032V33.5808H65.6375C66.478 33.5808 67.1396 32.8993 67.1396 32.0584V29.0136C67.1396 28.1726 66.478 27.4912 65.6375 27.4912Z"
          fill="url(#paint2_linear_1360_16669)"
        />
        <path
          d="M72.9888 35.9955C72.3941 35.4008 71.4307 35.4008 70.836 35.9955L69.7595 37.0718L68.6832 35.9955C68.0886 35.4008 67.1251 35.4008 66.5304 35.9955L64.3777 38.1482C63.783 38.7424 63.783 39.7068 64.3777 40.301C64.9723 40.8957 65.9358 40.8957 66.5304 40.301L67.6068 39.2247L68.6832 40.301C69.2779 40.8957 70.2414 40.8957 70.836 40.301L72.9888 38.1482C73.5834 37.5541 73.5834 36.5897 72.9888 35.9955Z"
          fill="url(#paint3_linear_1360_16669)"
        />
        <path
          d="M71.7292 45.7606H70.1865V44.2382C70.1865 43.3973 69.5249 42.7158 68.6844 42.7158H65.6396C64.7991 42.7158 64.1172 43.3973 64.1172 44.2382C64.1172 45.0791 64.7991 45.7606 65.6396 45.7606H67.162V47.283C67.162 48.124 67.8439 48.8054 68.6844 48.8054H71.7292C72.5697 48.8054 73.2516 48.124 73.2516 47.283C73.2516 46.4421 72.5697 45.7606 71.7292 45.7606Z"
          fill="url(#paint4_linear_1360_16669)"
        />
        <defs>
          <filter
            id="filter0_d_1360_16669"
            x="18.5635"
            y="23.4912"
            width="60.6572"
            height="62.6872"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16669" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16669" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16669" x1="50" y1="0.833954" x2="50" y2="100.834" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16669" x1="48.8921" y1="27.4912" x2="48.8921" y2="74.1784" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16669" x1="64.105" y1="27.4912" x2="64.105" y2="36.6256" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint3_linear_1360_16669" x1="68.6832" y1="35.5494" x2="68.6832" y2="40.747" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint4_linear_1360_16669" x1="68.6844" y1="42.7158" x2="68.6844" y2="48.8054" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
