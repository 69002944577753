export default function IconTKHome() {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="39" height="39" rx="11.5" fill="white" stroke="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6549 13.8488C27.2949 15.034 29.0205 17.6302 29.0205 20.5908C29.0205 24.1366 26.5679 27.0732 23.1954 27.8037C23.4684 28.4932 23.6298 29.3288 23.6298 30.1143C23.6298 30.4867 23.3285 30.7881 22.956 30.7881H14.8701C14.6451 30.7881 14.4345 30.6756 14.3094 30.488L12.6815 27.9562C11.909 26.7987 11.501 25.4504 11.501 24.0574C11.501 22.2892 12.1603 20.5987 13.3572 19.2971C14.5786 17.9699 14.8247 16.0899 13.9699 14.6178C13.2184 13.3235 13.0585 11.7659 13.5323 10.3452L14.2311 8.24871C14.3226 7.97366 14.5799 7.78809 14.8701 7.78809H18.9131C19.0883 7.78809 19.2644 7.85996 19.3902 7.9861C20.1987 8.79375 21.1981 9.41219 22.2822 9.77273L23.1403 10.0597C24.2392 10.4255 24.9775 11.4494 24.9775 12.6082C24.9775 13.0541 24.8523 13.4716 24.6549 13.8488ZM22.7238 24.1995C23.0258 23.9376 23.314 23.6941 23.5845 23.4658C23.6638 23.3989 23.7433 23.3322 23.8228 23.2656C25.0711 22.2187 26.2995 21.1884 26.2995 19.7104C26.2995 18.3147 25.269 17.2217 23.9536 17.2217C23.2134 17.2217 22.6646 17.5599 22.2822 17.9857C21.8999 17.5599 21.3511 17.2217 20.6108 17.2217C19.2954 17.2217 18.2649 18.3147 18.265 19.7104C18.265 21.1751 19.4034 22.1359 20.9794 23.4651C21.0171 23.4971 21.0553 23.5293 21.0937 23.5619C21.3306 23.7623 21.5803 23.9736 21.8407 24.1995C21.9677 24.3094 22.125 24.364 22.2823 24.364C22.4395 24.364 22.5968 24.3094 22.7238 24.1995ZM22.9299 19.5288C22.9411 19.4893 23.2187 18.5694 23.9531 18.5694C24.6085 18.5694 24.9514 19.1432 24.9514 19.7104C24.9514 20.5591 23.886 21.453 22.9165 22.2664C22.8486 22.3234 22.7812 22.3799 22.7147 22.436C22.6485 22.4923 22.581 22.5492 22.5125 22.6071C22.4368 22.6711 22.3598 22.7362 22.2817 22.8025C22.1336 22.6766 21.9893 22.5547 21.8497 22.4366L21.8481 22.4353C20.3379 21.1613 19.6121 20.5132 19.6121 19.7104C19.6121 19.1432 19.9549 18.5694 20.6103 18.5694C21.3447 18.5694 21.6224 19.4893 21.6322 19.5242C21.7125 19.8144 21.9764 20.0138 22.2778 20.0157C22.591 20.0513 22.8457 19.817 22.9299 19.5288Z"
          fill="url(#paint0_linear_655_5781)"
        />
        <defs>
          <linearGradient id="paint0_linear_655_5781" x1="12.6802" y1="29.6823" x2="33.474" y2="13.5167" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
