import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IFilterMySpecialPackage } from "./filterMySpecialPackage.interface";
import { ISpecialPackage, ISpecialPackagePage } from "./patientSpecialPackage.interface";

class PatientSpecialPackageService {
  calculateFee(body: any) {
    return httpService.post(apiService.patient.patientSpecialPackage.calculateFee, body);
  }

  buy(body: any) {
    return httpService.post(apiService.patient.patientSpecialPackage.buy, body);
  }

  findAll(params?: IFilterMySpecialPackage) {
    return httpService.get<ISpecialPackagePage>(apiService.patient.patientSpecialPackage.findAll, {
      params,
      headers: {
        platform: "web",
      },
    });
  }

  getDetail(key: string) {
    return httpService.get<ISpecialPackage>(apiService.patient.patientSpecialPackage.getDetails, { params: { key } });
  }
}

export default new PatientSpecialPackageService();
