import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { getAvatarLink } from "@base/helpers/common";
import { ISite } from "@base/modules/site/site.interface";
import profileService from "@base/modules/user/profile/profileService";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import CommonStyles from "@components/CommonStyles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface IProps {
  site: ISite;
}

export default function SiteListItem({ site }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSync = async () => {
    if (!site.code) {
      return;
    }

    try {
      setIsLoading(true);
      await profileService.syncClinicRecord(site.code);

      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Sync") + " " + t("Successfully") + "!" }));
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Sync") + " " + t("Failed") + "!" }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-common-background rounded-2xl p-2 flex gap-4">
      <div className="flex-none w-fit">
        <CommonStyles.ImageUI
          className="aspect-square max-w-[160px] max-h-[160px]"
          src={getAvatarLink(site?.avatar ?? [])}
          fallback={CommonImages.SiteDefault}
        />
      </div>
      <div className="grow flex flex-col justify-between gap-2 max-h-[160px]">
        <div className="flex flex-col gap-2">
          <div className="text-lg font-medium font-['Inter'] text-common-maintext line-clamp-1" title={site.name}>
            {site.name}
          </div>

          {site.address ? (
            <div className="flex items-start gap-2 text-sm font-normal font-['Inter'] text-common-subtext">
              <div>
                <CommonIcons.IconLocation />
              </div>
              <div className="line-clamp-2" title={site.address}>
                {site.address}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <CommonStyles.ButtonUI className="w-full h-10" onClick={onSync} loading={isLoading}>
            {t("Sync")}
          </CommonStyles.ButtonUI>
        </div>
      </div>
    </div>
  );
}
