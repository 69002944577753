import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import FormSetupPassword from "./FormSetupPassword";
import { useSearchParams } from "react-router-dom";

export default function SetupPassword() {
  //! state
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const reset = searchParams.get("reset");
  //! fuction
  //! render
  return (
    <div>
      <div className="flex flex-col items-center mb-6">
        <CommonStyles.TypographyUI className="!text-common-cyan500" level={3} use="title">
          {reset === "true" ? t("LoginPage.ResetPassword") : t("LoginPage.SetupPassword")}
        </CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI use="text" className="text-[14px] text-center px-2 text-common-subtext">
          {t("LoginPage.rulePass")}
        </CommonStyles.TypographyUI>
      </div>
      <FormSetupPassword />
    </div>
  );
}
