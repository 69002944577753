import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { backToTop } from "@base/helpers/common";
import useParseOption from "@base/hooks/useParseOptions";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import useGetSiteSpecialPackage from "@base/modules/siteSpecialPackage/hooks/useGetSiteSpecialPackage";
import IFilterSiteSpecialPackage from "@base/modules/siteSpecialPackage/interface/filterSiteSpecialPackage";
import themeColor from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import CardPackageService from "@components/CommonStyles/CardPackageService";
import FilterBookingHeader from "@components/CommonStyles/Filter/Booking/FilterBookingHeader";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function BookingPackage() {
  //! state
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IFilterSiteSpecialPackage>({
    page_number: 0,
    page_size: 12,
    search: "",
    site_code: null,
    lang: "vi",
  });
  const [textSearch, setTextSearch] = useState("");
  const { data, isLoading, refetch: refetchSiteSpecialPackage } = useGetSiteSpecialPackage(filters, false);
  const dataSiteSpecialPackage = data?.data.content_page;

  const { data: dataSite, isLoading: loadingSite } = useGetListSite({ page_number: 0, page_size: 50 });

  //!function
  const optionSite = useParseOption(dataSite?.data.content_page, "name", "code");
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };
  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, search: value }));
      }, 1000),
    [],
  );
  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page_number: value - 1 }));
  };
  const handleChangeSelect = (value: string) => {
    setFilters((prev) => ({ ...prev, site_code: value }));
  };

  const clearFilter = () => {
    if (filters.page_number !== 0 || filters.search || filters.site_code) {
      setFilters({ page_number: 0, page_size: 12 });
      setTextSearch("");
    }
  };

  //! render
  const renderSiteSpecialPackage = () => {
    return dataSiteSpecialPackage?.map((item, index) => {
      return <CardPackageService data={item} root="specialist" key={index} />;
    });
  };

  useEffect(() => {
    refetchSiteSpecialPackage();
  }, [filters]);

  useEffect(() => {
    backToTop();
  }, []);

  return (
    <div className="">
      {/* search */}
      <div className="bg-common-white">
        <FilterBookingHeader
          title="Danh sách gói khám"
          subTitle="Hệ thống 50+ Cơ sở y tế uy tín đã được chúng tôi xác minh và kiểm duyệt"
          ComponentFilter={
            <CommonStyles.InputUI
              placeholder="Nhập nội dung cần tìm kiếm..."
              variant="filled"
              onChange={changeSearch}
              value={textSearch}
              prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
            />
          }
        />
      </div>

      <div className={`${responseUI.screen}  py-4 flex flex-col gap-6`}>
        {/* filter */}
        <div className="flex gap-4 items-center bg-common-white  p-4 rounded-2xl">
          <div className="grow">
            <CommonStyles.SelectUI
              value={filters.site_code}
              onChange={handleChangeSelect}
              options={optionSite}
              loading={loadingSite}
              variant="filled"
              placeholder="Cơ sở y tế"
              showSearch
              optionFilterProp="label"
            />
          </div>
          <div className="flex items-center gap-3">
            <CommonStyles.ButtonUI
              onClick={clearFilter}
              icon={<CommonIcons.IconRefresh />}
              className="bg-common-orange100 h-10 lg:h-12 !px-6 hover:!bg-common-orange100"
            />
          </div>
        </div>

        {data?.data.total_records === 0 ? (
          <div className="flex flex-col justify-center items-center gap-6 mt-16">
            <img src={CommonImages.EmptyRecordList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("noResult")}!</div>
          </div>
        ) : (
          <>
            <div className={`grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-8 ${responseUI.padding}`}>{renderSiteSpecialPackage()}</div>
            <div className="flex justify-end items-center">
              <CommonStyles.PaginationUI
                current={Number(filters.page_number) + 1}
                onChange={handleChangePage}
                pageSize={filters.page_size}
                total={data?.data.total_records}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
