import { getAvatarLink } from "@base/helpers/common";
import { ISite } from "@base/modules/site/site.interface";
import siteService from "@base/modules/site/siteService";
import { baseRoutes } from "@base/routes/baseRoutes";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import CardFacility from "@components/CommonStyles/CardFacility";
import CardOrderFacility from "@components/OrderFacility/CardOrderFacility";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ConnectHome() {
  //! state
  const { t } = useTranslation();
  const [sites, setSites] = useState<ISite[]>([]);
  const navigate = useNavigate();
  //! function
  const handleViewAll = () => {
    navigate(baseRoutes.orderFacility);
  };
  const getSites = async () => {
    try {
      const resp = await siteService.getList(0, 20);
      setSites(resp.data.content_page ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    getSites();
  }, []);

  //! render
  const renderItemCarousel = () => {
    return sites?.map((site: ISite, idx: number) => {
      return (
        <div className="py-3 pl-3">
          <CardOrderFacility key={idx} site={site} />
        </div>
      );
    });
  };

  let settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToScroll: 4,
    slidesToShow: 1,
    slidesPerRow: 2,
    rows: 2,
    speed: 500,
    // autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          centerPadding: "100px",
          slidesToScroll: 1,
          slidesToShow: 1,
          slidesPerRow: 1,
          rows: 2,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          className: "right",
          centerMode: true,
          centerPadding: "30px",
          slidesToScroll: 1,
          slidesToShow: 1,
          slidesPerRow: 1,
          rows: 2,
          dots: false,
        },
      },

      {
        breakpoint: 500,
        settings: {
          className: "right",
          centerMode: true,
          centerPadding: "30px",
          slidesToScroll: 1,
          slidesToShow: 1,
          slidesPerRow: 1,
          rows: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="bg-common-white">
      <div className={`${responseUI.screen} ${responseUI.padding} py-8 lg:py-16`}>
        <div className="flex justify-between items-center mb-3 lg:mb-10">
          <div className="basis-2/3 ">
            <CommonStyles.TypographyUI use="title" className={`${responseUI.text.title} `} level={3}>
              {t("home.facilities")}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.subTitle} `}>
              {t("home.facilitiesIntro")}
            </CommonStyles.TypographyUI>
          </div>
          <CommonStyles.ButtonUI
            onClick={handleViewAll}
            ghost
            className="lg:h-12 lg:text-base font-medium hover:!bg-common-mainColor hover:!text-white"
          >
            {t("home.viewAll")}
          </CommonStyles.ButtonUI>
        </div>

        <CommonStyles.CarouselUI settings={settings}>{renderItemCarousel()}</CommonStyles.CarouselUI>
      </div>
    </div>
  );
}
