import { useEffect, useState } from "react";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ESpecialPackageStatus } from "@base/enums";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IFilterMySpecialPackage } from "@base/modules/patientSpecialPackage/filterMySpecialPackage.interface";
import useGetMySpecialPackage from "@base/modules/patientSpecialPackage/hooks/useGetMySpecialPackage";
import { IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import profileService from "@base/modules/user/profile/profileService";
import { ISpecialPackage } from "@base/modules/patientSpecialPackage/patientSpecialPackage.interface";
import ServicePackageListItem from "./ServicePackageListItem";

export default function ServicePackageList() {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>(ESpecialPackageStatus.UNPAID);

  const tabItems = [
    {
      key: ESpecialPackageStatus.UNPAID,
      label: t("user.servicePackage.tabPendingPayment"),
    },
    {
      key: ESpecialPackageStatus.PENDING,
      label: t("user.servicePackage.tabPending"),
    },
    {
      key: ESpecialPackageStatus.IN_PROGRESS,
      label: t("user.servicePackage.tabInprogress"),
    },
    {
      key: ESpecialPackageStatus.COMPLETED,
      label: t("user.servicePackage.tabCompleted"),
    },
    {
      key: ESpecialPackageStatus.CANCELLED,
      label: t("user.servicePackage.tabCancelled"),
    },
  ];

  const [patientRecords, setPatientRecords] = useState<IPatientRecordData[]>([]);

  const [filter, setFilter] = useState<IFilterMySpecialPackage>({ page_number: 0, page_size: 10, status: activeTab as ESpecialPackageStatus });
  const { isLoadingMySpecialPackage, mySpecialPackageData, mySpecialPackageError, refetchMySpecialPackage } = useGetMySpecialPackage(filter, false);

  //! function
  useEffect(() => {
    getPatientRecords();
  }, []);

  useEffect(() => {
    if (!filter.patient_record_code && patientRecords.length) {
      setFilter(Object.assign(filter, { page_number: 0, patient_record_code: patientRecords[0].patient_record?.code }));

      refetchMySpecialPackage();
    }
  }, [patientRecords]);

  const getPatientRecords = async () => {
    try {
      const resp = await profileService.getByUserKey();

      resp.data.sort((a: IPatientRecordData, b: IPatientRecordData) => {
        if (a.patient_record?.default_record) {
          return -1;
        }
        return 0;
      });

      setPatientRecords(resp.data);
    } catch (error) {}
  };

  const onChangeTabs = (key: string) => {
    setActiveTab(key);
    setFilter(Object.assign(filter, { page_number: 0, status: key }));
    refetchMySpecialPackage();
  };

  const onChangePatientRecord = (value: string) => {
    setFilter(Object.assign(filter, { page_number: 0, patient_record_code: value }));
    refetchMySpecialPackage();
  };

  const onChangePage = (page: number, pageSize: number) => {
    setFilter(Object.assign(filter, { page_number: page - 1, page_size: pageSize }));
    refetchMySpecialPackage();
  };

  //! render
  const operations = (
    <div className="flex justify-center items-center gap-4">
      <CommonStyles.ButtonUI
        iconPosition="start"
        className="bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
        onClick={() => navigate(baseRoutes.bookingPackage)}
      >
        {t("user.servicePackage.btnBuySpecialPackage")}
      </CommonStyles.ButtonUI>
    </div>
  );

  return (
    <div className="p-5">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center max-md:flex-col max-md:justify-start max-md:items-start gap-2">
          <div className="flex-none w-fit text-common-cyan500 text-base font-bold font-['Inter']">{t("user.servicePackage.servicePackages")}</div>

          <div className="grow justify-end max-lg:flex max-lg:items-center max-lg:gap-2 max-[370px]:flex-col">
            <CommonStyles.SelectUI
              rootClass="max-sm:w-full"
              className="w-72 max-sm:w-full float-right"
              value={filter?.patient_record_code}
              variant="filled"
              placeholder="Chọn hồ sơ"
              options={patientRecords.map((patientRecord: IPatientRecordData) => {
                return { label: patientRecord.patient_record?.patient_name, value: patientRecord.patient_record?.code };
              })}
              onChange={onChangePatientRecord}
            />

            <div className="lg:hidden w-fit">{operations}</div>
          </div>
        </div>

        <div>
          <CommonStyles.TabsUI
            className="w-full"
            items={tabItems}
            activeKey={activeTab}
            onChange={onChangeTabs}
            tabBarExtraContent={
              mySpecialPackageData && mySpecialPackageData.content_page && mySpecialPackageData.content_page.length > 0 ? (
                <div className="max-lg:hidden w-fit">{operations}</div>
              ) : null
            }
          />
        </div>
      </div>

      <div className="min-h-[428px] relative">
        <CommonStyles.SpinnerUI isSpinning={isLoadingMySpecialPackage} />

        {mySpecialPackageData && mySpecialPackageData.content_page && mySpecialPackageData.content_page.length > 0 ? (
          <div className="w-full">
            <div className="flex flex-col gap-6 max-h-[610px] overflow-y-auto">
              {mySpecialPackageData.content_page?.map((specialPackage: ISpecialPackage, idx: number) => {
                return <ServicePackageListItem key={idx} specialPackage={specialPackage} />;
              })}
            </div>

            {(mySpecialPackageData.total_records ?? 0) > filter.page_size ? (
              <div className="w-full flex justify-end items-center mt-2">
                <CommonStyles.PaginationUI
                  current={filter.page_number + 1}
                  pageSize={filter.page_size}
                  total={mySpecialPackageData.total_records}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full min-h-[428px] flex flex-col justify-center items-center gap-6">
            <img src={CommonImages.EmptyServicePackageList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("user.servicePackage.recordNotFound")}!</div>

            <CommonStyles.ButtonUI onClick={() => navigate(baseRoutes.bookingPackage)}>{t("user.servicePackage.btnBuy")}</CommonStyles.ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
}
