import { useCallback, useEffect, useState } from "react";
import specialCategoryService from "../specialCategory.service";
import IFilterListCategory from "../filterListShow";
import { ResponseSpecialCategory } from "./interface/ISpecialCategory";

const useGetListCategoryShow = (query: IFilterListCategory, enabled = true) => {
  const [data, setData] = useState<ResponseSpecialCategory>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return specialCategoryService.getListShow(query);
  }, [query]);

  const transformResponse = useCallback((response: ResponseSpecialCategory) => {
    if (response) {
      setData(response);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetListCategoryShow;
