export default function IconReply() {
  return (
    <div>
      <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.00041 1.56026C7.00041 0.620262 5.86041 0.140262 5.19041 0.810262L0.710413 5.29026C0.617709 5.38278 0.544162 5.49266 0.49398 5.61364C0.443799 5.73461 0.417969 5.86429 0.417969 5.99526C0.417969 6.12623 0.443799 6.25591 0.49398 6.37689C0.544162 6.49786 0.617709 6.60775 0.710413 6.70026L5.19041 11.1803C5.86041 11.8603 7.00041 11.3803 7.00041 10.4403C7.00041 10.1603 6.89041 9.89026 6.69041 9.69026L3.00041 6.00026L6.69041 2.31026C6.89041 2.11026 7.00041 1.84026 7.00041 1.56026ZM13.0004 3.00026V1.41026C13.0004 0.520262 11.9204 0.0702616 11.2904 0.700262L6.70041 5.29026C6.60771 5.38278 6.53416 5.49266 6.48398 5.61364C6.4338 5.73461 6.40797 5.86429 6.40797 5.99526C6.40797 6.12623 6.4338 6.25591 6.48398 6.37689C6.53416 6.49786 6.60771 6.60775 6.70041 6.70026L11.2904 11.2903C11.9204 11.9203 13.0004 11.4703 13.0004 10.5803V8.90026C18.0004 8.90026 21.5004 10.5003 24.0004 14.0003C23.0004 9.00026 20.0004 4.00026 13.0004 3.00026Z"
          fill="#005984"
        />
      </svg>
    </div>
  );
}
