import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ExtraOTP from "./ExtraOTP";
import { Form } from "antd";
import useOTP from "@base/modules/user/account/hooks/useOTP";
import { InputOTP } from "antd-input-otp";
import _ from "lodash";
import useFormValidation from "@base/hooks/useFormvalidation";
export default function OTPForm() {
  //! state
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const phoneUrl = searchParams.get("phone");
  const reset = searchParams.get("reset");

  const { isLoading, error, handleCheckOTP } = useOTP();
  const [form] = Form.useForm();
  const initialValues = {
    otp: "",
  };

  //! function
  const handleSubmit = (values: any) => {
    if (!phoneUrl) {
      return;
    }
    const otpConvert = _.join(values.otp, "");
    handleCheckOTP(phoneUrl, Number(otpConvert), reset === "true");
  };

  //! render
  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
      <div className="flex flex-col items-center mb-6">
        <CommonStyles.TypographyUI className="!text-common-cyan500" level={3} use="title">
          {t("LoginPage.CodeOTP")}
        </CommonStyles.TypographyUI>
        <CommonStyles.TypographyUI use="text" className="text-[14px] text-center px-2">
          {`${t("LoginPage.EnterOTP")} ${phoneUrl ?? ""}`}
        </CommonStyles.TypographyUI>
      </div>

      <CommonStyles.FormFieldUI name="otp" component={<InputOTP inputType="numeric" autoFocus />} />

      {error ? <CommonStyles.TypographyUI className="text-common-red500">{t("invalidOTP")}</CommonStyles.TypographyUI> : null}

      <div className="flex justify-center items-center mt-6">
        <CommonStyles.ButtonUI htmlType="submit" className="h-12 w-full" loading={isLoading}>
          <CommonStyles.TypographyUI use="text" className="text-white text-base font-semibold">
            {t("LoginPage.Continue")}
          </CommonStyles.TypographyUI>
        </CommonStyles.ButtonUI>
      </div>

      <ExtraOTP />
    </Form>
  );
}
