import { getErrorMsg } from "@base/helpers/common";
import { useState } from "react";
import orderService from "../orderService";
import { IPayloadOrder } from "../interface/IPayloadOrder";
import { BankCode, EOrderType, EStatusCode } from "@base/enums";
import { baseRoutes } from "@base/routes/baseRoutes";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";

const useOrder = (lang = "vi") => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");
  const [resultOrder, setResultOrder] = useState<any>();

  const handleOrder = async (body: IPayloadOrder) => {
    try {
      const resultOrder = await orderService.createOrder(body, lang);
      setResultOrder(resultOrder.data);

      if (resultOrder.status === EStatusCode.Success || resultOrder.status === EStatusCode.Created) {
        if (body.bank_code && body.bank_code !== BankCode.BANK_TRANSFER && body.bank_code !== BankCode.CASH && resultOrder.data.payment_url) {
          location.replace(resultOrder.data.payment_url);
        }
      }
    } catch (error) {
      setLoading(false);
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t(`${getErrorMsg(error)}`) + "!" }));
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    resultOrder,
    handleOrder,
  };
};

export default useOrder;
