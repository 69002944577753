import { AntDesignOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { dataFeedBack } from "@base/constants/mocks";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";

export default function Feedback() {
  //! state
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrowCarousel />,
    prevArrow: <RightArrowCarousel />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
    ],
  };

  //! render
  const renderFeedback = () => {
    return dataFeedBack.map((item, index) => {
      return (
        <div className="px-5 pt-3 lg:pt-8 pb-10 lg:pb-36" key={index}>
          <div className="bg-[#F5F5FA] py-10 px-4 rounded-2xl relative">
            <CommonStyles.TypographyUI use="paragraph" className="min-h-52 text-center text-sm sm:text-base text-common-maintext">
              {item.feedback}
            </CommonStyles.TypographyUI>

            <div className="absolute left-1/2 -translate-x-1/2 flex flex-col justify-center items-center gap-2">
              <div>
                <img src={item.avatar} />
              </div>
              <CommonStyles.TypographyUI use="paragraph" className="text-nowrap text-sm md:text-base font-medium !m-0">
                {item.author}
              </CommonStyles.TypographyUI>

              <CommonStyles.TypographyUI use="text" className="text-base text-common-subtext">
                {item.age} tuổi - {item.address}
              </CommonStyles.TypographyUI>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="bg-common-white">
      <div className={`py-10 lg:py-20 ${responseUI.screen}  ${responseUI.padding}`}>
        <CommonStyles.TypographyUI className={`${responseUI.text.title} text-center`} use="title" level={3}>
          KHÁCH HÀNG NÓI VỀ 365 MEDIHOME
        </CommonStyles.TypographyUI>
        <CommonStyles.CarouselUI settings={settings}>{renderFeedback()}</CommonStyles.CarouselUI>
      </div>
    </div>
  );
}

const NextArrowCarousel = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <div className="!border-common-mainColor border rounded-full text-common-mainColor flex items-center justify-center p-2 w-8 h-8">
        <CommonIcons.RightOutlined />
      </div>
    </div>
  );
};

const RightArrowCarousel = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <div className="!border-common-mainColor border rounded-full text-common-mainColor flex items-center justify-center p-2 w-8 h-8">
        <CommonIcons.LeftOutlined />
      </div>
    </div>
  );
};
