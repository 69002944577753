import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { ECurrency } from "@base/enums";
import { ETagSpecialPackageDetailType } from "@base/enums";
import useGetSiteSpecialPackageBySlug from "@base/modules/siteSpecialPackage/hooks/useGetSiteSpecialPackageBySlug";
import { ISiteSpecialPackageDetail } from "@base/modules/siteSpecialPackage/interface/ISiteSpecialPackage";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import TagSpecialPackageDetailType from "@components/CommonStyles/TagSpecialPackageDetailType";
import { Divider } from "antd";
import React from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

export default function PackageDetail() {
  //! state
  const { slug } = useParams();
  const navigate = useNavigate();

  const { data: dataSiteSpecialPackage, isLoading } = useGetSiteSpecialPackageBySlug(String(slug));
  const detailSiteSpecialPackage = dataSiteSpecialPackage?.data;

  //! funcion
  const handlePaymentPackageService = () => {
    navigate({
      pathname: `${baseRoutes.bookingPackage}/${detailSiteSpecialPackage?.id}${baseRoutes.paymentPackageService}`,
      search: createSearchParams({
        site_code: String(detailSiteSpecialPackage?.site.code),
        package_code: String(detailSiteSpecialPackage?.code),
      }).toString(),
    });
  };

  //! render
  const renderItems = () => {
    return detailSiteSpecialPackage?.site_special_package_details.map((item, index) => {
      return {
        key: index,
        label: (
          <LabelCollapse item={item} index={index + 1} TagColor={<TagSpecialPackageDetailType type={item.type as ETagSpecialPackageDetailType} />} />
        ),
        children: <ContentCollapse item={item} />,
      };
    });
  };
  return (
    <div>
      <div className="bg-common-white">
        <div className={`bg-common-white xl:pb-8 relative ${responseUI.screen} xl:py-5 flex flex-col justify-between`}>
          <div className="flex flex-col  md:flex-row gap-7 p-4 rounded-2xl bg-common-white xl:mt-9">
            <div className=" w-[370px] h-[187px] md:w-[480px] lg:h-[242px]">
              <div className="flex justify-center ">
                <img
                  className="rounded-xl w-[370px] h-[187px] sm:w-[480px]  md:w-[480px] lg:h-[242px]"
                  src={`${detailSiteSpecialPackage?.thumbnail}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = CommonImages.default512288;
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-center gap-2 lg:gap-4 h-full">
              <CommonStyles.TypographyUI className="font-medium text-sm lg:text-base text-common-maintext uppercase">
                {detailSiteSpecialPackage?.name}
              </CommonStyles.TypographyUI>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <CommonIcons.IconFacilityOutline />
                  <CommonStyles.TypographyUI className="lg:text-sm text-common-subtext line-clamp-1">
                    {detailSiteSpecialPackage?.site.name}
                  </CommonStyles.TypographyUI>
                </div>

                <div className="flex items-center gap-3">
                  <CommonIcons.IconLocation />
                  <CommonStyles.TypographyUI className="lg:text-sm text-common-subtext line-clamp-1">
                    {detailSiteSpecialPackage?.site.address}
                  </CommonStyles.TypographyUI>
                </div>

                <div className="flex items-center gap-3">
                  <CommonIcons.IconDollar />
                  <CommonStyles.TypographyUI className="lg:text-sm !text-common-inprogress font-semibold">
                    {detailSiteSpecialPackage?.amount.toLocaleString()}
                    {ECurrency.VND}
                  </CommonStyles.TypographyUI>
                </div>
              </div>

              <div>
                <CommonStyles.ButtonUI onClick={handlePaymentPackageService} className="h-10 lg:h-12 font-medium text-base mt-2">
                  Mua gói dịch vụ
                </CommonStyles.ButtonUI>
              </div>
            </div>
          </div>

          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className="bg-common-background">
        <div className={`${responseUI.screen} mt-8`}>
          <div className="bg-common-white p-4 rounded-lg">
            <div>
              <CommonStyles.TypographyUI className="font-semibold text-lg text-common-maintext">Tổng quan</CommonStyles.TypographyUI>
              <pre className="text-wrap font-[Inter] text-base ml-4 my-2">{detailSiteSpecialPackage?.description}</pre>
            </div>

            <Divider />

            <div>
              <CommonStyles.TypographyUI className="font-semibold text-lg text-common-maintext">Chi tiết Gói dịch vụ</CommonStyles.TypographyUI>
              <pre className="text-wrap font-[Inter] text-base ml-4 my-2">{detailSiteSpecialPackage?.details}</pre>
            </div>

            <Divider />

            <div>
              <CommonStyles.CollapseUI
                items={renderItems()}
                className="bg-common-background "
                expandIconPosition={"end"}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <CommonIcons.UpOutlined style={{ color: themeColor.orange, fontSize: "medium" }} />
                  ) : (
                    <CommonIcons.DownOutlined style={{ color: themeColor.orange, fontSize: "medium" }} />
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LabelCollapse = ({ index, item, TagColor }: { index: number; item: ISiteSpecialPackageDetail; TagColor?: React.ReactNode }) => {
  return (
    <div className="flex items-center gap-3">
      <div className="size-7 lg:size-10 text-center leading-7 lg:leading-10 bg-common-inprogress rounded-full text-common-white lg:text-lg">
        {index}
      </div>

      <div className="">
        <CommonStyles.TypographyUI className="lg:text-base font-medium text-common-maintext block">{item.item_name}</CommonStyles.TypographyUI>
        {TagColor && TagColor}
      </div>
    </div>
  );
};

const ContentCollapse = ({ item }: { item: ISiteSpecialPackageDetail }) => {
  return (
    <div className="flex flex-col">
      <CommonStyles.TypographyUI className="text-base !font-medium text-common-maintext">&#8226; Số lượt: {item.qty}</CommonStyles.TypographyUI>

      {item.description && (
        <>
          <CommonStyles.TypographyUI className="text-base !font-medium text-common-maintext">&#8226; Mô tả:</CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="pl-7 text-common-maintext text-base">
            {item.description}
          </CommonStyles.TypographyUI>
        </>
      )}
    </div>
  );
};
