import React from "react";

export default function IconRecord() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0098 5.95447C17.4882 5.7278 16.8832 5.82614 16.4673 6.20947L14.6665 7.87614V6.66614C14.6665 5.2878 13.5457 4.16614 12.1665 4.16614H4.6665C3.28817 4.16614 2.1665 5.2878 2.1665 6.66614V13.3328C2.1665 14.712 3.28817 15.8328 4.6665 15.8328H12.1665C13.5457 15.8328 14.6665 14.712 14.6665 13.3328V12.1236L16.4673 13.7895C16.7348 14.037 17.0807 14.1661 17.4332 14.1661C17.6273 14.1661 17.8232 14.127 18.0098 14.0453C18.5173 13.8236 18.8332 13.3495 18.8332 12.8086V7.19114C18.8332 6.6503 18.5173 6.17614 18.0098 5.95447Z"
        fill="currentColor"
      />
    </svg>
  );
}
