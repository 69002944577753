export default function IconMTHome() {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="39" height="39" rx="11.5" fill="white" stroke="white" />
        <path
          d="M20.0865 30C19.9626 30 19.8398 29.9716 19.728 29.9137L16.1267 28.0496C15.1038 27.5201 14.3044 26.9258 13.6812 26.2336C12.314 24.7195 11.5549 22.776 11.5418 20.7599L11.5005 14.1243C11.4955 13.3584 11.9888 12.671 12.7278 12.4122L19.3404 10.1068C19.7341 9.96656 20.172 9.9646 20.5707 10.0999L27.2085 12.3268C27.9515 12.5749 28.4539 13.2574 28.458 14.0223L28.5002 20.6628C28.5133 22.676 27.7804 24.6274 26.4353 26.1581C25.8171 26.8602 25.0258 27.4632 24.014 28.0025L20.4449 29.9088C20.3341 29.9686 20.2103 29.999 20.0865 30Z"
          fill="#F68E1E"
        />
        <path
          d="M20.7558 20.48L22.6515 20.505C23.0633 20.5105 23.392 20.1818 23.3865 19.77C23.3811 19.3583 23.0429 19.0201 22.6312 19.0147L20.7361 18.989L20.7112 17.0947C20.7057 16.6829 20.3676 16.3448 19.9558 16.3393C19.5441 16.3339 19.2154 16.6626 19.2208 17.0743L19.2458 18.9701L17.3501 18.9451C16.9383 18.9396 16.6097 19.2683 16.6151 19.68C16.6178 19.8866 16.7039 20.0739 16.8406 20.2106C16.9766 20.3466 17.1639 20.4327 17.3705 20.4354L19.2648 20.4604L19.2905 22.3554C19.2932 22.562 19.3786 22.7486 19.5152 22.8853C19.6519 23.022 19.8392 23.108 20.0458 23.1107C20.4576 23.1162 20.7862 22.7875 20.7808 22.3758L20.7558 20.48Z"
          fill="#FCAF17"
        />
        <path
          d="M20.7557 20.4796L22.6515 20.5046C23.0632 20.5101 23.3919 20.1814 23.3864 19.7696C23.381 19.3579 23.0429 19.0197 22.6311 19.0143L20.7361 18.9886L20.7111 17.0943C20.7056 16.6825 20.3675 16.3444 19.9557 16.3389C19.544 16.3335 19.2153 16.6622 19.2208 17.0739L19.2458 18.9697L17.35 18.9447C16.9383 18.9392 16.6096 19.2679 16.615 19.6796C16.6178 19.8862 16.7038 20.0735 16.8405 20.2102C16.9765 20.3462 17.1638 20.4323 17.3704 20.435L19.2647 20.46L19.2904 22.355C19.2931 22.5616 19.3785 22.7482 19.5152 22.8849C19.6518 23.0216 19.8392 23.1076 20.0457 23.1104C20.4575 23.1158 20.7861 22.7871 20.7807 22.3754L20.7557 20.4796Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
