import React from "react";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import dayjs from "dayjs";
import { EAppointmentStatus, EAppointmentType } from "@base/enums";
import TagAppointmentType from "@components/CommonStyles/TagAppointmentType";
import TagAppointmentStatus from "@components/CommonStyles/TagAppointmentStatus";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

interface IProps {
  appointment: IAppointment;
}

export default function AppointmentListItem({ appointment }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const bookingBtn = (
    <CommonStyles.ButtonUI
      type="primary"
      ghost
      onClick={() => navigate(baseRoutes.userAppointmentDetail.replace(":key", appointment.key ? (appointment.key as string) : ""))}
    >
      {t("user.profile.viewDetail")}
    </CommonStyles.ButtonUI>
  );

  return (
    <div className="w-full bg-common-background p-3 rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="flex items-center max-sm:flex-col max-sm:items-start gap-1">
          <span className="text-black text-base font-medium font-['Inter'] max-sm:line-clamp-1">{appointment.patient_record?.patient_name}</span>
          <span className="text-common-subtext text-xs font-normal font-['Inter'] max-sm:line-clamp-1">
            ({dayjs(appointment.patient_record?.patient_dob, "YYYY-MM-DD").format("DD/MM/YYYY")})
          </span>
        </div>

        <div>
          <TagAppointmentStatus status={appointment.status as EAppointmentStatus} />
        </div>
      </div>

      <Divider className="my-4" />

      <div className="flex justify-between items-start">
        <div className="flex items-start gap-4 max-lg:w-full">
          <img className="max-lg:flex-non h-[125px]" src={CommonImages.DefaultDoctorAvatar} alt="" />
          <div className="flex flex-col justify-between min-h-[125px] max-lg:flex-row max-lg:grow max-sm:flex-col">
            <div className="flex flex-col gap-1 max-lg:grow">
              <div className="max-md:hidden">
                <TagAppointmentType type={appointment.appointment_type as EAppointmentType} />
              </div>

              <div className="text-common-maintext text-base font-bold font-['Inter'] max-sm:line-clamp-1">{appointment.doctor?.doctor_name}</div>
              {appointment.status === EAppointmentStatus.PENDING && !appointment.payment_status ? (
                <span>
                  <span className="text-common-red500">(Chưa thanh toán)</span>
                </span>
              ) : null}

              <div className="lg:hidden">
                <div className="text-common-maintext text-base font-medium font-['Inter']">
                  {dayjs(appointment.time_table_period?.period_start_time).format("HH:mm")}
                </div>
                <div className="text-common-subtext text-xs font-normal font-['Inter']">
                  {dayjs(appointment.appointment_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
              </div>
            </div>

            <div className="items-end flex-none w-fit max-lg:flex max-lg:items-center">{bookingBtn}</div>

            {/* <div className="text-common-maintext text-xs font-normal font-['Inter']">Tư vấn da liễu</div> */}
          </div>
        </div>

        <div className="max-lg:hidden">
          <CommonStyles.DayCalendarUI value={dayjs(appointment.appointment_date, "YYYY-MM-DD").format("DD/MM/YYYY")} format="DD/MM/YYYY" />
        </div>
      </div>
    </div>
  );
}
