import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import CommonImages from "@base/assets/images";
import useToggle from "@base/hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import { EInComingCallStatus } from "@base/enums";
import CommonIcons from "@base/assets/icons";
import CommonStyles from "..";
import { setIncomingcall, setIncomingcallStatus } from "@base/store/reducers/stringeeClientSlice";

export default function VideoCall() {
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);
  const stringeeClient = useSelector((state: RootState) => state.stringeeClientSlice.stringeeClient);
  const incomingcall = useSelector((state: RootState) => state.stringeeClientSlice.incomingcall);
  const incomingcallStatus = useSelector((state: RootState) => state.stringeeClientSlice.incomingcallStatus);

  const { open, toggle } = useToggle();

  const [displayLocalVideo, setDisplayLocalVideo] = useState<boolean>(false);
  const [displayRemoteVideo, setDisplayRemoteVideo] = useState<boolean>(false);
  const [btnGroupRenderKey, setBtnGroupRenderKey] = useState<number>(0);

  useEffect(() => {
    if (incomingcall) {
      settingCallEvent(incomingcall);
    }
  }, [incomingcall]);

  useEffect(() => {
    if (incomingcallStatus === EInComingCallStatus.RECEIVED && !open) {
      toggle();
    }

    if (incomingcallStatus !== EInComingCallStatus.RECEIVED && open) {
      toggle();
    }
  }, [incomingcallStatus]);

  const settingCallEvent = (call?: any) => {
    call?.on("addlocaltrack", (localtrack?: any) => {
      let element = localtrack?.attach();
      document.getElementById("local_videos")?.appendChild(element);
      element.style.borderRadius = "8px";
      element.style.height = "156px";
      element.style.width = "156px";

      setDisplayLocalVideo(true);
    });

    call?.on("addremotetrack", (remoteTrack?: any) => {
      let element = remoteTrack?.attach();
      document.getElementById("remote_videos")?.appendChild(element);
      element.style.height = "648px";
      element.style.width = "864px";

      setDisplayRemoteVideo(true);
    });

    call?.on("removeremotetrack", (remoteTrack?: any) => {
      remoteTrack.detachAndRemove();
    });

    call?.on("removelocaltrack", (localtrack?: any) => {
      localtrack.detachAndRemove();
    });

    call?.on("signalingstate", (state?: any) => {
      let code = state?.code;

      switch (code) {
        case 6:
          // Ended
          // hide
          // todo
          dispatch(setIncomingcall(undefined));
          dispatch(setIncomingcallStatus(undefined));
          break;

        case 3:
          // Answered
          // todo
          break;

        case 5:
          // User busy
          // todo
          break;

        default:
          break;
      }
    });

    call?.on("mediastate", (state?: any) => {
      // todo
    });

    call?.on("otherdevice", (msg?: any) => {
      if (msg.type == "CALL2_STATE") {
        if (msg.code == 200 || msg.code == 486) {
          // hide
          // todo
          dispatch(setIncomingcall(undefined));
          dispatch(setIncomingcallStatus(undefined));
        }
      }
    });

    call?.on("info", (info?: any) => {
      // todo
    });
  };

  const onDisableVideo = () => {
    if (!incomingcall) {
      return;
    }

    if (incomingcall.localVideoEnabled) {
      incomingcall.enableLocalVideo(false);
      setDisplayLocalVideo(false);
    } else {
      incomingcall.enableLocalVideo(true);
      setDisplayLocalVideo(true);
    }

    setBtnGroupRenderKey(btnGroupRenderKey + 1);
  };

  const onMute = () => {
    if (!incomingcall) {
      return;
    }

    if (incomingcall.muted) {
      incomingcall.mute(false);
    } else {
      incomingcall.mute(true);
    }

    setBtnGroupRenderKey(btnGroupRenderKey + 1);
  };

  const onHangup = () => {
    if (incomingcall) {
      incomingcall.hangup((res?: any) => {
        // todo
      });
    }

    dispatch(setIncomingcall(undefined));
    dispatch(setIncomingcallStatus(undefined));

    toggle();
  };

  return (
    <Modal
      className="p-0"
      width={1278}
      height={682}
      styles={{
        body: { position: "relative" },
        content: { backgroundColor: "transparent", padding: 0, margin: 0, boxShadow: "none" },
        footer: { display: "none" },
        header: { display: "none" },
      }}
      open={open}
      closable={false}
      maskClosable
    >
      {/* remote video call */}
      <div
        className="w-[1278px] h-[682px]"
        style={{
          backgroundImage: `url(${CommonImages.BGVideoCall})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        style={{ display: displayRemoteVideo ? "flex" : "none" }}
        id="remote_videos"
        className="absolute top-0 left-0 w-[1278px] h-[682px] flex justify-center items-center"
      ></div>

      {/* doctor info */}
      <div className="absolute w-fit left-10 top-7 z-20 p-2 rounded-xl flex items-center gap-3 bg-gradient-to-r from-white from-100% to-white to-10%">
        <img src={CommonImages.DefaultDoctorAvatar} alt="" className="w-9 h-9" />
        <div className="flex flex-col">
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-sm text-common-maintext font-medium">
            {incomingcall?.fromNumber ?? ""}
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-xs text-common-maintext font-normal">
            Bác sĩ, Chuyên gia
          </CommonStyles.TypographyUI>
        </div>
      </div>

      <div style={{ display: displayRemoteVideo ? "none" : "flex" }} className="absolute top-[35%] w-full flex justify-center">
        <img
          src={CommonImages.DefaultDoctorAvatar}
          alt=""
          className="w-[186px] h-[186px] border-[16px] border-[#EAF3FF] border-opacity-50 rounded-full"
        />
      </div>

      {/* local video call */}
      <div className="absolute bg-[#CFEBFE] top-7 right-10 z-20 rounded-lg border-[2px] border-common-orange w-40 h-40 flex justify-center items-center">
        <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-[48px] text-[#2A3A8F] font-bold uppercase">
          {userInfo && userInfo.user_profiles && userInfo.user_profiles.display_name && userInfo.user_profiles.display_name.length > 0
            ? userInfo.user_profiles.display_name.charAt(0)
            : "A"}
        </CommonStyles.TypographyUI>
      </div>
      <div
        style={{ display: displayLocalVideo ? "block" : "none" }}
        id="local_videos"
        className="absolute top-7 right-10 z-30 rounded-lg border-[2px] border-common-orange w-40 h-40"
      ></div>

      <div key={btnGroupRenderKey} className="absolute w-full left-0 bottom-16 z-20 flex justify-center items-center gap-10">
        <div className="flex flex-col items-center gap-2">
          <CommonStyles.ButtonUI
            className="bg-gray-900 h-auto hover:!bg-gray-800 !p-3 !rounded-full border border-gray-500 text-common-white"
            onClick={onDisableVideo}
          >
            {incomingcall?.localVideoEnabled ? <CommonIcons.IconVideo /> : <CommonIcons.IconVideoOff />}
          </CommonStyles.ButtonUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base text-white font-normal">
            {incomingcall?.localVideoEnabled ? "Ẩn" : "Video"}
          </CommonStyles.TypographyUI>
        </div>

        <div className="flex flex-col items-center gap-2">
          <CommonStyles.ButtonUI
            className="bg-gray-900 h-auto hover:!bg-gray-800 !p-3 !rounded-full border border-gray-500 text-common-white"
            onClick={onMute}
          >
            {incomingcall?.muted ? <CommonIcons.IconMicOff /> : <CommonIcons.IconMic />}
          </CommonStyles.ButtonUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base text-white font-normal">
            {incomingcall?.muted ? "Bật tiếng" : "Tắt tiếng"}
          </CommonStyles.TypographyUI>
        </div>

        <div className="flex flex-col items-center gap-[7px]">
          <CommonStyles.ButtonUI
            className="bg-red-600 h-auto hover:!bg-red-500 !p-2 !rounded-full border border-gray-500 text-common-white"
            onClick={onHangup}
          >
            <CommonIcons.IconVideoCall />
          </CommonStyles.ButtonUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-base text-white font-normal">
            Kết thúc
          </CommonStyles.TypographyUI>
        </div>
      </div>
    </Modal>
  );
}
