import { useState } from "react";
import { getErrorMsg } from "@base/helpers/common";
import appointmentService from "../appointmentService";

const useCalCulateFeeAppt = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");
  const [data, setData] = useState({
    actual_fee: 0,
    discount: 0,
    other_fee: 0,
    tax: 0,
    total_fee: 0,
  });

  const handleFee = async (body: any, lang: string) => {
    setLoading(true);
    try {
      const result = await appointmentService.calculateFee(body, lang);
      return result;
    } catch (error) {
      setLoading(false);
      setError(getErrorMsg(error));
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    data,
    handleFee,
  };
};

export default useCalCulateFeeAppt;
