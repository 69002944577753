import { useCallback, useEffect, useState } from "react";
import wpCategoriesService from "../wpCategoriesService";
import { AxiosResponse } from "axios";
import { PostsCategory } from "@base/types/wp";

const useGetCategories = () => {
  const [data, setData] = useState<AxiosResponse<PostsCategory[]>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return wpCategoriesService.getCategories();
  }, []);

  const transformResponse = useCallback((response: AxiosResponse<PostsCategory[]>) => {
    if (response) {
      setData(response);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetCategories;
