import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

interface IProps {
  data?: IPatientRecordData;
}

export default function ProfileListItem({ data }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-common-background rounded-xl p-4 flex justify-between items-center">
      <div className="flex flex-col justify-start">
        <div className="text-common-maintext text-base font-medium font-['Inter']">{data?.patient_record?.patient_name}</div>
        <div className="text-common-subtext text-sm font-normal font-['Inter']">
          (
          <span>
            {data?.patient_record?.patient_gender === true
              ? t("user.profile.handler.male")
              : data?.patient_record?.patient_gender === false
              ? t("user.profile.handler.female")
              : "?"}
          </span>{" "}
          - <span>{data?.patient_record?.patient_dob ? dayjs(data?.patient_record?.patient_dob).format("DD/MM/YYYY") : null}</span>)
        </div>

        {data?.patient_record?.default_record ? (
          <div>
            <CommonStyles.TagUI className="border border-common-negative text-common-negative" tagName={t("user.profile.defaultRecord")} />
          </div>
        ) : null}
      </div>

      <div>
        <CommonStyles.ButtonUI
          type="primary"
          ghost
          onClick={() => navigate(baseRoutes.userProfileHandler.replace(":id", data?.patient_record?.id ? data?.patient_record?.id + "" : ""))}
        >
          {t("user.profile.viewDetail")}
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
