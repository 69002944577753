import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { optionProvince } from "@base/constants";
import { locateToLocationOnGGMap } from "@base/helpers/common";
import { ISite, ISiteBranch } from "@base/modules/site/site.interface";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function TabInfo({ site }: { site?: ISite }) {
  //! state
  const { t } = useTranslation();

  //! function

  //! render
  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div>
        <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">{t("Overview")}</CommonStyles.TypographyUI>
        {site?.description_html ? (
          <div className="pl-9 py-2 *:!max-w-full" dangerouslySetInnerHTML={{ __html: site.description_html }}></div>
        ) : (
          <div className="pl-9 py-2 text-common-maintext text-sm lg:text-base whitespace-pre-line">{site?.description}</div>
        )}
      </div>

      {/* <Divider /> */}

      <Divider />

      <div className="flex flex-col gap-4">
        {site?.site_branch && (
          <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">
            {t("SiteBranchs")}
          </CommonStyles.TypographyUI>
        )}

        <div className="grid md:grid-cols-2 gap-8 max-h-[700px] overflow-y-auto">
          {site?.site_branch ? (
            site?.site_branch.map((branch: ISiteBranch, index: number) => {
              return (
                <div key={index} className="rounded-2xl p-3 bg-common-background">
                  <div className="flex justify-between md:items-center mb-3">
                    <CommonStyles.TypographyUI className="text-base font-medium line-clamp-1">&#x2022; {branch.name}</CommonStyles.TypographyUI>
                    {/* <CommonStyles.ButtonUI
                      type="default"
                      className="hidden"
                      onClick={() => (branch.lon && branch.lat ? locateToLocationOnGGMap({ lon: branch.lon, lat: branch.lat }) : {})}
                    >
                      {t("Direct")}
                    </CommonStyles.ButtonUI> */}
                  </div>

                  <ul className="md:text-base flex flex-col gap-2 font-normal">
                    <li className="text-common-subtext line-clamp-1"> {branch.description}</li>
                  </ul>

                  <CommonStyles.ButtonUI
                    type="default"
                    className="mt-2"
                    onClick={() => (branch.lon && branch.lat ? locateToLocationOnGGMap({ lon: branch.lon, lat: branch.lat }) : {})}
                  >
                    {t("Direct")}
                  </CommonStyles.ButtonUI>
                </div>
              );
            })
          ) : (
            <div className="col-span-2 flex flex-col justify-center items-center gap-6 my-6">
              {/* <img src={CommonImages.EmptyRecordList} alt="" />

              <div className="text-common-maintext text-sm lg:text-base font-semibold font-['Inter']">{t("user.profile.recordNotFound")}!</div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
