import React from "react";

export default function IconLinkedin() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.3125" y="3.125" width="25.25" height="25.25" rx="3" fill="#C2C2C2" />
      <path
        d="M8.49034 10.976C8.11524 10.6278 7.9287 10.1967 7.9287 9.68375C7.9287 9.17083 8.11623 8.72077 8.49034 8.3715C8.86544 8.02323 9.34829 7.8486 9.93987 7.8486C10.5315 7.8486 10.9953 8.02323 11.3694 8.3715C11.7446 8.71977 11.9311 9.15785 11.9311 9.68375C11.9311 10.2096 11.7436 10.6278 11.3694 10.976C10.9943 11.3243 10.5185 11.4989 9.93987 11.4989C9.36125 11.4989 8.86544 11.3243 8.49034 10.976ZM11.6159 12.9738V23.6514H8.24293V12.9738H11.6159Z"
        fill="white"
      />
      <path
        d="M22.8439 14.0286C23.5792 14.8269 23.9463 15.9226 23.9463 17.3177V23.4628H20.743V17.7508C20.743 17.0473 20.5604 16.5004 20.1963 16.1112C19.8322 15.7221 19.3414 15.5265 18.7268 15.5265C18.1123 15.5265 17.6215 15.7211 17.2573 16.1112C16.8932 16.5004 16.7106 17.0473 16.7106 17.7508V23.4628H13.4884V12.9439H16.7106V14.339C17.0369 13.8739 17.4768 13.5067 18.0295 13.2363C18.5822 12.9658 19.2037 12.8311 19.895 12.8311C21.1261 12.8311 22.1097 13.2303 22.8439 14.0286Z"
        fill="white"
      />
    </svg>
  );
}
