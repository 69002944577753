import React from "react";

export default function IconSync() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 3.33332V1.84165C10.5 1.46665 10.05 1.28332 9.79171 1.54998L7.45837 3.87498C7.29171 4.04165 7.29171 4.29998 7.45837 4.46665L9.78337 6.79165C10.05 7.04998 10.5 6.86665 10.5 6.49165V4.99998C13.2584 4.99998 15.5 7.24165 15.5 9.99998C15.5 10.6583 15.375 11.3 15.1334 11.875C15.0084 12.175 15.1 12.5167 15.325 12.7417C15.75 13.1667 16.4667 13.0167 16.6917 12.4583C17 11.7 17.1667 10.8667 17.1667 9.99998C17.1667 6.31665 14.1834 3.33332 10.5 3.33332ZM10.5 15C7.74171 15 5.50004 12.7583 5.50004 9.99998C5.50004 9.34165 5.62504 8.69998 5.86671 8.12498C5.99171 7.82498 5.90004 7.48332 5.67504 7.25832C5.25004 6.83332 4.53337 6.98332 4.30837 7.54165C4.00004 8.29998 3.83337 9.13332 3.83337 9.99998C3.83337 13.6833 6.81671 16.6667 10.5 16.6667V18.1583C10.5 18.5333 10.95 18.7167 11.2084 18.45L13.5334 16.125C13.7 15.9583 13.7 15.7 13.5334 15.5333L11.2084 13.2083C10.95 12.95 10.5 13.1333 10.5 13.5083V15Z"
        fill="currentColor"
      />
    </svg>
  );
}
