import themeColor, { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import ArticleSearch from "./ArticleSearch";
import FacilitieSearch from "./FacilitieSearch";
import ServiceSearch from "./ServiceSearch";
import CommonImages from "@base/assets/images";
import { responseUI } from "@base/themes/response";
import { useTranslation } from "react-i18next";
import useGetTopKeywords from "@base/modules/allPageSearch/hooks/useGetTopKeywords";
import CommonIcons from "@base/assets/icons";
import useSearch from "@base/modules/allPageSearch/hooks/useSearch";
import { useEffect, useState } from "react";
import ISearchParams from "@base/modules/allPageSearch/searchParams.interface";
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

export default function Result() {
  //! state
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const keywordSearch = searchParams.get("keyword");

  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<ISearchParams>({ page_number: 0, page_size: 3, keyword: "", lang: i18n.resolvedLanguage });

  const { isLoading: isLoadingKeywords, data: keywords, error: keywordsError, refetch: refetchKeywords } = useGetTopKeywords(i18n.resolvedLanguage);
  const { isLoading, data, error, refetch } = useSearch(filters, false);

  //! function
  useEffect(() => {
    if (keywordSearch && keywordSearch?.length > 1) {
      setFilters((prev) => ({ ...prev, keyword: searchParams.get("keyword") ?? "" }));
    }
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [filters]);

  //! render
  const renderTags = () => {
    return keywords?.length ? (
      <div className="flex flex-wrap gap-3">
        {keywords.map((keyword, index) => {
          return keyword.keyword ? (
            <CommonStyles.TagUI
              key={index}
              tagName={keyword.keyword}
              className="px-4 py-2 opacity-80 m-0 cursor-pointer"
              style={{ borderColor: searchParams.get("keyword") === keyword.keyword ? themeColor.orange500 : "inherit" }}
              onClick={() => (keyword.keyword ? setSearchParams({ keyword: keyword.keyword }) : setSearchParams())}
            />
          ) : null;
        })}
      </div>
    ) : null;
  };

  const renderRightSide = () => {
    return (
      <div className="w-full flex flex-col gap-8 px-7 pt-2 pb-5 rounded-lg bg-common-white h-fit">
        <div>
          <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title} text-nowrap`}>
            Tìm kiếm theo
          </CommonStyles.TypographyUI>

          {renderTags()}
        </div>

        <div className="border-common-orange border max-lg:hidden">
          <div
            className="m-3 px-3 py-7 flex flex-col items-center text-center gap-4"
            style={{
              backgroundImage: `url(${CommonImages.bgMedicalDownload})`,
              minHeight: 200,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img className="w-24" src={CommonImages.logoMedicalDownload} alt={CommonImages.logoMedicalDownload} />
            <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext">
              Đặt lịch khám - chữa bệnh tại 365 Medihome giúp chủ động thời gian, tiết kiệm chi phí.
            </CommonStyles.TypographyUI>
            <div>
              <CommonStyles.ButtonUI className="h-10 font-medium" onClick={() => navigate(baseRoutes.orderFacility)}>
                Đặt lịch ngay
              </CommonStyles.ButtonUI>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDataSearch = () => {
    if (isLoading) {
      return <CommonStyles.LoadingUI />;
    }
    if (
      keywordSearch === "" ||
      (keywordSearch && keywordSearch?.length <= 1) ||
      (!data?.news?.total_records && !data?.site_special_packages?.total_records && !data?.sites?.total_records)
    ) {
      return (
        <div className="flex flex-col justify-center items-center">
          <CommonIcons.IconNotFound className="max-xs:w-1/2 max-xs:h-1/2 w-2/3 h-2/3" />
          <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext !font-medium text-sm lg:text-base !my-3">
            Không tìm thấy dữ liệu phù hợp!
          </CommonStyles.TypographyUI>
        </div>
      );
    } else {
      return (
        <>
          {data?.news?.total_records ? <ArticleSearch data={data.news} /> : null}

          {data?.sites?.total_records ? <FacilitieSearch data={data.sites} /> : null}

          {data?.site_special_packages?.total_records ? <ServiceSearch data={data.site_special_packages} /> : null}
        </>
      );
    }
  };

  return (
    <div className={`grid grid-cols-3 gap-8 mt-6 py-10 max-lg:mt-0 max-lg:pt-5 max-lg:pb-0 max-lg:gap-4 ${initialPage.paddingX}`}>
      <div className="max-lg:col-span-3 lg:hidden flex flex-col gap-2">
        <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title} text-nowrap`}>
          Tìm kiếm theo
        </CommonStyles.TypographyUI>

        <div className="w-full flex flex-col gap-8 p-2 rounded-lg bg-common-white h-fit">{renderTags()}</div>
      </div>

      <div className="col-span-2 max-lg:col-span-3 flex flex-col gap-6">
        <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title} text-nowrap`}>
          Kết quả tìm kiếm
        </CommonStyles.TypographyUI>

        {renderDataSearch()}
        {/* {!data?.news?.total_records && !data?.site_special_packages?.total_records && !data?.sites?.total_records ? (
          <div className="flex flex-col justify-center items-center">
            <CommonIcons.IconNotFound className="max-xs:w-1/2 max-xs:h-1/2 w-2/3 h-2/3" />
            <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext !font-medium text-sm lg:text-base !my-3">
              Không tìm thấy dữ liệu phù hợp!
            </CommonStyles.TypographyUI>
          </div>
        ) : (
          <>
            {data?.news?.total_records ? <ArticleSearch data={data.news} /> : null}

            {data?.sites?.total_records ? <FacilitieSearch data={data.sites} /> : null}

            {data?.site_special_packages?.total_records ? <ServiceSearch data={data.site_special_packages} /> : null}
          </>
        )} */}
      </div>

      <div className="max-lg:hidden">{renderRightSide()}</div>
    </div>
  );
}
