export default function IconMedalStar() {
  return (
    <div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.0703 3.26114C11.7718 3.09834 11.4111 3.09834 11.1126 3.26114L1.79906 8.34125C1.27781 8.62557 1.27794 9.37409 1.79929 9.65822L4.59145 11.18V16.5865C4.59145 16.9523 4.79126 17.289 5.11243 17.4643L11.1124 20.7385C11.411 20.9015 11.7719 20.9015 12.0705 20.7385L18.0705 17.4643C18.3916 17.289 18.5915 16.9523 18.5915 16.5865V11.18L20.5915 10.09V16C20.5915 16.5522 21.0392 17 21.5915 17C22.1437 17 22.5915 16.5522 22.5915 16V9.44518C22.5915 9.17072 22.4415 8.91818 22.2006 8.78675L12.0703 3.26114ZM18.0896 8.82437C18.2285 8.90017 18.2285 9.09973 18.0896 9.17553L11.6872 12.6677C11.6275 12.7003 11.5554 12.7003 11.4957 12.6677L5.09335 9.17553C4.95438 9.09973 4.95438 8.90017 5.09335 8.82437L11.4957 5.33219C11.5554 5.29963 11.6275 5.29963 11.6872 5.33219L18.0896 8.82437ZM16.5915 15.8713C16.5915 15.9444 16.5515 16.0118 16.4873 16.0468L11.6873 18.6676C11.6276 18.7002 11.5553 18.7002 11.4956 18.6676L6.69561 16.0468C6.6314 16.0118 6.59145 15.9444 6.59145 15.8713V12.607C6.59145 12.4552 6.75402 12.3587 6.8873 12.4315L11.232 14.8037C11.456 14.926 11.7269 14.926 11.9509 14.8037L16.2956 12.4315C16.4289 12.3587 16.5915 12.4552 16.5915 12.607V15.8713Z"
          fill="#5A6B81"
        />
      </svg>
    </div>
  );
}
