import React from "react";

export default function K30() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.573242" y="0.833954" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.57324" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27217)">
          <path
            d="M75.8399 50.259C75.3863 49.6308 64.5203 34.8688 50.5741 34.8688C36.6294 34.8688 25.7634 49.6325 25.3082 50.259C24.698 51.0992 24.698 52.2364 25.3082 53.0734C25.7634 53.7064 36.6294 68.4668 50.5741 68.4668C64.5203 68.4668 75.3863 53.7048 75.8399 53.0734C76.4501 52.2364 76.4501 51.0976 75.8399 50.259ZM46.5687 48.5344C45.7023 49.7581 45.5 51.3521 45.9683 53.2773C46.2815 54.5646 45.4935 55.8584 44.2062 56.17C44.0169 56.2173 43.8244 56.2336 43.6384 56.2336C42.5584 56.2336 41.5778 55.4978 41.3119 54.4047C40.2432 50.0077 41.6104 47.1166 42.9434 45.4231C44.7658 43.108 47.5998 41.9072 49.3439 41.9072H49.3488C50.6719 41.9072 51.7455 42.935 51.7422 44.2582C51.739 45.5683 50.685 46.606 49.3814 46.6288C48.9556 46.6745 47.5035 47.2145 46.5687 48.5344ZM55.5584 59.8556C53.8958 59.8556 52.5498 58.5112 52.5498 56.8487C52.5498 55.1845 53.8958 53.8369 55.5584 53.8369C57.2209 53.8369 58.5685 55.1845 58.5685 56.8487C58.5669 58.5096 57.2193 59.8556 55.5584 59.8556ZM30.3138 51.667C32.4543 49.1234 37.4452 43.7998 43.6221 41.1795C40.0491 43.4131 37.6719 47.381 37.6719 51.9068C37.6719 55.6593 39.3084 59.0301 41.9074 61.3436C36.5282 58.5585 32.252 53.9691 30.3138 51.667ZM57.9681 61.9684C60.9995 59.6582 62.9622 56.0134 62.9622 51.9068C62.9622 46.9372 60.0956 42.6397 55.9271 40.5742C62.8334 42.873 68.5209 48.913 70.8425 51.6702C68.7525 54.1551 63.9509 59.2927 57.9681 61.9684Z"
            fill="url(#paint0_linear_1205_27217)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27217"
            x="16.8506"
            y="30.8688"
            width="67.4468"
            height="49.598"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27217" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27217" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27217" x1="62.551" y1="34.8193" x2="34.852" y2="66.5335" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
