import React, { useEffect, useMemo, useState } from "react";
import CommonIcons from "@base/assets/icons";
import themeColor from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import Result from "@components/Search/Result";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

export default function Search() {
  //! state
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const keywordSearch = searchParams.get("keyword");
  const [searchValue, setSearchValue] = useState<string>("");
  //! function
  const handleSetSearchValue = (e: any) => {
    setSearchValue(e?.target?.value);
    // if (e.target.value) {
    onSearchDebounce(e?.target?.value.trim());
    // }
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setSearchParams({ keyword: value });
      }, 1000),
    [],
  );

  //! effect
  useEffect(() => {
    if (keywordSearch) {
      setSearchValue(keywordSearch);
    }
  }, [keywordSearch]);
  //! render
  return (
    <div>
      <div className="w-full bg-common-white">
        <div className={`pb-8 relative ${responseUI.screen} py-5 flex flex-col ${responseUI.padding}`}>
          <div className={`lg:mt-10 `}>
            <div className="text-center">
              <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title} text-nowrap`}>
                Tra cứu nhanh
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-xs lg:text-base">
                Nắm bắt nhanh chóng các thông tin y tế chính xác, tham khảo trải nghiệm khám chữa bệnh từ các người dùng khác
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-center w-full">
              <div className="flex justify-center items-center gap-4 w-full md:w-3/4 xl:w-4/5">
                <div className="grow">
                  <CommonStyles.InputUI
                    className="!w-full h-12"
                    variant="filled"
                    prefix={<CommonIcons.SearchOutlined style={{ fontSize: 16, color: themeColor.orange }} />}
                    placeholder="Tìm kiếm Thông tin, Cơ sở y tế, Gói khám"
                    // value={keywordSearch ?? undefined}
                    value={searchValue}
                    onChange={handleSetSearchValue}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className={`pb-5 ${responseUI.screen} ${responseUI.padding}`}>
        <Result />
      </div>
    </div>
  );
}
