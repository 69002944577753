import CommonIcons from "@base/assets/icons";
import { optionAppointmentTypeString } from "@base/constants";
import { LocalKey } from "@base/constants/LocalKey";
import { IAppointment, ITimeTablePeriod } from "@base/modules/appointment/appointment.interface";
import doctorService from "@base/modules/doctor/doctorService";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import { Divider, Form } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { IDataLocalAppointment } from "@base/types";
import useFormValidation from "@base/hooks/useFormvalidation";
import useParseOption from "@base/hooks/useParseOptions";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import useGetDrServiceListShow from "@base/modules/drService/hooks/useGetDrServiceListShow";
import IFiltersDrService from "@base/modules/drService/interface/filterDrService.interface";
import useGetDoctors from "@base/modules/doctor/hooks/useGetDoctors";
import IFilterDcotor from "@base/modules/doctor/interface/filterDoctor.interafce";
import useGetSpecializetation from "@base/modules/specialization/hooks/useGetSpecialization";
import { RangePickerProps } from "antd/es/date-picker";
import { ISpecialPackage } from "@base/modules/patientSpecialPackage/patientSpecialPackage.interface";
import patientSpecialPackageService from "@base/modules/patientSpecialPackage/patientSpecialPackageService";
import { CompareDateWithhNow } from "@base/helpers/common";
import { isEmpty } from "lodash";
import useGetSymptonList from "@base/modules/symptom/hooks/useGetSympton";
import useGetDoctorCodeLess from "@base/modules/doctor/hooks/useGetDoctorCodeLess";
import { ResponseDetailDoctor } from "@base/modules/doctor/interface/doctor.interface";

export default function Step1() {
  //! state
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const booleanOnline = type === "online" ? true : false;
  const stringAPPT = type === "online" ? "ONLINE" : "OFFLINE";
  const default_site_code = searchParams.get("site_code");
  const text_site = searchParams.get("text_site");
  const default_doctor_code = searchParams.get("doctor_code");
  const default_specialization_code = searchParams.get("specialization_code");
  const default_symptoms = searchParams.get("symptoms");
  const package_name = searchParams.get("package_name");
  const package_key = searchParams.get("package_key");
  const expected_date = searchParams.get("expected_date");
  const id_implement = searchParams.get("id_implement");
  const resultCompare = CompareDateWithhNow(String(expected_date));
  let dateExp = resultCompare < 0 ? dayjs().format("YYYY-MM-DD") : String(expected_date);
  const { t, i18n } = useTranslation();
  const { appointmentStep1 } = useFormValidation();

  const [optionTime, setOptionTime] = useState<ITimeTablePeriod[]>([]);
  const [selectedTime, setSelectedTime] = useState<ITimeTablePeriod | undefined>();

  const [form] = Form.useForm();
  const initialValues = {
    appointment_type: type === "online" ? "ONLINE" : "OFFLINE",
    site_code: null,
    specialization_code: null,
    dr_service_code: null,
    doctor_code: null,
    appointment_date: null,
    symptoms: null,
  };
  const { data: dataSite } = useGetListSite({
    page_number: 0,
    page_size: 50,
    district_code: "",
    search_text: text_site ?? "",
    state_id: "",
    supported_seller: "",
    ward_code: "",
  });
  const optionSites = useParseOption(dataSite?.data.content_page, "name", "code");

  const [filtersDrService, setFilterDrService] = useState<IFiltersDrService>({
    lang: "vi",
    online: type === "online" ? true : false,
    siteCode: String(default_site_code),
    specialization_code: String(default_specialization_code) !== "null" ? String(default_specialization_code) : undefined,
  });
  const { data: dataDrService, refetch: refetchDrService, isLoading: loadingDrService } = useGetDrServiceListShow(filtersDrService, false);

  const [filtersDoctor, setFiltersDoctor] = useState<IFilterDcotor>({
    page: 0,
    size: 50,
    site_code: filtersDrService.siteCode,
    lang: "vi",
    appt_date: !!expected_date ? dateExp : undefined,
    online: booleanOnline,
  });
  const { data: dataDoctors, refetch: refetchDoctor, isLoading: loadingDoctor } = useGetDoctors(filtersDoctor, false);

  const [specialPackage, setSpecialPackage] = useState<ISpecialPackage>({});

  const [filterSymptom, setFilterSymptom] = useState({
    lang: "vi",
    specialization_id: "",
  });
  const { data: dataSymptom, refetch: refetchSymptom, isLoading: loadingSymptom } = useGetSymptonList(filterSymptom);

  //! function

  const optionDrService = useParseOption(dataDrService?.data, "name", "code");

  const optionDoctor = useParseOption(dataDoctors?.data.content_page, "doctor_name", "doctor_code");

  const initData = async () => {
    const apptLocalStr = localStorage.getItem(LocalKey.BOOKING_APPOINTMENT);
    const apptLocal: IDataLocalAppointment = apptLocalStr ? JSON.parse(apptLocalStr) : undefined;

    const appt_type = apptLocal?.appointment_type ?? stringAPPT;
    const site_code = default_site_code ?? apptLocal?.site_code;
    const doctor_code = apptLocal?.doctor_code ?? default_doctor_code;
    const symptoms_text = default_symptoms ?? apptLocal?.symptoms;
    const drService = package_name ?? apptLocal?.dr_service_code;
    const expected_date_value = expected_date ? dayjs(dateExp) : dayjs(apptLocal?.appointment_date);
    form.setFields([
      { name: "appointment_type", value: appt_type },
      { name: "site_code", value: site_code },
      { name: "dr_service_code", value: drService },
      { name: "doctor_code", value: doctor_code },
      { name: "appointment_date", value: expected_date_value },
      { name: "symptoms", value: symptoms_text },
    ]);
    if (apptLocal?.payload?.time_table_period) {
      getTimeTable(form.getFieldValue("doctor_code"), form.getFieldValue("appointment_date").format("YYYY-MM-DD"));
      setSelectedTime(apptLocal.payload.time_table_period);
    }
  };

  const onChangeAppointmentType = (event: any) => {
    const typeTarget = event.target.value;
    form.setFields([
      { name: "appointment_type", value: typeTarget },
      { name: "dr_service_code", value: null },
      { name: "doctor_code", value: null },
      { name: "appointment_date", value: null },
      { name: "symptoms", value: null },
    ]);
    const typeBoolean = typeTarget === "ONLINE" ? true : false;
    setFilterDrService((prev) => ({ ...prev, online: typeBoolean }));
    setFiltersDoctor((prev) => ({ ...prev, online: typeBoolean }));
    setSelectedTime(undefined);
    setOptionTime([]);
  };

  const onChangeSite = (value: any) => {
    setFilterDrService((prev) => ({ ...prev, siteCode: value }));
    form.setFields([
      { name: "dr_service_code", value: null },
      // { name: "doctor_code", value: undefined },
      // { name: "appointment_date", value: undefined },
    ]);
  };

  const onChangeDrService = (value: any) => {
    const findService = dataDrService?.data.find((item) => item.code === value);
    setFiltersDoctor((prev) => ({ ...prev, service_code: value, specialization_code: findService?.supported_specialization }));
    form.setFieldValue("appointment_date", dayjs());
    if (!default_doctor_code) {
      form.setFieldValue("doctor_code", null);
    }
    if (default_doctor_code) {
      getTimeTable(form.getFieldValue("doctor_code"), form.getFieldValue("appointment_date").format("YYYY-MM-DD"));
    }
    setOptionTime([]);
    setSelectedTime(undefined);
  };

  const onChangeDoctor = async (value: string) => {
    try {
      const result: ResponseDetailDoctor = await doctorService.getByCodeLess(value);
      form.setFields([{ name: "appointment_date", value: dayjs() }]);
      setFilterSymptom((prev) => ({ ...prev, specialization_id: String(result.data.specialization?.id) }));

      if (!!expected_date) {
        getTimeTable(form.getFieldValue("doctor_code"), form.getFieldValue("appointment_date").format("YYYY-MM-DD"));
      } else {
        form.setFields([{ name: "appointment_date", value: dayjs() }]);

        getTimeTable(form.getFieldValue("doctor_code"), form.getFieldValue("appointment_date").format("YYYY-MM-DD"));
      }
    } catch (error) {}
  };

  const onChangeApptDate = (date: Dayjs) => {
    if (form.getFieldValue("doctor_code") && form.getFieldValue("appointment_date")) {
      getTimeTable(form.getFieldValue("doctor_code"), form.getFieldValue("appointment_date").format("YYYY-MM-DD"));
    }
  };

  const getTimeTable = async (doctor_code: string, assign_date: string) => {
    try {
      const resp = await doctorService.getTimeTable(doctor_code, assign_date);
      if (resp.data.time_table_period?.length) {
        let times: ITimeTablePeriod[] = [];
        resp.data.time_table_period.forEach((p: ITimeTablePeriod) => {
          if (p.period_start_time && dayjs(p.period_start_time).isAfter(dayjs())) {
            times.push(p);
          }
        });

        setOptionTime(times);
      } else {
        setOptionTime([]);
      }
    } catch (error) {}
  };

  const handleSelectTime = (value: ITimeTablePeriod) => {
    setSelectedTime(value);
  };

  const handleNextStep = () => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        step: "2",
        patient: isEmpty(specialPackage) ? "" : String(specialPackage.patient_record?.id),
      }).toString(),
    });
  };

  const handleSubmit = () => {
    const payload: IAppointment = {};
    payload.appointment_type = form.getFieldValue("appointment_type");
    payload.appointment_date = form.getFieldValue("appointment_date") ? form.getFieldValue("appointment_date").format("YYYY-MM-DD") : undefined;
    payload.symptoms = form.getFieldValue("symptoms");
    payload.time_table_period = selectedTime;

    const site =
      dataSite?.data.content_page &&
      dataSite?.data.content_page.find((s) => {
        return s.code === form.getFieldValue("site_code");
      });
    payload.site_code = site?.code;
    payload.site_name = site?.name;
    payload.site_type = site?.site_type;

    let drService = dataDrService?.data.find((drs) => {
      return drs.code === form.getFieldValue("dr_service_code");
    });
    drService = { ...drService, service_id: Number(drService?.id) };
    if (!!package_key) {
      payload.appointment_used_services = [
        {
          code: specialPackage.package_code,
          name: specialPackage.package_name,
          note: specialPackage.note,
          package_type: specialPackage.package_type,
          patient_special_package_detail_implement_id: String(id_implement),
          service_type: form.getFieldValue("appointment_type"),
          description: specialPackage.description,
        },
      ];
    } else {
      payload.appointment_used_services = drService ? [drService] : undefined;
    }
    const doctor =
      dataDoctors?.data.content_page &&
      dataDoctors?.data.content_page.find((d) => {
        return d.doctor_code === form.getFieldValue("doctor_code");
      });
    payload.doctor = doctor;
    localStorage.setItem(
      LocalKey.BOOKING_APPOINTMENT,
      JSON.stringify({
        ...form.getFieldsValue(),
        payload,
      }),
    );

    handleNextStep();
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().subtract(1, "day").endOf("day");
  };
  const getSpecialPackage = async () => {
    if (!package_key) {
      return;
    }

    try {
      const resp = await patientSpecialPackageService.getDetail(package_key);
      setSpecialPackage(resp.data);
    } catch (error) {}
  };

  const handleSelectSymptom = (value: string) => {
    const valueFormSymptom = form.getFieldValue("symptoms");
    form.setFieldValue("symptoms", `${valueFormSymptom ?? ""} ${value}`);
  };

  useEffect(() => {
    refetchDrService();
  }, [filtersDrService]);

  useEffect(() => {
    refetchDoctor();
  }, [filtersDoctor]);

  useEffect(() => {
    refetchSymptom();
  }, [filterSymptom]);

  useEffect(() => {
    initData();
    getSpecialPackage();
  }, []);

  //! render
  const renderOptionTime = () => {
    return (
      <div className="grid grid-cols-6 gap-x-4 gap-y-2 max-h-[270px] overflow-y-auto">
        {optionTime.map((item, index) => {
          const checked = selectedTime && selectedTime.id === item.id ? "border-common-orange bg-common-orange100 text-common-orange" : "";
          return (
            <div
              onClick={() => {
                handleSelectTime(item);
              }}
              className={`border border-common-divider rounded-md text-center py-3 cursor-pointer text-sm ${checked}`}
              key={index}
            >
              {dayjs(item.period_start_time).format("HH:mm")}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSymptoms = () => {
    return dataSymptom?.data.map((item, index) => {
      return (
        <CommonStyles.TagUI
          className="cursor-pointer"
          onClick={() => handleSelectSymptom(item.symptom_name)}
          tagName={item.symptom_name}
          key={index}
        />
      );
    });
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit} className="bg-common-white p-8 rounded-s-lg flex flex-col">
      <div className="flex flex-col">
        <CommonStyles.FormFieldUI
          name="appointment_type"
          component={
            <CommonStyles.RadioUI
              className="ml-3"
              label={t(`Appointment.method`)}
              direction="horizontal"
              options={optionAppointmentTypeString}
              onChange={onChangeAppointmentType}
              disabled={!!type}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="site_code"
          rules={appointmentStep1.facility()}
          component={
            <CommonStyles.SelectUI
              labelStyle="text-common-maintext text-sm"
              label={t(`Appointment.facility`)}
              disabled={default_site_code ? true : false}
              options={optionSites}
              variant="filled"
              required
              onChange={onChangeSite}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="dr_service_code"
          rules={appointmentStep1.service()}
          component={
            !isEmpty(specialPackage) ? (
              <CommonStyles.InputUI label={t(`Appointment.service`)} disabled variant="filled" />
            ) : (
              <CommonStyles.SelectUI
                labelStyle="text-common-maintext text-sm"
                label={t(`Appointment.service`)}
                options={optionDrService}
                variant="filled"
                onChange={onChangeDrService}
                required
              />
            )
          }
        />

        <CommonStyles.FormFieldUI
          name="doctor_code"
          rules={appointmentStep1.doctor()}
          component={
            <CommonStyles.SelectUI
              labelStyle="text-common-maintext text-sm"
              label={t(`Appointment.doctor`)}
              placeholder="Chọn bác sĩ mong muốn"
              disabled={!!default_doctor_code}
              options={optionDoctor}
              variant="filled"
              onChange={(value: string) => {
                onChangeDoctor(value);
              }}
              required
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="appointment_date"
          rules={appointmentStep1.date()}
          component={
            <CommonStyles.DatePickerUI
              labelStyle="text-common-maintext text-sm"
              label={t(`Appointment.date`)}
              variant="filled"
              format="DD/MM/YYYY"
              onChange={onChangeApptDate}
              disabledDate={disabledDate}
              required
            />
          }
        />

        <div className="mb-4">
          <div className="flex justify-between items-centers mb-2">
            <div className="flex gap-1">
              <CommonStyles.TypographyUI use="paragraph"> {t(`Appointment.hour`)}</CommonStyles.TypographyUI>
              <span className="text-common-red500">{` *`}</span>
            </div>

            {/* <CommonStyles.ButtonUI type="default" className="text-common-orange">
              Buổi sáng
              <div className="flex flex-col items-center justify-center">
                <CommonIcons.UpOutlined style={{ fontSize: 8 }} />
                <CommonIcons.DownOutlined style={{ fontSize: 8 }} />
              </div>
            </CommonStyles.ButtonUI> */}
          </div>

          {optionTime?.length > 0 && renderOptionTime()}
        </div>

        <CommonStyles.FormFieldUI
          name="symptoms"
          rules={appointmentStep1.symptoms()}
          component={
            <CommonStyles.TextAreaUI
              labelStyle="text-common-maintext text-sm"
              label={t(`Appointment.symptoms`)}
              required
              // disabled={!!default_symptoms}
              variant="filled"
              placeholder="Nhập triệu chứng của bạn cho chúng tôi ..."
            />
          }
        />

        <div className="flex flex-wrap gap-y-3">{renderSymptoms()}</div>
      </div>
      {/* <CommonStyles.FormAntdDebugs /> */}

      <Divider className="my-4" />

      <div className="flex justify-end items-center">
        {/* <CommonStyles.TypographyUI className="text-base text-common-subtext">1/4</CommonStyles.TypographyUI> */}
        <CommonStyles.ButtonUI htmlType="submit" className="h-12 font-medium text-base" icon={<CommonIcons.IconArrowRight />} iconPosition="end">
          Tiếp tục
        </CommonStyles.ButtonUI>
      </div>
    </Form>
  );
}
