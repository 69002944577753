export default function IconSuccess() {
  return (
    <div>
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#D2E9DB" />
        <path
          d="M32 18.6667C24.64 18.6667 18.6667 24.64 18.6667 32C18.6667 39.36 24.64 45.3334 32 45.3334C39.36 45.3334 45.3333 39.36 45.3333 32C45.3333 24.64 39.36 18.6667 32 18.6667ZM28.3867 37.72L23.6 32.9334C23.08 32.4134 23.08 31.5734 23.6 31.0534C24.12 30.5334 24.96 30.5334 25.48 31.0534L29.3333 34.8934L38.5067 25.72C39.0267 25.2 39.8667 25.2 40.3867 25.72C40.9067 26.24 40.9067 27.08 40.3867 27.6L30.2667 37.72C29.76 38.24 28.9067 38.24 28.3867 37.72Z"
          fill="#27AE60"
        />
      </svg>
    </div>
  );
}
