import React from "react";

export default function IconMail() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.4042 7.59583H7.59583C6.44026 7.59583 5.5053 8.5413 5.5053 9.69688L5.49479 22.3031C5.49479 23.4587 6.44026 24.4042 7.59583 24.4042H24.4042C25.5597 24.4042 26.5052 23.4587 26.5052 22.3031V9.69688C26.5052 8.5413 25.5597 7.59583 24.4042 7.59583ZM23.984 12.0605L16.5568 16.7038C16.2206 16.914 15.7794 16.914 15.4432 16.7038L8.01604 12.0605C7.9107 12.0014 7.81846 11.9215 7.74489 11.8257C7.67132 11.7299 7.61796 11.6201 7.58803 11.5031C7.55811 11.3861 7.55224 11.2642 7.57079 11.1448C7.58933 11.0254 7.63191 10.9111 7.69593 10.8086C7.75995 10.7062 7.8441 10.6178 7.94327 10.5488C8.04244 10.4799 8.15458 10.4317 8.27289 10.4073C8.3912 10.383 8.51324 10.3828 8.6316 10.407C8.74996 10.4311 8.8622 10.479 8.96151 10.5478L16 14.9495L23.0385 10.5478C23.1378 10.479 23.25 10.4311 23.3684 10.407C23.4868 10.3828 23.6088 10.383 23.7271 10.4073C23.8454 10.4317 23.9576 10.4799 24.0567 10.5488C24.1559 10.6178 24.24 10.7062 24.3041 10.8086C24.3681 10.9111 24.4107 11.0254 24.4292 11.1448C24.4478 11.2642 24.4419 11.3861 24.412 11.5031C24.382 11.6201 24.3287 11.7299 24.2551 11.8257C24.1815 11.9215 24.0893 12.0014 23.984 12.0605Z"
        fill="currentColor"
      />
    </svg>
  );
}
