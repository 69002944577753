import React from "react";
import themeColor from "@base/themes/colorTheme";

interface IProps {
  content?: React.ReactNode;
  textColor?: string;
  borderColor?: string;
  bgColor?: string;
  className?: string;
}

export default function TimelineDotUI(props: IProps) {
  const { content = null, textColor = themeColor.blue500, borderColor = themeColor.blue500, bgColor = themeColor.blue100, className = "" } = props;

  return (
    <div style={{ backgroundColor: bgColor, borderColor: borderColor, color: textColor }} className={`border rounded-[50%] ${className}`}>
      {content}
    </div>
  );
}
