import React from "react";
import { EOrderStatus } from "@base/enums";
import CommonStyles from "..";

interface IProps {
  status: EOrderStatus;
}

export default function TagOrderStatus({ status }: IProps) {
  const getTagName = () => {
    switch (status) {
      case EOrderStatus.UNPAID:
        return "Chờ thanh toán";
      case EOrderStatus.PAID:
        return "Đã thanh toán";
      case EOrderStatus.REFUND:
        return "Đã hoàn tiền";
      case EOrderStatus.CANCELLED:
        return "Đã hủy";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case EOrderStatus.UNPAID:
        return "bg-inherit text-common-negative text-xs font-medium border-none p-1 rounded";
      case EOrderStatus.PAID:
        return "bg-inherit text-common-positive text-xs font-medium border-none p-1 rounded";
      case EOrderStatus.REFUND:
        return "bg-inherit text-common-blue500 text-xs font-medium border-none p-1 rounded";
      case EOrderStatus.CANCELLED:
        return "bg-inherit text-common-negative text-xs font-medium border-none p-1 rounded";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
