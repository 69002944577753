import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { BankCode, ECollaborationType } from "@base/enums";
import { ResponseCommon } from "@base/modules/common/interface/Common.interface";
import { ResponseHomeModule } from "@base/modules/homeModules/interfaces/IHomeModule.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import { TMenu, TOption } from "@base/types";
import { DefaultOptionType } from "antd/es/select";

export const languageApp = [
  {
    title: "VN",
    value: "vi",
    code: "vi-VN",
    img: CommonImages.VNLanguage,
    display: CommonImages.VNLanguageCircle,
  },
  {
    title: "EN",
    value: "en",
    code: "en-EN",
    img: CommonImages.ENLanguage,
    display: CommonImages.ENLanguageCircle,
  },
];

export const optionContribues: TOption[] = [
  { label: "Partner.Form.rdFacility", value: ECollaborationType.SITE },
  { label: "Partner.Form.rdContribute", value: ECollaborationType.PERSONAL },
];

export const optionProvince: DefaultOptionType[] = [
  { label: "Hải Dương", value: 1 },
  { label: "Hà Nội", value: 2 },
];

export const optionGender: TOption[] = [
  { label: "Nam", value: true },
  { label: "Nữ", value: false },
];

export const optionEthics: TOption[] = [
  { label: "Việt Nam", value: "25" },
  { label: "Nước Ngoài", value: "55" },
];

export const optionBloodGroup: DefaultOptionType[] = [
  { label: "A-", value: "A-" },
  { label: "A+", value: "A+" },
  { label: "B-", value: "B-" },
  { label: "B+", value: "B+" },
  { label: "AB", value: "AB" },
  { label: "O-", value: "O-" },
  { label: "O+", value: "O+" },
];

export const menu = (baseRoutes: any, dataCommon?: ResponseHomeModule): TMenu[] => {
  return [
    {
      href: baseRoutes.home,
      label: "menuApp.Home",
    },

    {
      label: "menuApp.Booking",
      children: [
        {
          href: baseRoutes.orderFacility,
          label: "menuApp.Order_At_Facility",
        },
        {
          href: baseRoutes.bookingOnline,
          label: "menuApp.Order_Online",
        },
        {
          href: baseRoutes.doctor,
          label: "menuApp.Order_Doctor",
        },
      ],
    },
    {
      href: baseRoutes.bookingPackage,
      label: "menuApp.Order_Package",
    },
    {
      href: baseRoutes.medicine,
      label: "menuApp.Medicines",
    },
    {
      href: baseRoutes.medicalInfo,
      label: "menuApp.News",
    },
    {
      href: baseRoutes.partner,
      label: "menuApp.Partner",
    },
  ];
};

export const optionAppointmentType: TOption[] = [
  { label: "Khám tại cơ sở y tế", value: false },
  { label: "Tư vấn online", value: true },
];

export const optionAppointmentTypeString: TOption[] = [
  { label: "Khám tại cơ sở y tế", value: "OFFLINE" },
  { label: "Tư vấn online", value: "ONLINE" },
];

export const categories = {
  APPT: "APPT",
  PACKAGE: "PACKAGE",
};

export const methodPayment = [
  {
    image: CommonImages.ATM,
    title: "Thẻ ATM nội địa",
    value: BankCode.ATM,
    bankSupport: "ATM",
  },
  {
    image: CommonImages.VNPAY,
    title: "Ví VNPay",
    value: BankCode.VNPAY,
    bankSupport: "VNPAY",
  },
  {
    image: CommonImages.Momo,
    title: "Ví MoMo",
    value: BankCode.MOMO,
    bankSupport: "MOMO",
  },
  {
    image: CommonImages.VitaPay,
    title: "Ví VitaPay",
    value: BankCode.VITAPAY,
    bankSupport: "VITAPAY",
  },
  {
    image: CommonImages.Banking,
    title: "Chuyển khoản",
    value: BankCode.BANK_TRANSFER,
    bankSupport: "TRANSFER",
  },

  {
    image: CommonImages.Cash,
    title: "Tại CSYT",
    value: BankCode.CASH,
    bankSupport: "CASH",
  },
];

export const optionReasons: TOption[] = [
  { label: "Tôi bận / tôi muốn đổi lịch", value: "Tôi bận / tôi muốn đổi lịch" },
  { label: "Tôi đã đi khám / tôi đã khỏi", value: "Tôi đã đi khám / tôi đã khỏi" },
  { label: "Tôi muốn thay đổi lịch hẹn", value: "Tôi muốn thay đổi lịch hẹn" },
  { label: "Tôi muốn hủy / lý do khác", value: "Tôi muốn hủy / lý do khác" },
];

export const imageSpecialization = [
  {
    key: "K31",
    value: CommonIcons.K31,
  },
  {
    key: "K28",
    value: CommonIcons.K28,
  },
  {
    key: "K27",
    value: CommonIcons.K27,
  },
  {
    key: "K29",
    value: CommonIcons.K29,
  },
  {
    key: "K19",
    value: CommonIcons.K19,
  },
  {
    key: "K30",
    value: CommonIcons.K30,
  },
  {
    key: "K13",
    value: CommonIcons.K13,
  },
  {
    key: "K03",
    value: CommonIcons.K03,
  },
  {
    key: "K08",
    value: CommonIcons.K08,
  },
  {
    key: "BS.giadinh",
    value: CommonIcons.BS_giadinh,
  },
  {
    key: "No",
    value: CommonIcons.No,
  },
  {
    key: "K18",
    value: CommonIcons.K18,
  },
  {
    key: "K39",
    value: CommonIcons.K39,
  },
  {
    key: "K47",
    value: CommonIcons.K47,
  },
  {
    key: "K16",
    value: CommonIcons.K16,
  },
  {
    key: "K17",
    value: CommonIcons.K17,
  },
];

export const imageSpecializationActive = [
  {
    key: "K31",
    value: CommonIcons.K31A,
  },
  {
    key: "K28",
    value: CommonIcons.K28A,
  },
  {
    key: "K27",
    value: CommonIcons.K27A,
  },
  {
    key: "K29",
    value: CommonIcons.K29A,
  },
  {
    key: "K19",
    value: CommonIcons.K19A,
  },
  {
    key: "K30",
    value: CommonIcons.K30A,
  },
  {
    key: "K13",
    value: CommonIcons.K13A,
  },
  {
    key: "K03",
    value: CommonIcons.K03A,
  },
  {
    key: "K08",
    value: CommonIcons.K08A,
  },
  {
    key: "BS.giadinh",
    value: CommonIcons.BS_giadinhA,
  },
  {
    key: "No",
    value: CommonIcons.NoA,
  },
  {
    key: "K18",
    value: CommonIcons.K18A,
  },
  {
    key: "K39",
    value: CommonIcons.K39A,
  },
  {
    key: "K47",
    value: CommonIcons.K47A,
  },
  {
    key: "K16",
    value: CommonIcons.K16A,
  },
  {
    key: "K17",
    value: CommonIcons.K17A,
  },
];

export const Service365MediHome = (baseRoutes: any) => {
  return [
    {
      Icon: CommonIcons.IconMap,
      title: "home.ApptFacility",
      subTitle: "home.subApptFacility",
      route: baseRoutes.orderFacility,
    },
    {
      Icon: CommonIcons.ApptDoctor,
      title: "home.ApptDoctor",
      subTitle: "home.subDoctor",
      route: baseRoutes.doctor,
    },
    {
      Icon: CommonIcons.ApptOnline,
      title: "home.ApptOnline",
      subTitle: "home.subOnline",
      route: baseRoutes.bookingOnline,
    },
    {
      Icon: CommonIcons.ApptSpecialization,
      title: "home.ApptSpecialization",
      subTitle: "home.subSpe",
      route: baseRoutes.bookingOnline,
    },
    {
      Icon: CommonIcons.ApptPackage,
      title: "home.ApptPackage",
      subTitle: "home.subPackage",
      route: baseRoutes.bookingPackage,
    },
    {
      Icon: CommonIcons.ApptMedicine,
      title: "home.Medicine",
      subTitle: "home.subMedicine",
      route: baseRoutes.medicine,
    },
  ];
};

export const orders = [
  {
    Icon: CommonIcons.IconSearchHome,
    title: "home.SearchService",
    subTitle: "home.subSearchService",
  },
  {
    Icon: CommonIcons.IconContractHome,
    title: "home.CheckInfo",
    subTitle: "home.contentService",
  },
  {
    Icon: CommonIcons.IconOrderHome,
    title: "home.BookingPayment",
    subTitle: "home.subBooking",
  },
  {
    Icon: CommonIcons.IconExperienceHome,
    title: "home.Experience",
    subTitle: "home.subExperience",
  },
];

export const linkGGPlay = "https://play.google.com/store/apps/details?id=com.vtrc.medihome365&hl=en";
export const linkAppStore = "https://apps.apple.com/us/app/365-medihome-y-b%E1%BA%A1-%C4%91i%E1%BB%87n-t%E1%BB%AD/id1515899198";

export const DataPartnerConnect = [
  { id: 1, image: CommonImages.Partner_VNPT },
  { id: 2, image: CommonImages.Partner_Sumitomo },
  { id: 3, image: CommonImages.Partner_InSMart },
  { id: 4, image: CommonImages.Partner_Momo },
  { id: 5, image: CommonImages.Partner_OMRON },
  { id: 6, image: CommonImages.Partner_TLong },
  { id: 7, image: CommonImages.Partner_VNPAY },
];
