import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { optionProvince } from "@base/constants";
import useParseOption from "@base/hooks/useParseOptions";
import useGetDoctors from "@base/modules/doctor/hooks/useGetDoctors";
import IFilterDcotor from "@base/modules/doctor/interface/filterDoctor.interafce";
import { ISite } from "@base/modules/site/site.interface";
import useGetSpecializetation from "@base/modules/specialization/hooks/useGetSpecialization";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import CardDoctorSpecial from "@components/CommonStyles/CardDoctorSpecial";
import { Divider } from "antd";
import { debounce } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function TabDoctor({ site }: { site?: ISite }) {
  //! state
  const [searchParams] = useSearchParams();
  const site_code = searchParams.get("site_code");
  const [textSearch, setTextSearch] = useState("");
  const { data: specializations, isLoading: loadingSpecialization } = useGetSpecializetation({ site_code: site_code, lang: "vi" });
  const [filters, setFilters] = useState<IFilterDcotor>({
    page: 0,
    size: 10,
    site_code: site_code,
  });
  const { data: doctors, isLoading: loadingDoctor, refetch: refetchDoctor } = useGetDoctors(filters, false);
  const dataDoctor = doctors?.data.content_page;

  useEffect(() => {
    refetchDoctor();
  }, [filters]);
  //! function
  const optionSpecialization = useParseOption(specializations?.data, "specialization_name", "specialization_code");
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, doctor_name: value }));
      }, 1000),
    [],
  );
  const handleChangeOption = (value: string) => {
    setFilters((prev) => ({ ...prev, specialization_code: value }));
  };
  const clearFilter = () => {
    if (filters.page !== 0 || filters.doctor_name || filters.specialization_code) {
      setFilters({ page: 0, size: 10, site_code });
      setTextSearch("");
    }
  };
  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };
  //! render
  const renderDoctor = () => {
    return dataDoctor?.map((item, index) => {
      return <CardDoctorSpecial key={index} doctor={item} />;
    });
  };

  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="basis-3/4">
          <CommonStyles.InputUI
            value={textSearch}
            onChange={changeSearch}
            prefix={<CommonIcons.SearchOutlined />}
            placeholder="Nhập tên bác sĩ cần tìm..."
            variant="filled"
            className="h-10 lg:h-12"
          />
        </div>
        <div className="flex gap-3 grow">
          <div className="grow">
            <CommonStyles.SelectUI
              placeholder="Chuyên khoa"
              options={optionSpecialization}
              onChange={handleChangeOption}
              loading={loadingSpecialization}
              variant="filled"
            />
          </div>
          <CommonStyles.ButtonUI
            onClick={clearFilter}
            icon={<CommonIcons.IconRefresh />}
            className="bg-common-orange100 h-10 lg:h-12 !px-6 hover:!bg-common-orange100 "
          />
        </div>
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">{renderDoctor()}</div>

        <div className="flex justify-end mt-6">
          <CommonStyles.PaginationUI
            current={filters.page + 1}
            pageSize={filters.size}
            onChange={handleChangePage}
            total={doctors?.data.total_records}
          />
        </div>
      </div>
    </div>
  );
}
