import { useState } from "react";
import { useTranslation } from "react-i18next";
import authService from "../authService";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { AxiosError } from "axios";

const useRegister = () => {
  const [parmas] = useSearchParams();
  const redirectTo = parmas.get("redirectTo");
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const handleError = (phoneNumber: string, error: AxiosError | any) => {
    const status = error?.response?.status;

    if (status === 409) {
      // Phone number already is registered
      // Navigate to password screen
      navigate({
        pathname: baseRoutes.login,
        search: createSearchParams({
          phone: phoneNumber,
          redirectTo: String(redirectTo),
        }).toString(),
      });
    }

    if (status === 423) {
      // Show Alert: Account was blocked!
    }
  };

  const handleRegister = async (phoneNumber: string) => {
    try {
      setLoading(true);
      const data = await authService.register(phoneNumber, i18n.resolvedLanguage ?? "");

      if (data.status === 200) {
        // Phone number is not registered
        // Navigate to OTP Screen
        navigate({
          pathname: baseRoutes.login,
          search: createSearchParams({
            phone: phoneNumber,
            otp: "true",
          }).toString(),
        });
      }
    } catch (error: AxiosError | any) {
      handleError(phoneNumber, error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    handleRegister,
  };
};

export default useRegister;
