import React from "react";

export default function IconUtilities() {
  return (
    <div>
      <svg width="139" height="138" viewBox="0 0 139 138" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="69.5" cy="68.6429" r="68.6429" fill="url(#paint0_linear_933_42961)" />
        <circle cx="69.5" cy="68.6429" r="63.6429" stroke="url(#paint1_linear_933_42961)" strokeOpacity="0.3" strokeWidth="10" />
        <path
          d="M73.9691 41.7576L73.6816 41.9897L73.3587 44.6445L73.0346 47.309L72.6709 47.2657C72.2385 47.2142 71.4108 47.4609 70.9126 47.7863C70.7277 47.9123 70.3966 48.2576 70.1991 48.5695C69.8722 49.0436 69.8093 49.2334 69.7312 49.8752C69.6531 50.517 69.6688 50.7162 69.8726 51.2535C69.9897 51.6028 70.2286 52.016 70.3779 52.1818C70.7839 52.6149 71.5287 53.0489 71.9612 53.1004L72.3248 53.1437L71.8221 57.2766L71.3194 61.4095L75.5456 61.9127L79.7718 62.416L79.8049 62.1437C79.9161 61.2296 80.9414 60.1778 81.9031 59.9766C82.5483 59.8364 83.3936 59.937 83.9867 60.2247C84.8727 60.6459 85.6165 61.9085 85.5053 62.8226L85.4722 63.0949L88.2143 63.4214L90.9466 63.7468L91.2427 63.5255L91.5302 63.2934L92.6303 54.2496L93.7304 45.2059L93.5356 44.7585C93.4189 44.4881 93.1768 44.183 92.939 44.0067L92.546 43.7133L83.4056 42.6248L74.2652 41.5363L73.9691 41.7576Z"
          fill="url(#paint2_linear_933_42961)"
        />
        <g filter="url(#filter0_d_933_42961)">
          <path
            d="M46.0282 46.7948C45.7906 46.932 45.4936 47.2258 45.355 47.4609L45.1075 47.882V57.2457V66.6192H48.0773H51.0471V66.9816C51.0471 67.4126 51.3936 68.1962 51.7797 68.6467C51.9282 68.8132 52.3142 69.0973 52.6508 69.254C53.1656 69.5184 53.3636 69.5576 54.0169 69.5576C54.6703 69.5576 54.8682 69.5184 55.383 69.254C55.7196 69.0973 56.1057 68.8132 56.2541 68.6467C56.6402 68.1962 56.9867 67.4126 56.9867 66.9816V66.6192H61.1939H65.4011V62.4173V58.2252L64.9655 58.1762C64.0053 58.0685 62.9956 57.2653 62.6887 56.3642C62.4808 55.7765 62.4907 54.9244 62.6986 54.3171C63.0154 53.3964 64.1934 52.5149 65.1239 52.5149H65.4011V49.7822V47.0593L65.1437 46.7948L64.8764 46.5402H55.6602H46.4439L46.0282 46.7948Z"
            fill="url(#paint3_linear_933_42961)"
          />
        </g>
        <g filter="url(#filter1_d_933_42961)">
          <path
            d="M77.3991 65.9434C77.0625 66.1001 76.6764 66.3842 76.5279 66.5507C76.1419 67.0012 75.7954 67.7848 75.7954 68.206V68.5782H71.5882H67.381V71.9965V75.4148L67.9056 75.7087C68.6481 76.1299 69.4004 76.9624 69.836 77.8537C70.2023 78.6079 70.2023 78.6275 70.2023 79.842C70.2023 81.0566 70.2023 81.0762 69.836 81.8303C69.4103 82.7021 68.5095 83.6815 67.7968 84.0439L67.381 84.2496V86.4534V88.6572H76.8546H86.3183L86.744 88.4124C87.0014 88.2654 87.2786 87.9912 87.4271 87.7365L87.6745 87.3154V78.2063V69.0973L87.4172 68.8328L87.1499 68.5782H84.4474H81.735V68.206C81.735 67.7162 81.2301 66.7074 80.8143 66.3646C79.8541 65.5614 78.4979 65.3949 77.3991 65.9434Z"
            fill="url(#paint4_linear_933_42961)"
          />
        </g>
        <g filter="url(#filter2_d_933_42961)">
          <path
            d="M45.1075 77.9517V87.3154L45.355 87.7365C45.5035 87.9912 45.7807 88.2654 46.0381 88.4124L46.4637 88.6572H55.9275H65.4011V85.7188V82.7804H65.7773C66.2029 82.7804 66.9949 82.4376 67.4503 82.0556C67.6185 81.9087 67.9056 81.5267 68.064 81.1937C68.3313 80.6844 68.3709 80.4885 68.3709 79.842C68.3709 79.1956 68.3313 78.9997 68.064 78.4904C67.9056 78.1574 67.6185 77.7754 67.4503 77.6284C66.9949 77.2464 66.2029 76.9036 65.7773 76.9036H65.4011V72.7409V68.5782H61.9363H58.4716L58.2637 68.9895C57.8974 69.6948 56.9075 70.5861 56.0265 71.0072C55.2642 71.3697 55.2444 71.3697 54.0169 71.3697C52.7894 71.3697 52.7696 71.3697 52.0074 71.0072C51.1065 70.5763 50.2651 69.8319 49.8394 69.0875L49.5424 68.5782H47.325H45.1075V77.9517Z"
            fill="url(#paint5_linear_933_42961)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_933_42961"
            x="24.1075"
            y="27.5402"
            width="66.2936"
            height="69.0175"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42961" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42961" result="shape" />
          </filter>
          <filter
            id="filter1_d_933_42961"
            x="46.381"
            y="46.6233"
            width="66.2936"
            height="69.0339"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42961" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42961" result="shape" />
          </filter>
          <filter
            id="filter2_d_933_42961"
            x="24.1075"
            y="49.5781"
            width="69.2633"
            height="66.0791"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42961" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42961" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_933_42961" x1="122.453" y1="4.57323e-06" x2="11.1535" y2="125.519" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_933_42961" x1="0.857048" y1="150.655" x2="80.1982" y2="-6.88642" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint2_linear_933_42961" x1="32.25" y1="92.4288" x2="105.829" y2="56.7572" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint3_linear_933_42961" x1="35.8067" y1="63.2998" x2="66.9865" y2="35.6098" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFC07B" />
          </linearGradient>
          <linearGradient id="paint4_linear_933_42961" x1="67.381" y1="88.6572" x2="90.6777" y2="50.1651" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFCC93" />
          </linearGradient>
          <linearGradient id="paint5_linear_933_42961" x1="45.1075" y1="88.6572" x2="83.6706" y2="53.4711" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
