import CommonIcons from "@base/assets/icons";
import { optionAppointmentTypeString } from "@base/constants";
import useParseOption from "@base/hooks/useParseOptions";
import IFilterSite from "@base/modules/site/filterSite.interface";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import IFilterSpecialization from "@base/modules/specialization/interface/IFilterSpecialization.interfcae";
import { ISpecialization } from "@base/modules/specialization/interface/specialization.interface";
import useGetSpecializetation from "@base/modules/specialization/hooks/useGetSpecialization";
import useGetState from "@base/modules/state/hooks/useGetState";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor, { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import CardSpecialist from "@components/CommonStyles/CardSpecialist";
import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Specialist() {
  //! state
  const navigate = useNavigate();
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Đặt lịch",
    },
    {
      title: "Bác sĩ",
    },
  ];
  const [textSearch, setTextSearch] = useState("");

  // const [filters, setFilters] = useState<IFilterSpecialization>({
  //   appt_type: null,
  //   site_code: null,
  // });
  // const [filterSite, setFilterSite] = useState<IFilterSite>({
  //   page_number: 0,
  //   page_size: 10,
  //   state_id: "",
  //   district_code: "",
  //   search_text: "",
  //   supported_seller: "",
  //   ward_code: "",
  // });
  // const { data: dataState, isLoading: loadingState } = useGetState();
  // const { data: dataSite, isLoading: loadingSite, refetch: refetchSite } = useGetListSite(filterSite, false);
  const { data: dataSpecialization, isLoading: loadingSpecialization } = useGetSpecializetation();
  const [specializationSearch, setSpecializationSearch] = useState<ISpecialization[] | undefined>([]);
  //! function
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        if (isEmpty(value)) {
          setSpecializationSearch(dataSpecialization?.data);
          return;
        }
        const filterSearch = dataSpecialization?.data.filter(
          (item) => item.specialization_name?.toLowerCase().includes(value) || item.description?.toLowerCase().includes(value),
        );

        setSpecializationSearch(filterSearch);
      }, 1000),
    [loadingSpecialization],
  );

  // const optionState = useParseOption(dataState?.data, "name", "id");
  // const optionSite = useParseOption(dataSite?.data.content_page, "name", "id");

  // const changeFilterSite = (value: any) => {
  //   setFilterSite((prev) => ({ ...prev, state_id: value }));
  // };
  // const changeFilter = (value: any, key: string) => {
  //   setFilters((prev) => ({ ...prev, [key]: value }));
  // };

  // useEffect(() => {
  //   refetchSite();
  // }, [filterSite]);

  useEffect(() => {
    const dataSearch = dataSpecialization?.data;
    setSpecializationSearch(dataSearch);
  }, [loadingSpecialization]);

  //! render
  const renderSpecialization = useCallback(() => {
    return specializationSearch?.map((item, index) => {
      return <CardSpecialist root="specialist" specialization={item} key={index} />;
    });
  }, [specializationSearch]);
  return (
    <div>
      {/* search */}
      <div className={`bg-common-white h-72 pb-8 relative ${initialPage.paddingX} py-5 flex flex-col justify-between`}>
        {/* <CommonStyles.BreadcrumbUI items={breadcrumItem} /> */}

        <div className="">
          <div className="text-center">
            <CommonStyles.TypographyUI use="title" level={3} className="!text-common-maintext uppercase">
              Đặt lịch theo chuyên khoa
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-base">
              Đặt lịch khám, tư vấn tại các Cơ sở y tế, Bác sĩ theo Chuyên khoa bạn đang quan tâm
            </CommonStyles.TypographyUI>
          </div>

          <div className="flex justify-center items-center gap-4 md:px-20  lg:px-40 xl:px-72 w-full">
            <div className="grow">
              <CommonStyles.InputUI
                onChange={changeSearch}
                placeholder="Nhập tên Cơ sở y tế cần tìm..."
                variant="filled"
                prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
                value={textSearch}
              />
            </div>

            <CommonStyles.ButtonUI
              // onClick={handleSearchAndFilter}
              className="h-12 !w-12 !p-3 "
              icon={<CommonIcons.SearchOutlined style={{ fontSize: 24 }} />}
            />
          </div>
        </div>

        <div className="absolute right-0 bottom-0">
          <CommonIcons.IconHeart width={150} height={150} />
        </div>
      </div>

      <div className={`${initialPage.paddingX} py-4 flex flex-col gap-6`}>
        {/* filter */}
        {/* <div className="flex gap-4 items-center bg-common-white p-4 rounded-2xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 grow">
            <div className="grow">
              <CommonStyles.SelectUI
                value={filters.appt_type}
                onChange={(value) => {
                  changeFilter(value, "appt_type");
                }}
                options={optionAppointmentTypeString as DefaultOptionType[]}
                variant="filled"
                placeholder="Hình thức"
              />
            </div>
            <div className="grow">
              <CommonStyles.SelectUI
                value={filterSite.state_id === "" ? null : filterSite.state_id}
                loading={loadingState}
                onChange={changeFilterSite}
                options={optionState}
                variant="filled"
                placeholder="Khu vực"
              />
            </div>
            <div className="grow">
              <CommonStyles.SelectUI
                loading={loadingSite}
                value={filters.site_code}
                onChange={(value) => {
                  changeFilter(value, "site_code");
                }}
                options={optionSite}
                variant="filled"
                placeholder="Cơ sở y tế"
              />
            </div>
          </div>
          <div className="flex items-center gap-3">
         
            <CommonStyles.ButtonUI icon={<CommonIcons.IconRefresh />} className="bg-common-orange100 h-12 !px-6 hover:!bg-common-orange100" />
          </div>
        </div> */}

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-7">{renderSpecialization()}</div>
      </div>
    </div>
  );
}
