import React from "react";

export default function K27A() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.429688" y="0.833954" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16657)" />
        <rect x="1.42969" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1360_16657)">
          <path
            d="M59.7495 49.8541C57.8961 49.8541 57.1954 52.1758 57.1671 52.2753C56.9546 53.0027 56.3119 53.5939 55.5214 53.5043C54.7608 53.4993 54.0948 52.9961 53.8923 52.2638C53.8673 52.1758 53.1666 49.8541 51.3132 49.8541C49.6592 49.8541 48.7939 51.3022 48.7939 52.7337C48.7939 54.7596 50.6258 56.3954 54.437 59.6105C54.7907 59.9095 55.1561 60.2183 55.5313 60.5372C55.9067 60.2183 56.272 59.9112 56.6241 59.6122C59.1904 57.4478 62.2687 55.0254 62.2687 52.7337C62.2687 51.3022 61.4035 49.8541 59.7495 49.8541Z"
            fill="url(#paint1_linear_1360_16657)"
          />
          <path
            d="M72.5371 54.9556C72.5371 47.4838 68.1822 40.932 61.5196 37.9408C62.0178 36.989 62.3339 35.9353 62.3339 34.81C62.3339 31.8855 60.4706 29.3015 57.6973 28.3782L55.5318 27.6541C52.7958 26.7442 50.2735 25.1835 48.2331 23.1452C47.9157 22.8269 47.4714 22.6455 47.0291 22.6455H36.8259C36.0935 22.6455 35.4442 23.1138 35.2134 23.808L33.4497 29.0989C32.254 32.6843 32.6576 36.6151 34.554 39.8816C36.7112 43.5966 36.0901 48.3411 33.0079 51.6907C29.9873 54.9756 28.3232 59.2419 28.3232 63.7041C28.3232 67.2197 29.3529 70.6224 31.3025 73.5436L35.411 79.933C35.7265 80.4064 36.2579 80.6903 36.8258 80.6903H57.2322C58.1721 80.6903 58.9327 79.9298 58.9327 78.9898C58.9327 77.0076 58.5253 74.8987 57.8362 73.1587C66.3474 71.3152 72.5371 63.9042 72.5371 54.9556ZM58.8183 62.2111C58.1357 62.7874 57.4084 63.4018 56.6462 64.0628C56.3257 64.3401 55.9288 64.4779 55.5319 64.4779C55.135 64.4779 54.7381 64.3401 54.4176 64.0628C53.6537 63.4001 52.9263 62.7874 52.2437 62.2094C48.2664 58.8548 45.3934 56.4302 45.3934 52.7336C45.3933 49.2113 47.994 46.4529 51.3137 46.4529C53.1819 46.4529 54.5669 47.3065 55.5318 48.381C56.4967 47.3065 57.8817 46.4529 59.7499 46.4529C63.0696 46.4529 65.6702 49.2113 65.6702 52.7336C65.6702 56.7012 62.1628 59.3901 58.8183 62.2111Z"
            fill="url(#paint2_linear_1360_16657)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1360_16657"
            x="20.3232"
            y="18.6455"
            width="60.2139"
            height="74.0449"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16657" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16657" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16657" x1="50.4297" y1="0.833954" x2="50.4297" y2="100.834" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16657" x1="55.5313" y1="49.8541" x2="55.5313" y2="60.5372" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16657" x1="50.4302" y1="22.6455" x2="50.4302" y2="80.6903" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
