import React, { useMemo, useState } from "react";
import CommonStyles from "@components/CommonStyles";
import CommonIcons from "@base/assets/icons";
import themeColor from "@base/themes/colorTheme";
import dayjs from "dayjs";
import IFilterAppointment from "@base/modules/appointment/filterAppointment.interface";
import { debounce } from "lodash";
import { optionAppointmentType } from "@base/constants";
import { DefaultOptionType } from "antd/es/select";

interface IProps {
  filter: IFilterAppointment;
  setFilter: (value: any) => void;
  refetch: () => void;
  refetchReAppointment: () => void;
  activeTab: string;
}

export default function AppointmentFilter({ filter, setFilter, refetch, refetchReAppointment, activeTab }: IProps) {
  const dateFormat = "YYYY-MM-DD";
  const [renderKey, setRenderKey] = useState<number>(0);

  const fetchData = () => {
    if (activeTab === "RE-APPOINTMENT") {
      refetchReAppointment();
      return;
    }
    refetch();
  };

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    setFilter(Object.assign(filter, { search: value ? value : undefined, page: 0 }));
    onSearchDebounce(value ? value : undefined);
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value?: string) => {
        fetchData();
      }, 1000),
    [],
  );

  const onChangeType = (value: any) => {
    setFilter(Object.assign(filter, { is_online: value ?? undefined, page: 0 }));
    fetchData();
  };

  const onChangeDateRange = (dates: any, dateStrings: [string, string]) => {
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      setFilter(Object.assign(filter, { from: dates[0].format(dateFormat), to: dates[1].format(dateFormat), page: 0 }));
    } else {
      setFilter(Object.assign(filter, { from: undefined, to: undefined, page: 0 }));
    }

    fetchData();
  };

  const onClearFilter = () => {
    setFilter(Object.assign(filter, { from: undefined, to: undefined, is_online: undefined, search: undefined, page: 0 }));
    setRenderKey(renderKey + 1);
    fetchData();
  };

  return (
    <div key={renderKey} className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
      <CommonStyles.InputUI
        allowClear
        placeholder="Nhập nội dung tìm kiếm"
        variant="filled"
        prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
        defaultValue={filter.search}
        onChange={changeSearch}
      />

      <CommonStyles.SelectUI
        className="h-12 w-full"
        allowClear
        variant="filled"
        placeholder="Loại"
        options={optionAppointmentType as DefaultOptionType[]}
        defaultValue={filter.is_online}
        onChange={onChangeType}
      />

      <CommonStyles.RangePickerUI
        className="h-12 w-full"
        placeholder={["Bắt đầu", "Kết thúc"]}
        variant="filled"
        format={"DD/MM/YYYY"}
        separator={"-"}
        defaultValue={[filter.from ? dayjs(filter.from, dateFormat) : null, filter.to ? dayjs(filter.to, dateFormat) : null]}
        onChange={onChangeDateRange}
      />

      <div className="lg:col-span-3 2xl:col-span-1">
        <CommonStyles.ButtonUI
          className="h-12 bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange w-fit float-right"
          onClick={onClearFilter}
        >
          <CommonIcons.IconRotateRight />
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
