import React, { useState } from "react";
import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import themeColor from "@base/themes/colorTheme";
import AppointmentListItem from "./AppointmentListItem";
import { useNavigate } from "react-router-dom";
import useGetMyAppointment from "@base/modules/appointment/hooks/useGetMyAppointment";
import IFilterAppointment from "@base/modules/appointment/filterAppointment.interface";
import { EAppointmentStatus, EAppointmentType } from "@base/enums";
import { IAppointment, IReAppointment } from "@base/modules/appointment/appointment.interface";
import dayjs from "dayjs";
import { baseRoutes } from "@base/routes/baseRoutes";
import useGetMyReAppointment from "@base/modules/reAppointment/useGetMyReAppointment";
import AppointmentFilter from "./AppointmentFilter";
import IFilterReAppointment from "@base/modules/reAppointment/filterReAppointment.interface";
import ReAppointmentList from "./ReAppointmentListItem";
import ReAppointmentListItem from "./ReAppointmentListItem";

export default function AppointmentList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>(EAppointmentStatus.PENDING);

  const tabItems = [
    {
      key: EAppointmentStatus.PENDING,
      label: t("user.appointment.tabPending"),
    },
    {
      key: EAppointmentStatus.IN_PROGRESS,
      label: t("user.appointment.tabInprogress"),
    },
    {
      key: EAppointmentStatus.COMPLETED,
      label: t("user.appointment.tabCompleted"),
    },
    {
      key: EAppointmentStatus.CANCELLED,
      label: t("user.appointment.tabCancelled"),
    },
    {
      key: "RE-APPOINTMENT",
      label: t("user.appointment.tabReAppointment"),
    },
  ];

  const [filter, setFilter] = useState<IFilterAppointment>({ page: 0, size: 10, status: EAppointmentStatus.PENDING });
  const [reFilter, setReFilter] = useState<IFilterReAppointment>({ page: 0, size: 10 });
  const { isLoading, data, error, refetch } = useGetMyAppointment(filter, true);
  const { isLoadingReAppointment, reAppointmentData, reAppointmentError, refetchReAppointment } = useGetMyReAppointment(reFilter, false);

  const operations = (
    <div className="flex justify-center items-center gap-4">
      <CommonStyles.ButtonUI
        icon={<CommonIcons.IconCalendarFill />}
        iconPosition="start"
        className="bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
        onClick={() => navigate(baseRoutes.orderFacility)}
      >
        {t("user.appointment.btnApptBooking")}
      </CommonStyles.ButtonUI>
    </div>
  );

  const onChangeTabs = (key: string) => {
    setActiveTab(key);

    if (key === "RE-APPOINTMENT") {
      setReFilter(Object.assign(reFilter, { page: 0 }));
      refetchReAppointment();
      return;
    }

    setFilter(Object.assign(filter, { status: key, page: 0 }));
    refetch();
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (activeTab === "RE-APPOINTMENT") {
      setReFilter(Object.assign(reFilter, { page: page - 1, size: pageSize }));
      refetchReAppointment();
      return;
    }

    setFilter(Object.assign(filter, { page: page - 1, size: pageSize }));
    refetch();
  };

  const pageData = activeTab === "RE-APPOINTMENT" ? reAppointmentData : data;
  const pageFilter = activeTab === "RE-APPOINTMENT" ? reFilter : filter;

  const items = pageData?.content_page?.map((appt: IAppointment, idx: number) => {
    return {
      dot: (
        <CommonStyles.TimelineDotUI
          className="p-2"
          bgColor={appt.appointment_type === EAppointmentType.ONLINE ? themeColor.orange100 : themeColor.blue100}
          borderColor={appt.appointment_type === EAppointmentType.ONLINE ? themeColor.orange500 : themeColor.blue500}
          textColor={appt.appointment_type === EAppointmentType.ONLINE ? themeColor.orange500 : themeColor.blue500}
          content={appt.appointment_type === EAppointmentType.ONLINE ? <CommonIcons.IconRecord /> : <CommonIcons.IconFacility />}
        />
      ),
      children: (
        <div className="w-full flex items-start gap-8">
          <div className="flex-none w-[80px] max-lg:hidden">
            <div className="text-common-maintext text-base font-medium font-['Inter']">
              {dayjs(appt.time_table_period?.period_start_time).format("HH:mm")}
            </div>
            <div className="text-common-subtext text-xs font-normal font-['Inter']">
              {dayjs(appt.appointment_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
            </div>
          </div>

          <div className="grow">
            <AppointmentListItem appointment={appt} />
          </div>
        </div>
      ),
    };
  });

  return (
    <div className="p-5">
      <div className="flex flex-col">
        <div className="w-full flex justify-between">
          <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.appointment.appointment")}</div>

          <div className="lg:hidden w-fit">{operations}</div>
        </div>

        <div>
          <CommonStyles.TabsUI
            className="w-full"
            items={tabItems}
            tabBarExtraContent={
              pageData && pageData.content_page && pageData.content_page.length > 0 ? <div className="max-lg:hidden w-fit">{operations}</div> : null
            }
            activeKey={activeTab}
            onChange={onChangeTabs}
          />
        </div>
      </div>

      {activeTab !== "RE-APPOINTMENT" ? (
        <div className="w-full mb-3">
          <AppointmentFilter
            filter={filter}
            setFilter={setFilter}
            refetch={refetch}
            refetchReAppointment={refetchReAppointment}
            activeTab={activeTab}
          />
        </div>
      ) : null}

      <div className="min-h-[377px] relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading || isLoadingReAppointment} />

        {pageData && pageData.content_page && pageData.content_page.length > 0 ? (
          <div className="w-full">
            <div className="pt-4 max-h-[610px] overflow-y-auto">
              {activeTab !== "RE-APPOINTMENT" ? (
                <CommonStyles.TimelineUI items={items} />
              ) : (
                <div className="w-full flex flex-col gap-4">
                  {pageData.content_page.map((item: IReAppointment, idx) => {
                    return <ReAppointmentListItem key={idx} appointment={item} />;
                  })}
                </div>
              )}
            </div>

            {(pageData.total_records ?? 0) > pageFilter.size ? (
              <div className="w-full flex justify-end items-center mt-2">
                <CommonStyles.PaginationUI
                  current={pageFilter.page + 1}
                  pageSize={pageFilter.size}
                  total={pageData.total_records}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full min-h-[377px] flex flex-col justify-center items-center gap-6">
            <img src={CommonImages.EmptyAppointmentList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("user.appointment.recordNotFound")}!</div>

            <CommonStyles.ButtonUI onClick={() => navigate(baseRoutes.orderFacility)}>
              {t("user.appointment.btnApptBookingNow")}
            </CommonStyles.ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
}
