import Slider from "react-slick";
import React from "react";

interface IProps {
  children: React.ReactNode;
  settings?: any;
  refSlider?: any;
}
export default function CarouselUI(props: IProps) {
  //! state
  const { children, settings, refSlider, ...res } = props;
  //! function
  //! render
  return (
    <div className="slider-container">
      <Slider ref={refSlider} {...settings}>
        {children}
      </Slider>
    </div>
  );
}
