import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import IFilterListCategory from "./filterListShow";

class SpecialCategoryService {
  getListShow(query: IFilterListCategory) {
    return httpService.get(apiService.utils.specialCategory.getListShow, {
      params: query,
    });
  }
}

export default new SpecialCategoryService();
