import React from "react";

export default function NoA() {
  return (
    <div>
      <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.00146484" y="0.833954" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16594)" />
        <rect x="1.00146" y="1.83395" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1360_16594)">
          <path
            d="M49.6436 57.7547C47.4328 57.7547 45.5443 55.9393 45.984 53.7726C46.1279 53.0635 46.317 52.4329 46.5514 51.881C47.1586 50.4339 48.143 49.1825 49.5046 48.127C50.8663 47.0714 51.8967 46.1095 52.5959 45.2412C53.3136 44.3559 53.6724 43.3855 53.6724 42.3299C53.6724 39.7591 52.4763 38.4737 50.0843 38.4737C48.9802 38.4737 48.0878 38.8483 47.407 39.5974V39.5974C46.173 40.9551 44.8707 42.6364 43.036 42.6364H41.7584C39.1396 42.6364 36.8629 40.3947 37.9385 38.0069C38.5174 36.7218 39.3643 35.6175 40.4792 34.6942C42.7608 32.7874 45.9625 31.834 50.0843 31.834C54.1876 31.834 57.3617 32.7107 59.6066 34.4643C61.8698 36.2179 63.0015 38.7121 63.0015 41.9469C63.0015 43.3599 62.6979 44.6453 62.0906 45.803C61.4834 46.9607 60.5082 48.144 59.1649 49.3528L55.9908 52.0853C55.0892 52.8855 54.4636 53.7112 54.114 54.5624V54.5624C53.4119 56.2378 52.0886 57.7547 50.2722 57.7547H49.6436ZM44.5089 65.5181C44.5089 64.2582 44.9965 63.2282 45.9717 62.428C46.9654 61.6108 48.1982 61.2022 49.6703 61.2022C51.1423 61.2022 52.3659 61.6108 53.3412 62.428C54.3348 63.2282 54.8316 64.2582 54.8316 65.5181C54.8316 66.7779 54.3348 67.8165 53.3412 68.6337C52.3659 69.4339 51.1423 69.834 49.6703 69.834C48.1982 69.834 46.9654 69.4339 45.9717 68.6337C44.9965 67.8165 44.5089 66.7779 44.5089 65.5181Z"
            fill="url(#paint1_linear_1360_16594)"
            shape-rendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1360_16594"
            x="29.6655"
            y="27.834"
            width="41.3359"
            height="54"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16594" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16594" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16594" x1="50.0015" y1="0.833954" x2="50.0015" y2="100.834" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16594" x1="50.0015" y1="31.834" x2="50.0015" y2="69.834" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
