import React from "react";

export default function K28A() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.714844" y="0.833954" width="100" height="100" rx="16" fill="url(#paint0_linear_1360_16663)" />
        <rect x="2.21484" y="2.33395" width="97" height="97" rx="14.5" stroke="white" strokeOpacity="0.5" strokeWidth="3" />
        <g filter="url(#filter0_d_1360_16663)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.0826 29.7334C40.0654 25.2078 44.9592 22.7467 52.0031 22.7467C58.0611 22.7467 62.0776 25.733 64.5137 29.3838C66.8787 32.9281 67.777 37.097 67.9042 39.8962C67.9636 41.203 66.9524 42.3105 65.6456 42.3699C64.3388 42.4293 63.2313 41.4181 63.1719 40.1113C63.0735 37.9477 62.3476 34.6724 60.5732 32.0132C58.8699 29.4605 56.2317 27.4839 52.0031 27.4839C46.1888 27.4839 42.9617 29.4217 41.038 32.3403C38.9784 35.4653 38.1298 40.1264 38.1298 46.0945C38.1298 51.3932 40.1178 54.2072 42.4271 56.5165C43.0341 57.1235 43.6627 57.6934 44.321 58.2878L44.3923 58.3522C45.0143 58.9138 45.6723 59.508 46.2866 60.1222C47.5652 61.4008 48.8354 62.9384 49.513 64.9711C49.7467 65.6723 49.9492 66.3232 50.1465 66.9571C50.4291 67.8656 50.701 68.7392 51.0383 69.6762C51.5795 71.1795 52.1648 72.4556 52.8981 73.4555C53.6074 74.4228 54.432 75.1013 55.4913 75.4826C56.5706 75.8712 58.1021 76.0258 60.3363 75.579C60.9429 75.4576 61.8338 74.7573 62.5536 73.2457C63.2628 71.7563 63.3981 70.3101 63.2402 69.6783C62.9229 68.4092 63.6945 67.1232 64.9636 66.806C66.2327 66.4887 67.5187 67.2603 67.836 68.5294C68.3547 70.6046 67.8133 73.2189 66.8307 75.2824C65.8586 77.3238 64.0425 79.6688 61.2654 80.2242C58.4239 80.7925 55.9796 80.6933 53.8867 79.9398C51.7737 79.1792 50.2296 77.8274 49.0779 76.2569C47.9501 74.719 47.1819 72.9497 46.5811 71.2808C46.2267 70.2962 45.881 69.1914 45.5628 68.1747C45.37 67.5584 45.1873 66.9745 45.0188 66.4691C44.6813 65.4565 44.0058 64.5409 42.9369 63.472C42.4096 62.9447 41.8308 62.422 41.1782 61.8327L41.1461 61.8037C40.4958 61.2165 39.7815 60.5704 39.0773 59.8662C36.1418 56.9306 33.3926 52.9772 33.3926 46.0945C33.3926 39.8811 34.2359 34.0526 37.0826 29.7334Z"
            fill="url(#paint1_linear_1360_16663)"
          />
          <path
            d="M49.2968 36.6191C44.4243 36.0777 42.755 43.6122 42.5294 47.4471C39.8224 39.3261 43.2061 36.6191 45.9131 34.5889C54.7108 30.5284 57.4178 37.9726 57.4178 40.0029C57.4178 42.0331 60.1248 45.4168 60.8015 48.1238C61.3429 50.2894 59.6736 51.7331 58.7713 52.1843C58.3201 52.4099 57.4178 53.1318 57.4178 54.2146C57.4178 55.2973 59.6736 58.7262 60.8015 60.3053C60.8015 60.982 60.5308 62.4709 59.448 63.0123C54.7108 64.3658 52.0038 62.3355 49.9736 58.275C47.9434 54.2146 52.0038 50.1541 52.6806 48.8006C53.3573 47.4471 55.3876 37.2959 49.2968 36.6191Z"
            fill="url(#paint2_linear_1360_16663)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1360_16663"
            x="25.3926"
            y="18.7467"
            width="50.6436"
            height="73.8414"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_16663" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_16663" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1360_16663" x1="50.7148" y1="0.833954" x2="50.7148" y2="100.834" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFB571" />
            <stop offset="1" stopColor="#F68E1E" />
          </linearGradient>
          <linearGradient id="paint1_linear_1360_16663" x1="50.7146" y1="22.7467" x2="50.7146" y2="80.5881" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient id="paint2_linear_1360_16663" x1="51.2207" y1="33.3961" x2="51.2207" y2="63.4298" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
