import { LocalKey } from "@base/constants/LocalKey";
import { baseRoutes } from "@base/routes/baseRoutes";
import { RootState } from "@base/store/store";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const useCheckLogin = (enabeld = true) => {
  const authLogin = localStorage.getItem(LocalKey.AUTH);

  const location = useLocation();
  const navigate = useNavigate();
  const [parmas] = useSearchParams();
  const redirectTo = parmas.get("redirectTo");

  const handleCheckLogin = () => {
    if (enabeld && isEmpty(authLogin)) {
      const parmas = queryString.parse(location.search);
      navigate(
        {
          pathname: `${baseRoutes.login}`,
          search: createSearchParams({
            redirectTo: `${location.pathname}?${String(queryString.stringify(parmas))}`,
          }).toString(),
        },
        { replace: true },
      );
    }
  };

  return {
    isLogin: !!authLogin,
    redirectTo,
    handleCheckLogin,
  };
};

export default useCheckLogin;
