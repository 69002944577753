import React from "react";
import themeColor from "@base/themes/colorTheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TypographyUI from "../Typography";
import { TLinkTarget } from "@base/types";

interface IProps {
  text?: React.ReactNode;
  children?: React.ReactNode;
  isActive?: boolean;
  mainColor?: string | undefined;
  subColor?: string | undefined;
  className?: string | undefined;
  icon?: React.ReactNode;
  linkTo?: string;
  target?: TLinkTarget;
  hover?: boolean;
}

export default function NavLinkTextUI({
  text = null,
  children = null,
  isActive = false,
  mainColor = themeColor.orange500,
  subColor = themeColor.cyan600,
  className = undefined,
  icon = null,
  linkTo,
  target,
  hover,
}: IProps) {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (!linkTo) return;
        if (target && target == "_blank") {
          window.open(linkTo, target);
          return;
        }
        navigate(linkTo);
      }}
      style={{ color: isActive ? mainColor : subColor }}
      className={className ?? "flex items-center gap-3 text-base font-medium font-['Inter']"}
    >
      {icon ? <div>{icon}</div> : null}
      <TypographyUI
        className={
          `${hover ? "hover:!text-common-orange500 " : " "}` + `${isActive ? "!text-common-mainColor" : "!text-common-maintext"} ${className}`
        }
      >
        {t(`${text}`) ?? children}
      </TypographyUI>
    </div>
  );
}
