import { Tag } from "antd";
import React from "react";
import CommonStyles from "..";
import { EAppointmentType } from "@base/enums";

interface IProps {
  type: EAppointmentType;
}
export default function TagAppointmentType({ type }: IProps) {
  //! state
  const className =
    type === EAppointmentType.ONLINE
      ? "bg-common-yellow100 border-common-orange600 text-common-orange600 max-sm:line-clamp-1"
      : "bg-common-blue100 border-common-blue600 text-common-blue600 max-sm:line-clamp-1";
  const tagName = type === EAppointmentType.ONLINE ? "Trực tuyến qua app" : "Trực tiếp tại Cơ sở y tế";
  //! function
  //! render
  return <CommonStyles.TagUI tagName={tagName} className={className} />;
}
