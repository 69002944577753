import CommonIcons from "@base/assets/icons";
import TypographyUI from "../Typography";
import { createSearchParams, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import ButtonUI from "../Button";
import CommonStyles from "..";
import { ISpecialization } from "@base/modules/specialization/interface/specialization.interface";
import { imageSpecialization } from "@base/constants";

interface IProps {
  root?: "specialist" | "csyt";
  handleDetail?: () => void;
  specialization?: ISpecialization;
}
export default function CardSpecialist(props: IProps) {
  //! state
  const { root, handleDetail, specialization } = props;
  const navigate = useNavigate();
  const ImageMatch = imageSpecialization.find((item) => item.key === specialization?.specialization_code);
  //! function
  const handleDetailSpecialist = () => {
    navigate({
      pathname: `${baseRoutes.specialist}/${specialization?.id}`,
      search: createSearchParams({
        root: root ?? "csyt",
      }).toString(),
    });
  };
  //! render
  return (
    <div
      className="group rounded-2xl border border-common-divider p-4 flex gap-4 bg-common-white cursor-pointer hover:bg-common-orange100"
      onClick={handleDetail}
    >
      <div className=" flex justify-center items-center">{ImageMatch ? <ImageMatch.value /> : <CommonIcons.No />}</div>
      <div className="flex flex-col justify-center grow gap-3">
        <TypographyUI className="uppercase text-common-maintext text-base font-medium group-hover:text-common-mainColor">
          {specialization?.specialization_name}
        </TypographyUI>
        {/* {root === "specialist" && (
          <div className="flex flex-col 2xl:flex-row gap-3 py-2">
            <div className="flex items-center 2xl:border-r pr-3">
              <CommonIcons.IconUserOutline width={18} height={18} />
              <CommonStyles.TypographyUI>65 Bác sĩ chuyên khoa</CommonStyles.TypographyUI>
            </div>

            <div className="flex items-center gap-2">
              <CommonIcons.IconFacilityOutline width={18} height={18} />
              <CommonStyles.TypographyUI>21 Cơ sở y tế</CommonStyles.TypographyUI>
            </div>
          </div>
        )} */}

        {/* <CommonStyles.TypographyUI className="text-common-subtext">{specialization?.description}</CommonStyles.TypographyUI> */}

        {/* <div className="flex justify-between items-center gap-3">
          <ButtonUI className="grow h-10 xl:h-12">Đặt lịch khám</ButtonUI>

          <ButtonUI onClick={handleDetail ?? handleDetailSpecialist} className="grow h-10" ghost>
            Xem chi tiết
          </ButtonUI>
        </div> */}
      </div>
    </div>
  );
}
