import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { ISearchContent } from "@base/modules/allPageSearch/allPageSearch.interface";
import { INews } from "@base/modules/homeModules/interfaces/IHomeModule.interface";
import { RootState } from "@base/store/store";
import themeColor from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import { Divider } from "antd";
import Link from "antd/es/typography/Link";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function ArticleSearch({ data }: { data?: ISearchContent<INews> }) {
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  const onViewAll = () => {
    const article_link = dataCommon?.data.commons.find((item) => item.key === "SHOW_ALL_NEWS")?.article_link;

    if (article_link) {
      window.open(article_link, "_blank");
    }
  };

  const CardArticle = ({ title, date, content, img, link }: { title?: string; date?: string; content?: string; img?: string; link?: string }) => {
    return (
      <div className={`flex flex-row gap-4 p-6 max-md:p-2 rounded-2xl border-common-divider border shadow-lg shadow-common-disable bg-common-white`}>
        <div className="flex-none w-[359px] h-[240px] max-xl:w-[250px] max-xl:h-[168px] max-md:w-[180px] max-md:h-[120px]">
          <img
            className="rounded-lg h-full aspect-video object-fill"
            src={img}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = CommonImages.DrafInforArticle;
            }}
          />
        </div>

        <div className="grow flex flex-col justify-between">
          <div className="flex flex-col gap-4 max-xl:gap-2 max-md:gap-1">
            <CommonStyles.TypographyUI className="text-sm md:text-base lg:text-xl font-medium text-common-maintext line-clamp-2 max-xl:line-clamp-1">
              {title}
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI className="text-common-subtext text-xs md:text-sm lg:text-base">{date}</CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI
              use="paragraph"
              className="text-xs md:text-sm lg:text-base line-clamp-2 max-xl:line-clamp-1 font-normal text-common-subtext"
            >
              {content}
            </CommonStyles.TypographyUI>

            <Divider className="m-0" />
          </div>

          <div>
            <Link className="!text-common-orange text-base font-medium flex gap-1 w-fit" href={link} target="_blank">
              Đọc thêm <CommonIcons.IconArrowRight fillColor={themeColor.orange} />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext !font-medium text-sm lg:text-lg xl:text-xl !m-0">
            Bài viết
          </CommonStyles.TypographyUI>
          <div className="min-w-6 min-h-6 bg-common-negative rounded-full text-xs text-common-white flex justify-center items-center">
            {data?.total_records}
          </div>
        </div>

        <CommonStyles.ButtonUI ghost className="h-12 font-medium text-xs md:text-sm lg:text-base max-md:!p-2 max-md:h-fit" onClick={onViewAll}>
          Xem tất cả
        </CommonStyles.ButtonUI>
      </div>

      <div className="flex flex-col gap-4">
        {data?.content_page?.map((news: INews, index: number) => {
          return (
            <CardArticle
              key={index}
              img={news.image_link}
              title={news.title}
              date={dayjs(news.start_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
              content={news.caption}
              link={news.article_link}
            />
          );
        })}
      </div>
    </div>
  );
}
