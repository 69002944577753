import { useEffect, useState } from "react";
import CommonStyles from "@components/CommonStyles";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import CommonIcons from "@base/assets/icons";
import { useTranslation } from "react-i18next";
import { Divider, FloatButton } from "antd";
import CommonImages from "@base/assets/images";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor from "@base/themes/colorTheme";
import { clearLocalStorage } from "@base/helpers/common";
import authService from "@base/modules/auth/authService";
import useToggle from "@base/hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage, setAuth, setUserInfo } from "@base/store/reducers/appCommonSlice";
import { RootState } from "@base/store/store";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from "antd/es/breadcrumb/Breadcrumb";
import { Upload } from "antd";
import type { UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import accountService from "@base/modules/user/account/accountService";
import useGetUserInfo from "@base/modules/user/account/hooks/useGetUserInfo";
import { CommentOutlined, CustomerServiceOutlined, EditOutlined } from "@ant-design/icons";
import { responseUI } from "@base/themes/response";

type BeforeUpload = Exclude<UploadProps["beforeUpload"], undefined>;
type BeforeUploadReturnType = ReturnType<BeforeUpload>;

export default function User() {
  //! state
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);
  const { handleGetUserInfo } = useGetUserInfo();

  const { open, toggle } = useToggle();

  const [breadItems, setBreadItems] = useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]>([]);
  const { id, key } = useParams();

  //! function
  useEffect(() => {
    const defaultBreadItems: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
      {
        href: "/",
        title: <CommonIcons.IconHome />,
      },
    ];

    // user
    if (location.pathname.startsWith("/user")) {
      defaultBreadItems.push({
        href: "/user",
        title: t("user.user"),
      });
    }

    if (location.pathname.startsWith("/user/profile")) {
      defaultBreadItems.push({
        href: "/user/profile",
        title: t("user.menu.profile"),
      });
    }

    if (location.pathname.startsWith("/user/profile/profile-handler") && id === "0") {
      defaultBreadItems.push({
        href: "/user/profile/profile-handler",
        title: t("user.profile.labelNew"),
      });
    }

    if (location.pathname.startsWith("/user/profile/profile-handler") && id !== "0") {
      defaultBreadItems.push({
        href: "/user/profile/profile-handler",
        title: t("user.profile.labelDetail"),
      });
    }

    if (location.pathname.startsWith("/user/profile/profile-sync") && id !== "0") {
      defaultBreadItems.push({
        href: "/user/profile/profile-sync",
        title: t("user.profile.syncFromSite.title"),
      });
    }

    if (location.pathname.startsWith("/user/appointment")) {
      defaultBreadItems.push({
        href: "/user/appointment",
        title: t("user.menu.appointment"),
      });
    }

    if (location.pathname.startsWith("/user/appointment/appointment-detail") && key !== "0") {
      defaultBreadItems.push({
        href: "/user/appointment/appointment-detail",
        title: t("user.appointment.appointmentDetail"),
      });
    }

    if (location.pathname.startsWith("/user/prescription")) {
      defaultBreadItems.push({
        href: "/user/prescription",
        title: t("user.menu.prescription"),
      });
    }

    if (location.pathname.startsWith("/user/prescription/prescription-detail")) {
      defaultBreadItems.push({
        href: "/user/prescription/prescription-detail",
        title: "Chi tiết đơn thuốc",
      });
    }

    if (location.pathname.startsWith("/user/package")) {
      defaultBreadItems.push({
        href: "/user/package",
        title: t("user.menu.package"),
      });
    }

    if (location.pathname.startsWith("/user/package/package-detail") && key !== "0") {
      defaultBreadItems.push({
        href: "/user/package/package-detail",
        title: t("user.servicePackage.servicePackageDetail"),
      });
    }

    // if (location.pathname.startsWith("/user/promotion")) {
    //   defaultBreadItems.push({
    //     href: "/user/promotion",
    //     title: t("user.menu.promotion"),
    //   });
    // }

    if (location.pathname.startsWith("/user/examination")) {
      defaultBreadItems.push({
        href: "/user/examination",
        title: t("user.menu.examination"),
      });
    }

    if (location.pathname.startsWith("/user/chronic")) {
      defaultBreadItems.push({
        href: "/user/chronic",
        title: t("user.menu.chronic"),
      });
    }

    if (location.pathname.startsWith("/user/payment")) {
      defaultBreadItems.push({
        href: "/user/payment",
        title: t("user.menu.payment"),
      });
    }

    if (location.pathname.startsWith("/user/payment/payment-detail") && key !== "0") {
      defaultBreadItems.push({
        href: "/user/payment/payment-detail",
        title: t("user.payment.paymentDetail"),
      });
    }

    if (location.pathname.startsWith("/user/change-password")) {
      defaultBreadItems.push({
        href: "/user/change-password",
        title: t("user.menu.changePassword"),
      });
    }

    setBreadItems(defaultBreadItems);
  }, [location.pathname, t]);

  const checkLinkActive = (pathName: string | undefined): boolean => {
    return !!pathName && location.pathname.startsWith(pathName);
  };

  const handleLogout = async () => {
    toggle();
  };

  const onLogout = () => {
    toggle();

    requestAnimationFrame(async () => {
      try {
        await authService.logout();
      } catch (error) {}

      clearLocalStorage();
      dispatch(setAuth(undefined));
      dispatch(setUserInfo(undefined));

      window.location.replace(baseRoutes.home);
    });
  };

  const onUploadAvatar = (value: BeforeUploadReturnType) => {
    if (!value) {
      return;
    }

    try {
      accountService.uploadAvatar(value);
      window.location.reload();
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
    } finally {
      handleGetUserInfo();
    }
  };

  //! render
  return (
    <div className={`${responseUI.screen} ${responseUI.padding} pb-5 grid grid-cols-4 gap-x-8 gap-y-5`}>
      <div className="col-span-4 mt-5">
        <CommonStyles.BreadcrumbUI items={breadItems} />
      </div>

      <div className="bg-white p-8 rounded-lg max-h-[610px] overflow-y-hidden max-xl:hidden">
        <div className="flex items-center gap-4">
          <div className="relative">
            <img
              className="rounded-[50%]"
              width={64}
              height={64}
              src={
                userInfo?.user_profiles?.avatar && userInfo?.user_profiles?.avatar.length > 0
                  ? userInfo?.user_profiles?.avatar[0].link
                  : CommonImages.DefaultAvatar
              }
              alt=""
            />

            <ImgCrop
              rotationSlider
              cropShape="round"
              quality={1}
              modalTitle={t("user.accountSetting.updateAvatar")}
              modalOk={t("Submit")}
              modalCancel={t("Cancel")}
              onModalOk={onUploadAvatar}
            >
              <Upload className="absolute -right-2 -bottom-2" showUploadList={false} action="">
                <div className="w-fit bg-common-orange100 rounded-[50%] text-common-orange p-[5px] cursor-pointer outline outline-white outline-2">
                  <CommonIcons.IconCamera />
                </div>
              </Upload>
            </ImgCrop>
          </div>

          <div className="flex flex-col">
            <div
              className="text-common-blue500 text-[18px] leading-[27px] font-['Inter'] hover:underline cursor-pointer line-clamp-1"
              title={userInfo?.user_profiles?.display_name ? userInfo?.user_profiles?.display_name : "---"}
              onClick={() => navigate(baseRoutes.user)}
            >
              {userInfo?.user_profiles?.display_name ? userInfo?.user_profiles?.display_name : "---"}
              {/* <EditOutlined className="text-common-orange" /> */}
            </div>
            <div className="text-common-subtext text-sm font-['Inter']">{userInfo?.user_name}</div>
          </div>
        </div>

        <Divider />

        <div className="grid grid-flow-row gap-6">
          <NavLink to={baseRoutes.userProfile}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconProfile />}
              text={t("user.menu.profile")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userProfile)}
            />
          </NavLink>

          <NavLink to={baseRoutes.userAppointment}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconCalendarFill />}
              text={t("user.menu.appointment")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userAppointment)}
            />
          </NavLink>

          <NavLink to={baseRoutes.userPrescription}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconMedication />}
              text={t("user.menu.prescription")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userPrescription)}
            />
          </NavLink>

          <NavLink to={baseRoutes.userPackage}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconHealthcare />}
              text={t("user.menu.package")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userPackage)}
            />
          </NavLink>

          {/* <NavLink to={baseRoutes.userPromotion}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconVoucher />}
              text={t("user.menu.promotion")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userPromotion)}
            />
          </NavLink> */}

          <NavLink to={baseRoutes.userExamination}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconTextSnippet />}
              text={t("user.menu.examination")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userExamination)}
            />
          </NavLink>

          <NavLink to={baseRoutes.userChronic}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconMonitorHeart />}
              text={t("user.menu.chronic")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userChronic)}
            />
          </NavLink>

          <NavLink to={baseRoutes.userPayment}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconHistory />}
              text={t("user.menu.payment")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userPayment)}
            />
          </NavLink>
        </div>

        <Divider />

        <div className="grid grid-flow-row gap-6">
          <NavLink to={baseRoutes.userChangePassword}>
            <CommonStyles.NavLinkTextUI
              icon={<CommonIcons.IconLock />}
              text={t("user.menu.changePassword")}
              subColor={themeColor.subtext}
              isActive={checkLinkActive(baseRoutes.userChangePassword)}
            />
          </NavLink>

          <div className="flex items-center gap-3 text-common-negative cursor-pointer" onClick={handleLogout}>
            <CommonIcons.IconLogout />
            <span>{t("user.menu.logout")}</span>
          </div>
        </div>
      </div>

      <div className="col-span-3 max-xl:col-span-4 bg-white rounded-lg">
        <Outlet />
      </div>

      {/* Modal Confirmation */}
      <CommonStyles.ModalConfirmationUI
        title={t("Logout") + "?"}
        okText={t("Logout")}
        content={t("ConfirmLogoutContent") + "?"}
        onConfirm={onLogout}
        open={open}
        toggle={toggle}
      />

      <div className="xl:hidden">
        <FloatButton.Group trigger="click" type="primary" style={{ right: 15 }}>
          <FloatButton description={<CommonIcons.IconProfile />} onClick={() => navigate(baseRoutes.userProfile)} tooltip={t("user.menu.profile")} />
          <FloatButton
            description={<CommonIcons.IconCalendarFill />}
            onClick={() => navigate(baseRoutes.userAppointment)}
            tooltip={t("user.menu.appointment")}
          />
          <FloatButton
            description={<CommonIcons.IconHealthcare />}
            onClick={() => navigate(baseRoutes.userPackage)}
            tooltip={t("user.menu.package")}
          />
          <FloatButton
            description={<CommonIcons.IconTextSnippet />}
            onClick={() => navigate(baseRoutes.userExamination)}
            tooltip={t("user.menu.examination")}
          />
          <FloatButton
            description={<CommonIcons.IconMonitorHeart />}
            onClick={() => navigate(baseRoutes.userChronic)}
            tooltip={t("user.menu.chronic")}
          />
          <FloatButton description={<CommonIcons.IconHistory />} onClick={() => navigate(baseRoutes.userPayment)} tooltip={t("user.menu.payment")} />
          <FloatButton
            description={<CommonIcons.IconLock />}
            onClick={() => navigate(baseRoutes.userChangePassword)}
            tooltip={t("user.menu.changePassword")}
          />
          <FloatButton description={<CommonIcons.IconLogout />} onClick={handleLogout} tooltip={t("user.menu.logout")} />
        </FloatButton.Group>
      </div>
    </div>
  );
}
