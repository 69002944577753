import { useCallback, useEffect, useState } from "react";
import orderService from "../orderService";
import { IFilterMyOrder } from "../interface/filterMyOrder.interface";
import { IOrderInfoPage } from "../interface/OrderInfo.interface";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

const useGetMyOrders = (filters: IFilterMyOrder, enabled = true) => {
  const [data, setData] = useState<IOrderInfoPage>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    const convertedFilters = JSON.parse(JSON.stringify(filters)) as IFilterMyOrder;

    if (convertedFilters.to) {
      convertedFilters.to = dayjs(convertedFilters.to, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
    }

    return orderService.getMyOrders(convertedFilters);
  }, []);

  const transformResponse = useCallback((response: AxiosResponse<IOrderInfoPage>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetMyOrders;
