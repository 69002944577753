export default function IconPerson() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 0C7.6125 0 5.25 2.94 5.25 6.5625C5.25 10.185 7.6125 13.125 10.5 13.125C13.3875 13.125 15.75 10.185 15.75 6.5625C15.75 2.94 13.3875 0 10.5 0ZM5.01375 13.125C2.23125 13.2563 0 15.54 0 18.375V21H21V18.375C21 15.54 18.795 13.2563 15.9862 13.125C14.5688 14.7262 12.6263 15.75 10.5 15.75C8.37375 15.75 6.43125 14.7262 5.01375 13.125Z"
        fill="currentColor"
      />
    </svg>
  );
}
