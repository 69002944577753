import React from "react";

export default function IconVideoCall() {
  return (
    <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1361 25.9051C13.6547 25.8953 14.6086 24.9399 14.6135 23.4238C14.6183 22.2685 14.6232 21.1132 14.6086 19.9579C14.6037 19.6411 14.7011 19.4753 14.998 19.3925C15.5772 19.2219 16.1417 18.9879 16.7258 18.8514C19.4611 18.2274 22.2256 18.1787 24.9998 18.4614C26.3772 18.6028 27.74 18.866 29.0395 19.3974C29.3023 19.5046 29.4094 19.6411 29.4094 19.9433C29.3899 21.084 29.3996 22.2247 29.4045 23.3702C29.4094 24.9642 30.3439 25.9002 31.9451 25.9051C32.8553 25.9099 33.7557 25.9051 34.6658 25.9099C35.6003 25.9099 36.5348 25.9197 37.4692 25.9099C38.978 25.8904 39.932 24.935 39.9417 23.4238C39.9515 22.2637 39.9222 21.1132 39.9515 19.9531C39.9807 18.8806 39.6205 17.9642 38.9342 17.1599C37.8148 15.8535 36.3742 15.0053 34.841 14.3082C31.6774 12.8555 28.324 12.1097 24.8635 11.8123C20.1863 11.4077 15.6258 11.9488 11.1919 13.4844C9.19643 14.1766 7.30313 15.0686 5.70673 16.5018C4.60678 17.4865 3.96432 18.6564 4.05193 20.1968C4.11034 21.2497 4.0568 22.3173 4.06167 23.3751C4.0714 24.9447 5.02048 25.9051 6.57308 25.9099C8.42743 25.9148 10.2818 25.9099 12.1361 25.9051Z"
        fill="currentColor"
      />
    </svg>
  );
}
