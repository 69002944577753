import useFormValidation from "@base/hooks/useFormvalidation";
import usePassword from "@base/modules/user/account/hooks/usePassword";
import CommonStyles from "@components/CommonStyles";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function FormSetupPassword() {
  //! state
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { login } = useFormValidation();
  const [searchParams] = useSearchParams();
  const { isLoading, error, handleUpdatePassword } = usePassword();

  const initialValues = {
    password: "",
    confirmPassword: "",
    invited_by: "",
  };

  const phoneUrl = searchParams.get("phone");
  const otpUrl = searchParams.get("otp");
  const reset = searchParams.get("reset");

  //! function
  const handleSubmit = (values: { old_password: string; password: string; invited_by: string }) => {
    if (!phoneUrl || !otpUrl) {
      return;
    }

    handleUpdatePassword(
      {
        old_password: otpUrl,
        password: values.password,
        user_name: phoneUrl,
      },
      values.invited_by,
    );
  };

  //! render
  return (
    <Form name="setupPasswprd" layout="vertical" initialValues={initialValues} form={form} onFinish={handleSubmit}>
      <CommonStyles.FormFieldUI
        name="password"
        rules={login.password()}
        component={
          <CommonStyles.InputUI use="password" placeholder={t("LoginPage.PlaceholderPassword")} variant="filled" label={t("LoginPage.Password")} />
        }
      />

      <CommonStyles.FormFieldUI
        name="confirmPassword"
        rules={login.confirmPassword()}
        component={
          <CommonStyles.InputUI
            use="password"
            placeholder={t("LoginPage.PlaceholderConfirmPassword")}
            variant="filled"
            label={t("LoginPage.ConfirmPassword")}
          />
        }
      />

      <CommonStyles.FormFieldUI
        hidden={reset === "true"}
        name="invited_by"
        component={
          <CommonStyles.InputUI
            placeholder={t("user.accountSetting.placeholderRefCode")}
            label={t("user.accountSetting.labelRefCode")}
            variant="filled"
          />
        }
      />

      <div className="flex justify-center items-center">
        <CommonStyles.ButtonUI htmlType="submit" className="h-12 w-full" loading={isLoading}>
          <CommonStyles.TypographyUI use="text" className="text-white text-base font-semibold">
            {t("LoginPage.Complete")}
          </CommonStyles.TypographyUI>
        </CommonStyles.ButtonUI>
      </div>
    </Form>
  );
}
