import React from "react";

export default function IconFacebook() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.735 12.7778V10.8444C17.735 10.0067 17.9433 9.55556 19.4007 9.55556H21.2051V6.33333H18.4291C14.959 6.33333 13.5709 8.46 13.5709 10.8444V12.7778H10.7949V16H13.5709V25.6667H17.735V16H20.7887L21.2051 12.7778H17.735Z"
        fill="currentColor"
      />
    </svg>
  );
}
