import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ExtraFormLogin() {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const phoneUrl = searchParams.get("phone");
  //! function
  const handleOtherPhone = () => {
    navigate(baseRoutes.login);
  };
  //! render
  if (!!phoneUrl) {
    return (
      <div className="flex justify-center items-center mt-4 ">
        <CommonStyles.TypographyUI className="!text-common-inprogress font-semibold cursor-pointer" onClick={handleOtherPhone}>
          {t("LoginPage.Other phone number")}
        </CommonStyles.TypographyUI>
      </div>
    );
  }
  return (
    <div className="flex gap-1 mt-4">
      <CommonStyles.TypographyUI>{t("LoginPage.Do not have an account")}</CommonStyles.TypographyUI>
      <CommonStyles.TypographyUI className="!text-common-inprogress font-semibold">{t("LoginPage.Register now")}</CommonStyles.TypographyUI>
    </div>
  );
}
