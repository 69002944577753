import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { WPPagesFilter } from "./interface";
import { Page } from "@base/types/wp";
import axios from "axios";

class WPPagesService {
  getPages(filter: WPPagesFilter) {
    const _filter = Object.assign({}, filter) as WPPagesFilter;
    delete _filter._embed;
    let uri = axios
      .create({
        baseURL: apiService.wp.pages.getPages,
        params: _filter,
      })
      .getUri();

    uri += filter._embed ? "&_embed" : "";

    return httpService.get<Page[]>(uri);
  }
}

export default new WPPagesService();
