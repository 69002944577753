import React, { useRef } from "react";
import { RootState } from "@base/store/store";
import CommonStyles from "@components/CommonStyles";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import { IBanner } from "@base/modules/homeModules/interfaces/IHomeModule.interface";
import bannerService from "@base/modules/banner/banner.service";
import { useTranslation } from "react-i18next";

export default function CarouselHome() {
  //! state
  const { t, i18n } = useTranslation();
  let sliderRef = useRef<Slider>(null);
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          dots: false,
        },
      },
    ],
  };

  //! function
  const onClickBanner = (item: IBanner) => {
    try {
      bannerService.addView(item.id, i18n.resolvedLanguage);
    } catch (error) {}
  };

  //! render
  const renderBanner = () => {
    return dataCommon?.data.banner.slice(0, 5).map((item, index) => {
      return (
        <NavLink
          key={index}
          to={`${item.article_link}`}
          target="_blank"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
          onClick={() => onClickBanner(item)}
        >
          <img src={item.link} alt="banner" />
        </NavLink>
      );
    });
  };

  return (
    <div className="relative min-h-[100px]">
      <CommonStyles.CarouselUI refSlider={sliderRef} settings={settings}>
        {renderBanner()}
      </CommonStyles.CarouselUI>
    </div>
  );
}
