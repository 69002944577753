import React from "react";

export default function ApptOnline() {
  return (
    <div>
      <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.00909424" width="84" height="84" rx="18" fill="#FFF2E3" />
        <g filter="url(#filter0_d_674_517)">
          <path
            d="M27.9241 20.179C26.4998 20.6407 25.2817 21.8882 24.8593 23.3224C24.7218 23.8037 24.7021 25.729 24.7021 42.006C24.7021 59.5403 24.712 60.169 24.8888 60.7485C25.3407 62.1925 26.5784 63.4204 28.0322 63.8428C28.5037 63.9804 29.6825 64 38.0616 64C46.4408 64 47.6195 63.9804 48.091 63.8428C49.5449 63.4204 50.7826 62.1925 51.2345 60.7485C51.3916 60.2377 51.4211 59.835 51.4211 57.8802C51.4211 55.778 51.4014 55.5717 51.2246 55.2771C50.6549 54.3439 49.1028 54.3046 48.5036 55.2279C48.3563 55.4539 48.317 55.7879 48.2777 57.5658C48.2384 59.4224 48.2089 59.668 48.0223 59.9725C47.7472 60.444 47.2855 60.7387 46.6765 60.8075C46.3916 60.8369 42.3249 60.8566 37.6196 60.8369C29.2797 60.8075 29.0636 60.8075 28.7395 60.611C28.3072 60.3556 28.0518 60.002 27.9438 59.5206C27.8161 59.0098 27.8161 24.9825 27.9438 24.4717C28.1501 23.5483 28.6805 23.2241 30.0165 23.1554L30.989 23.1063V23.5581C30.989 24.9039 32.0007 26.5051 33.3465 27.2811C34.2601 27.8115 34.5842 27.8508 38.0714 27.8508C40.9496 27.8508 41.3524 27.8312 41.8828 27.6642C43.6805 27.1043 45.1343 25.2674 45.1343 23.5483V23.1063L46.1068 23.1554C47.1578 23.2045 47.5508 23.3518 47.9044 23.8332C48.2089 24.2359 48.2777 24.7369 48.2777 26.6229C48.2777 28.2143 48.2973 28.4206 48.4741 28.7153C48.7688 29.1868 49.2109 29.4225 49.8494 29.4225C50.4879 29.4225 50.9299 29.1868 51.2246 28.7153C51.4014 28.4206 51.4211 28.2143 51.4211 26.1121C51.4211 24.1573 51.3916 23.7546 51.2345 23.2438C50.7826 21.7998 49.5449 20.5719 48.091 20.1495C47.6195 20.012 46.4408 19.9923 38.0223 20.0021C29.0538 20.0021 28.4546 20.012 27.9241 20.179Z"
            fill="#FFBB7C"
          />
          <path
            d="M36.0184 32.7719C35.3209 33.047 34.6038 33.7641 34.3386 34.4811C34.1323 35.0214 34.1323 35.1688 34.1323 41.9959C34.1323 48.8229 34.1323 48.9703 34.3386 49.5106C34.6137 50.2375 35.3209 50.9447 36.0478 51.2198C36.4899 51.3868 36.8239 51.4261 37.7178 51.4261H38.8474V53.263C38.8474 55.3259 38.9064 55.5518 39.5547 55.9447C39.9476 56.1805 40.7924 56.2099 41.1853 56.0037C41.3327 55.9251 42.4427 54.8642 43.6608 53.6461L45.871 51.4261H51.3425C56.647 51.4261 56.8336 51.4162 57.3641 51.2198C58.091 50.9447 58.7982 50.2375 59.0733 49.5106C59.2796 48.9703 59.2796 48.8229 59.2796 41.9959C59.2796 35.1688 59.2796 35.0214 59.0733 34.4811C58.7982 33.7542 58.091 33.047 57.3641 32.7719C56.8238 32.5656 56.7255 32.5656 46.6765 32.5755C36.7256 32.5755 36.5292 32.5755 36.0184 32.7719Z"
            fill="#F98315"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.9771 45.678H45.4347V43.2899H43.0834V40.7083H45.4347V38.3215H47.9771V40.7083H50.3281V43.2899H47.9771V45.678Z"
            fill="white"
          />
        </g>
        <defs>
          <filter id="filter0_d_674_517" x="11.9909" y="16" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.745098 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_674_517" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_674_517" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
