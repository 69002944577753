import React from "react";

export default function IconMedia() {
  return (
    <div>
      <svg width="139" height="138" viewBox="0 0 139 138" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="69.5" cy="68.6429" r="68.6429" fill="url(#paint0_linear_933_42973)" />
        <circle cx="69.5" cy="68.6429" r="63.6429" stroke="url(#paint1_linear_933_42973)" strokeOpacity="0.3" strokeWidth="10" />
        <path
          d="M78.4459 42.2448C76.8385 42.7658 75.464 44.1736 74.9873 45.792C74.8321 46.3241 74.8099 47.4992 74.8099 55.3365C74.8099 63.1738 74.8321 64.3488 74.9873 64.8809C75.464 66.5215 76.8496 67.9183 78.4792 68.4282C79.0999 68.6277 79.5655 68.6388 84.565 68.6388C89.5644 68.6388 90.03 68.6277 90.6508 68.4282C92.2803 67.9183 93.666 66.5215 94.1427 64.8809C94.2979 64.3488 94.32 63.1738 94.32 55.3365C94.32 47.4992 94.2979 46.3241 94.1427 45.792C93.666 44.1625 92.3579 42.8212 90.6951 42.2669C90.03 42.0452 89.7861 42.0341 84.5428 42.0452C79.5544 42.0452 79.0223 42.0674 78.4459 42.2448ZM88.0014 47.6433C88.5668 47.9758 88.8882 48.5079 88.8882 49.1287C88.8882 49.7495 88.5668 50.2816 88.0014 50.6141C87.6356 50.8358 87.4361 50.8469 84.565 50.8469C81.6939 50.8469 81.4944 50.8358 81.1285 50.6141C79.7318 49.8049 80.0311 47.8539 81.6052 47.4548C81.7937 47.4105 83.2347 47.3772 84.7867 47.3883C87.4139 47.4105 87.6356 47.4216 88.0014 47.6433ZM88.0014 53.851C88.5668 54.1836 88.8882 54.7157 88.8882 55.3365C88.8882 55.9572 88.5668 56.4893 88.0014 56.8219C87.6356 57.0436 87.4361 57.0547 84.565 57.0547C81.6939 57.0547 81.4944 57.0436 81.1285 56.8219C79.7318 56.0127 80.0311 54.0617 81.6052 53.6626C81.7937 53.6182 83.2347 53.585 84.7867 53.5961C87.4139 53.6182 87.6356 53.6293 88.0014 53.851ZM88.0014 60.0588C88.5668 60.3913 88.8882 60.9234 88.8882 61.5442C88.8882 62.165 88.5668 62.6971 88.0014 63.0296C87.6356 63.2514 87.4361 63.2624 84.565 63.2624C81.6939 63.2624 81.4944 63.2514 81.1285 63.0296C79.7318 62.2204 80.0311 60.2694 81.6052 59.8703C81.7937 59.826 83.2347 59.7927 84.7867 59.8038C87.4139 59.826 87.6356 59.8371 88.0014 60.0588Z"
          fill="url(#paint2_linear_933_42973)"
        />
        <g filter="url(#filter0_d_933_42973)">
          <path
            d="M48.2939 42.2448C46.6866 42.7658 45.312 44.1736 44.8353 45.7921C44.6801 46.3131 44.658 47.2664 44.658 52.6428C44.658 56.0792 44.6801 58.8838 44.7134 58.8838C44.7466 58.8838 46.4649 57.609 48.5378 56.057C50.6108 54.494 52.4731 53.1305 52.6726 53.0086C53.0606 52.7647 53.6814 52.7204 54.158 52.9088C54.3243 52.9643 55.4994 54.0506 56.7631 55.3032L59.0577 57.5979L61.3635 56.057C63.4918 54.627 63.7135 54.5051 64.1791 54.5051C64.6669 54.5051 64.8886 54.6381 67.9814 56.7L71.2626 58.8838V52.6317C71.2626 46.8008 71.2515 46.3353 71.052 45.7034C70.5421 44.0739 69.1453 42.6882 67.5047 42.2115C66.9726 42.0563 65.7976 42.0342 57.9159 42.0453C49.5133 42.0453 48.8814 42.0563 48.2939 42.2448Z"
            fill="url(#paint3_linear_933_42973)"
          />
          <path
            d="M48.959 60.0034L44.658 63.2292V63.7502C44.658 65.7234 46.2875 67.7963 48.3272 68.4282C48.9701 68.6277 49.4801 68.6388 57.9603 68.6388C66.4405 68.6388 66.9505 68.6277 67.5934 68.4282C69.6885 67.7741 71.2626 65.7234 71.2626 63.6282V62.9853L67.7708 60.6574C65.853 59.3826 64.2346 58.3295 64.168 58.3295C64.0794 58.3295 62.0064 59.7262 61.696 59.9923C61.6406 60.0366 61.7625 60.3027 61.9621 60.5798C62.738 61.6883 62.1505 63.1072 60.8535 63.2846C59.9778 63.4065 59.8115 63.2735 56.5635 59.9258C54.8675 58.1965 53.4375 56.7775 53.371 56.7775C53.3045 56.7775 51.3202 58.2297 48.959 60.0034Z"
            fill="url(#paint4_linear_933_42973)"
          />
        </g>
        <g filter="url(#filter1_d_933_42973)">
          <path
            d="M48.2939 72.4336C46.6866 73.0494 45.312 74.7133 44.8353 76.6261C44.6801 77.2419 44.658 78.4473 44.658 85.6007C44.658 93.6189 44.669 93.881 44.8797 94.3002C45.2122 94.9422 45.7111 95.2435 46.4538 95.2435C47.0856 95.2435 47.0856 95.2435 49.7572 93.1473L52.4177 91.051H57.8938C62.9597 91.051 63.4253 91.0379 64.0461 90.8021C65.6756 90.1994 67.0613 88.5486 67.538 86.6096C67.7929 85.5614 67.7929 77.6743 67.538 76.6261C67.0613 74.6871 65.6756 73.0363 64.0461 72.4336C63.4142 72.1978 62.9376 72.1847 56.1534 72.1978C49.4579 72.1978 48.8814 72.2109 48.2939 72.4336Z"
            fill="url(#paint5_linear_933_42973)"
          />
        </g>
        <g filter="url(#filter2_d_933_42973)">
          <path
            d="M74.8986 72.3967C73.2913 72.9177 71.9167 74.3255 71.44 75.944C71.174 76.8419 71.174 87.0404 71.44 87.9383C71.9167 89.5789 73.3023 90.9756 74.9319 91.4855C75.5637 91.6851 76.0515 91.6962 82.7913 91.6962C89.5312 91.6962 90.0189 91.6851 90.6508 91.4855C92.2803 90.9756 93.666 89.5789 94.1427 87.9383C94.4087 87.0404 94.4087 76.8419 94.1427 75.944C93.666 74.3034 92.2803 72.9066 90.6508 72.3967C90.0189 72.1972 89.5423 72.1861 82.7581 72.1972C76.0626 72.1972 75.4861 72.2083 74.8986 72.3967ZM83.6227 79.591C85.995 80.7993 86.2278 81.01 86.2278 81.9411C86.2278 82.8723 85.995 83.0829 83.6227 84.2912C81.2616 85.4995 80.8292 85.5993 80.1309 85.1226C79.366 84.6127 79.2995 84.3577 79.2995 81.9411C79.2995 79.5245 79.366 79.2696 80.1309 78.7597C80.8292 78.283 81.2616 78.3828 83.6227 79.591Z"
            fill="url(#paint6_linear_933_42973)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_933_42973"
            x="23.658"
            y="23.0428"
            width="72.6046"
            height="72.5959"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42973" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42973" result="shape" />
          </filter>
          <filter
            id="filter1_d_933_42973"
            x="23.658"
            y="53.1943"
            width="69.0713"
            height="69.0492"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42973" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42973" result="shape" />
          </filter>
          <filter
            id="filter2_d_933_42973"
            x="50.2405"
            y="53.1942"
            width="69.1017"
            height="65.502"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="11.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.677006 0 0 0 0 0.329167 0 0 0 0.49 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_933_42973" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_933_42973" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_933_42973" x1="122.453" y1="4.57323e-06" x2="11.1535" y2="125.519" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF6ED" />
            <stop offset="1" stopColor="#FFF2E3" />
          </linearGradient>
          <linearGradient id="paint1_linear_933_42973" x1="0.857048" y1="150.655" x2="80.1982" y2="-6.88642" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint2_linear_933_42973" x1="47.9112" y1="115.551" x2="117.775" y2="87.0814" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFD3A2" stopOpacity="0.4" />
          </linearGradient>
          <linearGradient id="paint3_linear_933_42973" x1="44.658" y1="58.8838" x2="76.3783" y2="19.42" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
          <linearGradient id="paint4_linear_933_42973" x1="44.658" y1="68.6388" x2="64.2725" y2="33.9909" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
          <linearGradient id="paint5_linear_933_42973" x1="44.658" y1="95.2435" x2="87.8826" y2="61.1701" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
          <linearGradient id="paint6_linear_933_42973" x1="71.2405" y1="91.6962" x2="108.762" y2="56.6922" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
