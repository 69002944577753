import React from "react";

export default function IconDown({ color }: { color?: string }) {
  return (
    <div>
      <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9076 0.792893C14.2981 1.18342 14.2981 1.81658 13.9076 2.20711L7.90758 8.20711C7.72004 8.39464 7.46569 8.5 7.20047 8.5C6.93525 8.5 6.6809 8.39464 6.49336 8.20711L0.493363 2.20711C0.102839 1.81658 0.102839 1.18342 0.493363 0.792893C0.883887 0.402369 1.51705 0.402369 1.90758 0.792893L7.20047 6.08579L12.4934 0.792893C12.8839 0.402369 13.5171 0.402369 13.9076 0.792893Z"
          fill={color ? color : "#00335F"}
        />
      </svg>
    </div>
  );
}
