import { Drawer, DrawerProps } from "antd";

interface IProps extends DrawerProps {}
export default function DrawerUI(props: IProps) {
  //! state
  const { children, ...res } = props;
  //! function
  //! render
  return <Drawer {...res}>{children}</Drawer>;
}
