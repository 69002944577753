import React from "react";
import { Timeline, TimelineProps } from "antd";

interface IProps extends TimelineProps {}

export default function TimelineUI(props: IProps) {
  return (
    <Timeline
      {...props}
      className="[&_.ant-timeline-item]:ml-[15px] [&_.ant-timeline-item-content]:ml-10 [&_.ant-timeline-item-head.ant-timeline-item-head-custom]:translate-x-[-50%] [&_.ant-timeline-item-head.ant-timeline-item-head-custom]:translate-y-[-31%]"
    />
  );
}
