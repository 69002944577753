import useGetByOrderKey from "@base/modules/order/hooks/useGetByOrderKey";
import CommonStyles from "@components/CommonStyles";
import { Divider, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrescriptionCard from "./PrescriptionCard";
import useGetByCode from "@base/modules/drugOrder/hooks/useGetByCode";
import { useEffect, useState } from "react";
import useGetState from "@base/modules/state/hooks/useGetState";
import administrativeService from "@base/modules/administrative/administrativeService";

export default function PrescriptionDetail() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { code } = useParams();
  const { data: states } = useGetState();

  const { isLoading, data, error, refetch } = useGetByCode(code, "vi");
  const [provinceName, setProvinceName] = useState<string>("");
  const [districtName, setDistrictName] = useState<string>("");

  useEffect(() => {
    if (data && data.patient_record?.administrative_province_code) {
      setProvinceName(states?.data.find((e) => e.code === data.patient_record?.administrative_province_code)?.name ?? "");

      if (data && data.patient_record?.administrative_district_code) {
        getDistrictName(data.patient_record?.administrative_province_code, data.patient_record.administrative_district_code);
      }
    }
  }, [data]);

  const getDistrictName = async (province_code: string, district_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);

      setDistrictName(resp.data.find((e) => e.code === district_code)?.name ?? "");
    } catch (error) {}
  };

  return (
    <div className="p-5">
      <div className="flex flex-col gap-1">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">Chi tiết đơn thuốc</div>
      </div>

      <Divider className="mt-4 mb-5" />

      <div className="flex justify-between max-lg:flex-col max-lg:justify-start items-start gap-20 max-lg:gap-2 relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading} />

        <div className="w-full">
          <PrescriptionCard drugOrder={data ?? {}} />

          <div className="flex justify-between items-start max-md:flex-col max-md:justify-start max-md:items-start mt-5 gap-5">
            <div className="grow flex flex-col">
              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Mã order:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-orange font-normal">
                  {data?.drug_order_code}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Họ và tên:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {data?.patient_record?.patient_name}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Số điện thoại:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {data?.patient_record?.patient_phone_number}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Tỉnh/Thành phố:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {provinceName}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Quận/Huyện:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {districtName}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Địa chỉ chi tiết:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {data?.patient_record?.patient_address}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-1">
                <CommonStyles.TypographyUI use="paragraph" className="flex-none w-[150px] text-base text-common-subtext font-normal">
                  Ghi chú:
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="grow text-base text-common-maintext font-normal">
                  {data?.notes}
                </CommonStyles.TypographyUI>
              </div>
            </div>

            {data?.images && data?.images.length ? (
              <div className="flex-none w-[300px] flex flex-col">
                <CommonStyles.TypographyUI use="paragraph" className="text-base text-common-maintext font-normal">
                  Hình ảnh đơn thuốc:
                </CommonStyles.TypographyUI>

                <div className="flex gap-2">
                  <div className="flex-none w-fit">
                    <Image key={0} height={250} width={200} src={data.images[0].link} />
                  </div>
                  {data.images.length > 1 ? (
                    <div className="grow">
                      <div className="flex flex-col gap-2 max-h-[250px] overflow-y-auto">
                        {data.images.map((img, idx) => {
                          return idx >= 1 ? <Image key={idx} className="max-w-full" src={img.link} /> : null;
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
