import { Modal, ModalProps, Space } from "antd";
import ButtonUI from "../Button";
import { useTranslation } from "react-i18next";
import TypographyUI from "../Typography";
interface IProps extends ModalProps {
  toggle: () => void;
  content: React.ReactNode;
  onConfirm?: () => void;
  type?: "confirm" | "normal";
  hiddenAction?: boolean;
}

export default function ModalConfirmationUI(props: IProps) {
  //! state
  const { t } = useTranslation();
  const { toggle, onConfirm, title = t("Confirmation"), content, okText = t("Submit"), cancelText = t("Cancel"), type, hiddenAction, ...res } = props;

  //! render
  if (type === "normal") {
    return (
      <Modal
        title={<p className="font-medium">{title}</p>}
        // className={`${className}`}
        // width={width ?? 1000}
        onCancel={toggle}
        centered
        zIndex={2000}
        maskClosable={false}
        footer={
          !hiddenAction && (
            <Space size="middle">
              <ButtonUI
                className="w-fit h-12 px-5 py-3 bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
                onClick={toggle}
              >
                {cancelText}
              </ButtonUI>

              <ButtonUI className="w-fit h-12 px-5 py-3" onClick={onConfirm} type="primary">
                {okText}
              </ButtonUI>
            </Space>
          )
        }
        {...res}
      >
        <div>{content}</div>
      </Modal>
    );
  }
  return (
    <Modal
      title={<TypographyUI className="text-2xl">{title}</TypographyUI>}
      {...res}
      onCancel={toggle}
      centered
      classNames={{
        footer: "!pt-4",
        header: "!pb-4",
      }}
      footer={
        <Space size="middle">
          <ButtonUI
            className="w-fit h-12 px-5 py-3 bg-common-orange200 text-common-orange hover:!bg-common-orange300 hover:!text-common-orange"
            onClick={toggle}
          >
            {cancelText}
          </ButtonUI>

          <ButtonUI className="w-fit h-12 px-5 py-3" onClick={onConfirm} type="primary">
            {okText}
          </ButtonUI>
        </Space>
      }
      {...res}
    >
      {content}
    </Modal>
  );
}
