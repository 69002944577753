import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";
import { Outlet } from "react-router";
import { useEffect, useRef, useState } from "react";
import { FloatButton, Menu } from "antd";
import { baseRoutes } from "@base/routes/baseRoutes";
import { menu } from "@base/constants";
import type { GetProp, MenuProps } from "antd";
import CommonStyles from "@components/CommonStyles";
import useClickOutSide from "@base/hooks/useClickOutside";
import useWindowDimensions from "@base/hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import { initialPage } from "@base/themes/colorTheme";
import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { backToTop } from "@base/helpers/common";
import { responseUI } from "@base/themes/response";
import useGetHomeModule from "@base/modules/homeModules/hooks/useGetHomeModule";
import ImageLinkPopup from "@components/CommonStyles/ImageLinkPopup";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";

type MenuItem = GetProp<MenuProps, "items">[number];

interface PageLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
}

const PageLayout = (props: PageLayoutProps) => {
  //! state
  const { data } = useGetHomeModule();
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);
  //! function

  //! render
  return (
    <div className={` `}>
      {/* Page Header */}
      <div className={`mx-auto w-screen sm:w-[600px] md:w-[700px] lg:w-[900px] xl:w-[1200px] h-fit ${responseUI.padding}`}>
        <HeaderLayout />
      </div>

      {/* Page Content */}
      <div className="w-full  pt-2 md:pt-5 xl:pt-0">
        <div className="bg-common-background">
          <Outlet />
        </div>
      </div>

      {/* Page Footer */}

      <div className={`flex w-full  ${responseUI.screen} ${responseUI.padding}`}>
        <FooterLayout />
      </div>

      <ImageLinkPopup
        links={dataCommon?.data.broadcast_news ?? []}
        defaultEnable
        sessionStorageKey={baseRoutes.home}
        routeToCheck={baseRoutes.home}
      />
    </div>
  );
};

export default PageLayout;
