import { useState } from "react";

const useToggle = (status = false) => {
  const [open, setOpen] = useState(status);

  const toggle = () => {
    setOpen(!open);
  };

  const shouldRender = open;

  return {
    open,
    toggle,
    shouldRender,
  };
};

export default useToggle;
