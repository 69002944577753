export default function IconCalenderPlus() {
  return (
    <div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.6"
          d="M22.3333 4.74699V2.66699C22.3333 2.12033 21.88 1.66699 21.3333 1.66699C20.7867 1.66699 20.3333 2.12033 20.3333 2.66699V4.66699H11.6667V2.66699C11.6667 2.12033 11.2133 1.66699 10.6667 1.66699C10.12 1.66699 9.66666 2.12033 9.66666 2.66699V4.74699C6.06666 5.08032 4.31998 7.22699 4.05331 10.4137C4.02665 10.8003 4.34665 11.1203 4.71998 11.1203H27.28C27.6667 11.1203 27.9867 10.787 27.9467 10.4137C27.68 7.22699 25.9333 5.08032 22.3333 4.74699Z"
          fill="#F98315"
        />
        <path
          d="M28 14.4534V16.7734C28 17.5867 27.28 18.2134 26.48 18.08C26.1067 18.0267 25.72 17.9867 25.3333 17.9867C21.2933 17.9867 18 21.28 18 25.32C18 25.9334 18.24 26.7867 18.4933 27.5601C18.7867 28.4267 18.1467 29.32 17.2267 29.32H10.6667C6 29.32 4 26.6534 4 22.6534V14.44C4 13.7067 4.6 13.1067 5.33333 13.1067H26.6667C27.4 13.12 28 13.72 28 14.4534Z"
          fill="#F98315"
        />
        <path
          d="M11.3333 19.9999C10.9867 19.9999 10.64 19.8532 10.3867 19.6132C10.1467 19.3599 10 19.0132 10 18.6666C10 18.3199 10.1467 17.9732 10.3867 17.7199C10.6933 17.4132 11.16 17.2665 11.6 17.3599C11.68 17.3732 11.76 17.3999 11.84 17.4399C11.92 17.4665 12 17.5066 12.08 17.5599C12.1467 17.6132 12.2133 17.6666 12.28 17.7199C12.52 17.9732 12.6667 18.3199 12.6667 18.6666C12.6667 19.0132 12.52 19.3599 12.28 19.6132C12.2133 19.6665 12.1467 19.7199 12.08 19.7732C12 19.8265 11.92 19.8666 11.84 19.8932C11.76 19.9332 11.68 19.9599 11.6 19.9732C11.5067 19.9866 11.4133 19.9999 11.3333 19.9999Z"
          fill="white"
        />
        <path
          d="M16.0001 19.9996C15.6534 19.9996 15.3067 19.8529 15.0534 19.6129C14.8134 19.3596 14.6667 19.0129 14.6667 18.6663C14.6667 18.3196 14.8134 17.9729 15.0534 17.7196C15.5601 17.2263 16.4534 17.2263 16.9467 17.7196C17.1867 17.9729 17.3334 18.3196 17.3334 18.6663C17.3334 19.0129 17.1867 19.3596 16.9467 19.6129C16.6934 19.8529 16.3467 19.9996 16.0001 19.9996Z"
          fill="white"
        />
        <path
          d="M11.3333 24.6671C10.9867 24.6671 10.64 24.5205 10.3867 24.2805C10.1467 24.0271 10 23.6805 10 23.3338C10 22.9871 10.1467 22.6405 10.3867 22.3872C10.52 22.2672 10.6533 22.1738 10.8267 22.1071C11.32 21.8938 11.9067 22.0138 12.28 22.3872C12.52 22.6405 12.6667 22.9871 12.6667 23.3338C12.6667 23.6805 12.52 24.0271 12.28 24.2805C12.0267 24.5205 11.68 24.6671 11.3333 24.6671Z"
          fill="white"
        />
        <path
          d="M29.1067 21.56C27.0267 19.48 23.64 19.48 21.56 21.56C19.48 23.64 19.48 27.0266 21.56 29.1066C23.64 31.1866 27.0267 31.1866 29.1067 29.1066C31.1867 27.0266 31.1867 23.64 29.1067 21.56ZM28.0933 26.08C27.92 26.2666 27.6667 26.3733 27.3867 26.3733H26.4V27.4133C26.4 27.6933 26.2933 27.9333 26.1067 28.12C25.92 28.3066 25.68 28.4133 25.4 28.4133C24.8533 28.4133 24.4 27.96 24.4 27.4133V26.3733H23.4C22.8533 26.3733 22.4 25.9333 22.4 25.3733C22.4 24.8266 22.8533 24.3733 23.4 24.3733H24.4V23.4267C24.4 22.88 24.84 22.4267 25.4 22.4267C25.9467 22.4267 26.4 22.88 26.4 23.4267V24.3733H27.3867C27.9467 24.3733 28.3867 24.8266 28.3867 25.3733C28.3867 25.6533 28.28 25.9066 28.0933 26.08Z"
          fill="#FBB573"
        />
      </svg>
    </div>
  );
}
