export default function IconMap() {
  return (
    <svg width="55" height="58" viewBox="0 0 55 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_674_483)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6545 33.4399C27.2402 33.4386 26.8497 33.2554 26.5946 32.943C26.4816 32.8037 23.7937 29.4912 21.2479 25.3819C18.3115 20.5849 16.886 16.9184 16.886 14.1667C16.886 8.49896 21.7186 3.8877 27.6584 3.8877C33.5983 3.8877 38.4309 8.49896 38.4309 14.1667C38.4309 17.2333 36.5768 21.6124 32.9211 27.1837C30.7789 30.4435 28.7999 32.8489 28.7157 32.9506C28.4606 33.2593 28.0714 33.4399 27.6584 33.4399H27.6545ZM33.0447 14.1667C33.0447 11.3322 30.629 9.02722 27.6584 9.02722C24.6879 9.02722 22.2722 11.3322 22.2722 14.1667C22.2722 17.0013 24.6879 19.3063 27.6584 19.3063C30.629 19.3063 33.0447 17.0013 33.0447 14.1667ZM30.3509 14.1675C30.3509 15.5867 29.1452 16.7372 27.6579 16.7372C26.1706 16.7372 24.9648 15.5867 24.9648 14.1675C24.9648 12.7483 26.1706 11.5978 27.6579 11.5978C29.1452 11.5978 30.3509 12.7483 30.3509 14.1675Z"
          fill="#FFBB7C"
        />
        <path
          d="M49.9277 22.3965C49.4911 22.1719 48.9585 22.197 48.5456 22.4605L42.56 26.2682V47.5157L50.0394 42.7577C50.4142 42.5193 50.6391 42.1178 50.6391 41.6887V23.5296C50.6391 23.0553 50.3656 22.6199 49.9277 22.3965Z"
          fill="url(#paint0_linear_674_483)"
        />
        <path
          d="M4.676 28.6687V46.8279C4.676 47.3022 4.94952 47.7376 5.38741 47.961C5.82395 48.1856 6.35789 48.158 6.76948 47.897L12.7551 44.0889V22.8416L5.27565 27.5996C4.90087 27.838 4.676 28.2395 4.676 28.6687H4.676Z"
          fill="url(#paint1_linear_674_483)"
        />
        <path
          d="M35.202 28.9161C32.9574 32.3315 30.9126 34.8083 30.8272 34.9125C30.0579 35.8435 28.9021 36.378 27.6568 36.378H27.6489C26.3958 36.3742 25.2373 35.8322 24.468 34.8912C24.1669 34.5198 21.4909 31.1947 18.9281 27.0579C17.9596 25.4758 17.165 24.0236 16.501 22.66L15.5381 22.4764V43.2556L39.7754 47.8813V27.0987L36.7122 26.5087C36.2441 27.2878 35.7461 28.0866 35.202 28.9161Z"
          fill="url(#paint2_linear_674_483)"
        />
      </g>
      <defs>
        <filter id="filter0_d_674_483" x="-2.34245" y="0" width="60" height="61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.881329 0 0 0 0 0.744401 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_674_483" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_674_483" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_674_483" x1="48.4804" y1="22.2067" x2="38.9716" y2="24.4797" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9939" />
          <stop offset="1" stopColor="#F98315" />
        </linearGradient>
        <linearGradient id="paint1_linear_674_483" x1="10.5964" y1="22.8044" x2="1.0877" y2="25.0774" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9939" />
          <stop offset="1" stopColor="#F98315" />
        </linearGradient>
        <linearGradient id="paint2_linear_674_483" x1="33.2992" y1="22.439" x2="13.3136" y2="36.6961" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9939" />
          <stop offset="1" stopColor="#F98315" />
        </linearGradient>
      </defs>
    </svg>
  );
}
