import { IAccountSetting } from "@base/modules/user/account/account.interface";
import accountService from "@base/modules/user/account/accountService";
import CommonStyles from "@components/CommonStyles";
import { Divider, Form } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage, setAuth } from "@base/store/reducers/appCommonSlice";
import { RootState } from "@base/store/store";
import CommonIcons from "@base/assets/icons";
import useFormValidation from "@base/hooks/useFormvalidation";
import { responseUI } from "@base/themes/response";

export default function AccountSetting() {
  //! state
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accountSetting: accounfValidation } = useFormValidation();
  const [form] = Form.useForm();

  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [accountSetting, setAccountSetting] = useState<IAccountSetting>({ display_name: "", dob: "", email: "", invited_by: "" });

  //! function
  useEffect(() => {
    getAccountSetting();
  }, [userInfo]);
  useEffect(() => {
    accountSetting.dob = accountSetting.dob ? dayjs(accountSetting.dob, "YYYY-MM-DD") : undefined;
    form.resetFields(Object.keys(accountSetting as IAccountSetting));
  }, [accountSetting]);

  const getAccountSetting = async () => {
    if (!userInfo?.user_key) {
      return;
    }

    try {
      if (!isLoading) {
        setIsLoading(true);
      }
      const resp = await accountService.getAccountSetting(userInfo.user_key);
      setAccountSetting(resp.data ?? {});
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: IAccountSetting) => {
    if (!userInfo?.user_key) {
      return;
    }

    try {
      setIsSaving(true);
      await accountService.updateAccountSetting(userInfo.user_key, values);
      window.location.reload();
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
    } finally {
      setIsSaving(false);
    }
  };

  const onCopyRefCode = () => {
    navigator.clipboard.writeText(form.getFieldValue("invited_by"));

    dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Copied") + "!" }));
  };
  //! render
  const refCodeCopy = (
    <div className="flex items-center gap-2 text-sm font-medium font-['Inter'] text-common-orange cursor-pointer" onClick={onCopyRefCode}>
      <CommonIcons.IconCopy /> {t("copy")}
    </div>
  );

  if (isLoading) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.accountSetting.AccountInfo")}</div>
      </div>

      <Divider />

      <div className="flex flex-col gap-6">
        <Form form={form} initialValues={accountSetting} onFinish={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
          <CommonStyles.FormFieldUI
            name="display_name"
            rules={accounfValidation.display_name()}
            component={
              <CommonStyles.InputUI
                variant="filled"
                label={t("user.profile.handler.labelName")}
                placeholder={t("user.profile.handler.placeholderName")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="dob"
            component={
              <CommonStyles.DatePickerUI
                variant="filled"
                label={t("user.profile.handler.labelDateOfBirth")}
                labelStyle={responseUI.text.default}
                placeholder={t("user.profile.handler.placeholderDateOfBirth")}
                format="DD/MM/YYYY"
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="userPhoneNumber"
            initialValue={userInfo?.user_name}
            component={
              <CommonStyles.InputUI
                disabled
                variant="filled"
                label={t("user.profile.handler.labelPhoneNumber")}
                placeholder={t("user.profile.handler.placeholderPhoneNumber")}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="email"
            rules={accounfValidation.email()}
            component={
              <CommonStyles.InputUI
                variant="filled"
                label={t("user.profile.handler.labelEmail")}
                placeholder={t("user.profile.handler.placeholderEmail")}
              />
            }
          />

          <div className="sm:col-span-2">
            <CommonStyles.FormFieldUI
              name="invited_by"
              component={
                <CommonStyles.InputUI
                  variant="filled"
                  disabled={!!accountSetting.invited_by}
                  label={t("user.accountSetting.labelRefCode")}
                  placeholder={t("user.accountSetting.placeholderRefCode")}
                  suffix={refCodeCopy}
                />
              }
            />
          </div>

          <div className="sm:col-span-2 flex justify-center items-center">
            <CommonStyles.ButtonUI className="h-12" htmlType="submit" loading={isSaving}>
              {t("user.accountSetting.btnSave")}
            </CommonStyles.ButtonUI>
          </div>
        </Form>
      </div>
    </div>
  );
}
