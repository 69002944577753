import CommonImages from "@base/assets/images";
import TypographyUI from "../Typography";
import ButtonUI from "../Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { ISiteSpecialPackage } from "@base/modules/siteSpecialPackage/interface/ISiteSpecialPackage";
import { getAvatarLink } from "@base/helpers/common";

interface IProps {
  root?: "specialist" | "csyt";
  data?: ISiteSpecialPackage;
}
export default function CardPackageService(props: IProps) {
  //! state
  const { root, data } = props;
  const specialist = root === "specialist";
  const navigate = useNavigate();

  //! function
  const handleBooking = () => {
    navigate({
      pathname: `${baseRoutes.bookingPackage}/${data?.id}${baseRoutes.paymentPackageService}`,
      search: createSearchParams({
        site_code: String(data?.site.code),
        package_code: String(data?.code),
      }).toString(),
    });
  };

  const handleDetail = () => {
    navigate({ pathname: `${baseRoutes.bookingPackage}/${data?.slug}` });
  };

  //! render
  return (
    <div className="border border-common-divider bg-common-white p-6 rounded-2xl flex flex-col justify-between min-h-[450px]">
      <div className="flex flex-col gap-3">
        <div className="flex items-center ">
          <img
            className="rounded-lg h-full w-full aspect-video"
            src={data?.thumbnail ?? CommonImages.default512288}
            alt={data?.thumbnail}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = CommonImages.default512288;
            }}
          />
        </div>

        <div className="flex flex-col gap-4 items-start justify-start">
          <div className="flex gap-2 items-center justify-start">
            <div className="size-8 flex items-start flex-none">
              <img
                className=" rounded-full aspect-square border border-common-divider object-contain"
                src={getAvatarLink(data?.site.avatar ?? [])}
                alt={getAvatarLink(data?.site.avatar ?? [])}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.SiteDefault;
                }}
              />
            </div>

            <TypographyUI
              use="paragraph"
              className=" text-sm xl:text-sm  text-common-subtext !mb-0  line-clamp-1 md:flex md:items-center"
              title={String(data?.site.name)}
            >
              {data?.site.name}
            </TypographyUI>
          </div>

          <TypographyUI use="paragraph" className="text-sm md:text-base lg:text-lg font-medium line-clamp-2 md:h-12 !mb-0" title={String(data?.name)}>
            {data?.name}
          </TypographyUI>

          {Number(data?.amount) > 0 && (
            <TypographyUI className="!text-common-inprogress text-sm md:text-base lg:text-lg font-medium">{`${data?.amount.toLocaleString()} ${
              data?.currency
            }`}</TypographyUI>
          )}
        </div>
      </div>

      <div className="py-3">
        <div className="flex flex-col lg:flex-row items-center gap-3 lg:gap-5 ">
          <ButtonUI onClick={handleBooking} className="grow lg:h-10 font-medium lg:text-base w-full">
            Mua ngay
          </ButtonUI>
          <ButtonUI
            onClick={handleDetail}
            className="grow lg:h-10 w-full font-medium text-base bg-common-orange100 text-common-mainColor hover:!text-common-mainColor hover:!bg-common-orange200"
          >
            Xem chi tiết
          </ButtonUI>
        </div>
      </div>
    </div>
  );
}
