import React from "react";
import { ISpecialPackage } from "@base/modules/patientSpecialPackage/patientSpecialPackage.interface";
import CommonStyles from "@components/CommonStyles";
import TagSpecialPackageStatus from "@components/CommonStyles/TagSpecialPackageStatus";
import { Divider } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ECurrency, ESpecialPackageStatus } from "@base/enums";
import CommonImages from "@base/assets/images";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonIcons from "@base/assets/icons";

interface IProps {
  specialPackage: ISpecialPackage;
}

export default function ServicePackageCard({ specialPackage }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full bg-common-background p-3 rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="flex items-center max-sm:flex-col max-sm:items-start gap-1">
          <span className="text-black text-base font-medium font-['Inter'] max-sm:line-clamp-1">{specialPackage.patient_record?.patient_name}</span>
          <span className="text-common-subtext text-xs font-normal font-['Inter'] max-sm:line-clamp-1">
            ({dayjs(specialPackage.patient_record?.patient_dob, "YYYY-MM-DD").format("DD/MM/YYYY")})
          </span>
        </div>

        <div>
          <TagSpecialPackageStatus status={specialPackage.status as ESpecialPackageStatus} />
        </div>
      </div>

      <Divider className="my-4" />

      <div className="flex justify-between items-center max-sm:flex-col max-sm:justify-start max-sm:items-start max-sm:gap-2">
        <div className="flex items-start gap-4">
          <div className="w-fit max-md:hidden">
            <CommonStyles.ImageUI height={125} width={222} src={specialPackage.thumbnail} fallback={CommonImages.DrafPackage} />
          </div>

          <div className="flex flex-col gap-2">
            <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-base font-bold font-['Inter'] !mb-0">
              {specialPackage.package_name}
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI use="paragraph" className="text-common-inprogress text-base font-medium font-['Inter'] !mb-0">
              {specialPackage.amount?.toLocaleString()}
              {ECurrency.VND}
            </CommonStyles.TypographyUI>

            <div className="flex items-center gap-1">
              <CommonIcons.IconLocation />
              <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-xs font-normal font-['Inter'] !mb-0 inline-block">
                {specialPackage.site?.name}
                {specialPackage.site_branch_name ? ", " + specialPackage.site_branch_name : ""}
              </CommonStyles.TypographyUI>
            </div>

            {/* {specialPackage.start_date ? (
              <div className="flex items-center gap-2">
                <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-xs font-normal font-['Inter'] !mb-0">
                  Thời hạn:{" "}
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="text-common-negative text-xs font-normal font-['Inter'] !mb-0">
                  {dayjs(specialPackage.start_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </CommonStyles.TypographyUI>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}
