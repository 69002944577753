import type { CollapseProps } from "antd";
import { Collapse } from "antd";

interface IProps extends CollapseProps {}
export default function CollapseUI(props: IProps) {
  //! state
  const { ...res } = props;

  //! function

  //! render
  return <Collapse {...res} />;
}
