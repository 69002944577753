export default function IconVideoFilled() {
  return (
    <div>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7031 5.9545C17.1814 5.72783 16.5764 5.82617 16.1606 6.2095L14.3597 7.87617V6.66617C14.3597 5.28783 13.2389 4.16617 11.8597 4.16617H4.35974C2.98141 4.16617 1.85974 5.28783 1.85974 6.66617V13.3328C1.85974 14.712 2.98141 15.8328 4.35974 15.8328H11.8597C13.2389 15.8328 14.3597 14.712 14.3597 13.3328V12.1237L16.1606 13.7895C16.4281 14.037 16.7739 14.1662 17.1264 14.1662C17.3206 14.1662 17.5164 14.127 17.7031 14.0453C18.2106 13.8237 18.5264 13.3495 18.5264 12.8087V7.19117C18.5264 6.65033 18.2106 6.17617 17.7031 5.9545Z"
          fill="#F98315"
        />
      </svg>
    </div>
  );
}
