import CommonStyles from "..";
import { EAppointmentStatus } from "@base/enums";

interface IProps {
  status: EAppointmentStatus;
}

export default function TagAppointmentStatus({ status }: IProps) {
  const getTagName = () => {
    switch (status) {
      case EAppointmentStatus.PENDING:
        return "Chờ khám";
      case EAppointmentStatus.IN_PROGRESS:
        return "Đang khám";
      case EAppointmentStatus.COMPLETED:
        return "Hoàn thành";
      case EAppointmentStatus.CANCELLED:
        return "Đã hủy";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case EAppointmentStatus.PENDING:
        return "bg-common-green100 text-common-positive border-none";
      case EAppointmentStatus.IN_PROGRESS:
        return "bg-common-orange100 text-common-orange500 border-none";
      case EAppointmentStatus.COMPLETED:
        return "bg-gray-100 text-gray-500 border-none";
      case EAppointmentStatus.CANCELLED:
        return "bg-common-red100 text-common-negative border-none";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
