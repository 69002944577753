import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { backToTop } from "@base/helpers/common";
import useParseOption from "@base/hooks/useParseOptions";
import useGetDoctors from "@base/modules/doctor/hooks/useGetDoctors";
import IFilterDcotor from "@base/modules/doctor/interface/filterDoctor.interafce";
import IFilterSite from "@base/modules/site/filterSite.interface";
import useGetListSite from "@base/modules/site/hooks/useGetListSite";
import useGetSpecializetation from "@base/modules/specialization/hooks/useGetSpecialization";
import useGetState from "@base/modules/state/hooks/useGetState";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor, { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import CardDoctor from "@components/CommonStyles/CardDoctor";
import FilterBookingHeader from "@components/CommonStyles/Filter/Booking/FilterBookingHeader";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function BookingOnline() {
  //! state
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Đặt lịch",
    },
    {
      title: "Tư vấn trực tuyến",
    },
  ];
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IFilterDcotor>({
    page: 0,
    size: 12,
    doctor_name: "",
    specialization_code: null,
    online: true,
    state_code: null,
    site_code: null,
  });
  const [filterSite, setFilterSite] = useState<IFilterSite>({
    page_number: 0,
    page_size: 50,
    district_code: null,
    search_text: null,
    state_id: null,
    supported_seller: null,
    ward_code: null,
  });
  const [textSearch, setTextSearch] = useState("");

  const { data: dataState, isLoading: loadingState } = useGetState();
  const { data: dataSpecialization, isLoading: loadingSpecialization } = useGetSpecializetation();
  const { data: dataSite, isLoading: loadingSite, refetch: refetchSite } = useGetListSite(filterSite, false);
  const { data: dataDoctor, isLoading: loadingDoctor, refetch: refetchDoctor } = useGetDoctors(filters, false);
  const listDoctor = dataDoctor?.data.content_page;
  //!function
  const optionState = useParseOption(dataState?.data, "name", "code");
  const optionSite = useParseOption(dataSite?.data.content_page, "name", "code");
  const optionSpecialization = useParseOption(dataSpecialization?.data, "specialization_name", "specialization_code");
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, doctor_name: value }));
      }, 1000),
    [],
  );

  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };

  const changeSelect = (value: any, key: string) => {
    if (key === "state_code") {
      const findState = dataState?.data.find((item) => item.code === value);
      if (findState) {
        setFilterSite((prev) => ({ ...prev, state_id: findState.id }));
        setFilters((prev) => ({ ...prev, [key]: value, site_code: null }));
        return;
      }
    }
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const clearFilter = () => {
    if (filters.page !== 0 || filters.doctor_name || filters.specialization_code || filters.state_code || filters.site_code) {
      setFilters({ page: 0, size: 12, online: true });
      setFilterSite((prev) => ({ ...prev, state_id: null }));
      setTextSearch("");
    }
  };
  useEffect(() => {
    refetchDoctor();
  }, [filters]);

  useEffect(() => {
    refetchSite();
  }, [filterSite]);

  useEffect(() => {
    backToTop();
  }, []);
  //! render
  const renderDoctors = () => {
    if (loadingDoctor) {
      return <CommonStyles.LoadingUI />;
    }
    return (
      <div className={`grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-8 ${responseUI.padding}`}>
        {listDoctor?.map((doctor, index) => {
          return <CardDoctor doctor={doctor} key={index} />;
        })}
      </div>
    );
  };

  return (
    <div className="bg-common-white">
      {/* search */}

      <FilterBookingHeader
        title="Tư vấn sức khỏe trực tuyến"
        subTitle="Đặt lịch Tư vấn trực tiếp thông qua Video call với các Chuyên gia y tế hàng đầu trong lĩnh vực bạn đang quan tâm"
        ComponentFilter={
          <CommonStyles.InputUI
            placeholder="Nhập nội dung cần tìm kiếm..."
            variant="filled"
            onChange={changeSearch}
            prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
            value={textSearch}
          />
        }
      />

      <div className="bg-common-background">
        <div className={` py-4 flex flex-col gap-6 ${responseUI.screen}`}>
          {/* filter */}
          <div className="grid grid-cols-2 md:flex lg:flex-nowrap lg:gap-4 items-center bg-common-white p-4 rounded-2xl">
            <div className="basis-1/2 p-2 lg:grow lg:p-0">
              <CommonStyles.SelectUI
                loading={loadingState}
                value={filters.state_code}
                onChange={(value) => {
                  changeSelect(value, "state_code");
                }}
                options={optionState}
                variant="filled"
                placeholder="Khu vực"
              />
            </div>
            <div className="basis-1/2 p-2 lg:grow lg:p-0">
              <CommonStyles.SelectUI
                loading={loadingSite}
                value={filters.site_code}
                onChange={(value) => {
                  changeSelect(value, "site_code");
                }}
                options={optionSite}
                variant="filled"
                placeholder="Cơ sở y tế"
              />
            </div>
            <div className="basis-1/2 p-2 lg:grow lg:p-0">
              <CommonStyles.SelectUI
                loading={loadingSpecialization}
                value={filters.specialization_code}
                onChange={(value) => {
                  changeSelect(value, "specialization_code");
                }}
                options={optionSpecialization}
                variant="filled"
                placeholder="Chuyên khoa"
              />
            </div>

            <div className="lg:flex items-center gap-3 px-[11px]">
              <CommonStyles.ButtonUI
                icon={<CommonIcons.IconRefresh />}
                onClick={clearFilter}
                className="bg-common-orange100 h-12 !px-6 hover:!bg-common-orange100"
              />
            </div>
          </div>

          {Number(dataDoctor?.data.total_records) > 0 ? (
            <>
              {renderDoctors()}

              <div className="flex justify-end items-center">
                <CommonStyles.PaginationUI
                  current={filters.page + 1}
                  pageSize={filters.size}
                  onChange={handleChangePage}
                  total={dataDoctor?.data.total_records}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center gap-6 mt-16">
              <img src={CommonImages.EmptyRecordList} alt="" />

              <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("noResult")}!</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
