import React from "react";

export default function IconPinterest() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6C10.4766 6 6 10.4766 6 16C6 20.2383 8.63672 23.8555 12.3555 25.3125C12.2695 24.5195 12.1875 23.3086 12.3906 22.4453C12.5742 21.6641 13.5625 17.4766 13.5625 17.4766C13.5625 17.4766 13.2617 16.8789 13.2617 15.9922C13.2617 14.6016 14.0664 13.5625 15.0703 13.5625C15.9219 13.5625 16.3359 14.2031 16.3359 14.9727C16.3359 15.832 15.7891 17.1133 15.5078 18.3008C15.2734 19.2969 16.0078 20.1094 16.9883 20.1094C18.7656 20.1094 20.1328 18.2344 20.1328 15.5312C20.1328 13.1367 18.4141 11.4609 15.957 11.4609C13.1133 11.4609 11.4414 13.5937 11.4414 15.8008C11.4414 16.6602 11.7734 17.582 12.1875 18.082C12.2695 18.1797 12.2812 18.2695 12.2578 18.3672C12.1836 18.6836 12.0117 19.3633 11.9805 19.5C11.9375 19.6836 11.8359 19.7227 11.6445 19.6328C10.3945 19.0508 9.61328 17.2266 9.61328 15.7578C9.61328 12.6016 11.9063 9.70703 16.2188 9.70703C19.6875 9.70703 22.3828 12.1797 22.3828 15.4844C22.3828 18.9297 20.2109 21.7031 17.1953 21.7031C16.1836 21.7031 15.2305 21.1758 14.9023 20.5547C14.9023 20.5547 14.4023 22.4648 14.2812 22.9336C14.0547 23.8008 13.4453 24.8906 13.0391 25.5547C13.9766 25.8438 14.9688 26 16 26C21.5234 26 26 21.5234 26 16C26 10.4766 21.5234 6 16 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
