import React from "react";

export default function IconGiftFilled() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9967 17.383H10.8463V13.1566H15.6756V16.7123C15.6756 17.0827 15.3716 17.383 14.9967 17.383ZM4.80947 16.7119V13.1569H9.63868V17.3833H5.48828C5.11346 17.3833 4.80947 17.0823 4.80947 16.7119ZM16.2188 7.1201H14.8596C14.9917 6.84488 15.0721 6.54015 15.0721 6.21402C15.0721 5.0497 14.124 4.10156 12.9589 4.10156C11.5711 4.10156 10.7299 5.07552 10.2422 5.99709C9.75447 5.07552 8.91406 4.10156 7.52617 4.10156C6.36111 4.10156 5.41224 5.0497 5.41224 6.21402C5.41224 6.54015 5.4934 6.84488 5.62548 7.1201H4.26636C3.89965 7.1201 3.60156 7.55247 3.60156 8.08668V10.9835C3.60156 11.5169 3.89965 11.95 4.26636 11.95H9.63863V7.1201H10.8465V11.95H16.2188C16.5855 11.95 16.8828 11.5169 16.8828 10.9835V8.08668C16.8828 7.55247 16.5855 7.1201 16.2188 7.1201ZM12.959 7.12009H11.0546C11.3394 6.37044 11.9253 5.30942 12.959 5.30942C13.4585 5.30942 13.8651 5.71523 13.8651 6.21402C13.8651 6.71354 13.4585 7.12009 12.959 7.12009ZM7.52617 7.12009C7.02665 7.12009 6.6201 6.71354 6.6201 6.21402C6.6201 5.71523 7.02665 5.30942 7.52617 5.30942C8.5599 5.30942 9.14575 6.37044 9.43056 7.12009H7.52617Z"
        fill="currentColor"
      />
    </svg>
  );
}
