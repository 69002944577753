import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IPayloadOrder } from "./interface/IPayloadOrder";
import { IFilterMyOrder } from "./interface/filterMyOrder.interface";
import { IOrderInfo, IOrderInfoPage } from "./interface/OrderInfo.interface";

const configHeader = {
  headers: {
    platform: "web",
  },
};
class OrderService {
  createOrder(body: IPayloadOrder, lang: string) {
    return httpService.post(`${apiService.patient.order.create}?lang=${lang}`, body, configHeader);
  }

  getOrderCode(code: string, query: any) {
    return httpService.get(`${apiService.patient.order.orderCode}${code}`, {
      headers: {
        platform: "web",
      },
      params: query,
    });
  }

  getMyOrders(params: IFilterMyOrder) {
    return httpService.get<IOrderInfoPage>(`${apiService.patient.order.myOrders}`, {
      params,
    });
  }

  // get by order key
  getByOrderKey(order_key?: string, lang?: string) {
    return httpService.get<IOrderInfo>(`${apiService.patient.order.orderCode}${order_key}`, {
      params: { lang },
    });
  }

  getByOrderTransCode(orderTransCode: string, query: any) {
    return httpService.get(`${apiService.patient.order.orderTransCode}/${orderTransCode}`, {
      headers: {
        platform: "web",
      },
      params: query,
    });
  }

  getByRefCode(ref_code?: string, query?: any) {
    return httpService.get(`${apiService.patient.order.orderByRefCode}/${ref_code}`, {
      headers: {
        platform: "web",
      },
      params: query,
    });
  }
}

export default new OrderService();
