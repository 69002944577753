import { getErrorMsg } from "@base/helpers/common";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import appointmentService from "../appointmentService";
import { EStatusCode } from "@base/enums";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

const useCreateFromPackage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");
  const navigate = useNavigate();
  const handleCreateFromPackage = async (body: any) => {
    try {
      setLoading(true);
      const result = await appointmentService.createFromPackage(body);
      if (result.status === EStatusCode.Created || result.status === EStatusCode.Success) {
        dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: "Thành công" }));
        navigate(baseRoutes.userAppointmentDetail.replace(":key", result.data.key ? (result.data.key as string) : ""));
      }
    } catch (error) {
      setLoading(false);
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: getErrorMsg(error) }));
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleCreateFromPackage,
  };
};

export default useCreateFromPackage;
