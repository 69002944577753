import CommonIcons from "@base/assets/icons";
import useGetDrServiceListShow from "@base/modules/drService/hooks/useGetDrServiceListShow";
import IFiltersDrService from "@base/modules/drService/interface/filterDrService.interface";
import useGetSiteSpecialPackage from "@base/modules/siteSpecialPackage/hooks/useGetSiteSpecialPackage";
import IFilterSiteSpecialPackage from "@base/modules/siteSpecialPackage/interface/filterSiteSpecialPackage";
import { ISpecialization } from "@base/modules/specialization/interface/specialization.interface";
import CommonStyles from "@components/CommonStyles";
import CardPackageService from "@components/CommonStyles/CardPackageService";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  specialization?: ISpecialization;
}
export default function TabPackageServiceSpecialList(props: IProps) {
  //! state
  // const prop
  const [searchParams] = useSearchParams();
  const site_code = searchParams.get("site_code");
  const [filters, setFilters] = useState<IFilterSiteSpecialPackage>({
    page_number: 0,
    page_size: 10,
    search: "",
    site_code: site_code,
    lang: "vi",
  });
  const [textSearch, setTextSearch] = useState("");

  const { data, isLoading, refetch: refetchSiteSpecialPackage } = useGetSiteSpecialPackage(filters, false);
  const dataSiteSpecialPackage = data?.data.content_page;

  //! function
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };
  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, search: value }));
      }, 1000),
    [],
  );
  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page_number: value - 1 }));
  };
  useEffect(() => {
    refetchSiteSpecialPackage();
  }, [filters]);
  //! render
  const renderSiteSpecialPackage = () => {
    return dataSiteSpecialPackage?.map((item, index) => {
      return <CardPackageService data={item} root="specialist" key={index} />;
    });
  };
  return (
    <div>
      <div className="p-4 mt-5 rounded-lg bg-common-white">
        <div className="flex items-center gap-4 ">
          <div className="grow">
            <CommonStyles.InputUI
              onChange={changeSearch}
              value={textSearch}
              prefix={<CommonIcons.SearchOutlined />}
              placeholder="Nhập tên chuyên khoa cần tìm..."
              variant="filled"
            />
          </div>
          <CommonStyles.ButtonUI onClick={refetchSiteSpecialPackage} icon={<CommonIcons.SearchOutlined />} className=" h-12 !px-6 " />
        </div>

        <div className="grid grid-cols-3 gap-7 mt-6">{renderSiteSpecialPackage()}</div>

        <div className="flex justify-end items-center mt-6">
          <CommonStyles.PaginationUI
            current={Number(filters.page_number) + 1}
            onChange={handleChangePage}
            pageSize={filters.page_size}
            total={data?.data.total_records}
          />
        </div>
      </div>
    </div>
  );
}
