import { useEffect, useState } from "react";
import CommonImages from "@base/assets/images";
import MedicalManualLayout from "@base/layouts/MedicalManual/MedicalManualLayout";
import useGetCategories from "@base/modules/wpApis/hooks/useGetCategories";
import CommonStyles from "@components/CommonStyles";
import CommonMedicalInfo from "@components/MedicalInfo/CommonMedicalInfo";
import IndividualMedicalInfo from "@components/MedicalInfo/IndividualMedicalInfo";
import LatestMedicationInfo from "@components/MedicalInfo/LatestMedicationInfo";
import { Divider } from "antd";
import { PostsCategory } from "@base/types/wp";
import MedicalInfoByCategory from "@components/MedicalInfo/MedicalInfoByCategory";
import { useSearchParams } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonIcons from "@base/assets/icons";

export default function MedicalInfo() {
  //! state
  const [searchPamrams, setSearchPamrams] = useSearchParams();
  const { data: categories } = useGetCategories();
  const [selectedCategory, setSelectedCategory] = useState<PostsCategory | undefined>(undefined);

  const breadcrumItems = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.medicalInfo,
      title: "Tin tức",
    },
  ];

  //! function
  useEffect(() => {
    const catId = searchPamrams.get("cat");
    const _category = categories?.data?.find((c) => {
      return c.id?.toString() === catId;
    });

    if (_category) {
      setSelectedCategory(_category);
    }
  }, [categories]);

  const onSelectCategory = (category: PostsCategory) => {
    setSelectedCategory(category);

    if (category.id) {
      setSearchPamrams({ cat: category.id as string });
    } else {
      setSearchPamrams({});
    }
  };

  //! render
  const header = () => {
    return (
      <div className="w-full">
        <div
          className="rounded-2xl"
          style={{
            backgroundImage: `url(${CommonImages.NewsBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full flex flex-col gap-2 px-[70px] py-[22px] rounded-xl">
            <CommonStyles.TypographyUI use="title" level={1} className="!text-2xl !font-light !text-[#005984] uppercase !mb-0">
              Thông tin y tế
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="!text-xs !font-light !text-[#525252] !mb-0">
              {`Nắm bắt nhanh chóng các thông tin y tế chính xác, tham khảo trải nghiệm khám chữa bệnh từ các người dùng khác.`}
            </CommonStyles.TypographyUI>
          </div>
        </div>
      </div>
    );
  };

  const content = () => {
    return (
      <div className="flex flex-col">
        <CommonStyles.BreadcrumbUI items={breadcrumItems} className="mb-5" />

        <CommonMedicalInfo />

        {categories?.data.length ? (
          <>
            <Divider className="!mt-3 !mb-0 [border-block-start-width:2px] [border-block-start-color:#D9D9D9]" />

            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3 max-lg:!col-span-4 flex flex-col divide-y-2 divide-[#005984] gap-3">
                {categories?.data.map((cat, idx: number) => {
                  return <IndividualMedicalInfo key={idx} category={cat} onSelectCategory={() => onSelectCategory(cat)} />;
                })}
              </div>

              <LatestMedicationInfo className="max-lg:!hidden" />
            </div>

            <Divider className="!mt-3 !mb-0 [border-block-start-width:2px] [border-block-start-color:#D9D9D9]" />
          </>
        ) : null}
      </div>
    );
  };

  return (
    <MedicalManualLayout
      Header={header()}
      Content={searchPamrams.get("cat") ? selectedCategory ? <MedicalInfoByCategory category={selectedCategory} /> : null : content()}
    />
  );
}
