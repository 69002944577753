import { DatePickerProps } from "antd";
import React from "react";
import { DatePicker } from "antd";

interface IProps extends DatePickerProps {
  label?: string;
  labelStyle?: string;
  extraTitle?: React.ReactNode;
  required?: boolean;
}

export default function DatePickerUI(props: IProps) {
  const { label, extraTitle, required, labelStyle, ...res } = props;

  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <div className="mb-1">
            <span className={labelStyle}>{label}</span>
            {required && <span className="text-common-red500">{` *`}</span>}
          </div>
        )}
        {extraTitle && extraTitle}
      </div>

      <DatePicker className={`w-full h-12 ${res.className}`} {...res} />
    </div>
  );
}
