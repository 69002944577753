import React, { useEffect, useState } from "react";
import CommonStyles from "@components/CommonStyles";
import { Modal } from "antd";
import { Rate } from "antd";
import themeColor from "@base/themes/colorTheme";
import { useTranslation } from "react-i18next";
import CommonIcons from "@base/assets/icons";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import appointmentRatingService from "@base/modules/appointmentRating/appointmentRatingService";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import useGetListRatingComment from "@base/modules/appointmentRating/hooks/useGetListRatingComment";
import { IFilterRatingComment } from "@base/modules/appointmentRating/filterRatingComment.interface";
import { IRatingComment } from "@base/modules/appointmentRating/appointmentRating.interface";
import dayjs from "dayjs";

interface IProps {
  open: boolean;
  appointment: IAppointment;
  onClose: () => void;
}

export default function ModalAppointmentRating({ open = false, appointment, onClose }: IProps) {
  //! state
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<IFilterRatingComment>({ lang: i18n.resolvedLanguage, point: 0 });
  const { isLoadingratingComment, ratingCommentError, ratingComments, refetchRatingComments } = useGetListRatingComment(filter, true);

  const [star, setStar] = useState<number>(0);
  const [selectedComments, setSelectedComments] = useState<string[]>([]);
  const [comment, setComment] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const apptDate = appointment.appointment_date + " " + dayjs(appointment.time_table_period?.period_start_time).format("HH:mm");
  const isOverDate = dayjs().diff(dayjs(apptDate, "YYYY-MM-DD HH:mm"), "hours") > 48;

  //! function
  useEffect(() => {
    // init
    if (open) {
      setStar(0);
      setSelectedComments([]);
      setComment(undefined);
      setIsLoading(false);

      setFilter(Object.assign(filter, { point: 0 }));
      refetchRatingComments();
    }
  }, [open]);

  useEffect(() => {
    setSelectedComments([]);
    setFilter(Object.assign(filter, { lang: i18n.resolvedLanguage }));
    refetchRatingComments();
  }, [t, i18n]);

  const onChangeStar = (value: number) => {
    setStar(value);
    setSelectedComments([]);
    setFilter(Object.assign(filter, { point: value }));
    refetchRatingComments();
  };

  const onSelectComment = (value?: string) => {
    if (!value) {
      return;
    }

    const comments = JSON.parse(JSON.stringify(selectedComments)) as string[];
    const index = comments.findIndex((e) => {
      return e === value;
    });

    if (index > -1) {
      comments.splice(index, 1);
      setSelectedComments(comments);
      return;
    }

    comments.push(value);
    setSelectedComments(comments);
  };

  const onSubmit = async () => {
    if (!star || (!selectedComments.length && !comment)) {
      dispatch(setAlertMessage({ type: "warning", message: t("Notification"), description: "Vui lòng để lại đánh giá và cho chúng tôi ý kiến!" }));
      return;
    }

    try {
      setIsLoading(true);
      await appointmentRatingService.createAppointmentRating({
        id: 0,
        appointment_id: appointment.id,
        doctor: appointment.doctor,
        patient_record: appointment.patient_record,
        site_code: appointment.site_code,
        site_name: appointment.site_name,
        comment: (selectedComments.length ? selectedComments.join("\n") + "\n" : "") + comment,
        point: star,
      });
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));

      onClose();
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
    } finally {
      setIsLoading(false);
    }
  };

  //! render
  const title = <div className="w-full flex justify-center items-center">Vui lòng đánh giá chất lượng dịch vụ y tế bạn đã trải nghiệm</div>;

  const footer = (
    <div className="w-full flex justify-center items-center">
      <CommonStyles.ButtonUI disabled={isOverDate} onClick={onSubmit}>
        Gửi ý kiến
      </CommonStyles.ButtonUI>
    </div>
  );

  const CardSelect = ({
    content = null,
    value = undefined,
    selected = false,
    onSelect = (value?: string) => {},
  }: {
    content?: React.ReactNode;
    value?: string;
    selected?: boolean;
    onSelect?: (value?: string) => void;
  }) => {
    const className = selected
      ? "bg-common-orange300 text-common-orange500 outline outline-2 outline-common-orange500 rounded-2xl p-2 font-['Inter'] cursor-pointer"
      : "bg-common-orange100 text-common-orange500 rounded-2xl p-2 font-['Inter'] cursor-pointer";

    return (
      <div className={className} onClick={() => onSelect(value)}>
        {content}
      </div>
    );
  };

  return (
    <Modal open={open} width={800} title={title} footer={footer} maskClosable={false} onCancel={onClose} loading={isLoading}>
      <div className="w-full md:w-[80%] 2xl:w-[60%] mx-auto flex flex-col gap-4">
        <div className="w-full flex justify-center items-center">
          <Rate disabled={isOverDate} value={star} style={{ fontSize: 48, color: themeColor.orange500 }} onChange={onChangeStar} />
        </div>

        <div className="w-full flex justify-center items-center">
          <span className="font-['Inter']">Chúng tôi cần cải thiện thêm điều gì để phục vụ tốt hơn?</span>
        </div>

        {ratingComments?.length ? (
          <div className="w-full grid grid-cols-2 gap-4">
            {ratingComments.map((comment: IRatingComment, idx: number) => {
              return (
                <CardSelect
                  content={comment.title}
                  value={comment.description}
                  selected={selectedComments.includes(comment.description ?? "")}
                  onSelect={onSelectComment}
                />
              );
            })}
          </div>
        ) : null}

        {isOverDate ? (
          <div className="w-full flex item gap-2">
            <span className="text-common-negative text-lg">
              <CommonIcons.InfoCircleOutlined />
            </span>{" "}
            <span className="font-semibold leading-7">Đánh giá chỉ cho phép trong vòng 48 giờ sau khi lịch hẹn hoàn thành</span>
          </div>
        ) : null}

        <div className="w-full mb-6">
          <CommonStyles.TextAreaUI
            disabled={isOverDate}
            label="Ý kiến khác"
            className="!w-full !border-0 !bg-common-disable !rounded-lg"
            placeholder="Nhập đánh giá..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}
