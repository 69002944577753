import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IAppointmentRating, IRatingComment } from "./appointmentRating.interface";

class AppointmentRatingService {
  // create
  createAppointmentRating(payload: IAppointmentRating) {
    return httpService.post(`${apiService.doctor.appointment.rating}`, payload);
  }

  // get list rating comment
  getListRatingComment(lang?: string, point?: number) {
    return httpService.get<IRatingComment[]>(`${apiService.utils.ratingComment.getList}/${lang}/${point}`);
  }
}

export default new AppointmentRatingService();
