import { useState } from "react";
import { AxiosError } from "axios";
import accountService from "../accountService";
import { useDispatch } from "react-redux";
import { setUserInfo } from "@base/store/reducers/appCommonSlice";
import { IUserInfo } from "../account.interface";

const useGetUserInfo = () => {
  const dispatch = useDispatch();

  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [getUserInfoData, setGetUserInfoData] = useState<IUserInfo>();
  const [getUserInfoError, setGetUserInfoError] = useState<AxiosError | any>(null);

  const handleGetUserInfo = async () => {
    try {
      setIsGettingUserInfo(true);

      const resp1 = await accountService.getUserInfo();

      if (resp1.data.user_key) {
        const resp2 = await accountService.getAccountSetting(resp1.data.user_key);

        resp1.data.user_profiles = resp2.data;
      }

      setGetUserInfoData(resp1.data);
      dispatch(setUserInfo(resp1.data));
    } catch (error: AxiosError | any) {
      setGetUserInfoError(error);
    } finally {
      setIsGettingUserInfo(false);
    }
  };

  return {
    isGettingUserInfo,
    getUserInfoData,
    getUserInfoError,
    handleGetUserInfo,
  };
};

export default useGetUserInfo;
