import React, { useState } from "react";
import { EOrderStatus } from "@base/enums";
import CommonStyles from "@components/CommonStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IFilterMyOrder } from "@base/modules/order/interface/filterMyOrder.interface";
import dayjs, { Dayjs } from "dayjs";
import useGetMyOrders from "@base/modules/order/hooks/useGetMyOrders";
import { IOrderInfo, IOrderInfoPage } from "@base/modules/order/interface/OrderInfo.interface";
import CommonImages from "@base/assets/images";
import { baseRoutes } from "@base/routes/baseRoutes";
import PaymentListItem from "./PaymentListItem";
import { Divider } from "antd";

export default function PaymentList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";

  const [activeTab, setActiveTab] = useState<string>("ALL");

  const tabItems = [
    {
      key: "ALL",
      label: t("user.payment.tabAll"),
    },
    {
      key: EOrderStatus.UNPAID,
      label: t("user.payment.tabUnpaid"),
    },
    {
      key: EOrderStatus.PAID,
      label: t("user.payment.tabPaid"),
    },
    {
      key: EOrderStatus.REFUND,
      label: t("user.payment.tabRefund"),
    },
    {
      key: EOrderStatus.CANCELLED,
      label: t("user.payment.tabCancelled"),
    },
  ];

  const [filters, setFilters] = useState<IFilterMyOrder>({
    page_number: 0,
    page_size: 10,
    from: dayjs().subtract(3, "months").format(dateFormat),
    to: dayjs().format(dateFormat),
  });
  const { isLoading, data, error, refetch } = useGetMyOrders(filters, true);

  const onChangeTabs = (key: string) => {
    setActiveTab(key);

    setFilters(Object.assign(filters, { order_status: key === "ALL" ? undefined : key, page_number: 0 }));
    refetch();
  };

  const onChangeDateRange = (dates: any, dateStrings: [string, string]) => {
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      setFilters(Object.assign(filters, { from: dates[0].format(dateFormat), to: dates[1].format(dateFormat), page_number: 0 }));
      refetch();
    }
  };

  const onChangePage = (page: number, pageSize: number) => {
    setFilters(Object.assign(filters, { page_number: page - 1, page_size: pageSize }));
    refetch();
  };

  const pageData = data?.content_page ?? [];

  const operations = (
    <div className="flex justify-center items-center gap-4">
      <CommonStyles.RangePickerUI
        allowClear={false}
        format={"DD/MM/YYYY"}
        separator={"-"}
        value={[dayjs(filters.from, dateFormat), dayjs(filters.to, dateFormat)]}
        onChange={onChangeDateRange}
      />
    </div>
  );

  return (
    <div className="p-5">
      <div className="flex flex-col">
        <div className="flex justify-between items-center max-sm:flex-col max-sm:justify-start-col max-sm:items-start gap-2 max-sm:mb-1">
          <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.payment.paymentHistory")}</div>

          <div>{operations}</div>
        </div>

        <div>
          <CommonStyles.TabsUI className="w-full" items={tabItems} activeKey={activeTab} onChange={onChangeTabs} />
        </div>
      </div>

      <div className="min-h-[437px] relative">
        <CommonStyles.SpinnerUI isSpinning={isLoading} />

        {data && data.content_page && data.content_page.length > 0 ? (
          <div className="w-full">
            <div className="pt-4 max-h-[610px] overflow-y-auto">
              {pageData.map((orderInfo: IOrderInfo, idx: number) => {
                return (
                  <div key={idx} className="mr-1">
                    <PaymentListItem orderInfo={orderInfo} />
                    {idx < pageData.length - 1 ? <Divider className="!my-4" /> : null}
                  </div>
                );
              })}
            </div>

            {(data.total_records ?? 0) > filters.page_size ? (
              <div className="w-full flex justify-end items-center mt-2">
                <CommonStyles.PaginationUI
                  current={filters.page_number + 1}
                  pageSize={filters.page_size}
                  total={data.total_records}
                  onChange={onChangePage}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full min-h-[437px] flex flex-col justify-center items-center gap-6">
            <img src={CommonImages.EmptyAppointmentList} alt="" />

            <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("recordNotFound")}!</div>
          </div>
        )}
      </div>
    </div>
  );
}
