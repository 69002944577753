import { useState } from "react";
import appointmentService from "../appointmentService";
import { IAppointment } from "../appointment.interface";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";
import { getErrorMsg } from "@base/helpers/common";

const useCreateAppointment = (query?: { lang: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const handleCreateAppointment = async (body: IAppointment) => {
    try {
      setLoading(true);
      const result = await appointmentService.createAppointment(body, query);
      return result;
    } catch (error) {
      setLoading(false);
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t(`Appointment.${getErrorMsg(error)}`) + "!" }));
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleCreateAppointment,
  };
};

export default useCreateAppointment;
