import BreadcrumbUI from "./Breadcrumb";
import ButtonUI from "./Button";
import CheckBoxUI from "./Checkbox";
import FormAntdDebugs from "./FormAntdDebugs";
import FormFieldUI from "./FormField";
import InputUI from "./Input";
import ModalConfirmationUI from "./ModalConfirmation";
import TypographyUI from "./Typography";
import NavLinkTextUI from "./NavLinkText";
import RadioUI from "./Radio";
import SelectUI from "./Select";
import NotificationUI from "./Notification";
import TagUI from "./Tag";
import DatePickerUI from "./DatePicker";
import TabsUI from "./Tabs";
import DayCalendarUI from "./DayCalendar";
import TimelineUI from "./Timeline";
import TimelineDotUI from "./TimelineDot";
import CarouselUI from "./Carousel";
import NextArrowCarousel from "./NextArrowCarousel/NextArrowCarousel";
import RatingUI from "./Rating";
import PaginationUI from "./Pagination";
import StepUI from "./Steps";
import TextAreaUI from "./TextArea";
import CollapseUI from "./Collapse";
import ImageUI from "./Image/Index";
import DrawerUI from "./Drawer";
import LoadingUI from "./Loading";
import RangePickerUI from "./RangePicker";
// import AutoCompleteUI from "./AutoComplete";
import SpinnerUI from "./Spinner";
import TransferInformationDialog from "./TransferInformationDialog";

const CommonStyles = {
  ButtonUI: ButtonUI,
  InputUI: InputUI,
  BreadcrumbUI: BreadcrumbUI,
  ModalConfirmationUI: ModalConfirmationUI,
  TypographyUI: TypographyUI,
  FormFieldUI: FormFieldUI,
  CheckBoxUI: CheckBoxUI,
  FormAntdDebugs: FormAntdDebugs,
  NavLinkTextUI: NavLinkTextUI,
  RadioUI: RadioUI,
  SelectUI: SelectUI,
  NotificationUI: NotificationUI,
  TagUI: TagUI,
  DatePickerUI: DatePickerUI,
  TabsUI: TabsUI,
  DayCalendarUI: DayCalendarUI,
  TimelineUI: TimelineUI,
  TimelineDotUI: TimelineDotUI,
  CarouselUI: CarouselUI,
  NextArrowCarousel: NextArrowCarousel,
  RatingUI: RatingUI,
  PaginationUI: PaginationUI,
  StepUI: StepUI,
  TextAreaUI: TextAreaUI,
  CollapseUI: CollapseUI,
  ImageUI: ImageUI,
  DrawerUI: DrawerUI,
  LoadingUI: LoadingUI,
  RangePickerUI: RangePickerUI,
  // AutoCompleteUI: AutoCompleteUI,
  SpinnerUI: SpinnerUI,
  TransferInformationDialog: TransferInformationDialog,
};

export default CommonStyles;
