import React from "react";

interface IProps {
  isSpinning: boolean;
}

export default function SpinnerUI({ isSpinning = false }: IProps) {
  return isSpinning ? (
    <div className="absolute z-[1000] top-0 left-0 bottom-0 right-0 bg-black bg-opacity-5 flex justify-center items-center">
      <div className="loader"></div>
    </div>
  ) : null;
}
