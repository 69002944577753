import { methodPayment } from "@base/constants";
import { RootState } from "@base/store/store";
import CheckBoxUI from "@components/CommonStyles/Checkbox";
import TypographyUI from "@components/CommonStyles/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface IProps {
  setBankCode?: (value?: any) => void;
  listPayment?: string;
}
export default function Payment(props: IProps) {
  //! state
  const { setBankCode, listPayment } = props;
  const [checkedPayment, setCheckedPayment] = useState<string | null>(null);
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);
  const terms_of_payment = dataCommon?.data.commons.find((item) => item.key === "WEB_DIEU_KHOAN_THANH_TOAN");
  const paymentSupport = dataCommon?.data.commons.find((item) => item.key === "PAYMENT_METHOD");

  //! function
  const handleChoicePayment = (value: string) => {
    setCheckedPayment(value);
    setBankCode && setBankCode(value);
  };
  //! render
  const renderPayment = () => {
    return methodPayment.map((item, index) => {
      if ((listPayment ?? paymentSupport?.description)?.includes(item.bankSupport)) {
        const checked = item.value === checkedPayment ? "bg-common-mainColor flex justify-center items-center" : "";
        return (
          <div
            onClick={() => {
              handleChoicePayment(item.value);
            }}
            className="p-4 border rounded-xl border-common-divider flex flex-col justify-between bg-common-white  cursor-pointer"
            key={index}
          >
            <div className="flex justify-between items-start">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img className="mb-2" src={item.image} alt={item.image} />
                </div>
              </div>

              <div className={` rounded-full h-5 w-5 border border-common-disable ${checked}`}>
                {checked && <div className={`bg-common-white h-2 w-2 rounded-full`}></div>}
              </div>
            </div>

            <TypographyUI className="text-base">{item.title}</TypographyUI>
          </div>
        );
      }
    });
  };
  return (
    <div className="flex flex-col gap-3">
      <div className="grid sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-6">{renderPayment()}</div>
      <CheckBoxUI
        defaultChecked
        titleCustom={
          <TypographyUI>
            Tôi đã đọc và đồng ý với các{" "}
            <Link to={String(terms_of_payment?.article_link)} target={"_blank"} className="text-common-inprogress">
              Điều khoản giao dịch và chính sách của 365 Medihome
            </Link>
          </TypographyUI>
        }
      />
    </div>
  );
}
