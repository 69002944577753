import { IReAppointment } from "@base/modules/appointment/appointment.interface";
import CommonStyles from "..";
import { EReAppointmentStatus } from "@base/enums";
import dayjs from "dayjs";

interface IProps {
  appointment: IReAppointment;
}

export default function TagReAppointmentStatus({ appointment }: IProps) {
  const status =
    appointment.date_of_appointment && dayjs(appointment.date_of_appointment, "YYYY-MM-DD").isAfter(dayjs().endOf("day"))
      ? EReAppointmentStatus.INCOMING
      : EReAppointmentStatus.EXPIRED;

  const getTagName = () => {
    switch (status) {
      case EReAppointmentStatus.INCOMING:
        return "Sắp tới";
      case EReAppointmentStatus.EXPIRED:
        return "Quá hạn";

      default:
        return "";
    }
  };

  const getClassName = () => {
    switch (status) {
      case EReAppointmentStatus.INCOMING:
        return "bg-common-green100 text-common-positive border-none";
      case EReAppointmentStatus.EXPIRED:
        return "bg-gray-100 text-gray-500 border-none";

      default:
        return "";
    }
  };

  return (
    <div>
      <CommonStyles.TagUI tagName={getTagName()} className={getClassName()} />
    </div>
  );
}
