import CommonIcons from "@base/assets/icons";
import CommonStyles from "@components/CommonStyles";
import { TabsProps } from "rc-tabs";
import { useCallback, useEffect, useState } from "react";
import AllPackage from "./AllPackage";
import useGetListCategoryShow from "@base/modules/specialCategory/hooks/useGetListShow";
import { categories } from "@base/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { useTranslation } from "react-i18next";
import { responseUI } from "@base/themes/response";
import useWindowDimensions from "@base/hooks/useWindowDimensions";

export default function PackageService() {
  //! state
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState("");

  const {
    data: dataCategories,
    isLoading: loadingCategories,
    refetch: refetchCategories,
  } = useGetListCategoryShow(
    {
      lang: i18n.resolvedLanguage,
      type: categories.PACKAGE,
    },
    false,
  );
  const listCategory = dataCategories?.data;
  const renderTabs = useCallback(() => {
    let tabs: TabsProps["items"] = [
      {
        key: "",
        label: `${t("all")}`,
        children: <AllPackage category={{ name: "", id: 1, type: "" }} />,
      },
    ];
    listCategory?.map((item, index) => {
      tabs?.push({ key: `${item.name}`, label: item.name, children: <AllPackage category={item} />, tabKey: String(index + 1) });
    });
    return tabs;
  }, [loadingCategories]);

  const { width } = useWindowDimensions();
  const [mode, setMode] = useState<"top" | "right" | "bottom" | "left">("left");

  useEffect(() => {
    if (width <= 500) {
      setMode("top");
    } else {
      setMode("left");
    }
  }, [width]);

  //! function
  useEffect(() => {
    navigate({
      pathname: baseRoutes.home,
    });
  }, []);

  useEffect(() => {
    refetchCategories();
  }, [i18n.resolvedLanguage]);
  //! render
  const CustomTabBar = ({ activeKey, setActiveKey }: any) => {
    // Handle tab click event
    //! state
    //! function

    const handleTabClick = (item: any) => {
      setActiveKey(item.key);
      navigate({
        pathname: baseRoutes.home,
        search: createSearchParams({
          package: String(item.key),
        }).toString(),
      });
    };
    //! render

    const renderTab = () => {
      return renderTabs().map((item, index) => {
        const active = activeKey === `${item.key}` ? "text-common-orange bg-common-orange100" : "";
        return (
          <div
            className={`py-3 px-2 my-2 lg:my-4 text-common-maintext font-medium text-sm lg:text-base flex items-center gap-3 rounded-lg border-common-divider  lg:rounded-2xl relative cursor-pointer custom-tab  ${active}`}
            onClick={() => handleTabClick(item)}
            key={index}
          >
            {item.icon}
            <CommonStyles.TypographyUI className={`${active} text-nowrap whitespace-nowrap  text-xs lg:text-sm`}>
              {item.label}
            </CommonStyles.TypographyUI>
            {activeKey === `${item.key}` && mode !== "top" && (
              <div className="absolute right-0 bottom-0 z-40">
                <CommonIcons.IconHeart />
              </div>
            )}
          </div>
        );
      });
    };
    return (
      <div
        className={`custom-tab-bar bg-white lg:p-3 rounded-3xl  min-w-60 xl:min-w-80 ${
          mode === "top" ? "flex overflow-x-scroll w-[95%] mb-5 px-5" : `overflow-y-scroll`
        }`}
      >
        {renderTab()}
      </div>
    );
  };

  return (
    <div className={`py-5 lg:py-16 bg-common-background`}>
      <div className={`${responseUI.screen} ${responseUI.padding}`}>
        <div className="flex justify-between items-center mb-5 lg:mb-10">
          <div className="basis-2/3">
            <CommonStyles.TypographyUI use="title" level={2} className={`${responseUI.text.title}`}>
              {t("home.outstandingPackage")}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.subTitle} `}>
              {t("home.subOutstandingPackage")}
            </CommonStyles.TypographyUI>
          </div>
          <CommonStyles.ButtonUI
            onClick={() => {
              navigate(baseRoutes.bookingPackage);
            }}
            ghost
            className="lg:h-12 lg:text-base font-medium"
          >
            {t("home.viewAll")}
          </CommonStyles.ButtonUI>
        </div>

        <CommonStyles.TabsUI
          activeKey={activeKey}
          tabPosition={mode}
          style={{ height: 550 }}
          items={renderTabs()}
          renderTabBar={(props, DefaultTabBar) => <CustomTabBar setActiveKey={setActiveKey} {...props} />}
        />
      </div>
    </div>
  );
}
