import { optionBloodGroup, optionEthics, optionGender } from "@base/constants";
import useFormValidation from "@base/hooks/useFormvalidation";
import useParseOption from "@base/hooks/useParseOptions";
import { IDistrict } from "@base/modules/administrative/administrative.interface";
import administrativeService from "@base/modules/administrative/administrativeService";
import useGetState from "@base/modules/state/hooks/useGetState";
import useGetUserInfo from "@base/modules/user/account/hooks/useGetUserInfo";
import useSavePatientRecord from "@base/modules/user/profile/hooks/useSavePatientRecord";
import { IPatientRecord, IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import { RootState } from "@base/store/store";
import CommonStyles from "@components/CommonStyles";
import { Form, RadioChangeEvent } from "antd";
import { Dayjs } from "dayjs";
import { userInfo } from "os";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IProps {
  refetch: () => void;
  toggle: () => void;
}
export default function ModalCreateUser(props: IProps) {
  //! state
  const { refetch, toggle } = props;
  const { t } = useTranslation();
  const { patientRecord, accountSetting } = useFormValidation();
  const [form] = Form.useForm();
  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);

  const initialValues = {
    patient_name: "",
    patient_dob: "",
    patient_gender: "",
    patient_ethic: "",
    administrative_province_code: "",
    administrative_district_code: "",
    patient_address: "",
    patient_height: "",
    patient_weight: "",
    blood_group: "",
    patient_phone_number: userInfo?.user_name,
    patient_email: "",
  };
  const { data: dataState, isLoading: loadingState } = useGetState();
  const [districts, setDistricts] = useState<IDistrict[]>([]);
  const { isSavingPatientRecord, savePatientRecordError, handleCreatePatientRecord, handleUpdatePatientRecord } = useSavePatientRecord();

  //! function
  const optionState = useParseOption(dataState?.data, "name", "code");
  const handleChangeRadio = (event: RadioChangeEvent, fieldName: string) => {
    form.setFieldValue(fieldName, event.target.value);
  };
  const getDistricts = async (province_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);
      setDistricts(resp.data);
    } catch (error) {}
  };
  const onChangeState = (value: any, option: any) => {
    const provinceCode = form.getFieldValue("administrative_province_code");
    if (provinceCode) {
      getDistricts(provinceCode);
    }
  };

  const handleSubmit = async (values: IPatientRecord) => {
    if (!userInfo?.user_key) {
      return;
    }

    const data = JSON.parse(JSON.stringify(values)) as IPatientRecord;
    if (values.patient_dob) {
      data.patient_dob = (values.patient_dob as Dayjs).format("YYYY-MM-DD");
    }

    let payload: IPatientRecordData;

    // create new
    payload = {
      id: 0,
      active: true,
      patient_record: undefined,
      user_key: userInfo.user_key,
    };

    payload.patient_record = data;

    await handleCreatePatientRecord(payload);
    refetch();
    toggle();
  };

  //! render
  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
        <CommonStyles.FormFieldUI
          name="patient_name"
          rules={patientRecord.patientName()}
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("user.profile.handler.labelName")}
              placeholder={t("user.profile.handler.placeholderName")}
              required
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="patient_dob"
          rules={patientRecord.patientDob()}
          component={
            <CommonStyles.DatePickerUI
              variant="filled"
              label={t("user.profile.handler.labelDateOfBirth")}
              placeholder={t("user.profile.handler.placeholderDateOfBirth")}
              required
              format="DD/MM/YYYY"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="patient_gender"
          component={
            <CommonStyles.RadioUI
              handleChange={(e) => handleChangeRadio(e, "patient_gender")}
              options={optionGender}
              label={t("user.profile.handler.labelGender")}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="patient_ethic"
          component={
            <CommonStyles.RadioUI
              handleChange={(e) => handleChangeRadio(e, "patient_ethic")}
              options={optionEthics}
              label={t("user.profile.handler.labelEthic")}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="administrative_province_code"
          rules={patientRecord.province()}
          component={
            <CommonStyles.SelectUI
              variant="filled"
              placeholder={t("user.profile.syncFromSite.placeHolderState")}
              options={optionState}
              onChange={onChangeState}
              label={t("user.profile.handler.labelProvince")}
              required
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="administrative_district_code"
          rules={patientRecord.district()}
          component={
            <CommonStyles.SelectUI
              variant="filled"
              placeholder={t("user.profile.syncFromSite.placeHolderDistrict")}
              options={districts.map((district: IDistrict) => {
                return { label: district.name, value: district.code };
              })}
              // onChange={onChangeDistrict}
              label={t("user.profile.handler.labelDistrict")}
              required
            />
          }
        />
        <div className="sm:col-span-2">
          <CommonStyles.FormFieldUI
            name="patient_address"
            rules={patientRecord.address()}
            component={
              <CommonStyles.InputUI
                variant="filled"
                label={t("user.profile.handler.labelAddress")}
                required
                placeholder={t("user.profile.handler.placeHolderAddress")}
              />
            }
          />
        </div>

        <div className="grid grid-cols-2 gap-x-3">
          <CommonStyles.FormFieldUI
            name="patient_height"
            component={
              <CommonStyles.InputUI
                type="number"
                variant="filled"
                label={t("user.profile.handler.labelHeight") + " (cm)"}
                placeholder={t("user.profile.handler.placeHolderHeight")}
                min={1}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="patient_weight"
            component={
              <CommonStyles.InputUI
                type="number"
                variant="filled"
                label={t("user.profile.handler.labelWeight") + " (kg)"}
                placeholder={t("user.profile.handler.placeHolderWeight")}
              />
            }
          />
        </div>
        <CommonStyles.FormFieldUI
          name="blood_group"
          component={
            <CommonStyles.SelectUI
              variant="filled"
              label={t("user.profile.handler.labelBloodGroup")}
              placeholder={t("user.profile.handler.placeholderBloodGroup")}
              options={optionBloodGroup}
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="patient_phone_number"
          component={
            <CommonStyles.InputUI
              variant="filled"
              disabled
              label={t("user.profile.handler.labelPhoneNumber")}
              placeholder={t("user.profile.handler.placeholderPhoneNumber")}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="patient_email"
          rules={accountSetting.email()}
          component={
            <CommonStyles.InputUI
              variant="filled"
              label={t("user.profile.handler.labelEmail")}
              placeholder={t("user.profile.handler.placeholderEmail")}
            />
          }
        />
        <div className="sm:col-span-2 flex justify-center items-center">
          <CommonStyles.ButtonUI htmlType="submit" loading={isSavingPatientRecord}>
            {t("user.profile.handler.btnSave")}
          </CommonStyles.ButtonUI>
        </div>
      </div>

      {/* <CommonStyles.FormAntdDebugs /> */}
    </Form>
  );
}
