import React from "react";

export default function BS_giadinh() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.141602" y="0.0499573" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.1416" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27221)">
          <path
            d="M38.8027 66.73C38.8027 61.1891 43.2945 56.6974 48.8353 56.6974H51.4434C56.9842 56.6974 61.4759 61.1891 61.4759 66.73V72.0975C61.4759 72.7901 60.9145 73.3516 60.2218 73.3516H40.0568C39.3642 73.3516 38.8027 72.7901 38.8027 72.0975V66.73Z"
            fill="url(#paint0_linear_1205_27221)"
          />
          <path
            d="M56.2473 47.0209C56.2473 50.3943 53.5126 53.1289 50.1393 53.1289C46.7659 53.1289 44.0312 50.3943 44.0312 47.0209C44.0312 43.6476 46.7659 40.9129 50.1393 40.9129C53.5126 40.9129 56.2473 43.6476 56.2473 47.0209Z"
            fill="url(#paint1_linear_1205_27221)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.3288 45.1403C38.8435 46.1732 37.0387 46.7787 35.0925 46.7787C30.0213 46.7787 25.9102 42.6677 25.9102 37.5965C25.9102 32.5252 30.0213 28.4142 35.0925 28.4142C40.1637 28.4142 44.2747 32.5252 44.2747 37.5965C44.2747 38.0788 44.2375 38.5524 44.1659 39.0146C42.2073 40.4789 40.8039 42.6451 40.3288 45.1403ZM40.3091 48.7934C38.7735 49.6595 36.9921 50.1549 35.0925 50.1549C32.1333 50.1549 29.4611 48.9527 27.5578 47.0201C22.2059 49.1821 18.4395 54.3397 18.4395 60.3583V68.0752C18.4395 69.0709 19.2642 69.8781 20.2816 69.8781H34.5798V66.8995C34.5798 61.1371 38.1191 56.2013 43.1424 54.1473C41.697 52.7278 40.6815 50.8721 40.3091 48.7934ZM65.7027 69.8781H80.0009C81.0183 69.8781 81.8431 69.0709 81.8431 68.0752V60.3583C81.8431 54.3397 78.0766 49.1821 72.7247 47.0201C70.8215 48.9527 68.1492 50.1549 65.1901 50.1549C63.2904 50.1549 61.509 49.6595 59.9734 48.7934C59.6009 50.8721 58.5854 52.7278 57.1401 54.1473C62.1634 56.2013 65.7027 61.1371 65.7027 66.8995V69.8781ZM59.9537 45.1402C61.439 46.1732 63.2439 46.7787 65.1901 46.7787C70.2613 46.7787 74.3723 42.6677 74.3723 37.5965C74.3723 32.5252 70.2613 28.4142 65.1901 28.4142C60.1189 28.4142 56.0078 32.5252 56.0078 37.5965C56.0078 38.0788 56.045 38.5524 56.1167 39.0147C58.0752 40.4789 59.4785 42.6451 59.9537 45.1402Z"
            fill="url(#paint2_linear_1205_27221)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27221"
            x="10.4395"
            y="24.4142"
            width="79.4033"
            height="60.9373"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27221" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27221" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27221" x1="55.4177" y1="56.6729" x2="41.5638" y2="70.7757" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint1_linear_1205_27221" x1="52.9832" y1="40.8949" x2="43.2345" y2="48.1843" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint2_linear_1205_27221" x1="64.9017" y1="28.3532" x2="30.7116" y2="67.4452" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
