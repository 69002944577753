import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IState } from "./state.interface";

class StateService {
  // get list
  getList() {
    return httpService.get<IState[]>(`${apiService.auth.state.getList}`);
  }
}

export default new StateService();
