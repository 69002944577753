import React from "react";
import CommonImages from "@base/assets/images";
import { EDrugOrderStatus } from "@base/enums";
import CommonStyles from "@components/CommonStyles";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { useTranslation } from "react-i18next";
import { IDrugOrder } from "@base/modules/drugOrder/drugOrder.interface";
import TagDrugOrderStatus from "@components/CommonStyles/TagDrugOrderStatus";

interface IProps {
  drugOrder: IDrugOrder;
}

export default function PrescriptionListItem({ drugOrder }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-between max-sm:flex-col gap-36 max-lg:gap-2">
      <div className="grow flex items-start gap-5">
        <div className="w-fit max-lg:hidden">
          <CommonStyles.ImageUI width={104} height={104} src={CommonImages.DrugOrder} />
        </div>
        <div className="flex flex-col gap-1">
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-maintext text-base font-medium">
            {drugOrder.patient_record?.patient_name}
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-subtext text-sm font-normal max-lg:line-clamp-2">
            {drugOrder.notes}
          </CommonStyles.TypographyUI>
        </div>
      </div>

      <div className="flex-none w-max flex flex-col text-right max-sm:text-left max-sm:w-full gap-1">
        <TagDrugOrderStatus status={drugOrder.status as EDrugOrderStatus} />

        <CommonStyles.TypographyUI use="paragraph" className="!mb-0 text-common-subtext text-sm font-normal">
          {drugOrder.order_date ? dayjs(drugOrder.order_date).format("DD/MM/YYYY") : ""}
        </CommonStyles.TypographyUI>

        <div className="flex justify-end max-sm:justify-start">
          <CommonStyles.ButtonUI
            type="primary"
            className="w-fit"
            ghost
            onClick={() =>
              navigate(baseRoutes.userPrescriptionDetail.replace(":code", drugOrder.drug_order_code ? (drugOrder.drug_order_code as string) : ""))
            }
          >
            {t("user.profile.viewDetail")}
          </CommonStyles.ButtonUI>
        </div>
      </div>
    </div>
  );
}
