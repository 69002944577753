import { AxiosError } from "axios";
import { useState } from "react";
import profileService from "../profileService";
import { IPatientRecord } from "../profile.interface";

const useGetDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IPatientRecord>({});
  const [error, setError] = useState<AxiosError | any>(null);

  const handleGetDetail = async (id: string | number) => {
    try {
      setLoading(true);
      const resp = await profileService.getById(id);
      setData(resp.data);
    } catch (error: AxiosError | any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isGettingDetail: isLoading,
    getDetailData: data,
    getDetailerror: error,
    handleGetDetail,
  };
};

export default useGetDetail;
