import React, { useEffect, useState } from "react";
import { Post, PostsCategory } from "@base/types/wp";
import { WPPostsFilter } from "@base/modules/wpApis/interface";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { NavLink } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonImages from "@base/assets/images";
import TypographyUI from "@components/CommonStyles/Typography";
import CommonIcons from "@base/assets/icons";
import dayjs from "dayjs";
import { Divider } from "antd";
import CommonStyles from "@components/CommonStyles";
import LatestMedicationInfo from "./LatestMedicationInfo";

interface IProps {
  category: PostsCategory;
}

const MedicalInfoByCategory = ({ category }: IProps) => {
  const [filter, setFilter] = useState<WPPostsFilter>({
    _embed: true,
    page: 1,
    per_page: 6,
    categories: [category.id],
    orderby: "modified",
    order: "desc",
  });
  const { data, error, isLoading, refetch } = useGetPosts(filter, false);

  const breadcrumItems = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.medicalInfo,
      title: "Tin tức",
    },
    {
      href: baseRoutes.medicalInfo + "?cat=" + category.id,
      title: category.name as string,
    },
  ];

  useEffect(() => {
    refetch();
  }, [filter]);

  const handleChangePage = (value: number) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const Cart = ({ p }: { p: Post }) => {
    const imgLink =
      p._embedded &&
      p._embedded["wp:featuredmedia"] &&
      p._embedded["wp:featuredmedia"].length &&
      p._embedded["wp:featuredmedia"][0] &&
      p._embedded["wp:featuredmedia"][0].source_url
        ? p._embedded["wp:featuredmedia"][0].source_url
        : "";

    return (
      <div className="w-full grid grid-cols-2 gap-[18px]">
        <div className="rounded-lg w-full flex justify-center items-center">
          <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
            <img
              className="rounded-lg w-full cursor-pointer drop-shadow-lg"
              src={imgLink ?? ""}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = CommonImages.DrafInforArticle;
              }}
            />
          </NavLink>
        </div>

        <div className="flex flex-col gap-2">
          <NavLink to={`${baseRoutes.medicalInfo}/${p.slug + ".html"}`} target="_self">
            <TypographyUI className="text-base font-medium text-[#000000] line-clamp-2 cursor-pointer hover:text-[#005984] !mb-0">
              <div dangerouslySetInnerHTML={{ __html: p.title?.rendered ?? "" }}></div>
            </TypographyUI>
          </NavLink>

          <TypographyUI use="paragraph" className="text-[#000000] text-xs font-light line-clamp-3 !mb-0">
            <div dangerouslySetInnerHTML={{ __html: p.excerpt?.rendered ?? "" }}></div>
          </TypographyUI>

          <TypographyUI className="text-[#A1A1A1] text-xs font-light !mb-0 flex items-center gap-1">
            <CommonIcons.IconCalendarFill /> {dayjs(p.date).format("DD/MM/YYYY")}
          </TypographyUI>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <CommonStyles.BreadcrumbUI items={breadcrumItems} className="mb-5" />

      <div className="grid grid-cols-4 gap-3">
        <div className="col-span-3 max-lg:!col-span-4 flex flex-col">
          {data?.data?.length
            ? data.data.map((e, idx: number) => {
                return (
                  <div key={idx}>
                    <Cart p={e} />
                    {idx < data.data.length - 1 ? <Divider /> : null}
                  </div>
                );
              })
            : null}

          {data?.data?.length ? (
            <div className="flex justify-center mt-6">
              <CommonStyles.PaginationUI
                current={filter.page}
                pageSize={filter.per_page}
                total={data?.headers["x-wp-total"] ? Number(data?.headers["x-wp-total"]) : 0}
                onChange={handleChangePage}
              />
            </div>
          ) : null}
        </div>

        <LatestMedicationInfo className="!mt-0 max-lg:!hidden" category={category} />
      </div>
    </div>
  );
};

export default MedicalInfoByCategory;
