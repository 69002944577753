import { useState } from "react";
import { AxiosError } from "axios";
import accountService from "../accountService";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IPassword } from "../account.interface";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "@base/store/store";

const usePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | any>(null);

  const handleUpdatePassword = async (payload: IPassword, invited_by?: string) => {
    try {
      setLoading(true);

      await accountService.updatePassword(payload);
      if (userInfo && userInfo.user_key && invited_by) {
        await accountService.updateAccountSetting(userInfo.user_key, { invited_by });
      }

      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));

      setTimeout(() => {
        // update password success
        window.location.replace(baseRoutes.home);
      }, 500);
    } catch (error: AxiosError | any) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));

      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleUpdatePassword,
  };
};

export default usePassword;
