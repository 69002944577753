import { apiAuthorize, apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IAccountSetting, IPassword, IUserInfo } from "./account.interface";

class AccountService {
  // check otp
  checkOTP(phone_number: string, otp: number) {
    return httpService.post(`${apiService.auth.user.checkOTP}`, undefined, { params: { phone_number, otp } });
  }

  // get otp
  getOTP(phone_number: string, lang: string) {
    return httpService.post(`${apiService.auth.user.getOTP}/${phone_number}/${lang}`, undefined);
  }

  // update password
  updatePassword(payload: IPassword) {
    return httpService.put(`${apiService.auth.user.updatePassword}`, payload);
  }

  // get account setting
  getAccountSetting(user_key: string) {
    return httpService.get<IAccountSetting>(`${apiService.auth.user.updateAccountSetting}/${user_key}`);
  }

  // update account setting
  updateAccountSetting(user_key: string, payload: IAccountSetting) {
    return httpService.put(`${apiService.auth.user.updateAccountSetting}/${user_key}`, payload);
  }

  // get user info
  getUserInfo() {
    return httpService.get<IUserInfo>(`${apiService.auth.user.getUserInfo}`);
  }

  // upload avatar
  uploadAvatar(avatar: any) {
    const formData = new FormData();
    formData.append("file", avatar);

    const headers = {
      Authorization: apiAuthorize,
      "Content-Type": "multipart/form-data",
    };

    return httpService.post(`${apiService.auth.user.uploadAvatar}`, formData, { headers });
  }
}

export default new AccountService();
