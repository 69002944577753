import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}
export default function NextArrowCarousel(props: IProps) {
  //! state
  const { className, onClick, style } = props;
  //! render
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}
