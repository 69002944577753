import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";

class HomeModuleService {
  listForWeb() {
    return httpService.get(apiService.utils.home.listForWeb);
  }
}

export default new HomeModuleService();
