import { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setDataCommon } from "@base/store/reducers/appCommonSlice";
import { ResponseHomeModule } from "../interfaces/IHomeModule.interface";
import homeModuleService from "../homeModule.service";

const useGetHomeModule = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<ResponseHomeModule>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return homeModuleService.listForWeb();
  }, []);

  const transformResponse = useCallback((response: ResponseHomeModule) => {
    if (response) {
      setData(response);
      dispatch(setDataCommon(response));
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetHomeModule;
