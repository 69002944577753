export default function IconInfo() {
  return (
    <div>
      <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 32.5C0 14.8269 14.3269 0.5 32 0.5C49.6731 0.5 64 14.8269 64 32.5C64 50.1731 49.6731 64.5 32 64.5C14.3269 64.5 0 50.1731 0 32.5Z"
          fill="#CDE3FF"
        />
        <path
          d="M31.9998 19.1667C24.6398 19.1667 18.6665 25.14 18.6665 32.5C18.6665 39.86 24.6398 45.8333 31.9998 45.8333C39.3598 45.8333 45.3332 39.86 45.3332 32.5C45.3332 25.14 39.3598 19.1667 31.9998 19.1667ZM31.9998 39.1667C31.2665 39.1667 30.6665 38.5667 30.6665 37.8333V32.5C30.6665 31.7667 31.2665 31.1667 31.9998 31.1667C32.7332 31.1667 33.3332 31.7667 33.3332 32.5V37.8333C33.3332 38.5667 32.7332 39.1667 31.9998 39.1667ZM33.3332 28.5H30.6665V25.8333H33.3332V28.5Z"
          fill="#3D7EFF"
        />
      </svg>
    </div>
  );
}
