export default function IconArrowOrderHome() {
  return (
    <div>
      <svg width="84" height="12" viewBox="0 0 84 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M83.9385 6L73.9385 0.226497V11.7735L83.9385 6ZM0.938477 7H74.9385V5H0.938477V7Z" fill="url(#paint0_linear_655_5531)" />
        <defs>
          <linearGradient id="paint0_linear_655_5531" x1="0.938477" y1="6.5" x2="83.9385" y2="6.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5F5FA" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
