import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { LocalKey } from "@base/constants/LocalKey";
import { apiAuthorize, apiUrl } from "@base/configs";
import { clearLocalStorage } from "@base/helpers/common";
import { IAuth } from "@base/modules/auth/auth.interface";
import { baseRoutes } from "@base/routes/baseRoutes";

class Services {
  axios: AxiosInstance;
  constructor() {
    this.axios = axios.create();
    this.axios.defaults.withCredentials = false;

    //! Interceptor request
    this.axios.interceptors.request.use(
      (config) => {
        const { accessToken } = this.getToken() || "";
        if (accessToken && config.url !== `${apiUrl}/auth/oauth/token`) {
          config.headers[`Authorization`] = `Bearer ${accessToken}`;
        } else {
          config.headers[`Authorization`] = apiAuthorize;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    //! Interceptor response
    this.axios.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          if (error.response.status === 401) {
            if (originalRequest.url === `${apiUrl}/auth/oauth/token`) {
              this.logout();
              return;
            }

            // Access Token was expired
            originalRequest._retry = true;
            try {
              const { refreshToken } = this.getToken();
              if (refreshToken) {
                const params = { grant_type: "refresh_token", refresh_token: refreshToken };

                const headers = {
                  Authorization: apiAuthorize,
                  "Content-Type": "application/x-www-form-urlencoded",
                  Accept: "application/json",
                };

                const response = await this.axios.post<IAuth>(`${apiUrl}/auth/oauth/token`, undefined, { headers, params });

                this.setToken(response.data);

                if (response.data.access_token && response.data.refresh_token) {
                  originalRequest.headers[`Authorization`] = `Bearer ${response.data.access_token}`;
                }

                return axios(originalRequest);
              } else {
                this.logout();
              }
            } catch (_error) {
              this.logout();
            }
          }
        }

        return Promise.reject(error);
      },
    );
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.axios.get<T>(url, config);
  }

  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.post<T>(url, data, config);
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.axios.delete<T>(url, config);
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.put<T>(url, data, config);
  }

  patch<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.patch<T>(url, data, config);
  }

  setToken(auth: IAuth) {
    localStorage.setItem(LocalKey.AUTH, JSON.stringify(auth));
  }

  getToken() {
    const authLocal = localStorage.getItem(LocalKey.AUTH);

    if (authLocal) {
      const auth = JSON.parse(authLocal) as IAuth;

      return { accessToken: auth.access_token, refreshToken: auth.refresh_token };
    }

    return { accessToken: undefined, refresh_token: undefined };
  }

  logout() {
    clearLocalStorage();

    if (location.pathname !== baseRoutes.login) {
      window.location.replace(baseRoutes.login);
    }
  }
}

export default new Services();
