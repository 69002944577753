export default function IconError() {
  return (
    <div>
      <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 32.5C0 14.8269 14.3269 0.5 32 0.5C49.6731 0.5 64 14.8269 64 32.5C64 50.1731 49.6731 64.5 32 64.5C14.3269 64.5 0 50.1731 0 32.5Z"
          fill="#F5DADA"
        />
        <path
          d="M31.9998 19.1667C24.6398 19.1667 18.6665 25.14 18.6665 32.5C18.6665 39.86 24.6398 45.8334 31.9998 45.8334C39.3598 45.8334 45.3332 39.86 45.3332 32.5C45.3332 25.14 39.3598 19.1667 31.9998 19.1667ZM31.9998 33.8334C31.2665 33.8334 30.6665 33.2334 30.6665 32.5V27.1667C30.6665 26.4334 31.2665 25.8334 31.9998 25.8334C32.7332 25.8334 33.3332 26.4334 33.3332 27.1667V32.5C33.3332 33.2334 32.7332 33.8334 31.9998 33.8334ZM33.3332 39.1667H30.6665V36.5H33.3332V39.1667Z"
          fill="#F63737"
        />
      </svg>
    </div>
  );
}
