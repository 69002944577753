import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import appointmentService from "@base/modules/appointment/appointmentService";
import AppointmentCard from "./AppointmentCard";
import TagAppointmentStatus from "@components/CommonStyles/TagAppointmentStatus";
import { EAppointmentStatus, EAppointmentType, EOrderType } from "@base/enums";
import dayjs from "dayjs";
import CommonStyles from "@components/CommonStyles";
import CommonImages from "@base/assets/images";
import ModalAppointmentRating from "./ModalAppointmentRating";
import ModalAppointmentCancelling from "./ModalAppointmentCancelling";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import orderService from "@base/modules/order/orderService";
import { baseRoutes } from "@base/routes/baseRoutes";
import useToggle from "@base/hooks/useToggle";
import CardTransferInfo from "@components/CommonStyles/CardTransferInfo";
import useGetByRefCode from "@base/modules/order/hooks/useGetByRefCode";

export default function AppointmentDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key } = useParams();
  const dispatch = useDispatch();
  const { data: resultOrder, fetch: fetchResultOrder } = useGetByRefCode();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointment, setAppointment] = useState<IAppointment>({});

  const [showRating, setShowRating] = useState<boolean>(false);
  const [showCancelling, setShowCancelling] = useState<boolean>(false);

  const { open: openConfirmTransfer, shouldRender: shouldRenderConfirmTransfer, toggle: toggleConfirmTransfer } = useToggle();

  useEffect(() => {
    getAppointment();
  }, []);

  useEffect(() => {
    if (appointment.key) {
      fetchResultOrder({ ref_code: appointment.key, order_type: EOrderType.APPT, lang: "vi" });
    }
  }, [appointment]);

  const getAppointment = async () => {
    if (!key) {
      return;
    }

    try {
      if (!isLoading) {
        setIsLoading(true);
      }
      const resp = await appointmentService.getByKey(key);
      setAppointment(resp.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onClickCancelAppt = () => {
    if (!appointment.payment_status) {
      setShowCancelling(true);
    } else {
      dispatch(
        setAlertMessage({
          type: "warning",
          message: t("Notification"),
          description: "Lịch khám đã được thanh toán. Bạn vui lòng liên hệ 19009001 để được hỗ trợ đổi hoặc hủy lịch.",
        }),
      );
    }
  };

  const InfoItem = ({ label = null, value = null, breakAll = false }: { label?: React.ReactNode; value?: React.ReactNode; breakAll?: boolean }) => {
    return (
      <div className="flex items-center gap-2">
        <div className="flex-none w-[110px] text-common-subtext font-normal font-['Inter'] text-base">{label}</div>
        <div
          className={
            breakAll
              ? "grow text-common-maintext font-normal font-['Inter'] text-base break-all"
              : "grow text-common-maintext font-normal font-['Inter'] text-base"
          }
        >
          {value}
        </div>
      </div>
    );
  };

  const handlePayAppt = () => {
    if (!resultOrder?.order_key) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: "Không thể thanh toán! Vui lòng thử lại sau." }));
      return;
    }

    navigate(baseRoutes.userPaymentDetail.replace(":key", resultOrder.order_key));
  };

  if (isLoading) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div className="p-5">
      <div className="flex flex-col">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.appointment.appointmentDetail")}</div>

        <div></div>
      </div>

      <Divider />

      <div className="w-full">
        <AppointmentCard appointment={appointment} />

        {/* {appointment.status === EAppointmentStatus.PENDING && !appointment.payment_status ? (
          <CardTransferInfo showDetail={toggleConfirmTransfer} />
        ) : null} */}

        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-2 my-3">
          <div className="flex flex-col gap-2 items-start">
            <InfoItem
              label="Trạng thái:"
              value={
                <div>
                  <TagAppointmentStatus status={appointment.status as EAppointmentStatus} />{" "}
                  {appointment.status === EAppointmentStatus.PENDING && !appointment.payment_status ? (
                    <span>
                      <span className="text-common-red500">(Chưa thanh toán)</span>
                    </span>
                  ) : null}
                </div>
              }
            />
            {appointment.status === EAppointmentStatus.CANCELLED && appointment.cancel_reason ? (
              <InfoItem label="Lý do hủy:" value={<span className="text-common-red500">{appointment.cancel_reason}</span>} />
            ) : null}
            <InfoItem label="Cơ sở y tế:" value={appointment.site_name + (appointment.site_branch_name ? ". " + appointment.site_branch_name : "")} />
            <InfoItem
              label="Gói dịch vụ:"
              value={appointment.appointment_used_services?.length ? appointment.appointment_used_services[0].name : ""}
            />
            <InfoItem label="Bác sĩ:" value={appointment.doctor?.doctor_name} />
            <InfoItem label="Địa chỉ:" value={appointment.patient_record?.patient_address} />
          </div>

          <div className="flex flex-col gap-2 items-start">
            <InfoItem label="Họ và tên:" value={appointment.patient_record?.patient_name} />
            <InfoItem label="Giới tính:" value={appointment.patient_record?.patient_gender ? "Nam" : "Nữ"} />
            <InfoItem label="Ngày sinh:" value={dayjs(appointment.patient_record?.patient_dob, "YYYY-MM-DD").format("DD/MM/YYYY")} />
            <InfoItem label="Số điện thoại:" value={appointment.patient_record?.patient_phone_number} />
            <InfoItem label="Email:" value={appointment.patient_record?.patient_email} breakAll />
            <InfoItem label="Triệu chứng:" value={appointment.symptoms} />
          </div>
        </div>
      </div>

      {appointment.appointment_type === EAppointmentType.ONLINE ? (
        <div className="flex justify-between items-center bg-common-blue100 border border-common-blue600 p-3 rounded-lg my-6 md:gap-8 gap-10">
          <div className="grow">
            <div className="mb-4 text-common-blue700 text-base font-medium font-['Inter']">Scan QR để tải ứng dụng 365 Medihome</div>
            <div className="text-common-subtext text-sm leading-[22px] font-normal font-['Inter']">
              Để tham gia tư vấn sức khỏe trực tuyến, bạn vui lòng cài đặt ứng dụng 365 Medihome trên điện thoại và trải nghiệm những tiện ích tuyệt
              vời khác!
            </div>
          </div>

          <div className="flex-none h-fit">
            <img src={CommonImages.medihomeQr} alt="" className="h-full" />
          </div>
        </div>
      ) : null}

      {appointment.status === EAppointmentStatus.PENDING ? (
        <div className="w-full flex justify-center items-center gap-4">
          <CommonStyles.ButtonUI type="primary" ghost onClick={onClickCancelAppt}>
            {t("user.appointment.btnCancelAppt")}
          </CommonStyles.ButtonUI>

          {!appointment.is_free && !appointment.payment_status ? (
            <CommonStyles.ButtonUI type="primary" onClick={handlePayAppt}>
              {t("payNow")}
            </CommonStyles.ButtonUI>
          ) : null}
        </div>
      ) : null}

      {appointment.status === EAppointmentStatus.COMPLETED ? (
        <div className="w-full flex justify-center items-center">
          <CommonStyles.ButtonUI type="primary" ghost onClick={() => setShowRating(true)}>
            {t("user.appointment.btnRating")}
          </CommonStyles.ButtonUI>
        </div>
      ) : null}

      <ModalAppointmentRating appointment={appointment} open={showRating} onClose={() => setShowRating(false)} />

      <ModalAppointmentCancelling appointment={appointment} open={showCancelling} onClose={() => setShowCancelling(false)} />

      {shouldRenderConfirmTransfer && (
        <CommonStyles.TransferInformationDialog
          resultOrder={resultOrder}
          transferContent={resultOrder.description}
          toggle={toggleConfirmTransfer}
          open={openConfirmTransfer}
        />
      )}
    </div>
  );
}
