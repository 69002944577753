import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IDistrict, IProvince } from "./administrative.interface";

class AdministrativeService {
  getAllProvince() {
    return httpService.get<IProvince[]>(`${apiService.utils.administrative.getAllProvince}`);
  }

  getListDistrictByProvinceCode(province_code?: string) {
    return httpService.get<IDistrict[]>(`${apiService.utils.administrative.getAllDistrictByProvinceCode}`, { params: { province_code } });
  }
}

export default new AdministrativeService();
