import React from "react";
import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import CommonStyles from "@components/CommonStyles";
import { Divider, QRCode } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import { Link } from "react-router-dom";
import { linkAppStore, linkGGPlay } from "@base/constants";

export default function PatientChronic() {
  //! state
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  //! function

  //! render
  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.chronic.chronic")}</div>
      </div>

      <Divider />

      <div className="h-full flex justify-between items-center max-lg:flex-col max-lg:justify-start max-lg:items-start gap-10 mt-28 mx-auto max-xl:mt-5 w-full">
        <div>
          <div>
            <CommonStyles.TypographyUI use="paragraph" className="font-semibold text-2xl !text-common-inprogress">
              Tải ngay ứng dụng 365 Medihome để
            </CommonStyles.TypographyUI>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-1">
              <span className="text-common-positive">
                <CommonIcons.IconCheckCircle />
              </span>
              <CommonStyles.TypographyUI use="paragraph" className="font-normal text-lg text-common-maintext !m-0">
                Theo dõi chỉ số sức khỏe cá nhân
              </CommonStyles.TypographyUI>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-common-positive">
                <CommonIcons.IconCheckCircle />
              </span>
              <CommonStyles.TypographyUI use="paragraph" className="font-normal text-lg text-common-maintext !m-0">
                Nhận kết quả khám/ xét nghiệm nhanh nhất
              </CommonStyles.TypographyUI>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-common-positive">
                <CommonIcons.IconCheckCircle />
              </span>
              <CommonStyles.TypographyUI use="paragraph" className="font-normal text-lg text-common-maintext !m-0">
                Đảm bảo trải nghiệm xuyên suốt
              </CommonStyles.TypographyUI>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-common-positive">
                <CommonIcons.IconCheckCircle />
              </span>
              <CommonStyles.TypographyUI use="paragraph" className="font-normal text-lg text-common-maintext !m-0">
                Tận hưởng những tiện ích tuyệt vời khác
              </CommonStyles.TypographyUI>
            </div>
          </div>
        </div>

        <div className="w-fit max-lg:w-full max-lg:flex max-lg:justify-center max-lg:items-center">
          <div className="flex gap-4">
            <div className="w-fit h-fit relative">
              <QRCode
                className="!bg-common-orange50 p-2 border-0"
                size={110}
                value={dataCommon?.data.commons.find((item) => item.key === "LINK_DOWNLOAD_APP")?.article_link || "-"}
              />

              <div className="w-5 h-5 absolute -top-[4px] -left-[4px] border-l-[4px] border-t-[4px] border-common-orange rounded-tl-lg"></div>
              <div className="w-5 h-5 absolute -top-[4px] -right-[4px] border-r-[4px] border-t-[4px] border-common-orange rounded-tr-lg"></div>
              <div className="w-5 h-5 absolute -bottom-[4px] -left-[4px] border-l-[4px] border-b-[4px] border-common-orange rounded-bl-lg"></div>
              <div className="w-5 h-5 absolute -bottom-[4px] -right-[4px] border-r-[4px] border-b-[4px] border-common-orange rounded-br-lg"></div>
            </div>

            <div className="flex flex-col justify-around">
              <div className="cursor-pointer">
                <Link to={linkGGPlay} target="_blank">
                  <img src={CommonImages.googleplayBlack} alt={CommonImages.googleplayBlack} />
                </Link>
              </div>

              <div className="cursor-pointer">
                <Link to={linkAppStore} target="_blank">
                  <img src={CommonImages.appstoreBlack} alt={CommonImages.appstoreBlack} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
