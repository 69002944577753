import { useCallback, useEffect, useState } from "react";
import orderService from "../orderService";
import { AxiosResponse } from "axios";
import { IFilterOrderByRefCode } from "../interface/filterOrderByRefCode.interface";

const useGetByRefCode = (filter?: IFilterOrderByRefCode, enabled = false) => {
  const [data, setData] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return orderService.getByRefCode(filter?.ref_code, { lang: filter?.lang, order_type: filter?.order_type });
  }, [filter]);

  const transformResponse = useCallback((response: AxiosResponse<any>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const fetch = async (_filter: IFilterOrderByRefCode) => {
    try {
      setLoading(true);
      const response = await orderService.getByRefCode(_filter?.ref_code, { lang: _filter?.lang, order_type: _filter?.order_type });
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, fetch, refetch };
};

export default useGetByRefCode;
