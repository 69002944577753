import CommonImages from "@base/assets/images";
import { baseRoutes } from "@base/routes/baseRoutes";
import { RootState } from "@base/store/store";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import LoginForm from "@components/Login/LoginForm";
import LoginPasswordForm from "@components/Login/LoginPasswordForm";
import OTPForm from "@components/Login/OTP/OTPForm";
import SetupPassword from "@components/Login/SetupPassword/SetupPassword";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

export default function Login() {
  //! state
  const userInfo = useSelector((state: RootState) => state.appCommonSlice.userInfo);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const phoneUrl = searchParams.get("phone");
  const setupPassword = searchParams.get("setupPassword");
  const otpUrl = searchParams.get("otp");

  //! function
  if (!!userInfo) {
    return <Navigate to={baseRoutes.home} />;
  }
  //! render
  const renderForm = () => {
    if (otpUrl && !setupPassword) {
      return <OTPForm />;
    }
    if (setupPassword) {
      return <SetupPassword />;
    }
    return (
      <>
        <div className="flex flex-col items-center mb-6">
          <CommonStyles.TypographyUI className={`${responseUI.text.title} font-semibold mb-2`}>{t("Register/Login")}</CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="text" className="text-xs lg:text-[14px] text-center px-2">
            {phoneUrl ? (
              <>
                {`${t("LoginPage.PhoneLogin")}`} <span className="font-bold">{phoneUrl}</span>
              </>
            ) : (
              t("LoginPage.title")
            )}
          </CommonStyles.TypographyUI>
        </div>

        {phoneUrl ? <LoginPasswordForm /> : <LoginForm />}
      </>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${CommonImages.bgLogin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: 825,
      }}
      className="flex items-center lg:px-56 xl:px-64 md:justify-center lg:justify-normal"
    >
      <div className="rounded-3xl lg:ml-[81px] bg-gradient-to-b from-common-sWhite75 to-common-sWhite8 p-4  w-[600px]">
        <div className="bg-common-white rounded-2xl flex flex-col justify-center items-center">
          <div className="w-80 py-10">{renderForm()}</div>
        </div>
      </div>
    </div>
  );
}
