import { StepProps, Steps } from "antd";
import React from "react";

interface IProps extends StepProps {
  className?: string;
  current?: number;
  direction?: "horizontal" | "vertical";
  initial?: number;
  labelPlacement?: "horizontal" | "vertical";
  percent?: number;
  progressDot?: boolean | React.ReactNode;
  responsive?: boolean;
  size?: "default" | "small";
  type?: "default" | "navigation" | "inline";
  onChange?: (current: number) => void;
  items: {
    title: string;
    description?: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    status?: "wait" | "process" | "finish" | "error";
    subTitle?: React.ReactNode;
  }[];
}
export default function StepUI(props: IProps) {
  //! state
  const {
    className,
    current,
    items,
    description,
    direction,
    initial,
    labelPlacement,
    percent,
    progressDot,
    responsive,
    size,
    type,
    onChange,

    ...res
  } = props;
  //! function
  //! render
  return (
    <Steps
      className={className}
      current={current}
      items={items}
      direction={direction}
      initial={initial}
      labelPlacement={labelPlacement}
      percent={percent}
      responsive={responsive}
      size={size}
      type={type}
      onChange={onChange}
      {...res}
    />
  );
}
