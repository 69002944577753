import React from "react";

export default function IconUserFilled() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.242 10.2576C12.0599 10.2576 13.5336 8.70212 13.5336 6.78338C13.5336 4.86465 12.0599 3.3092 10.242 3.3092C8.42404 3.3092 6.95033 4.86465 6.95033 6.78338C6.95033 8.70212 8.42404 10.2576 10.242 10.2576Z"
        fill="currentColor"
      />
      <path
        d="M10.242 11.9946C8.04479 11.9946 3.65869 13.1585 3.65869 15.4688V16.3374C3.65869 16.8151 4.029 17.2059 4.4816 17.2059H16.0023C16.4549 17.2059 16.8252 16.8151 16.8252 16.3374V15.4688C16.8252 13.1585 12.4391 11.9946 10.242 11.9946Z"
        fill="currentColor"
      />
    </svg>
  );
}
