import { IPromotion } from "@base/modules/promotion/interface/promotion.interface";
import TypographyUI from "../Typography";

interface IProps {
  data: IPromotion;
  isChecked?: boolean;
  setDiscountCode?: any;
}
export default function CardDiscount(props: IProps) {
  //! state
  const { data, isChecked, setDiscountCode } = props;
  //! function
  const handleChecked = () => {
    setDiscountCode(String(data.type));
  };
  //! render
  const checked = isChecked ? "bg-common-blue500 flex justify-center items-center" : "";

  return (
    <div className="bg-common-blue50 rounded-xl cursor-pointer" onClick={handleChecked}>
      <div className="flex justify-between gap-5 px-3 pt-3 pb-1">
        <div className="flex flex-col gap-2">
          <TypographyUI className="!font-medium uppercase line-clamp-1">{data?.name}</TypographyUI>
          <div>
            <TypographyUI className="font-normal line-clamp-1">{data?.description}</TypographyUI>
            <TypographyUI className="text-common-subtext text-xs">HSD: {data?.expired_date}</TypographyUI>
          </div>
        </div>

        <div>
          <div className={` rounded-full h-5 w-5 border border-common-disable  ${checked}`}>
            {checked && <div className={`bg-common-white h-2 w-2 rounded-full`}></div>}
          </div>
        </div>
      </div>
      <div className="bg-common-blue200 rounded-b-xl px-3 py-1 ">
        <TypographyUI className="!text-common-blue500 text-xs">Khả dụng</TypographyUI>
      </div>
    </div>
  );
}
