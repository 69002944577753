import React from "react";

export default function K08() {
  return (
    <div>
      <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.427734" y="0.0499573" width="100" height="100" rx="16" fill="#FFF2E3" />
        <rect x="1.42773" y="1.04996" width="98" height="98" rx="15" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <g filter="url(#filter0_d_1205_27220)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.3795 34.8893C55.026 31.7964 56.7713 25.4911 66.5805 25.0139C78.8419 24.4174 82.8848 35.287 82.6197 41.9811C82.3617 48.4961 80.597 63.1095 67.9774 65.231C67.2737 65.3493 66.5488 65.3718 65.8414 65.2781C59.4305 64.4287 57.6925 59.3639 57.5667 57.225C57.4341 54.9715 57.5667 53.3809 59.555 52.5855C59.9692 52.4199 60.648 52.2916 61.4649 52.1372C62.6119 51.9204 64.0313 51.6521 65.3732 51.1567C65.4103 51.1912 65.4502 51.2236 65.4928 51.2535C66.1153 51.6916 67.1882 52.2367 68.5333 52.571C68.5365 52.6008 68.5409 52.6307 68.5465 52.6606C68.7076 53.5198 68.7222 55.9056 67.3876 57.9305C67.0451 58.4501 67.1887 59.1489 67.7083 59.4913C68.2279 59.8338 68.9267 59.6902 69.2691 59.1706C70.7647 56.9015 70.9665 54.3509 70.8437 52.8772C71.9705 52.8963 73.1883 52.7239 74.4327 52.2453C75.0135 52.0219 75.3033 51.37 75.0799 50.7892C74.8565 50.2084 74.2046 49.9187 73.6238 50.1421C71.3019 51.0351 69.062 50.5209 67.7123 49.9191C68.9121 49.0014 69.7651 47.6889 69.8281 45.7589C69.7855 43.1187 68.5117 41.6002 66.8152 40.8344C66.8255 40.7883 66.8356 40.7424 66.8455 40.6965C67.8243 40.601 69.1906 40.2806 70.5579 39.6042C70.7091 39.5293 70.861 39.4498 71.0129 39.3654C71.0878 39.4464 71.176 39.5178 71.2765 39.576C71.8535 39.9101 73.3438 41.287 73.5693 43.6776C73.6277 44.2971 74.1773 44.7519 74.7968 44.6935C75.4164 44.635 75.8712 44.0854 75.8128 43.4659C75.5518 40.7 74.0473 38.8564 72.9284 37.9831C73.6022 37.3562 74.214 36.5955 74.6949 35.6773C74.9837 35.126 74.7709 34.4451 74.2197 34.1564C73.6684 33.8676 72.9875 34.0804 72.6988 34.6317C71.9622 36.0378 70.7834 36.9784 69.5585 37.5844C68.6998 38.0093 67.8491 38.2539 67.1776 38.3757C67.3606 35.8347 66.9143 33.8266 66.5203 32.8697C66.2833 32.2943 65.6248 32.0199 65.0494 32.2568C64.474 32.4938 64.1996 33.1523 64.4366 33.7277C64.7718 34.5418 65.3274 37.0706 64.6357 40.254C64.3354 40.2186 64.0326 40.1983 63.7305 40.1916C63.1794 40.1793 62.5761 40.244 61.9535 40.3108C59.2068 40.6055 56.0819 40.9407 55.3795 34.8893Z"
            fill="url(#paint0_linear_1205_27220)"
          />
          <path
            d="M56.9278 43.3072C55.0185 43.6685 53.5403 47.4909 52.9941 49.5077C52.9649 49.6157 52.9512 49.7265 52.9512 49.8384V75.452C52.9512 76.1841 53.5446 76.7776 54.2767 76.7776H54.9426C55.6989 76.7776 56.302 76.1497 56.269 75.3941C55.9028 67.0028 55.479 52.5377 56.3313 52.3873C57.4581 52.1885 57.7895 51.7245 58.5185 50.8629C59.2476 50.0013 61.5673 49.1397 64.2847 49.0071C67.0021 48.8746 68.0625 47.3502 67.7311 44.8979C67.0021 40.9212 59.3801 42.8433 56.9278 43.3072Z"
            fill="url(#paint1_linear_1205_27220)"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.542 38.0697C53.2384 37.8439 53.986 38.2254 54.2118 38.9217C55.3288 42.3662 58.2272 43.3616 59.405 43.443C60.1353 43.4935 60.6865 44.1264 60.636 44.8568C60.5855 45.5871 59.9526 46.1383 59.2222 46.0878C57.2628 45.9524 53.2242 44.4705 51.69 39.7395C51.4642 39.0431 51.8456 38.2955 52.542 38.0697Z"
            fill="url(#paint2_linear_1205_27220)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.4769 34.8893C45.8304 31.7964 44.0851 25.4911 34.276 25.0139C22.0146 24.4174 17.9716 35.287 18.2367 41.9811C18.4948 48.4961 20.2595 63.1095 32.8791 65.231C33.5827 65.3493 34.3077 65.3718 35.015 65.2781C41.4259 64.4287 43.1639 59.3639 43.2898 57.225C43.4223 54.9715 43.2898 53.3809 41.3014 52.5855C40.8872 52.4199 40.2085 52.2916 39.3915 52.1372C38.2445 51.9204 36.8252 51.6521 35.4833 51.1567C35.4462 51.1912 35.4063 51.2236 35.3637 51.2535C34.7411 51.6916 33.6683 52.2367 32.3231 52.571C32.32 52.6008 32.3156 52.6307 32.31 52.6606C32.1489 53.5198 32.1343 55.9056 33.4689 57.9305C33.8113 58.4501 33.6677 59.1489 33.1481 59.4913C32.6286 59.8338 31.9298 59.6902 31.5873 59.1706C30.0918 56.9015 29.8899 54.3509 30.0128 52.8772C28.886 52.8963 27.6681 52.7239 26.4237 52.2453C25.8429 52.0219 25.5532 51.37 25.7766 50.7892C26 50.2084 26.6519 49.9187 27.2327 50.1421C29.5545 51.0351 31.7945 50.5209 33.1441 49.9191C31.9444 49.0014 31.0913 47.6889 31.0284 45.7589C31.0709 43.1187 32.3448 41.6002 34.0412 40.8344C34.0309 40.7883 34.0208 40.7424 34.011 40.6965C33.0322 40.601 31.6658 40.2806 30.2986 39.6042C30.1473 39.5293 29.9954 39.4498 29.8435 39.3654C29.7686 39.4464 29.6804 39.5178 29.5799 39.576C29.0029 39.9101 27.5127 41.287 27.2872 43.6776C27.2287 44.2971 26.6791 44.7519 26.0596 44.6935C25.4401 44.635 24.9852 44.0854 25.0437 43.4659C25.3046 40.7 26.8091 38.8564 27.928 37.9831C27.2543 37.3562 26.6425 36.5955 26.1615 35.6773C25.8728 35.126 26.0856 34.4451 26.6368 34.1564C27.188 33.8676 27.8689 34.0804 28.1577 34.6317C28.8942 36.0378 30.073 36.9784 31.2979 37.5844C32.1567 38.0093 33.0074 38.2539 33.6788 38.3757C33.4958 35.8347 33.9421 33.8266 34.3362 32.8697C34.5731 32.2943 35.2316 32.0199 35.807 32.2568C36.3824 32.4938 36.6568 33.1523 36.4199 33.7277C36.0847 34.5418 35.5291 37.0706 36.2208 40.254C36.5211 40.2186 36.8238 40.1983 37.1259 40.1916C37.6771 40.1793 38.2803 40.244 38.903 40.3108C41.6497 40.6055 44.7746 40.9407 45.4769 34.8893Z"
            fill="url(#paint3_linear_1205_27220)"
          />
          <path
            d="M43.9296 43.3072C45.8389 43.6685 47.3172 47.4909 47.8633 49.5077C47.8926 49.6157 47.9062 49.7265 47.9062 49.8384V75.452C47.9062 76.1841 47.3128 76.7776 46.5807 76.7776H45.9148C45.1585 76.7776 44.5555 76.1497 44.5884 75.3941C44.9546 67.0028 45.3784 52.5377 44.5261 52.3873C43.3994 52.1885 43.068 51.7245 42.3389 50.8629C41.6099 50.0013 39.2901 49.1397 36.5727 49.0071C33.8553 48.8746 32.7949 47.3502 33.1263 44.8979C33.8553 40.9212 41.4773 42.8433 43.9296 43.3072Z"
            fill="url(#paint4_linear_1205_27220)"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.3144 38.0697C47.618 37.8439 46.8704 38.2254 46.6446 38.9217C45.5277 42.3662 42.6292 43.3616 41.4515 43.443C40.7211 43.4935 40.17 44.1264 40.2204 44.8568C40.2709 45.5871 40.9039 46.1383 41.6342 46.0878C43.5936 45.9524 47.6323 44.4705 49.1664 39.7395C49.3923 39.0431 49.0108 38.2955 48.3144 38.0697Z"
            fill="url(#paint5_linear_1205_27220)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1205_27220"
            x="10.2246"
            y="20.9904"
            width="80.4072"
            height="67.7872"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847173 0 0 0 0 0.670833 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1205_27220" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1205_27220" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1205_27220" x1="75.3389" y1="24.931" x2="48.2986" y2="38.609" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint1_linear_1205_27220" x1="63.825" y1="42.3845" x2="47.1076" y2="47.7855" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint2_linear_1205_27220" x1="58.2306" y1="37.9928" x2="51.6129" y2="43.5089" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint3_linear_1205_27220" x1="25.5175" y1="24.931" x2="52.5578" y2="38.609" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint4_linear_1205_27220" x1="37.0324" y1="42.3845" x2="53.7498" y2="47.7855" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
          <linearGradient id="paint5_linear_1205_27220" x1="42.6258" y1="37.9928" x2="49.2435" y2="43.5089" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEA757" />
            <stop offset="1" stopColor="#F98315" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
