import { apiService, apiAuthorize } from "@base/configs";
import httpService from "@base/services/httpService";
import { IPatientRecord, IPatientRecordData } from "./profile.interface";

class AuthService {
  // create
  createPatientRecord(payload: IPatientRecordData) {
    return httpService.post<IPatientRecordData>(`${apiService.patient.record.create}`, payload);
  }

  // update
  updatePatientRecord(payload: IPatientRecord, id: string | number) {
    return httpService.put<IPatientRecord>(`${apiService.patient.record.update}/${id}`, payload);
  }

  // delete
  deletePatientRecord(id: string | number) {
    return httpService.delete(`${apiService.patient.record.delete}/${id}`);
  }

  // set default
  setDefault(code: string) {
    return httpService.put(`${apiService.patient.record.seDefault}/${code}`);
  }

  // get detail
  getById(id: string | number) {
    return httpService.get<IPatientRecordData>(`${apiService.patient.record.getById}/${id}`);
  }

  getByCode(code: string) {
    return httpService.get(`${apiService.patient.record.getByCode}/${code}`);
  }

  // get all
  getByUserKey() {
    return httpService.get<IPatientRecordData[]>(`${apiService.patient.record.getByUserKey}`);
  }

  // sync clinic record
  syncClinicRecord(site_code: string) {
    return httpService.get(`${apiService.patient.record.syncClinicRecord}`, { params: { site_code } });
  }
}

export default new AuthService();
