import React from "react";

export default function IconMicOff() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7722 17.0013C21.1202 16.2933 21.3335 15.508 21.3335 14.6666V7.99996C21.3335 5.05863 18.9415 2.66663 16.0002 2.66663C13.4029 2.66663 11.2402 4.53463 10.7682 6.99729L20.7722 17.0013ZM25.3335 14.6666C25.3335 13.9306 24.7375 13.3333 24.0001 13.3333C23.2628 13.3333 22.6668 13.9306 22.6668 14.6666C22.6668 15.8786 22.3175 17 21.7495 17.9786L23.7001 19.9293C24.7295 18.4306 25.3335 16.6186 25.3335 14.6666ZM16.1138 19.989C16.0766 19.9945 16.0391 20 16 20C13.0587 20 10.6667 17.608 10.6667 14.6666V14.4373L16.208 19.9786C16.1763 19.9798 16.1452 19.9844 16.1138 19.989ZM27.6094 25.724L6.27609 4.39063C5.75476 3.86929 4.91209 3.86929 4.39076 4.39063C3.86942 4.91196 3.86942 5.75463 4.39076 6.27596L25.7241 27.6093C25.9841 27.8693 26.3254 28 26.6668 28C27.0081 28 27.3494 27.8693 27.6094 27.6093C28.1308 27.088 28.1308 26.2453 27.6094 25.724ZM17.3334 26.6666H20C20.7334 26.6666 21.3334 27.2666 21.3334 28C21.3334 28.7333 20.7334 29.3333 20 29.3333H12C11.2667 29.3333 10.6667 28.7333 10.6667 28C10.6667 27.2666 11.2667 26.6666 12 26.6666H14.6667V23.8933C10.1507 23.2426 6.66671 19.36 6.66671 14.6666C6.66671 13.9306 7.26271 13.3333 8.00004 13.3333C8.73737 13.3333 9.33337 13.9306 9.33337 14.6666C9.33337 18.3426 12.324 21.3333 16 21.3333C16.4827 21.3333 16.952 21.276 17.4067 21.1773L19.5307 23.3013C18.836 23.5866 18.0974 23.7813 17.3334 23.892V26.6666Z"
        fill="#231F20"
      />
      <mask id="mask0_2303_2221" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3" y="2" width="26" height="28">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7722 17.0013C21.1202 16.2933 21.3335 15.508 21.3335 14.6666V7.99996C21.3335 5.05863 18.9415 2.66663 16.0002 2.66663C13.4029 2.66663 11.2402 4.53463 10.7682 6.99729L20.7722 17.0013ZM25.3335 14.6666C25.3335 13.9306 24.7375 13.3333 24.0001 13.3333C23.2628 13.3333 22.6668 13.9306 22.6668 14.6666C22.6668 15.8786 22.3175 17 21.7495 17.9786L23.7001 19.9293C24.7295 18.4306 25.3335 16.6186 25.3335 14.6666ZM16.1138 19.989C16.0766 19.9945 16.0391 20 16 20C13.0587 20 10.6667 17.608 10.6667 14.6666V14.4373L16.208 19.9786C16.1763 19.9798 16.1452 19.9844 16.1138 19.989ZM27.6094 25.724L6.27609 4.39063C5.75476 3.86929 4.91209 3.86929 4.39076 4.39063C3.86942 4.91196 3.86942 5.75463 4.39076 6.27596L25.7241 27.6093C25.9841 27.8693 26.3254 28 26.6668 28C27.0081 28 27.3494 27.8693 27.6094 27.6093C28.1308 27.088 28.1308 26.2453 27.6094 25.724ZM17.3334 26.6666H20C20.7334 26.6666 21.3334 27.2666 21.3334 28C21.3334 28.7333 20.7334 29.3333 20 29.3333H12C11.2667 29.3333 10.6667 28.7333 10.6667 28C10.6667 27.2666 11.2667 26.6666 12 26.6666H14.6667V23.8933C10.1507 23.2426 6.66671 19.36 6.66671 14.6666C6.66671 13.9306 7.26271 13.3333 8.00004 13.3333C8.73737 13.3333 9.33337 13.9306 9.33337 14.6666C9.33337 18.3426 12.324 21.3333 16 21.3333C16.4827 21.3333 16.952 21.276 17.4067 21.1773L19.5307 23.3013C18.836 23.5866 18.0974 23.7813 17.3334 23.892V26.6666Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_2303_2221)">
        <rect width="32" height="32" fill="currentColor" />
      </g>
    </svg>
  );
}
