export default function IconVLTL() {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.957031" width="39" height="39" rx="11.5" fill="white" stroke="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4516 27.2394C26.6847 27.1381 26.9263 27.087 27.1738 27.087C28.2527 27.087 29.1304 27.9646 29.1304 29.0435C29.1304 30.1223 28.2527 31 27.1738 31H19.9999C18.5614 31 17.3912 29.8298 17.3912 28.3913C17.3912 28.0408 17.4596 27.7009 17.5942 27.381C18.3619 25.5574 18.2812 24.9737 17.9806 22.8022L17.9773 22.7786C17.8696 21.9987 17.736 21.0315 17.6078 19.7594C17.5378 19.4689 17.2749 19.2609 16.9739 19.2609H10.6522C10.2921 19.2609 10 18.9689 10 18.6087V11.6522C10 11.2919 10.2921 11 10.6522 11H18.0435C21.2801 11 23.9043 13.6331 23.9043 16.8696V25.4449L26.4516 27.2394ZM20.6522 28.3913H21.9565C22.3165 28.3913 22.6087 28.0993 22.6087 27.7391C22.6087 27.3788 22.3165 27.0869 21.9565 27.0869H20.6522C20.2921 27.0869 20 27.3788 20 27.7391C20 28.0993 20.2921 28.3913 20.6522 28.3913ZM26.0865 11.6522C26.0865 11.2919 26.3786 11 26.7386 11C27.0987 11 27.3821 11.2919 27.3821 11.6522V12.9565C27.3821 13.3168 27.0987 13.6087 26.7386 13.6087H26.0777V14.2609C26.0777 14.6211 25.7943 14.913 25.4343 14.913C25.0742 14.913 24.7821 14.6211 24.7821 14.2609V12.9565C24.7821 12.5963 25.0742 12.3044 25.4343 12.3044H26.0865V11.6522ZM28.9654 14.643C29.2202 14.3882 29.6329 14.3882 29.8876 14.643C30.1423 14.8975 30.1423 15.3107 29.8876 15.5652L28.9654 16.4874C28.7107 16.7422 28.2979 16.7422 28.0432 16.4874L27.582 16.0263L27.121 16.4874C26.8662 16.7422 26.4535 16.7422 26.1988 16.4874C25.944 16.2329 25.944 15.8197 26.1988 15.5652L27.121 14.643C27.3757 14.3882 27.7884 14.3882 28.0432 14.643L28.5043 15.1041L28.9654 14.643ZM29.3479 18.8261H28.687V18.1739C28.687 17.8137 28.4036 17.5217 28.0436 17.5217H26.7392C26.3792 17.5217 26.087 17.8137 26.087 18.1739C26.087 18.5341 26.3792 18.8261 26.7392 18.8261H27.3914V19.4782C27.3914 19.8385 27.6835 20.1304 28.0436 20.1304H29.3479C29.708 20.1304 30.0001 19.8385 30.0001 19.4782C30.0001 19.118 29.708 18.8261 29.3479 18.8261Z"
          fill="url(#paint0_linear_655_5752)"
        />
        <defs>
          <linearGradient id="paint0_linear_655_5752" x1="11.3515" y1="30.0385" x2="30.0037" y2="10.9267" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F68F38" />
            <stop offset="1" stopColor="#FFB059" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
