import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { IFilterMySpecialPackage } from "../filterMySpecialPackage.interface";
import patientSpecialPackageService from "../patientSpecialPackageService";
import { ISpecialPackagePage } from "../patientSpecialPackage.interface";

const useGetMySpecialPackage = (filters?: IFilterMySpecialPackage, enabled = true) => {
  const [data, setData] = useState<ISpecialPackagePage>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return patientSpecialPackageService.findAll(filters);
  }, [filters]);

  const transformResponse = useCallback((response: AxiosResponse<ISpecialPackagePage>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { mySpecialPackageData: data, mySpecialPackageError: error, isLoadingMySpecialPackage: isLoading, refetchMySpecialPackage: refetch };
};

export default useGetMySpecialPackage;
