import { ConfigProvider } from "antd";
import { antdTheme } from "../themes/antdTheme";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";

export default function SettingProvider() {
  return (
    <ConfigProvider theme={antdTheme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ConfigProvider>
  );
}
