import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import themeColor from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { Input, InputProps } from "antd";
import React from "react";

interface IProps extends InputProps {
  use?: "password" | "text" | "otp";
  label?: string;
  placeholder?: string;
  extraTitle?: React.ReactNode;
  length?: number;
  autoFocus?: boolean;
  required?: boolean;
  otpOnChange?: (value: string) => void;
}
export default function InputUI(props: IProps) {
  //! state
  const { label, placeholder, extraTitle, use = "text", length = 6, autoFocus, required, otpOnChange, ...res } = props;
  //! function
  //! render
  if (use === "password") {
    return (
      <div>
        <div className="flex justify-between">
          {label && <div className="mb-1">{label}</div>}
          {extraTitle && extraTitle}
        </div>

        <Input.Password
          iconRender={(visible) =>
            visible ? <EyeOutlined style={{ color: themeColor.orange }} /> : <EyeInvisibleOutlined style={{ color: themeColor.orange }} />
          }
          className={`h-12 ${res.className}`}
          placeholder={placeholder}
          {...res}
        />
      </div>
    );
  }

  if (use === "otp") {
    return <Input.OTP length={length} variant="outlined" size="large" autoFocus={autoFocus} value={String(res.value)} onChange={otpOnChange} />;
  }

  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <div className={`mb-1 ${responseUI.text.default}`}>
            {label}
            {required && <span className="text-common-red500">{` *`}</span>}
          </div>
        )}
        {extraTitle && extraTitle}
      </div>

      <Input
        className={`h-10 lg:h-12 w-full ${res.className} ${res.disabled ? `border-none !bg-common-divider` : ""}`}
        placeholder={placeholder}
        {...res}
      />
    </div>
  );
}
