import { useEffect, useState } from "react";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, Modal, RadioChangeEvent } from "antd";
import CommonStyles from "@components/CommonStyles";
import { optionReasons } from "@base/constants";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import appointmentService from "@base/modules/appointment/appointmentService";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import useFormValidation from "@base/hooks/useFormvalidation";
interface IProps {
  open: boolean;
  appointment: IAppointment;
  onClose: () => void;
}

export default function ModalAppointmentCancelling({ open = false, appointment, onClose }: IProps) {
  //! state
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { cancelAppt } = useFormValidation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textAreaRenderKey, setTextAreaRenderKey] = useState<number>(0);
  const initialValues = {
    cancel_select: null,
    reason: "",
  };

  //! function
  useEffect(() => {
    // init
    if (open) {
      form.resetFields(["cancel_select", "reason"]);
    }
  }, [open]);

  const onChangeCancelSelect = () => {
    form.resetFields(["reason"]);
    setTextAreaRenderKey(textAreaRenderKey + 1);
  };

  const onSubmit = async (value: { cancel_select: string; reason: string }) => {
    const reasonParams = value.cancel_select !== "Tôi muốn hủy / lý do khác" ? value.cancel_select : value.reason;

    try {
      setIsLoading(true);
      await appointmentService.setCancelled(appointment.id, reasonParams);
      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Successfully") + "!" }));
      navigate(baseRoutes.userAppointmentList);
      onClose();
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t("Failed") + "!" }));
    } finally {
      setIsLoading(false);
    }
  };

  //! render
  const title = <div className="w-full flex items-center text-xl">Hủy lịch hẹn</div>;

  const footer = (
    <div className="w-full flex justify-end items-center gap-3">
      <CommonStyles.ButtonUI onClick={onClose} className="h-12 bg-common-orange100 text-common-orange500 hover:bg-common-orange300">
        Đóng
      </CommonStyles.ButtonUI>
      <CommonStyles.ButtonUI className="h-12" htmlType="submit">
        Hủy lịch hẹn
      </CommonStyles.ButtonUI>
    </div>
  );

  return (
    <Modal open={open} width={600} title={title} footer={null} maskClosable={false} onCancel={onClose} loading={isLoading}>
      <Form form={form} name="cancelReson" layout="vertical" initialValues={initialValues} onFinish={onSubmit}>
        <div className="w-full mb-6">
          <div className="text-sm leading-[22px] font-normal font-['Inter'] mb-6">
            Vui lòng chọn lý do để hủy <span className="text-common-red500">{` *`}</span>
          </div>

          <CommonStyles.FormFieldUI
            name="cancel_select"
            validateTrigger={"onChange"}
            rules={cancelAppt.cancelSelect()}
            component={<CommonStyles.RadioUI options={optionReasons} onChange={onChangeCancelSelect} />}
          />
        </div>

        <div className="w-full mb-6">
          <CommonStyles.FormFieldUI
            name="reason"
            rules={cancelAppt.reason()}
            component={
              <CommonStyles.TextAreaUI
                key={textAreaRenderKey}
                label="Ý kiến khác"
                className="!w-full !border-0 !bg-common-placeholder_inactive !rounded-lg !text-base"
                placeholder="Nhập đánh giá..."
                disabled={form.getFieldValue("cancel_select") !== "Tôi muốn hủy / lý do khác"}
                required={form.getFieldValue("cancel_select") === "Tôi muốn hủy / lý do khác"}
              />
            }
          />
        </div>

        {footer}
      </Form>
    </Modal>
  );
}
