import { DefaultOptionType } from "antd/es/select";
import { useCallback, useEffect } from "react";

const useParseOption = (data: any, labelKey: string, valueKey: string, deps = []): DefaultOptionType[] => {
  const parseOption = useCallback(() => {
    if (!data) {
      return [];
    } else {
      return data.map((item: any) => {
        return { label: item[labelKey], value: item[valueKey] };
      });
    }
  }, [data, labelKey, valueKey]);

  useEffect(() => {
    parseOption();
  }, [parseOption, ...deps]);

  return parseOption();
};

export default useParseOption;
