import React from "react";

export default function IconMic() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 15.0094C21.3333 17.9508 18.9413 20.3428 16 20.3428C13.0586 20.3428 10.6666 17.9508 10.6666 15.0094V8.34276C10.6666 5.40143 13.0586 3.00943 16 3.00943C18.9413 3.00943 21.3333 5.40143 21.3333 8.34276V15.0094ZM24 13.6761C24.7373 13.6761 25.3333 14.2734 25.3333 15.0094C25.3333 19.7028 21.8493 23.5854 17.3333 24.2361V27.0094H20.14C20.7973 27.0094 21.3333 27.5454 21.3333 28.2028V28.4828C21.3333 29.1401 20.7973 29.6761 20.14 29.6761H11.86C11.2026 29.6761 10.6666 29.1401 10.6666 28.4828V28.2028C10.6666 27.5454 11.2026 27.0094 11.86 27.0094H14.6666V24.2361C10.1506 23.5854 6.66663 19.7028 6.66663 15.0094C6.66663 14.2734 7.26263 13.6761 7.99996 13.6761C8.73729 13.6761 9.33329 14.2734 9.33329 15.0094C9.33329 18.6854 12.324 21.6761 16 21.6761C19.676 21.6761 22.6666 18.6854 22.6666 15.0094C22.6666 14.2734 23.2626 13.6761 24 13.6761Z"
        fill="#231F20"
      />
      <mask id="mask0_2302_1432" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="6" y="3" width="20" height="27">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3333 15.0094C21.3333 17.9508 18.9413 20.3428 16 20.3428C13.0586 20.3428 10.6666 17.9508 10.6666 15.0094V8.34276C10.6666 5.40143 13.0586 3.00943 16 3.00943C18.9413 3.00943 21.3333 5.40143 21.3333 8.34276V15.0094ZM24 13.6761C24.7373 13.6761 25.3333 14.2734 25.3333 15.0094C25.3333 19.7028 21.8493 23.5854 17.3333 24.2361V27.0094H20.14C20.7973 27.0094 21.3333 27.5454 21.3333 28.2028V28.4828C21.3333 29.1401 20.7973 29.6761 20.14 29.6761H11.86C11.2026 29.6761 10.6666 29.1401 10.6666 28.4828V28.2028C10.6666 27.5454 11.2026 27.0094 11.86 27.0094H14.6666V24.2361C10.1506 23.5854 6.66663 19.7028 6.66663 15.0094C6.66663 14.2734 7.26263 13.6761 7.99996 13.6761C8.73729 13.6761 9.33329 14.2734 9.33329 15.0094C9.33329 18.6854 12.324 21.6761 16 21.6761C19.676 21.6761 22.6666 18.6854 22.6666 15.0094C22.6666 14.2734 23.2626 13.6761 24 13.6761Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_2302_1432)">
        <rect y="0.342773" width="32" height="32" fill="currentColor" />
      </g>
    </svg>
  );
}
